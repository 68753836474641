import { FC } from 'react';

type EllipseType = FC<{ className?: string }>;

export const EllipseTop: EllipseType = ({ className }) => {
  return (
    <svg
      className={className}
      fill="none"
      height="376"
      viewBox="0 0 664 376"
      width="1164"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_f_6223_32171)" opacity="0.3">
        <ellipse
          cx="132.983"
          cy="-32.9089"
          fill="white"
          rx="848.917"
          ry="109.435"
          transform="rotate(-12.1233 132.983 -32.9089)"
        />
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="815.916"
          id="filter0_f_6223_32171"
          width="2060.62"
          x="-897.326"
          y="-440.867"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur_6223_32171" stdDeviation="100" />
        </filter>
      </defs>
    </svg>
  );
};

export const EllipseMid: EllipseType = ({ className }) => {
  return (
    <svg
      className={className}
      fill="none"
      height="794"
      viewBox="0 0 1620.37 824.24"
      width="2060"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_f_7162_45496)" opacity="0.3">
        <ellipse
          cx="1089.023"
          cy="222.263"
          fill="white"
          rx="1048.917"
          ry="85.8921"
          transform="rotate(-12.1233 889.023 122.263)"
        />
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="794.24"
          id="filter0_f_7162_45496"
          width="2060.37"
          x=""
          y="0"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur_7162_45496" stdDeviation="100" />
        </filter>
      </defs>
    </svg>
  );
};

export const EllipseBottom: EllipseType = ({ className }) => {
  return (
    <svg
      className={className}
      fill="none"
      height="257"
      viewBox="0 0 1112 257"
      width="1112"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_f_6223_32170)">
        <ellipse
          cx="527.628"
          cy="470.455"
          fill="#02072D"
          rx="388.089"
          ry="262.81"
          transform="rotate(-12.1233 527.628 470.455)"
        />
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="939.275"
          id="filter0_f_6223_32170"
          width="1166.96"
          x="-55.8496"
          y="0.818359"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur_6223_32170" stdDeviation="100" />
        </filter>
      </defs>
    </svg>
  );
};
