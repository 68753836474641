import { yupResolver } from '@hookform/resolvers/yup';
import { Button, LinkButton, TextField } from 'components';
import { useAuth, useSitemap } from 'hooks';
import { TFunction } from 'i18next';
import { GoogleIcon, LinkedInBlueIcon } from 'icons';
import { Shape } from 'interfaces';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { LegalAgreements } from '../LegalAgreements';

interface SignUpEmailInputStepFormValues {
  email: string;
  loading: boolean;
}

const signUpEmailInputStepSchema = (t: TFunction) =>
  yup.object().shape<Shape<SignUpEmailInputStepFormValues>>({
    email: yup
      .string()
      .email(t('forms:validationMessages.wrongEmail') ?? '')
      .required(
        t('forms:validationMessages.required', {
          fieldName: t('forms:labels.yourEmail'),
        }) ?? '',
      ),
  });

interface SignUpEmailInputStepProps {
  onEmailSubmit: (email: string) => Promise<void>;
  loading: boolean;
}

export const SignUpEmailInputStep = ({ onEmailSubmit, loading }: SignUpEmailInputStepProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const sitemap = useSitemap();

  const { signInWithGoogle, signInWithLinkedIn, authLoading } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpEmailInputStepFormValues>({
    resolver: yupResolver(signUpEmailInputStepSchema(t)),
  });

  const onSubmit = (values: SignUpEmailInputStepFormValues) => onEmailSubmit(values.email);

  return (
    <div className="flex flex-col items-stretch w-full max-w-[538px] pt-2 pb-0 lg:py-10">
      <p className="font-bold text-3xl md:text-[40px] mb-2">{t('auth:signUpForm.title')}</p>
      <p className="text-base md:text-lg text-gray-500 mb-8">{t('auth:signUpForm.subtitle')}</p>
      <Button
        className="w-full !rounded-lg"
        label={t('auth:signUpForm.signUpWithGoogle')}
        leftIcon={GoogleIcon}
        leftIconClassName="w-6 h-6 mr-4"
        onClick={() => signInWithGoogle()}
        variant="outline"
      />
      <Button
        className="w-full !rounded-lg mt-4"
        label={t('auth:signUpForm.signUpWithLinkedIn')}
        leftIcon={LinkedInBlueIcon}
        leftIconClassName="w-6 h-6 mr-4"
        onClick={() => signInWithLinkedIn()}
        variant="outline"
      />
      <div className="flex items-center space-x-4 py-6 md:py-8">
        <span className="border-t border-gray-200 w-full" />
        <span className="text-sm text-gray-400 whitespace-nowrap">
          {t('auth:continueWithEmail')}
        </span>
        <span className="border-t border-gray-200 w-full" />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-6">
          <TextField
            {...register('email')}
            error={errors.email}
            label={t('forms:labels.yourEmail')}
          />
        </div>
        <Button
          className="w-full mt-8 !rounded-lg"
          isLoading={loading || authLoading}
          label={t('auth:actions.next')}
          type="submit"
        />
      </form>
      <div className="flex mt-6 md:mt-8 pt-6 md:pt-8 border-t border-t-gray-200 justify-center text-sm md:text-base">
        <p>{t('auth:signUpForm.signInLinkText')}</p>
        <LinkButton
          className="ml-1"
          label={t('auth:OTPcode.checkOffers')}
          onClick={() => navigate(sitemap.signIn)}
        />
      </div>
      <LegalAgreements className="pt-6 lg:pt-10" textClassName="text-[14px] lg:text-[16px]" />
    </div>
  );
};
