import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { ExpertLanguage } from 'interfaces';
import { Word } from 'react-wordcloud';
import { findElementByKey } from 'utils';
import { MyProfileQuery } from '../../hooks/api/myProfile/myProfile.generated';
import { OnboardingDataQuery } from '../../hooks/api/onboardingData/onboardingData.generated';
import { getAvailabilityString } from '../../utils/getAvailabilityString';
import { ExpertProfile } from '../Profile/Profile';

type MyProfileType = MyProfileQuery['myProfile'];

const mapStringToWords = (array: string[], value: number): Word[] =>
  array.map((s) => ({
    text: s,
    value,
  }));

const getLanguageWords = (
  expertLanguages: ExpertLanguage[],
  languages: OnboardingDataQuery['languages'],
) => {
  const languageNames =
    expertLanguages?.map(
      ({ languageId }) => findElementByKey(languages, 'languageId', languageId)?.name ?? '',
    ) ?? [];

  return mapStringToWords(languageNames, 1);
};

const getTagsWords = (tagIds: string[], tags: OnboardingDataQuery['tags']) => {
  const tagNames = tagIds.map((tagId) => findElementByKey(tags, 'id', tagId)?.name ?? '');
  return mapStringToWords(tagNames, 1);
};

const getIndustriesWords = (
  t: TFunction,
  industryIds: string[],
  industries: OnboardingDataQuery['industries'],
) => {
  const industryNames = industryIds
    .map((industryId) => findElementByKey(industries, 'industryId', industryId)?.name ?? '')
    .filter(Boolean);

  return mapStringToWords(industryNames, 1);
};

const getSkillsWords = (
  skills: MyProfileType['tags'],
  superPowers: MyProfileType['tagsSuperPowers'],
) =>
  skills.map(({ weight, name, id }) => {
    const isSuperPower = Number(superPowers?.includes(id));
    return {
      value: weight + isSuperPower,
      text: name,
    };
  });

export const getWordsFromProfile = (
  t: TFunction,
  profile: ExpertProfile,
  onboardingData: OnboardingDataQuery | undefined,
): Word[] => {
  const { tags, languages, industries } = onboardingData ?? {
    languages: [],
    industries: [],
    tags: [],
  };
  const {
    firstName,
    jobPositionName,
    experienceYears,
    seniority,
    availability,
    noticeLength,
    languages: expertLanguages,
    location,
    workType,
    desiredIndustries,
    desiredFields,
    tags: expertSkills,
    desiredTechnicalSkills,
    tagsSuperPowers,
  } = profile;

  const experienceYearsString = experienceYears
    ? dayjs.duration(experienceYears, 'years').humanize()
    : '';
  const availabilityString = getAvailabilityString(t, availability, noticeLength);
  const expertSkillsIds = expertSkills.map(({ id }) => id);

  const languageWords = getLanguageWords(expertLanguages ?? [], languages);
  const desiredIndustriesWords = getIndustriesWords(t, desiredIndustries ?? [], industries);
  const desiredTechnicalSkillsWords = getTagsWords(
    desiredTechnicalSkills?.filter((skillId) => !expertSkillsIds.includes(skillId)) ?? [],
    tags,
  );
  const desiredFieldsWords = getTagsWords(
    desiredFields?.filter(
      (skillId) => !expertSkillsIds.includes(skillId) && !desiredTechnicalSkills?.includes(skillId),
    ) ?? [],
    tags,
  );
  const skillsWords = getSkillsWords(expertSkills, tagsSuperPowers);

  return [
    {
      text: firstName ?? '',
      value: 6,
    },
    {
      text: jobPositionName ?? '',
      value: 2,
    },
    {
      text: seniority ?? '',
      value: 2,
    },
    {
      text: experienceYearsString,
      value: 1,
    },
    {
      text: location ?? '',
      value: 1,
    },
    {
      text: workType ? t(`dictionaries:workTypes.${workType}`) : '',
      value: 1,
    },
    {
      text: availabilityString,
      value: 1,
    },
    ...languageWords,
    ...desiredIndustriesWords,
    ...desiredFieldsWords,
    ...desiredTechnicalSkillsWords,
    ...skillsWords,
  ];
};
