import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
const defaultOptions = {} as const;
export type CreateExpertPubnubAccessDetailsMutationVariables = Types.Exact<{
  [key: string]: never;
}>;

export type CreateExpertPubnubAccessDetailsMutation = {
  __typename?: 'Mutation';
  createExpertPubnubAccessDetails: {
    __typename?: 'ExpertPubnubAccessDetailsType';
    token: string;
    channel: string;
    uuid: string;
  };
};

export const CreateExpertPubnubAccessDetailsDocument = gql`
  mutation createExpertPubnubAccessDetails {
    createExpertPubnubAccessDetails {
      token
      channel
      uuid
    }
  }
`;
export type CreateExpertPubnubAccessDetailsMutationFn = Apollo.MutationFunction<
  CreateExpertPubnubAccessDetailsMutation,
  CreateExpertPubnubAccessDetailsMutationVariables
>;

/**
 * __useCreateExpertPubnubAccessDetailsMutation__
 *
 * To run a mutation, you first call `useCreateExpertPubnubAccessDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExpertPubnubAccessDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExpertPubnubAccessDetailsMutation, { data, loading, error }] = useCreateExpertPubnubAccessDetailsMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateExpertPubnubAccessDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateExpertPubnubAccessDetailsMutation,
    CreateExpertPubnubAccessDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateExpertPubnubAccessDetailsMutation,
    CreateExpertPubnubAccessDetailsMutationVariables
  >(CreateExpertPubnubAccessDetailsDocument, options);
}
export type CreateExpertPubnubAccessDetailsMutationHookResult = ReturnType<
  typeof useCreateExpertPubnubAccessDetailsMutation
>;
export type CreateExpertPubnubAccessDetailsMutationResult =
  Apollo.MutationResult<CreateExpertPubnubAccessDetailsMutation>;
export type CreateExpertPubnubAccessDetailsMutationOptions = Apollo.BaseMutationOptions<
  CreateExpertPubnubAccessDetailsMutation,
  CreateExpertPubnubAccessDetailsMutationVariables
>;
