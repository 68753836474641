import { IconType, PriceTagIcon } from 'icons';
import { FC } from 'react';
import { mergeClasses } from 'utils';

export type JobBoardSearchBarMenuOptionType = 'keyword';
export interface JobBoardSearchBarMenuOption {
  value: string;
  label: string;
  type: JobBoardSearchBarMenuOptionType;
}
interface JobBoardSearchBarMenuProps {
  options: JobBoardSearchBarMenuOption[];
  onSelect: (option: JobBoardSearchBarMenuOption) => void;
  visible: boolean;
  selectedOptionIndex: number;
}

const typeIcons: Record<JobBoardSearchBarMenuOptionType, IconType> = {
  keyword: PriceTagIcon,
};

export const JobBoardSearchBarMenu: FC<JobBoardSearchBarMenuProps> = ({
  options,
  onSelect,
  visible,
  selectedOptionIndex,
}) => {
  return (
    <ul
      className={mergeClasses(
        'absolute top-[calc(100%+4px)] left-0 border border-new-gray-200 rounded-lg bg-white w-full overflow-hidden transition-opacity duration-150',
        visible ? 'opacity-100 z-30' : 'opacity-0',
      )}
    >
      {options.map((opt, index) => {
        const Icon = typeIcons[opt.type];
        return (
          <li
            key={opt.value}
            className={mergeClasses(
              'p-6 cursor-pointer flex items-center space-x-2 break-all',
              selectedOptionIndex === index ? 'bg-new-gray-300' : 'hover:bg-new-gray-300',
            )}
            onClick={() => onSelect(opt)}
          >
            <Icon className="text-gray-350 w-6 h-6 min-w-[24px]" />
            <span>{opt.label}</span>
          </li>
        );
      })}
    </ul>
  );
};
