import { useReactiveVar } from '@apollo/client';
import bgImageSrc from 'assets/images/profile-bg.png';
import { IconButton, ProfilePreviewModal, SmallButton } from 'components';
import Breakpoint from 'components/Breakpoint/Breakpoint';
import { EyeIcon, MobileMenuIcon } from 'icons';
import { FC, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { profileVar } from 'reactive-vars';
import { mergeClasses } from 'utils';
import connectisWhiteLogo from '../../assets/images/connectis-white-logo.svg';
import { ExpertNotificationsQuery } from '../../hooks/api/expertNotifications/expertNotifications.generated';
import { useScrollVisibility } from '../../hooks/useScrollVisibility';
import NotificationButton from '../Buttons/NotificationButton/NotificationButton';
import NotificationModule from '../Navigation/NotificationModule/NotificationModule';
import { ProfileCompletion } from '../Profile/subcomponents';
import { ChangeAvatarModal } from './modals';
import { Avatar, VerifiedBadge } from './subcomponents';
import { MobileFloatingMenu } from './subcomponents/MobileFloatingMenu';

interface ProfileHeaderProps {
  onCompleteYourProfileClick: () => void;
  completionPercent: number;
  children?: ReactNode;
  onMobileMenuToggle?: () => void;
  notificationsData?: ExpertNotificationsQuery;
}

export const ProfileHeader: FC<ProfileHeaderProps> = ({
  onCompleteYourProfileClick,
  children,
  completionPercent,
  onMobileMenuToggle,
  notificationsData,
}) => {
  const { t } = useTranslation();
  const { ref: headerRef, isScrollPastElement } = useScrollVisibility<HTMLDivElement>();

  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [isChangeAvatarModalOpen, setIsChangeAvatarModalOpen] = useState(false);
  const [isNotificationModuleOpen, setIsNotificationModuleOpen] = useState(false);

  const { firstName, lastName, jobPositionName, location, fileUrls, isVerified } =
    useReactiveVar(profileVar) ?? {};
  const { avatarUrl } = fileUrls ?? {};

  const onNotificationIconClick = () => {
    setIsNotificationModuleOpen((prev) => !prev);
  };

  const hasUnviewedNotifications = notificationsData?.expertNotifications.notifications.some(
    (notification) => !notification.viewedAt,
  );

  return (
    <>
      <ProfilePreviewModal
        isOpen={isPreviewModalOpen}
        onClose={() => setIsPreviewModalOpen(false)}
      />
      <ChangeAvatarModal
        isOpen={isChangeAvatarModalOpen}
        onClose={() => setIsChangeAvatarModalOpen(false)}
      />
      <MobileFloatingMenu
        isVisible={isScrollPastElement}
        notificationsData={notificationsData}
        onMobileMenuToggle={onMobileMenuToggle}
      />
      <div
        ref={headerRef}
        className="bg-primary-800 bg-center bg-cover bg-blend-soft-light rounded-t-none rounded-b-2xl lg:rounded-2xl px-6 lg:px-10 pt-20 lg:pt-16 pb-6 xl:pb-24 w-screen lg:w-auto"
        style={{
          backgroundImage: `url(${bgImageSrc})`,
        }}
      >
        <Breakpoint.Medium>
          <div className="flex xl:hidden mb-8 w-full align-center relative">
            <img alt="logo" src={connectisWhiteLogo} width="120px" />
            <div className="!p-0 text-white !w-6 !min-h-[24px] !min-w-[24px] absolute right-12 -top-1 flex flex-row gap-4">
              <NotificationButton
                className="text-white !w-6 min-h-[24px] mb-2"
                hover={false}
                isNotificationModuleOpen={isNotificationModuleOpen}
                isNotificationUnread={hasUnviewedNotifications}
                onClick={onNotificationIconClick}
              />
              <IconButton
                className="!p-0 text-white !w-6 min-h-[24px] min-w-[24px]"
                hover={false}
                icon={MobileMenuIcon}
                iconClassName="!w-6 transition-all min-h-[24px] min-w-[24px] w-full z-[19]"
                onClick={onMobileMenuToggle}
              />
            </div>
          </div>
        </Breakpoint.Medium>
        <div className="flex flex-col gap-6 xl:gap-0 xl:flex-row justify-between items-center xl:items-start xl:mb-8">
          <div className="flex items-center gap-4 w-full xl:gap-6 md:w-full xl:w-auto md:justify-center">
            <Avatar
              avatarUrl={avatarUrl}
              firstName={firstName || undefined}
              onChangeClick={() => setIsChangeAvatarModalOpen(true)}
            />
            <div className="text-white">
              <div className="flex gap-1 lg:gap-4 items-center flex-wrap lg:flex-nowrap">
                <p className="text-[24px] md:text-[32px] leading-[40px] font-bold [word-break:break-word]">
                  {firstName ?? '-'} {lastName}
                </p>
                {isVerified && <VerifiedBadge />}
              </div>
              <p className="text-[15px] md:text-2xl leading-[18px] md:leading-9 font-semibold">
                {jobPositionName ?? '-'}
              </p>
              <p className="mt-3 md:mt-4 text-[13px] leading-[18px] md:font-medium">
                <span className="text-gray-200 mr-2 font-normal">
                  {t('forms:labels.location')}:
                </span>
                {location ?? '-'}
              </p>
            </div>
          </div>
          <div className="flex items-end md:items-center xl:items-end flex-col lg:self-end xl:self-center">
            <div
              className={mergeClasses(
                'flex space-x-8 w-full xl:w-auto z-10',
                completionPercent < 100 && 'mb-6 md:mb-0',
              )}
            >
              <SmallButton
                className="md:mb-5 !text-sm md:text-xs"
                icon={EyeIcon}
                label={t(`profile:profileHeader.profilePreview`)}
                onClick={() => setIsPreviewModalOpen(true)}
                variant="white"
              />
            </div>
            {completionPercent < 100 && (
              <ProfileCompletion
                onCompleteYourProfileClick={onCompleteYourProfileClick}
                percentage={completionPercent}
              />
            )}
          </div>
        </div>
        <div>{children}</div>
        <NotificationModule
          isOpen={isNotificationModuleOpen}
          notificationData={notificationsData?.expertNotifications.notifications}
          onClose={() => setIsNotificationModuleOpen(false)}
        />
      </div>
    </>
  );
};
