import { useReactiveVar } from '@apollo/client';
import { useOnClickOutside } from 'hooks';
import { AngleIcon } from 'icons';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { profileVar } from 'reactive-vars';
import { mergeClasses } from 'utils';
import { ExpertNotificationsQuery } from '../../../hooks/api/expertNotifications/expertNotifications.generated';
import NotificationButton from '../../Buttons/NotificationButton/NotificationButton';
import { DiscordCard } from '../../DiscordCard';
import { Avatar } from '../../ProfileHeader/subcomponents';
import NotificationModule from '../NotificationModule/NotificationModule';
import { UserPanelDropdown } from './subcomponents/UserPanelDropdown';

interface UserPanelProps {
  isMobileMenuOpen?: boolean;
  notificationsData?: ExpertNotificationsQuery;
}

export const UserPanel = ({ isMobileMenuOpen, notificationsData }: UserPanelProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isNotificationModuleOpen, setIsNotificationModuleOpen] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(wrapperRef, () => {
    setIsOpen(false);
    setIsNotificationModuleOpen(false);
  });

  const onNotificationIconClick = () => {
    setIsOpen(false);
    setIsNotificationModuleOpen((prev) => !prev);
  };
  const onProfileIconClick = () => {
    setIsNotificationModuleOpen(false);
    setIsOpen((prev) => !prev);
  };

  const { firstName, email, lastName, fileUrls } = useReactiveVar(profileVar) ?? {};
  const { avatarUrl } = fileUrls ?? {};

  const hasUnviewedNotifications = notificationsData?.expertNotifications.notifications.some(
    (notification) => !notification.viewedAt,
  );

  return (
    <div
      ref={wrapperRef}
      className={mergeClasses(
        'relative flex flex-row items-center pl-2',
        isOpen ? 'bg-white' : 'delay-100',
      )}
    >
      <NotificationButton
        className={mergeClasses('', isMobileMenuOpen ? 'hidden' : '')}
        isMobileMenuOpen={isMobileMenuOpen}
        isNotificationModuleOpen={isNotificationModuleOpen}
        isNotificationUnread={hasUnviewedNotifications}
        onClick={onNotificationIconClick}
      />
      <div>
        <div
          className={mergeClasses(
            'items-center space-x-4 rounded-tl-2xl rounded-tr-2xl cursor-pointer transition-all select-none space-x-4 flex',
            isMobileMenuOpen ? 'pl-2 pt-4' : 'ml-4 p-2',
          )}
          onClick={onProfileIconClick}
        >
          <Avatar
            avatarUrl={avatarUrl}
            className="!w-8 !h-8 !min-w-8 !min-h-8"
            firstName={firstName ?? '-'}
            size="sm"
          />
          <div className="space-y-0.5 font-medium">
            <p className="[word-break:break-word]">
              {firstName && lastName ? `${firstName} ${lastName}` : email}
            </p>
          </div>
          <AngleIcon
            className={mergeClasses(
              'text-gray-400 w-4 h-4 ml-10 transition-all place-self-center justify-self-end',
              isOpen ? 'rotate-180' : '',
            )}
          />
        </div>
        <div className={mergeClasses('pt-[64px]', !isMobileMenuOpen && 'hidden')}>
          <DiscordCard isMobileMenuOpen={isMobileMenuOpen} />
        </div>
      </div>
      <UserPanelDropdown isOpen={isOpen} />
      <NotificationModule
        isOpen={isNotificationModuleOpen}
        notificationData={notificationsData?.expertNotifications.notifications}
        onClose={() => setIsNotificationModuleOpen(false)}
      />
    </div>
  );
};
