import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
const defaultOptions = {} as const;
export type FinishOnboardingMutationVariables = Types.Exact<{ [key: string]: never }>;

export type FinishOnboardingMutation = {
  __typename?: 'Mutation';
  finishOnboarding: {
    __typename?: 'ExpertType';
    expertId: string;
    isOnboardingFinished: boolean;
    isCertificatesSkipped: boolean;
  };
};

export const FinishOnboardingDocument = gql`
  mutation finishOnboarding {
    finishOnboarding {
      expertId
      isOnboardingFinished
      isCertificatesSkipped
    }
  }
`;
export type FinishOnboardingMutationFn = Apollo.MutationFunction<
  FinishOnboardingMutation,
  FinishOnboardingMutationVariables
>;

/**
 * __useFinishOnboardingMutation__
 *
 * To run a mutation, you first call `useFinishOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishOnboardingMutation, { data, loading, error }] = useFinishOnboardingMutation({
 *   variables: {
 *   },
 * });
 */
export function useFinishOnboardingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FinishOnboardingMutation,
    FinishOnboardingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FinishOnboardingMutation, FinishOnboardingMutationVariables>(
    FinishOnboardingDocument,
    options,
  );
}
export type FinishOnboardingMutationHookResult = ReturnType<typeof useFinishOnboardingMutation>;
export type FinishOnboardingMutationResult = Apollo.MutationResult<FinishOnboardingMutation>;
export type FinishOnboardingMutationOptions = Apollo.BaseMutationOptions<
  FinishOnboardingMutation,
  FinishOnboardingMutationVariables
>;
