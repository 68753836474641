import { Button } from 'components';
import { t } from 'i18next';

interface ErrorScreenProps {
  onClick: () => void;
}

export const ErrorScreen = ({ onClick }: ErrorScreenProps) => {
  return (
    <div className="flex flex-col items-stretch w-full max-w-[538px] pt-2 pb-0 lg:py-10">
      <p className="font-bold text-[24px] lg:text-[40px] mb-2 lg:mb-4">
        {t('auth:errorScreen.title')}
      </p>
      <p className="text-[14px] lg:text-[16px] text-gray-500 mb-6 lg:mb-12 lg:mb-8">
        {t('auth:errorScreen.subtitle')}
      </p>
      <Button
        className="w-full !rounded-lg"
        label={t('auth:errorScreen.startAgain')}
        labelClassName="text-[14px] lg:text-[16px]"
        onClick={onClick}
      />
    </div>
  );
};
