import { JobBoardFiltersValues } from 'components';
import { PublicJobBoardOffersFiltersInputType, SeniorityLevelEnum, WorkModeEnum } from 'interfaces';
import { mapArrayIntoOptions, removeEmptyFilters } from 'utils';

function mapTruthyKeys<TValues extends string>(
  obj?: Record<TValues, boolean>,
): TValues[] | undefined {
  if (!obj) {
    return undefined;
  }
  return (Object.keys(obj) as TValues[]).filter((key) => obj[key]);
}

const mapArrayIntoBooleanObj = <TEnum extends string>(arr: Array<any>, keys: string[]) =>
  keys.reduce((acc, key) => {
    acc[key as TEnum] = arr.includes(key);
    return acc;
  }, {} as Record<TEnum, boolean>);

export const mapJobBoardFilters = (
  values: JobBoardFiltersValues,
): PublicJobBoardOffersFiltersInputType =>
  removeEmptyFilters(
    {
      ...values,
      keywords: values.keywords?.map(({ value }) => value),
      workMode: mapTruthyKeys<WorkModeEnum>(values.workMode),
      seniority: mapTruthyKeys<SeniorityLevelEnum>(values.seniority),
    },
    true,
  );

export const parseJobBoardFilters = (values: Record<string, string>): JobBoardFiltersValues => ({
  ...values,
  ...(values.rateRangeTo && { rateRangeTo: Number(values.rateRangeTo) }),
  ...(values.rateRangeFrom && { rateRangeFrom: Number(values.rateRangeFrom) }),
  ...(values.keywords && { keywords: mapArrayIntoOptions(values.keywords.split(',')) as any }),
  ...(values.workMode && {
    workMode: mapArrayIntoBooleanObj<WorkModeEnum>(
      values.workMode.split(','),
      Object.keys(WorkModeEnum),
    ),
  }),
  ...(values.seniority && {
    seniority: mapArrayIntoBooleanObj<SeniorityLevelEnum>(
      values.seniority.split(','),
      Object.keys(SeniorityLevelEnum),
    ),
  }),
});
