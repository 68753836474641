import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
const defaultOptions = {} as const;
export type MarkExpertNotificationAsViewedMutationVariables = Types.Exact<{
  notificationId: Types.Scalars['String'];
}>;

export type MarkExpertNotificationAsViewedMutation = {
  __typename?: 'Mutation';
  markExpertNotificationAsViewed: {
    __typename?: 'ExpertNotificationType';
    id: string;
    type: Types.ExpertNotificationTypeEnum;
    expertId: string;
    createdAt: string;
    viewedAt?: string | null;
    relatesTo: { __typename?: 'ExpertNotificationRelationsType'; jobOrderId?: string | null };
  };
};

export const MarkExpertNotificationAsViewedDocument = gql`
  mutation markExpertNotificationAsViewed($notificationId: String!) {
    markExpertNotificationAsViewed(notificationId: $notificationId) {
      id
      type
      expertId
      relatesTo {
        jobOrderId
      }
      createdAt
      viewedAt
    }
  }
`;
export type MarkExpertNotificationAsViewedMutationFn = Apollo.MutationFunction<
  MarkExpertNotificationAsViewedMutation,
  MarkExpertNotificationAsViewedMutationVariables
>;

/**
 * __useMarkExpertNotificationAsViewedMutation__
 *
 * To run a mutation, you first call `useMarkExpertNotificationAsViewedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkExpertNotificationAsViewedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markExpertNotificationAsViewedMutation, { data, loading, error }] = useMarkExpertNotificationAsViewedMutation({
 *   variables: {
 *      notificationId: // value for 'notificationId'
 *   },
 * });
 */
export function useMarkExpertNotificationAsViewedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkExpertNotificationAsViewedMutation,
    MarkExpertNotificationAsViewedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkExpertNotificationAsViewedMutation,
    MarkExpertNotificationAsViewedMutationVariables
  >(MarkExpertNotificationAsViewedDocument, options);
}
export type MarkExpertNotificationAsViewedMutationHookResult = ReturnType<
  typeof useMarkExpertNotificationAsViewedMutation
>;
export type MarkExpertNotificationAsViewedMutationResult =
  Apollo.MutationResult<MarkExpertNotificationAsViewedMutation>;
export type MarkExpertNotificationAsViewedMutationOptions = Apollo.BaseMutationOptions<
  MarkExpertNotificationAsViewedMutation,
  MarkExpertNotificationAsViewedMutationVariables
>;
