/* eslint-disable consistent-return */
import { useRefSafeCallback } from 'hooks';
import { useRef, useState } from 'react';

export const useAsyncCallback = <T extends (...args: any) => ReturnType<T>, E = unknown>(
  fn?: T,
  { rethrow, onError }: { rethrow?: boolean; onError?: (err: unknown) => void } = {},
): [T, { loading: boolean }] => {
  const [loading, setLoading] = useState(false);
  const queryUUID = useRef<number | null>(null);

  const asyncExec = useRefSafeCallback(async (...args) => {
    setLoading(true);
    const uuid = Date.now();

    try {
      if (!fn) throw new Error('Function is undefined');

      queryUUID.current = uuid;
      const result = await fn(...args);

      return result;
    } catch (e) {
      if (onError) onError(e);
      if (rethrow) throw e;
      return e as Awaited<E>;
    } finally {
      setLoading(false);
    }
  });

  return [asyncExec as T, { loading }];
};
