import { Paper, TextField } from 'components';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface SalaryValues {
  rateDesired: number;
}

export const Salary = () => {
  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
  } = useFormContext<SalaryValues>();

  return (
    <Paper
      className="!p-4 xl:!p-8"
      id="salary"
      subtitle={t('profile:papers.salary.subtitle')}
      subtitleClassName="!mb-4 lg:!mb-6"
      title={t('profile:papers.salary.title')}
      titleClassName="!mb-0 xl:!mb-0.5"
    >
      <TextField
        {...register('rateDesired', {
          valueAsNumber: true,
        })}
        error={errors.rateDesired}
        inputAdornment={t('profile:papers.salary.salaryInputAdornment')}
        label={t('profile:papers.salary.salaryInputLabel')}
        type="number"
        wrapperClassName="md:max-w-sm lg:max-w-xl"
      />
    </Paper>
  );
};
