import { DatePicker, SubformFooter, TextField } from 'components';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'utils';

export interface CertificateFormValues {
  name: string;
  issuer?: string;
  imageUrl: string;
  url?: string;
  identifier?: string;
  issueDate: string | undefined;
}

interface CertificateFormProps {
  initialData?: CertificateFormValues;
  onCancelClick?: () => void;
  onSaveClick?: () => void;
  className?: string;
  isDisabled?: boolean;
}

export const CertificateForm: FC<CertificateFormProps> = ({
  initialData,
  className = '',
  onSaveClick,
  onCancelClick,
  isDisabled,
}) => {
  const { t } = useTranslation();

  const {
    setValue,
    register,
    formState: { errors },
    watch,
    reset,
  } = useFormContext<CertificateFormValues>();

  const issueDateValues = watch('issueDate');

  useEffect(() => {
    if (!initialData) {
      return;
    }

    reset(initialData);
  }, [initialData, reset]);

  return (
    <div className={mergeClasses('w-full', className)}>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-4 md:gap-6 mb-4 md:mb-10">
        <TextField
          {...register('name')}
          disabled={isDisabled}
          error={errors.name}
          label={t('forms:labels.certificateName')}
          placeholder={
            t('forms:placeholders.enterField', {
              fieldName: t('forms:labels.certificateName').toLowerCase(),
            }) ?? ''
          }
          wrapperClassName="justify-end self-start"
        />
        <TextField
          {...register('issuer')}
          disabled={isDisabled}
          error={errors.issuer}
          label={t('forms:labels.issuingOrganization')}
          placeholder={
            t('forms:placeholders.enterField', {
              fieldName: t('forms:labels.issuingOrganization').toLowerCase(),
            }) ?? ''
          }
          wrapperClassName="justify-end self-start"
        />
        <DatePicker
          error={errors.issueDate}
          isDisabled={isDisabled}
          label={t('forms:labels.obtainedDate') ?? undefined}
          name="issueDate"
          onChange={(value) =>
            setValue('issueDate', value, {
              shouldValidate: true,
              shouldDirty: true,
            })
          }
          showMonthYearPicker
          value={issueDateValues}
        />
        <span />
        <TextField
          {...register('url')}
          disabled={isDisabled}
          error={errors.url}
          label={t('forms:labels.credentialUrl')}
          placeholder={
            t('forms:placeholders.enterField', {
              fieldName: t('forms:labels.link').toLowerCase(),
            }) ?? ''
          }
          wrapperClassName="justify-end"
        />
        <TextField
          {...register('identifier')}
          disabled={isDisabled}
          error={errors.identifier}
          label={t('forms:labels.credentialNumber')}
          placeholder={
            t('forms:placeholders.enterField', {
              fieldName: t('forms:labels.credentialNumber').toLowerCase(),
            }) ?? ''
          }
          wrapperClassName="justify-end"
        />
      </div>
      <SubformFooter
        className="!justify-end"
        confirmButtonClassName="w-full md:w-auto !ml-0"
        isCancelButtonVisible={false}
        onCancel={onCancelClick}
        onSave={onSaveClick}
      />
    </div>
  );
};
