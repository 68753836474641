import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateExpertNotificationConsentsMutationVariables = Types.Exact<{
  notificationConsents: Types.ExpertNotificationConsentsInputType;
}>;

export type UpdateExpertNotificationConsentsMutation = {
  __typename?: 'Mutation';
  updateExpertNotificationConsents: {
    __typename?: 'UpdateExpertNotificationConsentsResultType';
    notificationConsents: {
      __typename?: 'ExpertNotificationConsentsType';
      OffersAndProfiles: boolean;
      ApplicationAndMeetings: boolean;
      News: boolean;
    };
  };
};

export const UpdateExpertNotificationConsentsDocument = gql`
  mutation updateExpertNotificationConsents(
    $notificationConsents: ExpertNotificationConsentsInputType!
  ) {
    updateExpertNotificationConsents(notificationConsents: $notificationConsents) {
      notificationConsents {
        OffersAndProfiles
        ApplicationAndMeetings
        News
      }
    }
  }
`;
export type UpdateExpertNotificationConsentsMutationFn = Apollo.MutationFunction<
  UpdateExpertNotificationConsentsMutation,
  UpdateExpertNotificationConsentsMutationVariables
>;

/**
 * __useUpdateExpertNotificationConsentsMutation__
 *
 * To run a mutation, you first call `useUpdateExpertNotificationConsentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExpertNotificationConsentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExpertNotificationConsentsMutation, { data, loading, error }] = useUpdateExpertNotificationConsentsMutation({
 *   variables: {
 *      notificationConsents: // value for 'notificationConsents'
 *   },
 * });
 */
export function useUpdateExpertNotificationConsentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateExpertNotificationConsentsMutation,
    UpdateExpertNotificationConsentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateExpertNotificationConsentsMutation,
    UpdateExpertNotificationConsentsMutationVariables
  >(UpdateExpertNotificationConsentsDocument, options);
}
export type UpdateExpertNotificationConsentsMutationHookResult = ReturnType<
  typeof useUpdateExpertNotificationConsentsMutation
>;
export type UpdateExpertNotificationConsentsMutationResult =
  Apollo.MutationResult<UpdateExpertNotificationConsentsMutation>;
export type UpdateExpertNotificationConsentsMutationOptions = Apollo.BaseMutationOptions<
  UpdateExpertNotificationConsentsMutation,
  UpdateExpertNotificationConsentsMutationVariables
>;
