import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
const defaultOptions = {} as const;
export type UploadExpertPhotoMutationVariables = Types.Exact<{
  expertId: Types.Scalars['String'];
  params: Types.UploadExpertAvatarInputType;
}>;

export type UploadExpertPhotoMutation = { __typename?: 'Mutation'; uploadExpertPhoto: string };

export const UploadExpertPhotoDocument = gql`
  mutation uploadExpertPhoto($expertId: String!, $params: UploadExpertAvatarInputType!) {
    uploadExpertPhoto(params: $params, expertId: $expertId)
  }
`;
export type UploadExpertPhotoMutationFn = Apollo.MutationFunction<
  UploadExpertPhotoMutation,
  UploadExpertPhotoMutationVariables
>;

/**
 * __useUploadExpertPhotoMutation__
 *
 * To run a mutation, you first call `useUploadExpertPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadExpertPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadExpertPhotoMutation, { data, loading, error }] = useUploadExpertPhotoMutation({
 *   variables: {
 *      expertId: // value for 'expertId'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUploadExpertPhotoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadExpertPhotoMutation,
    UploadExpertPhotoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadExpertPhotoMutation, UploadExpertPhotoMutationVariables>(
    UploadExpertPhotoDocument,
    options,
  );
}
export type UploadExpertPhotoMutationHookResult = ReturnType<typeof useUploadExpertPhotoMutation>;
export type UploadExpertPhotoMutationResult = Apollo.MutationResult<UploadExpertPhotoMutation>;
export type UploadExpertPhotoMutationOptions = Apollo.BaseMutationOptions<
  UploadExpertPhotoMutation,
  UploadExpertPhotoMutationVariables
>;
