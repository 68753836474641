import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
const defaultOptions = {} as const;
export type ResendEmailVerificationMutationVariables = Types.Exact<{ [key: string]: never }>;

export type ResendEmailVerificationMutation = {
  __typename?: 'Mutation';
  resendEmailVerification: string;
};

export const ResendEmailVerificationDocument = gql`
  mutation resendEmailVerification {
    resendEmailVerification
  }
`;
export type ResendEmailVerificationMutationFn = Apollo.MutationFunction<
  ResendEmailVerificationMutation,
  ResendEmailVerificationMutationVariables
>;

/**
 * __useResendEmailVerificationMutation__
 *
 * To run a mutation, you first call `useResendEmailVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendEmailVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendEmailVerificationMutation, { data, loading, error }] = useResendEmailVerificationMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendEmailVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendEmailVerificationMutation,
    ResendEmailVerificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendEmailVerificationMutation,
    ResendEmailVerificationMutationVariables
  >(ResendEmailVerificationDocument, options);
}
export type ResendEmailVerificationMutationHookResult = ReturnType<
  typeof useResendEmailVerificationMutation
>;
export type ResendEmailVerificationMutationResult =
  Apollo.MutationResult<ResendEmailVerificationMutation>;
export type ResendEmailVerificationMutationOptions = Apollo.BaseMutationOptions<
  ResendEmailVerificationMutation,
  ResendEmailVerificationMutationVariables
>;
