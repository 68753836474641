type RangeValue = Date | number;

type Range<D> = {
  exclusive: {
    left: boolean;
    right: boolean;
  };
  left: D;
  right: D;
};

export type DatesRange = Range<RangeValue>;

export const DateRange = {
  contains: (ranges: DatesRange, date: Date) => {
    if (ranges.exclusive.left && ranges.exclusive.right) {
      return date > ranges.left && date < ranges.right;
    }
    if (!ranges.exclusive.left && !ranges.exclusive.right) {
      return date >= ranges.left && date <= ranges.right;
    }
    if (ranges.exclusive.left && !ranges.exclusive.right) {
      return date > ranges.left && date <= ranges.right;
    }
    if (!ranges.exclusive.left && ranges.exclusive.right) {
      return date >= ranges.left && date < ranges.right;
    }
  },
  asExclusive: {
    toToday: (): DatesRange => ({
      exclusive: {
        left: true,
        right: true,
      },
      left: Number.NEGATIVE_INFINITY,
      right: new Date(),
    }),
    fromToday: (): DatesRange => ({
      exclusive: {
        left: true,
        right: true,
      },
      left: new Date(),
      right: Number.POSITIVE_INFINITY,
    }),
    of: (startDate: RangeValue, endDate: RangeValue): DatesRange => ({
      exclusive: {
        left: true,
        right: true,
      },
      left: startDate,
      right: endDate,
    }),
  },
  asInclusive: {
    toToday: (): DatesRange => ({
      exclusive: {
        left: false,
        right: false,
      },
      left: Number.NEGATIVE_INFINITY,
      right: new Date(),
    }),
    fromToday: (): DatesRange => ({
      exclusive: {
        left: false,
        right: false,
      },
      left: new Date(),
      right: Number.POSITIVE_INFINITY,
    }),
    of: (startDate: RangeValue, endDate: RangeValue): DatesRange => ({
      exclusive: {
        left: false,
        right: false,
      },
      left: startDate,
      right: endDate,
    }),
  },
  asMixed: {
    toToday: (isLeftExclusive: boolean, isRightExclusive: boolean): DatesRange => ({
      exclusive: {
        left: isLeftExclusive,
        right: isRightExclusive,
      },
      left: Number.NEGATIVE_INFINITY,
      right: new Date(),
    }),
    fromToday: (isLeftExclusive: boolean, isRightExclusive: boolean): DatesRange => ({
      exclusive: {
        left: isLeftExclusive,
        right: isRightExclusive,
      },
      left: new Date(),
      right: Number.POSITIVE_INFINITY,
    }),
    of: (
      isLeftExclusive: boolean,
      isRightExclusive: boolean,
      startDate: RangeValue,
      endDate: RangeValue,
    ): DatesRange => ({
      exclusive: {
        left: isLeftExclusive,
        right: isRightExclusive,
      },
      left: startDate,
      right: endDate,
    }),
  },
};
