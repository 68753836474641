import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const useUrlTabs = <TabEnum>(
  initialTab: TabEnum,
  sitemapFn: (urlTabName: string) => string,
) => {
  const [currentTab, setCurrentTab] = useState<TabEnum>(initialTab);
  const { tabName } = useParams();
  const navigate = useNavigate();

  const handleTabChange = (newTab: TabEnum) => {
    setCurrentTab(newTab);
    navigate(sitemapFn(newTab === initialTab ? '' : (newTab as string)));
  };

  useEffect(() => {
    if (tabName && currentTab !== tabName) {
      setCurrentTab(tabName as TabEnum);
    }
  }, [tabName]);

  return {
    handleTabChange,
    currentTab,
  };
};
