import { FileInput, Paper } from 'components';
import { useExpertProfile } from 'hooks';
import { t } from 'i18next';
import { useFormContext } from 'react-hook-form';
import { allowedCvFileTypes } from 'utils';

interface CvInfoValues {
  expertCv: File | null;
}
export const CvInfo = () => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<CvInfoValues>();
  const { profile } = useExpertProfile();
  return (
    <Paper
      className="!p-4 xl:!p-8"
      id="cvInfo"
      title={t('profile:papers.cvInfo.title') ?? ''}
      titleClassName="!mb-4 xl:!mb-6"
    >
      <div>
        <FileInput
          {...register('expertCv')}
          accept={allowedCvFileTypes}
          error={errors.expertCv}
          initialFile={profile?.cv ?? null}
          onChange={(cvFile) =>
            setValue('expertCv', cvFile, {
              shouldValidate: true,
              shouldDirty: true,
            })
          }
        />
        <p className="text-sm text-gray-500 leading-[18px] pt-1">
          {t('onboarding:nameStep.allowedFormats')}
        </p>
      </div>
    </Paper>
  );
};
