const getValue = <TElement extends Record<any, any>, TGetKey>(el: TElement, getKey?: TGetKey) => {
  if (!getKey) {
    return el;
  }
  if (typeof getKey === 'function') return getKey(el);
  return el[getKey];
};

export const mapArrayIntoOptions = <A extends any[], K extends keyof A[number]>(
  array: A,
  labelKey?: K | ((arrayElement: A[number]) => any),
  valueKey?: K | ((arrayElement: A[number]) => any),
) =>
  array.map((el) => ({
    label: getValue(el, labelKey),
    value: getValue(el, valueKey),
  }));
