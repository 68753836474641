import { JsonObject } from 'type-fest';

export const profile: JsonObject = {
  profileHeader: {
    profilePreview: 'Podgląd profilu',
    editProfile: 'Edytuj profil',
    profileVerified: 'Profil zweryfikowany',
    profile: 'Profil',
    tabs: {
      myData: 'Moje dane',
      preferences: 'Preferencje',
      experience: 'Doświadczenie',
    },
  },
  tabs: {
    profile: 'Profil',
    preferences: 'Preferencje',
    experience: 'Doświadczenie',
  },
  profileCompletion: {
    title: 'Twoj profil jest prawie gotowy',
    subtitle: 'Uzupełnij dane - pomoże nam to lepiej dobrać dla Ciebie oferty',
    completeYourProfile: 'Uzupełnij profil',
  },
  avatarChangeModal: {
    title: 'Zmień avatar',
    addPhoto: 'Dodaj zdjęcie',
    fillInProfile: 'Uzupełnij profil',
    toGenerateID: 'aby wygenerować C_hub ID',
    avatarRequirements:
      'Maksymalna waga zdjęcia: 2 MB, rozdzielczość maksymalna: 500 px x 500 px, dozwolone formaty: jpg, png.',
  },
  expertVerifiedInfoModal: {
    title: 'Przyznaliśmy Ci odznakę',
    titlePrimary: '“Profil zweryfikowany”',
    description:
      'Teraz Twoje aplikacje będą jeszcze szybsze – umówimy Cię od razu z firmą rekrutującą, bez konieczności wstępnych rozmów.',
  },
  papers: {
    basicInfo: {
      title: 'Dane podstawowe',
    },
    cvInfo: {
      title: 'Twoje CV',
    },
    skills: {
      title: 'Twoje umiejętności',
      subtitle: 'Oznacz gwiazdką Twoje TOP umiejętności',
      technologiesInputLabel: 'Dodaj technologie',
      technologiesInputPlaceholder: 'np. React, Vue.js',
    },
    languages: {
      title: 'Języki obce',
      languagesInputLabel: 'Dodaj język',
      languagesInputPlaceholder: 'np. angielski',
    },
    chubId: {
      title: 'Twój C_hub ID',
      generate: 'Wygeneruj',
      generateAgain: 'Wygeneruj ponownie',
      downloadAvatar: 'Pobierz avatar',
      setAsAvatar: 'Ustaw jako avatar',
      toast: {
        loading: 'Zapisywanie C_hub ID',
        success: 'Twój C_hub ID został zapisany pomyślnie',
      },
      completionList: {
        title: 'Uzupełnij profil aby wygenerować',
        basicInfo: 'Dane podstawowe',
        preferences: 'Preferencje',
        experience: 'Doświadczenie',
      },
    },
    location: {
      title: 'Lokalizacja',
      locationInputLabel: 'Twoja lokalizacja',
    },
    workType: {
      title: 'Preferowany tryb pracy',
    },
    desiredOfficeLocations: {
      title: 'Jaką lokalizację biura preferujesz?',
      subtitle: 'Możesz wskazać więcej niż 1 lokalizację',
    },
    salary: {
      title: 'Twoja oczekiwana stawka godzinowa ',
      subtitle: 'Przy kontrakcie b2b',
      salaryInputLabel: 'Podaj stawkę',
      salaryInputAdornment: 'PLN/h',
    },
    availability: {
      title: 'Twoja dostępność',
      subtitle: 'Od kiedy możesz zaczynać nową współpracę',
      availabilitySelectLabel: 'Wybierz okres',
    },
    desiredTechnologies: {
      title: 'Preferowane technologie',
      subtitle: 'Na pracy z jakimi technologiami najbardziej Ci zależy?',
      desiredTechnologiesInputLabel: 'Szukaj technologii',
    },
    desiredIndustries: {
      title: 'Preferowane branże',
      subtitle: 'Wybierz wszystkie jakie Cię interesują pod kątem nowej współpracy',
      desiredIndustriesInputLabel: 'Szukaj branży',
      desiredIndustriesInputPlaceholder: 'np. FinTech',
    },
    desiredFields: {
      title: 'Preferowane dziedziny',
      subtitle: 'Wybierz wszystkie jakie Cię interesują pod kątem nowej współpracy',
      desiredFieldsInputLabel: 'Szukaj dziedziny',
      desiredFieldsInputPlaceholder: 'np. Cloud',
    },
    workHistory: {
      title: 'Twoje doświadczenie',
      subtitle: 'Jakie były Twoje wcześniejsze stanowiska?',
      addNewButton: 'Dodaj kolejne doświadczene',
      noDataPlaceholder: 'Nie dodano jeszcze żadnego stanowiska',
      removeModal: {
        text: 'Czy na pewno chcesz usunąć to doświadczenie?',
      },
    },
    certificates: {
      title: 'Kursy i certyfikaty',
      subtitle: 'Dodaj zdobyte do tej pory kursy i certyfikaty',
      addNewButton: 'Dodaj kolejny kurs lub certyfikat',
      noDataPlaceholder: 'Nie dodano jeszcze żadnego kurs ani certyfikatu',
      removeModal: {
        text: 'Czy na pewno chcesz usunąć ten kurs lub certyfikat?',
      },
      noCertificates: 'Brak kursów i certyfikatów',
    },
    contactData: {
      title: 'Dane kontaktowe i profile społecznościowe',
    },
  },
};
