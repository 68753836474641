import { RoundedButton } from 'components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ExternalOfferThankYouProps {
  onCheckOffers: () => void;
}

export const ExternalOfferThankYou: FC<ExternalOfferThankYouProps> = ({ onCheckOffers }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'jobBoard:offerThankYou',
  });

  return (
    <div className="rounded-3xl bg-gradient-blue p-6 space-y-6">
      <p className="text-xl lg:text-2xl text-white font-medium text-center">{t('heading')}</p>
      <p className="text-gray-150">{t('description')}</p>
      <RoundedButton label={t('checkOffers')} onClick={onCheckOffers} type="button" />
    </div>
  );
};
