import { ProgressBar } from 'components';
import { CheckIcon, CrossIcon } from 'icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { basicInfoFields, experienceFields, preferencesFields } from '../../../utils';

interface ProfileCompletionListProps {
  completionPercent: number;
  unfilledFields: string[];
}

export const isAllFieldsFilled = (allFields: string[], unfilledFields: string[]) =>
  allFields.every((field) => !unfilledFields.includes(field));

const listSchema = [
  { name: 'basicInfo', fields: basicInfoFields },
  { name: 'preferences', fields: preferencesFields },
  { name: 'experience', fields: experienceFields },
];

const ListItem: FC<{ name: string; isChecked: boolean }> = ({ isChecked, name }) => {
  const { t } = useTranslation();
  return (
    <li className="flex items-center space-x-2">
      {isChecked ? (
        <CheckIcon className="w-4 h-4 text-primary-500" />
      ) : (
        <CrossIcon className="w-4 h-4 text-red-500" />
      )}
      <span className="text-gray-500">{t(`profile:papers.chubId.completionList.${name}`)}</span>
    </li>
  );
};

export const ProfileCompletionList: FC<ProfileCompletionListProps> = ({
  completionPercent,
  unfilledFields,
}) => {
  const { t } = useTranslation();
  return (
    <div className="mb-8">
      <p className="font-medium">{t('profile:papers.chubId.completionList.title')}</p>
      <ProgressBar className="my-2" value={completionPercent} />
      <ul className="space-y-2">
        {listSchema.map(({ fields, name }) => {
          const isChecked = isAllFieldsFilled(fields, unfilledFields);
          return (
            <ListItem key={`ProfileCompletionList_${name}`} isChecked={isChecked} name={name} />
          );
        })}
      </ul>
    </div>
  );
};
