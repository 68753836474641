import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
const defaultOptions = {} as const;
export type CurrentExpertIdentityQueryVariables = Types.Exact<{ [key: string]: never }>;

export type CurrentExpertIdentityQuery = {
  __typename?: 'Query';
  currentExpertIdentity: {
    __typename?: 'ExpertIdentityType';
    source: Types.ExpertIdentitySourceEnum;
    externalId: string;
    verifiedAt?: string | null;
    createdAt: string;
  };
};

export const CurrentExpertIdentityDocument = gql`
  query currentExpertIdentity {
    currentExpertIdentity {
      source
      externalId
      verifiedAt
      createdAt
    }
  }
`;

/**
 * __useCurrentExpertIdentityQuery__
 *
 * To run a query within a React component, call `useCurrentExpertIdentityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentExpertIdentityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentExpertIdentityQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentExpertIdentityQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentExpertIdentityQuery,
    CurrentExpertIdentityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentExpertIdentityQuery, CurrentExpertIdentityQueryVariables>(
    CurrentExpertIdentityDocument,
    options,
  );
}
export function useCurrentExpertIdentityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentExpertIdentityQuery,
    CurrentExpertIdentityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentExpertIdentityQuery, CurrentExpertIdentityQueryVariables>(
    CurrentExpertIdentityDocument,
    options,
  );
}
export type CurrentExpertIdentityQueryHookResult = ReturnType<typeof useCurrentExpertIdentityQuery>;
export type CurrentExpertIdentityLazyQueryHookResult = ReturnType<
  typeof useCurrentExpertIdentityLazyQuery
>;
export type CurrentExpertIdentityQueryResult = Apollo.QueryResult<
  CurrentExpertIdentityQuery,
  CurrentExpertIdentityQueryVariables
>;
export function refetchCurrentExpertIdentityQuery(variables?: CurrentExpertIdentityQueryVariables) {
  return { query: CurrentExpertIdentityDocument, variables: variables };
}
