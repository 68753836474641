import { IconType } from 'icons';
import { FC, MouseEvent } from 'react';
import { mergeClasses } from 'utils';

interface IconButtonProps {
  className?: string;
  icon: IconType;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  onMouseDown?: (e: MouseEvent<HTMLButtonElement>) => void;
  size?: 'sm' | 'md';
  iconClassName?: string;
  hover?: boolean;
  ariaLabel?: string | null;
}

export const IconButton: FC<IconButtonProps> = ({
  icon: Icon,
  className = '',
  onClick,
  size = 'md',
  onMouseDown,
  iconClassName = 'w-4 h-4',
  hover = true,
  ariaLabel,
}) => {
  return (
    <button
      aria-label={ariaLabel ?? undefined}
      className={mergeClasses(
        'transition-all rounded-lg flex justify-center items-center',
        size === 'md' ? 'p-2' : 'p-1',
        className,
        hover ? 'hover:bg-gray-200 active:bg-gray-100 focus:outline-none' : '',
      )}
      onClick={onClick}
      onMouseDown={onMouseDown}
      style={{
        WebkitTapHighlightColor: 'transparent',
      }}
      type="button"
    >
      <Icon className={mergeClasses(iconClassName)} />
    </button>
  );
};
