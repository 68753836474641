import {
  Checkbox,
  DatePicker,
  ItemBadge,
  RichTextEditor,
  SearchInput,
  Select,
  SubformFooter,
  TextField,
} from 'components';
import { FC, useEffect } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mapArrayIntoOptions } from 'utils';
import { useOnboardingDataQuery } from '../../hooks/api/onboardingData/onboardingData.generated';
import { DateRange } from '../Inputs/DatePicker/DateRange';

const RICH_TEXT_EDITOR_LIMIT = 2000;

export interface WorkExperienceFormValues {
  jobPositionName: string;
  companyName?: string;
  isCompanyNameSkipped?: boolean;
  industry?: string;
  dateStart: string;
  dateEnd?: string | null;
  isCurrentJob: boolean;
  tagsIds: string[];
  description: string;
}

interface WorkExperienceFormProps {
  initialData?: WorkExperienceFormValues;
  onCancelClick?: () => void;
  onSaveClick?: () => void;
  className?: string;
}

export const WorkHistoryForm: FC<WorkExperienceFormProps> = ({
  initialData,
  className,
  onCancelClick,
  onSaveClick,
}) => {
  const { t } = useTranslation();

  const { data } = useOnboardingDataQuery();
  const { industries, tags } = data ?? { industries: [], tags: [] };

  const {
    setValue,
    register,
    formState: { errors },
    watch,
    reset,
  } = useFormContext<WorkExperienceFormValues>();

  const dateStartValue = watch('dateStart');
  const dateEndValue = watch('dateEnd');
  const isCurrentJobValue = watch('isCurrentJob');
  const isCompanyNameSkippedValue = watch('isCompanyNameSkipped');
  const tagsIdsValue = watch('tagsIds') ?? [];

  const industryOptions = mapArrayIntoOptions(industries, 'name', 'industryId');

  const skillsOptions = tags
    .filter(({ id }) => !tagsIdsValue?.includes(id))
    .map(({ id, name }) => ({
      label: name,
      value: id,
    }));

  const handleFieldChange = (name: keyof WorkExperienceFormValues, value: string | boolean) =>
    setValue(name, value, { shouldValidate: true, shouldDirty: true });

  const handleSkipName = () => {
    handleFieldChange('companyName', '');
    handleFieldChange('isCompanyNameSkipped', !isCompanyNameSkippedValue);
  };

  const handleTagAdd = (tagId: string) =>
    setValue('tagsIds', [...tagsIdsValue, tagId], {
      shouldValidate: true,
      shouldDirty: true,
    });

  const handleTagRemove = (tagId: string) =>
    setValue(
      'tagsIds',
      tagsIdsValue.filter((id) => id !== tagId),
      {
        shouldValidate: true,
        shouldDirty: true,
      },
    );

  useEffect(() => {
    if (!initialData) {
      return;
    }
    reset(initialData);
  }, [initialData, reset]);

  return (
    <div className={className}>
      <div className="grid grid-cols-1 gap-4 mb-4 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 md:gap-6 md:mb-10">
        <TextField
          {...register('jobPositionName')}
          error={errors.jobPositionName}
          label={t('forms:labels.yourRole') ?? ''}
        />
        <div>
          <TextField
            {...register('companyName')}
            disabled={isCompanyNameSkippedValue}
            error={errors.companyName}
            label={t('forms:labels.company') ?? ''}
          />
          <Checkbox
            className="mt-4"
            {...register('isCompanyNameSkipped')}
            label={<p>{t('onboarding:experienceStep.hideCompanyName')}</p>}
            onChange={() => handleSkipName()}
          />
          {isCompanyNameSkippedValue && (
            <Select
              className="mt-4"
              error={errors.industry as FieldError}
              label={t('forms:labels.industry') ?? ''}
              onChange={(value) =>
                setValue('industry', value as string, { shouldValidate: true, shouldDirty: true })
              }
              options={industryOptions}
              placeholder={t('forms:placeholders.select') ?? undefined}
            />
          )}
        </div>
        <DatePicker
          allowedRanges={
            dateEndValue
              ? DateRange.asExclusive.of(Number.NEGATIVE_INFINITY, new Date(dateEndValue))
              : DateRange.asExclusive.toToday()
          }
          error={errors.dateStart}
          label={t('forms:labels.dateStart') ?? ''}
          name="dateStart"
          onChange={(value) => handleFieldChange('dateStart', value)}
          showMonthYearPicker
          value={dateStartValue}
        />
        <div>
          <DatePicker
            allowedRanges={DateRange.asInclusive.of(new Date(dateStartValue), new Date())}
            error={errors.dateEnd}
            isDisabled={isCurrentJobValue}
            label={t('forms:labels.dateEnd') ?? ''}
            name="dateEnd"
            onChange={(value) => handleFieldChange('dateEnd', value)}
            showMonthYearPicker
            value={dateEndValue ?? ''}
          />
          <Checkbox
            className="mt-4"
            {...register('isCurrentJob')}
            label={<p>{t('common:currentWork')}</p>}
          />
        </div>
      </div>
      <div>
        <SearchInput
          error={errors.tagsIds as FieldError}
          label={t('forms:labels.usedTechnologies') ?? ''}
          onChange={(value) => handleTagAdd(value as string)}
          options={skillsOptions}
          placeholder={t('forms:placeholders.select') ?? undefined}
        />
        <div className="flex flex-wrap gap-2 mt-4">
          {tagsIdsValue?.map((tagId) => (
            <ItemBadge
              key={`usedTechnologies${tagId}`}
              label={tags.find(({ id }) => id === tagId)?.name ?? tagId}
              onRemove={() => handleTagRemove(tagId)}
            />
          ))}
        </div>
      </div>
      <RichTextEditor
        className="min-h-[120px]"
        error={errors.description}
        initialValue={initialData?.description}
        label={t('forms:labels.description') ?? ''}
        limit={RICH_TEXT_EDITOR_LIMIT}
        onChange={(value) =>
          setValue('description', value, { shouldValidate: true, shouldDirty: true })
        }
        placeholder={
          t('forms:placeholders.addField', {
            fieldName: t('forms:labels.description').toLowerCase(),
          }) ?? ''
        }
        wrapperClassName="mt-4 md:mt-10"
      />
      <SubformFooter onCancel={onCancelClick} onSave={onSaveClick} />
    </div>
  );
};
