import chubIdBackground from 'assets/images/chubid-background.svg';
import { InfoBox, LinkButton } from 'components';
import { useSitemap } from 'hooks';
import { t } from 'i18next';
import { InfoIcon } from 'icons';
import { ExpertAvatarEnum } from 'interfaces';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { mergeClasses } from 'utils';
import { ProfileTabEnum } from '../../../../Profile/Profile';
import { SelectItemProps } from './PhotoSelectItem';
import { SelectItemWrapper } from './SelectItemWrapper';

interface AvatarInfoBoxProps {
  text: string;
  onClick: () => void;
}
const AvatarInfoBox = ({ text, onClick }: AvatarInfoBoxProps) => {
  return (
    <p className="inline lg:flex">
      <LinkButton
        className="mr-1 md:n"
        icon={InfoIcon}
        iconClassName="hidden lg:block"
        label={text}
        onClick={onClick}
      />
      {t('profile:avatarChangeModal.toGenerateID')}
    </p>
  );
};

export const CHubIdSelectItem: FC<Omit<SelectItemProps, 'children'>> = ({
  isActive,
  onSelect,
  imageUrl,
  setAvatarEnum,
}) => {
  const navigate = useNavigate();
  const sitemap = useSitemap();
  const handleSelect = () => {
    onSelect(imageUrl);
    setAvatarEnum(ExpertAvatarEnum.WordCloud);
  };

  return (
    <SelectItemWrapper isActive={isActive} onClick={handleSelect}>
      <div
        className={mergeClasses(
          'rounded-full w-[100px] md:w-[160px] h-[100px] md:h-[160px] flex justify-center items-center transition-all bg-no-repeat bg-center bg-cover bg-[size:200%]',
          imageUrl ? 'bg-primary-700' : 'bg-gray-200',
        )}
        style={{
          backgroundImage: `url(${imageUrl || chubIdBackground})`,
        }}
      />
      <p className="font-bold text-[18px] leading-normal mt-6 mb-[6px] md:mb-2">
        {t('profile:papers.chubId.title')}
      </p>
      {!imageUrl && (
        <InfoBox
          className="w-full bg-primary-500 bg-opacity-5 text-primary-500"
          text={
            <AvatarInfoBox
              onClick={() => navigate(sitemap.profile(ProfileTabEnum.MyData))}
              text={t('profile:avatarChangeModal.fillInProfile')}
            />
          }
        />
      )}
    </SelectItemWrapper>
  );
};
