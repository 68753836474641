import { JsonObject } from 'type-fest';

export const common: JsonObject = {
  return: 'Wróć',
  currentWork: 'Pracuję tu obecnie',
  currently: 'obecnie',
  welcome: 'Witaj',
  years: 'lat',
  hour: 'godzinę',
  hourShort: 'h',
  showMore: 'Pokaż więcej',
  showLess: 'Pokaż mniej',
  help: 'Pomoc',
  actions: {
    next: 'Dalej',
    back: 'Cofnij',
    edit: 'Edytuj',
    change: 'Zmień',
    remove: 'Usuń',
    close: 'Zamknij',
    save: 'Zapisz',
    saveChanges: 'Zapisz zmiany',
    confirm: 'Potwierdź',
    cancel: 'Anuluj',
    skip: 'Pomiń',
    addOther: 'Dodaj inną',
    logout: 'Wyloguj się',
    join: 'Dołącz',
    joinDiscord: 'Dołącz do naszej społeczności na Discordzie i zostaw swoją opinie o C_hub',
    ok: 'Ok',
  },
};
