import { HtmlRenderer, SmallButton, TagBadge } from 'components';
import dayjs from 'dayjs';
import { EditIcon, RemoveIcon } from 'icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { findElementByKey, mergeClasses } from 'utils';
import { useOnboardingDataQuery } from '../../hooks/api/onboardingData/onboardingData.generated';
import { WorkExperienceFormValues } from './WorkHistoryForm';

interface WorkHistoryItemProps extends WorkExperienceFormValues {
  onEdit: () => void;
  onRemove: () => void;
  className?: string;
}

export const WorkHistoryItem: FC<WorkHistoryItemProps> = ({
  companyName,
  dateEnd,
  dateStart,
  jobPositionName,
  tagsIds = [],
  isCurrentJob,
  onEdit,
  onRemove,
  description,
  industry,
  className = '',
}) => {
  const { t } = useTranslation();

  const { data } = useOnboardingDataQuery();
  const { industries, tags } = data ?? { industries: [], tags: [] };

  const industryName = industry ? findElementByKey(industries, 'industryId', industry)?.name : '-';

  return (
    <div
      className={mergeClasses(
        'border border-gray-200 rounded-lg p-4 flex justify-between items-center md:items-start flex-col md:flex-row lg:flex-col xl:flex-row gap-4 md:gap-0',
        className,
      )}
    >
      <div>
        <p className="font-semibold text-[17px] mb-2">{jobPositionName}</p>
        <div className="flex items-center mb-4 space-x-2 text-sm">
          {companyName && (
            <>
              <p>{companyName}</p>
              <span className="bg-gray-400 w-[2px] h-[2px] rounded-full" />
            </>
          )}
          {industry && (
            <>
              <p>{industryName}</p>
              <span className="bg-gray-400 w-[2px] h-[2px] rounded-full" />
            </>
          )}
          <p>
            {dayjs(dateStart).format('M/YYYY')} -{' '}
            {isCurrentJob ? t('common:currently') : dayjs(dateEnd).format('M/YYYY')}
          </p>
        </div>
        <div className="flex gap-2 items-center md:items-stretch whitespace-nowrap flex-wrap md:whitespace-normal border-b-gray-50 border-b-2 mb-4 pb-4 md:pb-0 md:border-none">
          {tagsIds.map((tagId) => {
            return (
              <TagBadge key={tagId} label={tags.find(({ id }) => id === tagId)?.name ?? tagId} />
            );
          })}
        </div>
        {description && <HtmlRenderer className="text-xs mt-4" html={description} />}
      </div>
      <div className="space-x-4 flex items-center md:mt-4 lg:self-center xl:self-start">
        <SmallButton icon={EditIcon} label={t('common:actions.edit')} onClick={onEdit} />
        <SmallButton icon={RemoveIcon} label={t('common:actions.remove')} onClick={onRemove} />
      </div>
    </div>
  );
};
