import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PublicJobBoardOffersQuery } from '../../../../hooks/api/publicJobBoardOffers/publicJobBoardOffers.generated';
import { JobBoardOffer } from './subcomponents';

interface JobBoardOffersListProps {
  offers: PublicJobBoardOffersQuery['publicJobBoardOffers'];
}

export const JobBoardOffersList: FC<JobBoardOffersListProps> = ({ offers }) => {
  const { t } = useTranslation();

  return (
    <ul className="w-full space-y-6">
      {offers.length ? (
        offers.map((offer) => <JobBoardOffer key={offer.id} {...offer} />)
      ) : (
        <p className="text-center text-xl py-10">{t('jobBoard:noItemsPlaceholder')}</p>
      )}
    </ul>
  );
};
