import { EditIcon } from 'icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface EditAvatarButtonProps {
  onClick?: () => void;
}

export const EditAvatarButton: FC<EditAvatarButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <button
      className="opacity-0 hover:opacity-80 group-hover:opacity-80 transition-all w-full h-full absolute flex flex-col justify-center items-center bg-primary-900 cursor-pointer"
      onClick={onClick}
    >
      <EditIcon className="w-4 h-4 text-white mb-2" />
      <p className="text-white font-medium text-sm">{t('common:actions.change')}</p>
    </button>
  );
};
