import { useLocation, useNavigate } from 'react-router-dom';
import { useSitemap } from './useSitemap';

export const useLogoNavigation = () => {
  const navigate = useNavigate();
  const sitemap = useSitemap();
  const location = useLocation();

  const isPasswordChange =
    location.pathname.includes('/password-reset') || location.pathname.includes('/new-password');

  const handleLogoClick = () => {
    if (location.pathname === sitemap.main || location.pathname === sitemap.homepageAfterLogin) {
      return;
    }

    navigate(sitemap.main);
  };

  const isMainPage = location.pathname === sitemap.main;

  return { handleLogoClick, isPasswordChange, isMainPage };
};
