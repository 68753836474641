import { Button, InfoBox, SmallButton } from 'components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StepComponentProps } from '../stepsConfig';
import { CheckedListItem } from '../subcomponents';

export const ExperienceTitleStep: FC<StepComponentProps> = ({ onNextClick, onSkipClick }) => {
  const { t } = useTranslation();

  return (
    <div>
      <ul className="mt-6 md:mt-10 space-y-4">
        <CheckedListItem label={t('onboarding:experienceTitleStep.stepContent.yourExperience')} />
        <CheckedListItem label={t('onboarding:experienceTitleStep.stepContent.technologies')} />
        <CheckedListItem label={t('onboarding:experienceTitleStep.stepContent.certificates')} />
      </ul>
      <InfoBox className="mt-6 md:mt-10" text={t('onboarding:experienceTitleStep.optionalInfo')} />
      <div className="flex my-12 md:mt-16 md:mb-0 flex-col md:flex-row lg:flex-col xl:flex-row gap-6 md:gap-10 lg:gap-6 xl:gap-10 md:justify-center xl:justify-start items-center md:items-stretch lg:items-center">
        <SmallButton
          className="ml-0 md:whitespace-nowrap"
          label={t('onboarding:experienceTitleStep.skipBtnText')}
          onClick={onSkipClick}
          variant="black"
        />
        <Button
          className="md:whitespace-nowrap"
          label={t('onboarding:experienceTitleStep.continueBtnText')}
          onClick={onNextClick}
        />
      </div>
    </div>
  );
};
