import { useTranslation } from 'react-i18next';

export const WeekdaysHeader = () => {
  const { t } = useTranslation();

  const weekdays = [
    t('datePicker:weekDaysShort.monday'),
    t('datePicker:weekDaysShort.tuesday'),
    t('datePicker:weekDaysShort.wednesday'),
    t('datePicker:weekDaysShort.thursday'),
    t('datePicker:weekDaysShort.friday'),
    t('datePicker:weekDaysShort.saturday'),
    t('datePicker:weekDaysShort.sunday'),
  ];

  return (
    <ul className="grid grid-cols-7 gap-x-2.5">
      {weekdays.map((name) => (
        <li
          key={`weekdayName_${name}`}
          className="flex justify-center items-center py-2.5 text-xs md:text-base"
        >
          {name}
        </li>
      ))}
    </ul>
  );
};
