import { BreakpointType } from 'hooks/useBreakpoint';
import { BreakpointProps } from '../Breakpoint';

const withProps =
  (size: BreakpointType) => (WrappedComponent: React.ComponentType<BreakpointProps>) => {
    const WithPropsComponent = (props: Omit<BreakpointProps, 'size'>) => (
      <WrappedComponent {...props} size={size} />
    );

    const displayName = `withProps(${WrappedComponent.displayName || WrappedComponent.name})`;
    WithPropsComponent.displayName = displayName;

    return WithPropsComponent;
  };

export default withProps;
