import { useReactiveVar } from '@apollo/client';
import { Paper, SmallButton, TextField } from 'components';
import { useAuth, useModalState } from 'hooks';
import { ExpertIdentitySourceEnum } from 'interfaces';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { profileVar } from 'reactive-vars';
import { useCurrentExpertIdentityQuery } from '../../../../hooks/api/currentExpertIdentity/currentExpertIdentity.generated';
import { ChangePasswordForm } from './ChangePasswordForm';
import { RemoveAccountModal } from './modals';
import { SetPasswordForm } from './SetPasswordForm';

export interface PasswordChangeValues {
  newPassword: string;
  currentPassword: string;
}

export const AccountTab = () => {
  const { t } = useTranslation();
  const [currentAuthMethod, setCurrentAuthMethod] = useState<'OTP' | 'PASSWORD' | null>(null);

  const { getCognitoSignInMethod } = useAuth();

  useEffect(() => {
    getCognitoSignInMethod().then(setCurrentAuthMethod);
  }, [getCognitoSignInMethod, setCurrentAuthMethod]);

  const { email } = useReactiveVar(profileVar) ?? {};

  const {
    modalState: removeAccountModal,
    closeModal: closeRemoveAccountModal,
    openModal: openRemoveAccountModal,
  } = useModalState<null>();

  const { data: currentExpertIdentityData } = useCurrentExpertIdentityQuery();

  return (
    <>
      <RemoveAccountModal
        expertIdentity={currentExpertIdentityData?.currentExpertIdentity}
        isOpen={removeAccountModal.isOpen}
        onClose={closeRemoveAccountModal}
      />

      <Paper className="relative">
        <TextField
          disabled
          label={t('forms:labels.email')}
          name="email"
          value={email ?? '-'}
          wrapperClassName="pb-2 xl:w-96"
        />

        <SmallButton
          className="hidden lg:block absolute top-2 xl:top-8 right-8"
          label={t('settings:actions.deleteAccount')}
          onClick={() => openRemoveAccountModal(null)}
          variant="danger"
        />

        {currentExpertIdentityData &&
          currentExpertIdentityData.currentExpertIdentity.source ===
            ExpertIdentitySourceEnum.Cognito && (
            <>
              {currentAuthMethod === 'PASSWORD' && <ChangePasswordForm />}
              {currentAuthMethod === 'OTP' && (
                <SetPasswordForm onComplete={() => setCurrentAuthMethod('PASSWORD')} />
              )}
            </>
          )}

        <SmallButton
          className="pb-2 pt-6 lg:hidden"
          label={t('settings:actions.deleteAccount')}
          onClick={() => openRemoveAccountModal(null)}
          variant="danger"
        />
      </Paper>
    </>
  );
};
