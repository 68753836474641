import { ArrowIcon } from 'icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectisWebsiteMenuItem } from '../../../../configs';

export const ConnectisWebsiteHeaderSidePanel: FC<ConnectisWebsiteMenuItem> = ({
  name,
  url,
  thumbnail,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: `connectisWebsite:portfolioProject`,
  });

  return (
    <div className="h-full relative">
      <div className="bg-black/10 absolute top-0 left-0 h-screen w-screen z-0" />
      <a className="p-8 z-10 block relative" href={url}>
        {thumbnail && (
          <div
            className="h-32 w-full rounded-xl mb-3.5 overflow-hidden bg-center bg-cover"
            style={{
              backgroundImage: `url(${thumbnail})`,
            }}
          />
        )}
        <p className="text-xl font-medium mb-2">{t(`${name}.title`)}</p>
        <p className="text-xs">{t(`${name}.description`)}</p>
        {url && (
          <a className="flex items-center mt-3.5 space-x-1" href={url}>
            {t('seeProject')} <ArrowIcon className="w-4 rotate-180" />
          </a>
        )}
      </a>
    </div>
  );
};
