import { Button, CircularProgress } from 'components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ProfileCompletionProps {
  percentage: number;
  onCompleteYourProfileClick: () => void;
}

export const ProfileCompletion: FC<ProfileCompletionProps> = ({
  percentage,
  onCompleteYourProfileClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className="p-4 xl:py-2.5 xl:px-5 rounded-md bg-primary-900 flex items-center flex-col xl:flex-row gap-y-2 xl:gap-0">
      <div className="flex">
        <CircularProgress
          className="w-14 h-14 min-h-[56px] min-w-[56px] !pr-3 md:p-0 md:mr-3 box-content"
          value={percentage}
        />
        <div className="mr-2">
          <p className="text-[14px] leading-[19px] font-semibold text-white">
            {t('profile:profileCompletion.title')}
          </p>
          <p className="mt-1 text-[13px] leading-[17px] text-gray-200 max-w-[280px]">
            {t('profile:profileCompletion.subtitle')}
          </p>
        </div>
      </div>
      <Button
        className="w-full md:w-auto h-auto lg:h-12 px-2 lg:px-4 py-2 z-10"
        label={t('profile:profileCompletion.completeYourProfile')}
        labelClassName="leading-[19px]"
        onClick={onCompleteYourProfileClick}
        size="sm"
        variant="white"
      />
    </div>
  );
};
