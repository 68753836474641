import { MinusIcon, RoundPlusIcon } from 'icons';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'utils';
import { ActionButton } from './ActionButton';

interface NumberInputProps {
  onChange: (value: number) => void;
  name: string;
  value: number;
  step?: number;
  min?: number;
  max?: number;
  currency?: string;
  className?: string;
  error?: FieldError;
  placeholder?: string;
}

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  (
    {
      onChange,
      name,
      value,
      step = 1,
      max = Infinity,
      min = -Infinity,
      currency,
      className = '',
      error,
      placeholder,
    },
    ref,
  ) => {
    const widthRef = useRef<HTMLSpanElement>(null);
    const [inputWidth, setInputWidth] = useState(50);
    const { t } = useTranslation();

    const handleButtonClick = (type: 'increment' | 'decrement') => {
      if (type === 'increment') {
        return onChange(Math.min((value || 0) + step, max));
      }
      return onChange(Math.max((value || 0) - step, min));
    };

    useEffect(() => {
      if (!widthRef.current) {
        return;
      }
      setInputWidth(widthRef.current.offsetWidth);
    }, [value, widthRef]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(parseFloat(e.target.value));
    };

    return (
      <label className={mergeClasses('relative block', className)} htmlFor={name}>
        <div className="flex items-center space-x-2 w-full">
          <ActionButton
            ariaLabel={t('aria:decrease')}
            icon={MinusIcon}
            isError={!!error}
            onClick={() => handleButtonClick('decrement')}
          />
          <div
            className={mergeClasses(
              'rounded-lg border text-2xl py-[11px] text-center flex justify-center items-center w-full transition-all',
              error ? 'border-red-500' : 'border-gray-200',
            )}
          >
            <input
              ref={ref}
              className="outline-none min-w-[16px]"
              id={name}
              name={name}
              onChange={handleInputChange}
              placeholder={placeholder}
              style={{
                width: inputWidth,
              }}
              type="number"
              value={value || ''}
            />
            <span ref={widthRef} className="opacity-0 absolute top-0 left-0 -z-10">
              {value || ''}
            </span>
            {currency && <span className="ml-1 text-gray-400">{currency}</span>}
          </div>
          <ActionButton
            ariaLabel={t('aria:increase')}
            icon={RoundPlusIcon}
            isError={!!error}
            onClick={() => handleButtonClick('increment')}
          />
        </div>
        {error && <p className="text-red-500 leading-[18px] mt-2">{error.message}</p>}
      </label>
    );
  },
);

NumberInput.displayName = 'NumberInput';
