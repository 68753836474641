import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
const defaultOptions = {} as const;
export type RegisterExpertLoginFromPublicOfferMutationVariables = Types.Exact<{
  expertId: Types.Scalars['String'];
  offerId: Types.Scalars['String'];
}>;

export type RegisterExpertLoginFromPublicOfferMutation = {
  __typename?: 'Mutation';
  registerExpertLoginFromPublicOffer: string;
};

export const RegisterExpertLoginFromPublicOfferDocument = gql`
  mutation registerExpertLoginFromPublicOffer($expertId: String!, $offerId: String!) {
    registerExpertLoginFromPublicOffer(expertId: $expertId, offerId: $offerId)
  }
`;
export type RegisterExpertLoginFromPublicOfferMutationFn = Apollo.MutationFunction<
  RegisterExpertLoginFromPublicOfferMutation,
  RegisterExpertLoginFromPublicOfferMutationVariables
>;

/**
 * __useRegisterExpertLoginFromPublicOfferMutation__
 *
 * To run a mutation, you first call `useRegisterExpertLoginFromPublicOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterExpertLoginFromPublicOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerExpertLoginFromPublicOfferMutation, { data, loading, error }] = useRegisterExpertLoginFromPublicOfferMutation({
 *   variables: {
 *      expertId: // value for 'expertId'
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useRegisterExpertLoginFromPublicOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterExpertLoginFromPublicOfferMutation,
    RegisterExpertLoginFromPublicOfferMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterExpertLoginFromPublicOfferMutation,
    RegisterExpertLoginFromPublicOfferMutationVariables
  >(RegisterExpertLoginFromPublicOfferDocument, options);
}
export type RegisterExpertLoginFromPublicOfferMutationHookResult = ReturnType<
  typeof useRegisterExpertLoginFromPublicOfferMutation
>;
export type RegisterExpertLoginFromPublicOfferMutationResult =
  Apollo.MutationResult<RegisterExpertLoginFromPublicOfferMutation>;
export type RegisterExpertLoginFromPublicOfferMutationOptions = Apollo.BaseMutationOptions<
  RegisterExpertLoginFromPublicOfferMutation,
  RegisterExpertLoginFromPublicOfferMutationVariables
>;
