import { Switch } from 'components/Inputs';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ExpertNotificationConsentsEnum } from '../NotificationsTab';

interface NotificationListItemProps {
  name: ExpertNotificationConsentsEnum;
  isChecked: boolean;
  onCheck: (name: ExpertNotificationConsentsEnum) => void;
}

export const NotificationListItem: FC<NotificationListItemProps> = ({
  isChecked,
  name,
  onCheck,
}) => {
  const { t } = useTranslation();

  return (
    <div className="border-b border-gray-200 pb-3 last:border-none">
      <div className="lg:max-w-xl flex items-center justify-between">
        <div className="pr-6">
          <p className="font-medium text-lg mb-1">{t(`settings:notifications.${name}.title`)}</p>
          <p className="text-sm text-gray-500">{t(`settings:notifications.${name}.description`)}</p>
        </div>
        <Switch className="mt-2" onChange={() => onCheck(name)} value={isChecked} />
      </div>
    </div>
  );
};
