import { Paper, PhoneNumberInput, TextField } from 'components';
import { GithubIcon, LinkedInIcon } from 'icons';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface ContactDataValues {
  githubUrl: string;
  linkedInUrl: string;
  phoneNumber: string;
}

export const ContactData = () => {
  const { t } = useTranslation();

  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<ContactDataValues>();

  const phoneNumberValue = watch('phoneNumber');

  return (
    <Paper
      className="!p-4 xl:!p-8"
      id="basicInfo"
      title={t('profile:papers.contactData.title') ?? ''}
      titleClassName="!mb-4 xl:!mb-6"
    >
      <PhoneNumberInput
        error={errors.phoneNumber}
        label={t('forms:labels.yourPhoneNumber')}
        name="phoneNumber"
        onChange={(value) =>
          setValue('phoneNumber', value, {
            shouldDirty: true,
            shouldTouch: true,
          })
        }
        value={phoneNumberValue}
      />
      <div className="grid gap-4 mt-6 grid-rows-2 md:grid-cols-2 lg:grid-rows-2 lg:grid-cols-1 2xl:grid-cols-2 2xl:grid-rows-1">
        <TextField
          error={errors.linkedInUrl}
          label={t('forms:labels.linkedInProfile')}
          leftSideComponent={<LinkedInIcon className="text-black w-6 h-6" />}
          {...register('linkedInUrl')}
        />
        <TextField
          error={errors.githubUrl}
          label={t('forms:labels.githubProfile')}
          leftSideComponent={<GithubIcon className="text-black w-6 h-6" />}
          {...register('githubUrl')}
        />
      </div>
    </Paper>
  );
};
