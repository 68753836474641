const endLength = 10;

export const trimString = (string: string, maxLength = 100, includeEnd = false) => {
  const { length } = string;
  if (length <= maxLength) {
    return string;
  }

  const trimmedString = string.substring(0, maxLength - (includeEnd ? endLength + 3 : 3));
  const ending = string.slice(length - endLength, length);

  return `${trimmedString}...${includeEnd ? ending : ''}`;
};
