import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
const defaultOptions = {} as const;
export type ToggleExpertSavedJobOrderMutationVariables = Types.Exact<{
  expertId: Types.Scalars['String'];
  jobOrderId: Types.Scalars['String'];
}>;

export type ToggleExpertSavedJobOrderMutation = {
  __typename?: 'Mutation';
  toggleExpertSavedJobOrder: Array<string>;
};

export const ToggleExpertSavedJobOrderDocument = gql`
  mutation toggleExpertSavedJobOrder($expertId: String!, $jobOrderId: String!) {
    toggleExpertSavedJobOrder(expertId: $expertId, jobOrderId: $jobOrderId)
  }
`;
export type ToggleExpertSavedJobOrderMutationFn = Apollo.MutationFunction<
  ToggleExpertSavedJobOrderMutation,
  ToggleExpertSavedJobOrderMutationVariables
>;

/**
 * __useToggleExpertSavedJobOrderMutation__
 *
 * To run a mutation, you first call `useToggleExpertSavedJobOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleExpertSavedJobOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleExpertSavedJobOrderMutation, { data, loading, error }] = useToggleExpertSavedJobOrderMutation({
 *   variables: {
 *      expertId: // value for 'expertId'
 *      jobOrderId: // value for 'jobOrderId'
 *   },
 * });
 */
export function useToggleExpertSavedJobOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleExpertSavedJobOrderMutation,
    ToggleExpertSavedJobOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ToggleExpertSavedJobOrderMutation,
    ToggleExpertSavedJobOrderMutationVariables
  >(ToggleExpertSavedJobOrderDocument, options);
}
export type ToggleExpertSavedJobOrderMutationHookResult = ReturnType<
  typeof useToggleExpertSavedJobOrderMutation
>;
export type ToggleExpertSavedJobOrderMutationResult =
  Apollo.MutationResult<ToggleExpertSavedJobOrderMutation>;
export type ToggleExpertSavedJobOrderMutationOptions = Apollo.BaseMutationOptions<
  ToggleExpertSavedJobOrderMutation,
  ToggleExpertSavedJobOrderMutationVariables
>;
