import aboutUsSrc from 'assets/images/job-board/navbar/about-us.webp';
import generativeAISrc from 'assets/images/job-board/navbar/ai.png';
import becomeConnectedExpertSrc from 'assets/images/job-board/navbar/become-expert.webp';
import caseStudiesSrc from 'assets/images/job-board/navbar/case-studies.webp';
import cloudServicesSrc from 'assets/images/job-board/navbar/cloud.png';
import cPlatformSrc from 'assets/images/job-board/navbar/c_platform.avif';
import digitalTransformationSrc from 'assets/images/job-board/navbar/digital-transformation.jpeg';
import hireAnExpertSrc from 'assets/images/job-board/navbar/hire-expert.webp';
import jobOffersSrc from 'assets/images/job-board/navbar/job-orders.webp';
import dataAndMLAISrc from 'assets/images/job-board/navbar/ml.png';
import applicationEngineeringServicesSrc from 'assets/images/job-board/navbar/projecting.png';
import ourServicesSrc from 'assets/images/job-board/navbar/services.webp';
import techExpertsSrc from 'assets/images/job-board/navbar/tech-experts.webp';
import { EnvironmentConfig } from '../../../environment-config';

export interface ConnectisWebsiteMenuItem {
  name: string;
  thumbnail?: string;
  url: string;
  header?: boolean;
}

export interface ConnectisWebsiteMenu {
  name: string;
  items: ConnectisWebsiteMenuItem[];
  portfolioProject?: ConnectisWebsiteMenuItem;
}

export const menuSchema: ConnectisWebsiteMenu[] = [
  {
    name: 'ourServices',
    items: [
      {
        name: 'generativeAI',
        thumbnail: generativeAISrc,
        url: `${EnvironmentConfig.connectisWebsiteUrl}/uslugi/generative-ai`,
      },
      {
        name: 'dataAndMLAI',
        thumbnail: dataAndMLAISrc,
        url: `${EnvironmentConfig.connectisWebsiteUrl}/uslugi/data-ml-ai`,
      },
      {
        name: 'applicationEngineeringServices',
        thumbnail: applicationEngineeringServicesSrc,
        url: `${EnvironmentConfig.connectisWebsiteUrl}/uslugi/projektowanie-oprogramowania`,
      },
      {
        name: 'cloudServices',
        thumbnail: cloudServicesSrc,
        url: `${EnvironmentConfig.connectisWebsiteUrl}/uslugi/rozwiazania-chmurowe`,
      },
      {
        name: 'hireAnExpert',
        url: `${EnvironmentConfig.connectisWebsiteUrl}/zatrudnij-eksperta`,
        header: false,
      },
    ],
    portfolioProject: {
      name: 'digitalTransformation',
      thumbnail: digitalTransformationSrc,
      url: `${EnvironmentConfig.connectisWebsiteUrl}/works/dedykowany-system-wspierajacy-cyfrowa-transformacje`,
    },
  },
  {
    name: 'companies',
    items: [
      {
        name: 'hireAnExpert',
        thumbnail: hireAnExpertSrc,
        url: `${EnvironmentConfig.connectisWebsiteUrl}/zatrudnij-eksperta`,
      },
      {
        name: 'ourServices',
        thumbnail: ourServicesSrc,
        url: `${EnvironmentConfig.connectisWebsiteUrl}/uslugi`,
      },
      {
        name: 'iNeedAQuote',
        url: `${EnvironmentConfig.connectisWebsiteUrl}/formularz/formularz`,
        header: false,
      },
    ],
    portfolioProject: {
      name: 'digitalTransformation',
      thumbnail: digitalTransformationSrc,
      url: `${EnvironmentConfig.connectisWebsiteUrl}/works/dedykowany-system-wspierajacy-cyfrowa-transformacje`,
    },
  },
  {
    name: 'experts',
    items: [
      {
        name: 'techExperts',
        thumbnail: techExpertsSrc,
        url: `${EnvironmentConfig.connectisWebsiteUrl}/dla-ekspertow`,
      },
      {
        name: 'becomeConnectedExpert',
        thumbnail: becomeConnectedExpertSrc,
        url: `${EnvironmentConfig.connectisWebsiteUrl}/connected-experts-r`,
      },
      {
        name: 'jobOffers',
        thumbnail: jobOffersSrc,
        url: 'https://hub.connectis.pl/job-board',
      },
      {
        name: 'cHub',
        thumbnail: cPlatformSrc,
        url: 'https://hub.connectis.pl/',
      },
    ],
    portfolioProject: {
      name: 'digitalTransformation',
      thumbnail: digitalTransformationSrc,
      url: `${EnvironmentConfig.connectisWebsiteUrl}/works/dedykowany-system-wspierajacy-cyfrowa-transformacje`,
    },
  },
  {
    name: 'whyConnectis',
    items: [
      {
        name: 'caseStudies',
        thumbnail: caseStudiesSrc,
        url: `${EnvironmentConfig.connectisWebsiteUrl}/case-studies`,
      },
      {
        name: 'aboutUs',
        thumbnail: aboutUsSrc,
        url: `${EnvironmentConfig.connectisWebsiteUrl}/o-nas`,
      },
      {
        name: 'ambassadorProgram',
        url: `${EnvironmentConfig.connectisWebsiteUrl}/program-ambasadorski`,
        header: false,
      },
      {
        name: 'contact',
        url: `${EnvironmentConfig.connectisWebsiteUrl}/kontakt`,
        header: false,
      },
    ],
    portfolioProject: {
      name: 'digitalTransformation',
      thumbnail: digitalTransformationSrc,
      url: `${EnvironmentConfig.connectisWebsiteUrl}/works/dedykowany-system-wspierajacy-cyfrowa-transformacje`,
    },
  },
];
export const getStartedUrl = `${EnvironmentConfig.connectisWebsiteUrl}/kontakt#getstarted`;
