import { FileInput, TextField } from 'components';
import { useExpertProfile } from 'hooks';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { allowedCvFileTypes } from 'utils';

export interface NameStepValues {
  firstName: string;
  lastName: string;
  expertCv: File | null;
}

export const NameStep = () => {
  const { t } = useTranslation();
  const { profile } = useExpertProfile();

  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext<NameStepValues>();
  const initialFile = profile?.cvId ? profile?.cv : null;

  return (
    <div className="pb-10 mb-20">
      <div className="w-full mt-6 md:mt-14 grid grid-cols-1 md:grid-cols-2 gap-y-4 md:gap-x-4 grid-rows-2 md:grid-rows-1">
        <TextField
          {...register('firstName')}
          autoComplete="given-name"
          error={errors.firstName}
          label={t('forms:labels.firstName')}
          placeholder={
            t('forms:placeholders.enterField', {
              fieldName: t('forms:labels.firstName').toLowerCase(),
            }) ?? ''
          }
        />
        <TextField
          {...register('lastName')}
          autoComplete="family-name"
          error={errors.lastName}
          label={t('forms:labels.lastName')}
          placeholder={
            t('forms:placeholders.enterField', {
              fieldName: t('forms:labels.lastName').toLowerCase(),
            }) ?? ''
          }
        />
      </div>
      <div className="md:mt-10 mt-6">
        <FileInput
          accept={allowedCvFileTypes}
          error={errors.expertCv}
          initialFile={initialFile}
          label={t('onboarding:nameStep.addYourCv')}
          name="expertCv"
          onChange={(cvFile) => setValue('expertCv', cvFile)}
        />
        <p className="md:text-sm text-xs text-gray-500 leading-[18px] pt-1">
          {t('onboarding:nameStep.allowedFormats')}
        </p>
      </div>
    </div>
  );
};
