import { FC } from 'react';
import { mergeClasses } from 'utils';

interface ProgressBarProps {
  value: number;
  className?: string;
}

export const ProgressBar: FC<ProgressBarProps> = ({ value, className }) => {
  return (
    <div className={mergeClasses('flex items-center space-x-2', className)}>
      <div className="h-2 w-full rounded-[32px] bg-gray-200 overflow-hidden">
        <div
          className="bg-green-500 h-full transition-all"
          style={{
            width: `${value}%`,
          }}
        />
      </div>
      <span className="text-[13px] font-bold w-8">{value ?? '-'}%</span>
    </div>
  );
};
