import { useEffect, useState } from 'react';

export const useScrollTop = (topOffset = 5) => {
  const [isScrollTop, setIsScrollTop] = useState(false);

  useEffect(() => {
    const updatePosition = () => {
      if (window.scrollY > topOffset) {
        setIsScrollTop(true);
        return;
      }
      setIsScrollTop(false);
    };

    window.addEventListener('scroll', updatePosition);
    return () => window.removeEventListener('scroll', updatePosition);
  }, []);

  return isScrollTop;
};
