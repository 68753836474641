import { SearchInput, Skill } from 'components';
import { ExpertTag } from 'interfaces';
import { FieldError, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { filterTechnicalSkills, removeObjectFromArray } from 'utils';
import { useOnboardingDataQuery } from '../../../hooks/api/onboardingData/onboardingData.generated';
import { ErrorMessage } from '../../Inputs/ErrorMessage';
import { DefaultSkillLevelType } from '../../Skill/Skill';
import { AddItemButton } from '../subcomponents';

export type SkillLevel = 1 | 2 | 3 | 4 | 5 | 6 | null;

export interface SkillsStepValues {
  tags: ExpertTag[];
  tagsSuperPowers: string[];
}

export const SkillsStep = () => {
  const { t } = useTranslation();

  const { data } = useOnboardingDataQuery();
  const { tags: skills } = data ?? { tags: [] };

  const {
    watch,
    setValue,
    formState: { errors, isValid },
  } = useFormContext<SkillsStepValues>();

  const tagsValue = watch('tags') ?? [];
  const tagsSuperPowersValue = watch('tagsSuperPowers') ?? [];

  const technicalTagsValue = filterTechnicalSkills(skills, tagsValue);

  const skillsOptions = skills
    .filter(({ id }) => !tagsValue.find(({ tagId }) => tagId === id))
    .map(({ id, name }) => ({
      label: name,
      value: id,
    }));

  const handleSkillAdd = (tagId: string) => {
    setValue('tags', [...tagsValue, { tagId, weight: 1 }], {
      shouldValidate: !isValid,
    });
  };

  const handleRemoveSkill = (tagId: string) => {
    setValue('tags', removeObjectFromArray(tagsValue, 'tagId', tagId), {
      shouldValidate: !isValid,
    });
    setValue(
      'tagsSuperPowers',
      tagsSuperPowersValue.filter((tag) => tag !== tagId),
    );
  };

  const handleSkillLevelChange = (tagId: string, newLevel: DefaultSkillLevelType) =>
    setValue(
      'tags',
      tagsValue.map((tag) => (tag.tagId === tagId ? { ...tag, weight: newLevel } : tag)),
      { shouldValidate: !isValid },
    );

  return (
    <div className="w-full m-0 md:mt-6 lg:mt-14 relative">
      <div className="flex md:grid flex-col md:grid-cols-3 gap-2 md:gap-8 mt-8 md:mt-14 mb-32 lg:mb-16">
        <div className="w-full md:hidden">
          <SearchInput onChange={handleSkillAdd} options={skillsOptions} />
          <hr className="block my-6" />
        </div>
        {technicalTagsValue?.map(({ tagId, weight }) => (
          <Skill
            key={`skillItem_${tagId}`}
            className="order-2 md:order-1 min-h-[122px]"
            error={!weight ? (errors.tags as FieldError) : undefined}
            id={tagId}
            level={weight as DefaultSkillLevelType}
            levelDiscName="skillLevels"
            name={skills.find(({ id }) => id === tagId)?.name ?? tagId}
            onClick={!weight ? () => handleSkillLevelChange(tagId, 1) : undefined}
            onLevelChange={handleSkillLevelChange}
            onRemove={handleRemoveSkill}
          />
        ))}
        <AddItemButton
          centerButtonForGrid
          label={t('onboarding:skillsStep.addNewTechnologyLabel')}
          onChange={handleSkillAdd}
          options={skillsOptions}
        />
      </div>
      <ErrorMessage
        className="mt-4 absolute lg:static bottom-24"
        error={errors.tags as FieldError}
      />
    </div>
  );
};
