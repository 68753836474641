import { Paper, SearchInput, Skill } from 'components';
import { DefaultSkillLevelType } from 'components/Skill/Skill';
import { ExpertTag } from 'interfaces';
import { FieldError, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  filterTechnicalSkills,
  findElementByKey,
  mapArrayIntoOptions,
  mapObjectInArray,
  removeObjectFromArray,
  toggleStringOnArray,
} from 'utils';
import { useOnboardingDataQuery } from '../../../../../../hooks/api/onboardingData/onboardingData.generated';
import { MAX_SUPERPOWERS_ALLOWED } from '../../../../../Onboarding/utils/inputLimitations';

interface SkillsValue {
  tags: ExpertTag[];
  tagsSuperPowers: string[];
}

export const Skills = () => {
  const { t } = useTranslation();
  const { data } = useOnboardingDataQuery();
  const { tags } = data ?? { tags: [] };

  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<SkillsValue>();
  const tagsValue = watch('tags') ?? [];
  const tagsSuperPowersValue = watch('tagsSuperPowers') ?? [];

  const technicalTagsValue = filterTechnicalSkills(tags, tagsValue);

  const skillsOptions = mapArrayIntoOptions(
    tags.filter(({ id }) => !findElementByKey(tagsValue, 'tagId', id)),
    'name',
    'id',
  );

  const handleAddSkill = (tagId: string) => {
    const updatedSkills = [...tagsValue, { tagId, weight: 1 as DefaultSkillLevelType }];
    setValue('tags', updatedSkills, { shouldValidate: true, shouldDirty: true });
  };

  const handleSkillLevelChange = (tagId: string, newLevel: DefaultSkillLevelType) =>
    setValue(
      'tags',
      mapObjectInArray(
        tagsValue,
        (tag) => tag.tagId === tagId,
        (tag) => ({ ...tag, weight: newLevel }),
      ),
      {
        shouldValidate: true,
        shouldDirty: true,
      },
    );

  const handleRemoveSkill = (tagId: string) => {
    setValue('tags', removeObjectFromArray(tagsValue, 'tagId', tagId), {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue(
      'tagsSuperPowers',
      tagsSuperPowersValue.filter((tag) => tag !== tagId),
      {
        shouldValidate: true,
        shouldDirty: true,
      },
    );
  };

  const handleToggleSuperPower = (tagId: string) => {
    const updatedSuperPower = toggleStringOnArray(
      tagsSuperPowersValue,
      tagId,
      MAX_SUPERPOWERS_ALLOWED,
    );
    setValue('tagsSuperPowers', updatedSuperPower, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <Paper
      className="!p-4 xl:!p-8"
      id="skills"
      subtitle={t('profile:papers.skills.subtitle')}
      title={t('profile:papers.skills.title')}
      titleClassName="lg:mb-0.5"
    >
      <SearchInput
        error={errors.tags as FieldError}
        label={t('profile:papers.skills.technologiesInputLabel')}
        onChange={handleAddSkill}
        options={skillsOptions}
        placeholder={t('profile:papers.skills.technologiesInputPlaceholder')}
      />
      <div className="grid md:grid-cols-[repeat(auto-fit,minmax(250px,max-content))] gap-2 md:gap-8 mt-6">
        {technicalTagsValue?.map(({ tagId, weight }) => (
          <Skill
            key={`skillItem_${tagId}`}
            className="md:min-h-[125px]"
            id={tagId}
            isSuperPower={tagsSuperPowersValue.includes(tagId)}
            isSuperPowerStarAlwaysVisible
            level={weight}
            levelDiscName="skillLevels"
            name={tags.find(({ id }) => id === tagId)?.name ?? tagId}
            onLevelChange={handleSkillLevelChange}
            onRemove={handleRemoveSkill}
            onSuperPowerStarClick={() => handleToggleSuperPower(tagId)}
            superPowerStarPosition="bottom"
          />
        ))}
      </div>
    </Paper>
  );
};
