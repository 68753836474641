import { SmallButton } from 'components';
import dayjs from 'dayjs';
import { BadgeIcon, EditIcon, RemoveIcon } from 'icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'utils';
import { CertificateFormValues } from './CertificateForm';

interface CertificateItemProps extends CertificateFormValues {
  onEdit: () => void;
  onRemove: () => void;
  className?: string;
}

const prepareUrl = (url?: string) => {
  if (!url) return url;
  if (!url.includes('http://') && !url.includes('https://')) {
    return `//${url}`;
  }
  return url;
};

export const CertificateItem: FC<CertificateItemProps> = ({
  identifier,
  issueDate,
  issuer,
  name,
  url,
  onEdit,
  onRemove,
  className = '',
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={mergeClasses(
        'border border-gray-200 rounded-lg p-4 flex justify-between items-start flex-col gap-4 md:gap-0',
        className,
      )}
    >
      <div className="w-full">
        <div className="flex space-x-4 mb-2">
          <div className="flex items-center justify-center border border-gray-200 bg-gray-100 rounded-full w-12 h-12 min-w-[48px]">
            <BadgeIcon className="w-4 h-4" />
          </div>
          <div className="max-w-[80%] lg:max-w-[85%]">
            <p className="font-semibold text-[17px] break-words md:w-[145px] lg:w-auto xl:w-[145px]">
              {name}
            </p>
            <p className="text-gray-500 text-sm break-words md:w-[145px] lg:w-auto xl:w-[145px]">
              {issuer}
            </p>
            <p className="text-xs font-medium">
              {issueDate ? dayjs(issueDate).format('MM.YYYY') : undefined}
            </p>
          </div>
        </div>
        <p className="text-xs break-words">{identifier}</p>
        <a
          className="text-xs break-words hover:underline"
          href={prepareUrl(url)}
          rel="noreferrer"
          target="_blank"
        >
          {url}
        </a>
      </div>
      <div className="space-x-4 flex justify-center border-t border-t-gray-200 pt-4 mt-4 w-full">
        <SmallButton icon={EditIcon} label={t('common:actions.edit')} onClick={onEdit} />
        <SmallButton icon={RemoveIcon} label={t('common:actions.remove')} onClick={onRemove} />
      </div>
    </div>
  );
};
