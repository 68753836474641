import useBreakpoint, { BreakpointType } from 'hooks/useBreakpoint';
import withProps from './subcomponents/withProps';

export interface BreakpointProps {
  size: BreakpointType;
  children: React.ReactNode;
}

interface BreakpointComponent extends React.FC<BreakpointProps> {
  Small: React.FC<{ children: React.ReactNode }>;
  Medium: React.FC<{ children: React.ReactNode }>;
  Large: React.FC<{ children: React.ReactNode }>;
  Xlarge: React.FC<{ children: React.ReactNode }>;
}

const ORDERED_BREAKPOINTS: BreakpointType[] = ['sm', 'md', 'lg', 'xl'];

const overlapsBreakpoint = (breakpoint: BreakpointType) => (currentBreakpoint: BreakpointType) =>
  ORDERED_BREAKPOINTS.indexOf(currentBreakpoint) <= ORDERED_BREAKPOINTS.indexOf(breakpoint);

const Breakpoint: BreakpointComponent = ({ size, children }) => {
  const currentBreakpoint = useBreakpoint();

  const breakpointStyle = {
    display: overlapsBreakpoint(size)(currentBreakpoint) ? 'block' : 'none',
  };

  return <div style={breakpointStyle}>{children}</div>;
};

Breakpoint.displayName = 'Breakpoint';

Breakpoint.Small = withProps('sm')(Breakpoint);
Breakpoint.Small.displayName = 'Breakpoint.Small';

Breakpoint.Medium = withProps('md')(Breakpoint);
Breakpoint.Medium.displayName = 'Breakpoint.Medium';

Breakpoint.Large = withProps('lg')(Breakpoint);
Breakpoint.Large.displayName = 'Breakpoint.Large';

Breakpoint.Xlarge = withProps('xl')(Breakpoint);
Breakpoint.Xlarge.displayName = 'Breakpoint.Xlarge';

export default Breakpoint;
