import { LoadingSpinner, SmallButton } from 'components';
import { useProfilePreview } from 'hooks';
import { DesktopIcon } from 'icons';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OnboardingFormValues } from '../OnboardingForm';

export const PreviewStep = () => {
  const { t } = useTranslation();

  const [isLoaded, setIsLoaded] = useState(false);
  const { getValues } = useFormContext<OnboardingFormValues>();
  const values = getValues();

  const { profileUrl } = useProfilePreview(values);

  const handleProfileRedirect = () => profileUrl && window.open(profileUrl);

  return (
    <div className="flex flex-col items-center grow">
      <div className="md:mt-14 md:relative md:overflow-hidden w-full h-full md:text-center mt-6 order-2 md:order-none flex flex-col grow md:grow-0 mb-16 md:mb-0">
        {!isLoaded && <LoadingSpinner />}
        {profileUrl && (
          <iframe
            className="h-[450px] lg:h-[900px] md:block w-full scale-90 rounded-t-2xl"
            onLoad={() => setIsLoaded(true)}
            src={profileUrl}
            title="profile-preview"
          />
        )}
      </div>
      <SmallButton
        className="md:mt-4 text-xs leading-7 mt-6 md:mb-20 xl:mb-14"
        icon={DesktopIcon}
        label={t('onboarding:previewStep.openAtFullScreen')}
        onClick={handleProfileRedirect}
        variant="primary"
      />
    </div>
  );
};
