import { Button } from 'components';
import { useSitemap } from 'hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const JobOrderDetailsFillProfile: FC<{ closeModal: () => void }> = ({ closeModal }) => {
  const { t } = useTranslation();
  const sitemap = useSitemap();
  const navigate = useNavigate();

  const handleFillProfileClick = () => {
    closeModal();
    navigate(sitemap.profile(), {
      replace: true,
    });
  };

  return (
    <div className="border border-gray-200 rounded-xl p-4 md:p-8 lg:max-w-[424px]">
      <div className="border-b border-gray-10">
        <p className="md:text-xl font-bold mb-4">
          {t('offers:offerRecommendation.wantToSeeOffers')}
        </p>
      </div>
      <div className="mt-2 mb-4">
        <p className="text-sm font-medium">{t('offers:offerRecommendation.fillProfileDesc')}</p>
      </div>
      <Button
        className="w-full"
        label={t('offers:offerRecommendation.fillProfile')}
        onClick={handleFillProfileClick}
      />
    </div>
  );
};
