import { CheckIcon } from 'icons';
import { FC, ReactNode } from 'react';
import { mergeClasses } from 'utils';

interface SelectItemWrapperProps {
  isActive: boolean;
  onClick: () => void;
  children: ReactNode;
}

export const SelectItemWrapper: FC<SelectItemWrapperProps> = ({ isActive, children, onClick }) => {
  return (
    <div
      className={mergeClasses(
        'border flex flex-col justify-center items-center transition-all rounded-2xl py-8 md:py-16 px-4 cursor-pointer relative overflow-hidden',
        isActive ? 'border-primary-500 shadow-primary-md' : 'border-gray-200',
      )}
      onClick={onClick}
    >
      <div
        className={mergeClasses(
          'absolute top-0 right-0 bg-primary-500 rounded-bl-xl w-10 h-10 flex justify-center items-center transition-all',
          isActive ? '' : 'opacity-0',
        )}
      >
        <CheckIcon className="w-5 h-5 text-white" />
      </div>
      {children}
    </div>
  );
};
