import { useReactiveVar } from '@apollo/client';
import { LoadingSpinner, Modal, ModalProps, SmallButton } from 'components';
import { useProfilePreview } from 'hooks';
import { DesktopIcon } from 'icons';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { profileVar } from 'reactive-vars';
import { OnboardingFormValues } from '../../Onboarding/OnboardingForm';
import { mapExpertProfileToValues } from '../../Onboarding/utils';

export const ProfilePreviewModal: FC<ModalProps> = (modalProps) => {
  const { t } = useTranslation();
  const [isLoaded, setIsLoaded] = useState(false);

  const profile = useReactiveVar(profileVar);
  const mappedValues = mapExpertProfileToValues(profile) as OnboardingFormValues;
  const { profileUrl, isError } = useProfilePreview(mappedValues);

  const handleProfileRedirect = () => profileUrl && window.open(profileUrl);

  return (
    <Modal
      {...modalProps}
      className="w-full max-w-screen-md"
      iconButtonClassName="top-0 p-0"
      iconClassName="w-8 h-8"
      title={t(`profile:profileHeader.profilePreview`)}
    >
      <div className="relative overflow-hidden w-full h-[450px] text-center">
        {!isLoaded && !isError && (
          <div className="py-20">
            <LoadingSpinner />
          </div>
        )}
        {isError && <p className="text-red-500 font-medium py-20 my-auto">{t('toasts:error')}</p>}
        {profileUrl && (
          <iframe
            className="w-[200%] h-[900px] scale-50 block origin-top-left"
            onLoad={() => setIsLoaded(true)}
            src={profileUrl}
            title="profile-preview"
          />
        )}
      </div>
      <div className="flex justify-center">
        <SmallButton
          className="mt-4"
          icon={DesktopIcon}
          label={t('onboarding:previewStep.openAtFullScreen')}
          onClick={handleProfileRedirect}
          variant="primary"
        />
      </div>
    </Modal>
  );
};
