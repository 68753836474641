import { RefObject, useEffect, useState } from 'react';

export const useElementSize = (elementRef: RefObject<any>) => {
  const [elementSize, setElementSize] = useState([0, 0]);

  useEffect(() => {
    if (!elementRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      const { height, width } = elementRef.current.getBoundingClientRect();
      setElementSize([width, height]);
    });
    resizeObserver.observe(elementRef.current);
    return () => resizeObserver.disconnect();
  }, [elementRef?.current]);

  return elementSize;
};
