import ApplyHubBlur from 'assets/images/job-board/apply-hub-blur.svg';
import { useSitemap } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RoundedButton } from '../../Buttons';
import { ConnectisWebsiteHubSectionStep } from './subcomponents';

const startWithHubStepIndex = 3;
export const ConnectisWebsiteHubSection = () => {
  const navigate = useNavigate();
  const sitemap = useSitemap();

  const { t } = useTranslation(undefined, {
    keyPrefix: 'connectisWebsite:hubSection',
  });

  const steps = Object.values(
    t('steps', {
      returnObjects: true,
    }),
  );

  return (
    <section className="md:mx-auto pt-[100px] md:pt-30 !px-0 md:!px-[3rem] job-board-container light-section">
      <div className="relative mx-auto bg-gradient-blue px-4 lg:px-12 py-20 lg:py-14 md:rounded-3xl flex justify-between flex-col lg:flex-row">
        <img alt="" className="absolute left-0 top-0 hidden md:block" src={ApplyHubBlur} />
        <div className="sm:max-w-md lg:mt-20 lg:mr-4">
          <h3 className="font-medium text-[40px] lg:text-5xl text-white mb-6">{t('title')}</h3>
          <p className="lg:text-2xl text-gray-200 mb-10 sm:whitespace-pre-line">
            {t('description')}
          </p>
          <RoundedButton
            className="md:w-auto"
            label={t('joinHub')}
            onClick={() => navigate(sitemap.main)}
            variant="white"
          />
        </div>
        <ul className="gap-6 grid md:grid-cols-2 max-w-2xl w-full mt-12 lg:mt-0 md:min-w-[450px]">
          {steps.map((step, index) => (
            <ConnectisWebsiteHubSectionStep
              key={step}
              index={index}
              label={step}
              primary={index === startWithHubStepIndex}
            />
          ))}
        </ul>
      </div>
    </section>
  );
};
