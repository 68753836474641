import {
  MobileMeetingHourSelectState,
  TimeslotsData,
} from 'components/Modals/MeetingScheduleModal/MeetingScheduleModal';
import dayjs from 'dayjs';
import { ClockIcon } from 'icons';
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'utils';
import { Button } from '../Buttons';
import { MeetingDayItem } from './subcomponents';
import { MeetingLength } from './utils';

const NoItemsPlaceholder = () => {
  const { t } = useTranslation();

  return (
    <div className="text-center text-sm p-8 flex flex-col justify-center border border-gray-200 rounded-xl">
      <p className="font-medium leading-[18px]">
        {t('datePicker:meetingHourSelect.placeholder.title')}
      </p>
      <p className="text-gray-400 mt-2 leading-[22px]">
        {t('datePicker:meetingHourSelect.placeholder.subtitle')}
      </p>
    </div>
  );
};

interface MeetingHourSelectProps extends MobileMeetingHourSelectState {
  selectedDays: string[];
  timeslotsData: TimeslotsData;
  onChange: Dispatch<SetStateAction<TimeslotsData>>;
  onRemoveDay: (date: string) => void;
  meetingLength: MeetingLength;
  isTimingSet: { [p: string]: boolean };
  error: boolean;
  onSubmit: (...args: never[]) => Promise<unknown>;
  onAddDayClick: () => void;
  sendLoading?: boolean;
  isBelowDesktopWidth?: boolean | 0;
}

export const MeetingHourSelect: FC<MeetingHourSelectProps> = ({
  selectedDays,
  onChange,
  onRemoveDay,
  timeslotsData,
  meetingLength,
  isTimingSet,
  error,
  isOpen,
  onSubmit,
  onAddDayClick,
  sendLoading = false,
  isBelowDesktopWidth,
}) => {
  const { t } = useTranslation();

  const [scrollTargetId, setScrollTargetId] = useState<string | null>(null);

  const scrollIntoView = useCallback(() => {
    if (scrollTargetId) {
      const element = document.getElementById(scrollTargetId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
      setScrollTargetId(null);
    }
  }, [scrollTargetId]);

  useEffect(() => {
    if (error) {
      setScrollTargetId('error-placeholder');
    }
  }, [error]);

  const futureDays = selectedDays.filter((date) =>
    dayjs(date).endOf('day').isAfter(dayjs().startOf('day')),
  );

  const handleAddTimeslot = useCallback(
    (dayDate: string) => {
      onChange((prev) => ({
        ...prev,
        [dayDate]: [...prev[dayDate], null],
      }));
    },
    [onChange],
  );

  const handleChangeTimeslot = useCallback(
    (dayDate: string, timeslotIndex: number, newValue: string) => {
      onChange((prev) => ({
        ...prev,
        [dayDate]: prev[dayDate].map((currentValue, i) =>
          i === timeslotIndex ? newValue : currentValue,
        ),
      }));
    },
    [onChange],
  );

  const handleRemoveTimeslot = useCallback(
    (dayDate: string, timeslotIndex: number) => {
      onChange((prev) => ({
        ...prev,
        [dayDate]: prev[dayDate].filter((_, i) => i !== timeslotIndex),
      }));
    },
    [onChange],
  );

  useEffect(() => {
    onChange((prev) => Object.fromEntries(selectedDays.map((day) => [day, prev[day] ?? [null]])));
  }, [selectedDays]);

  const selectedDaysItems = useMemo(
    () =>
      futureDays
        .sort((dateA, dateB) => new Date(dateA).getTime() - new Date(dateB).getTime())
        .map((date) => (
          <MeetingDayItem
            key={date}
            date={date}
            error={error}
            isTimingSet={isTimingSet}
            meetingLength={60}
            onAdd={() => handleAddTimeslot(date)}
            onChange={(timeslotIndex, value) => handleChangeTimeslot(date, timeslotIndex, value)}
            onRemove={(timeslotIndex) => handleRemoveTimeslot(date, timeslotIndex)}
            onRemoveDay={onRemoveDay}
            timeslots={timeslotsData[date] ?? []}
          />
        )),
    [
      futureDays,
      timeslotsData,
      handleAddTimeslot,
      handleChangeTimeslot,
      handleRemoveTimeslot,
      onRemoveDay,
      meetingLength,
    ],
  );

  return (
    <div
      className={mergeClasses(
        isBelowDesktopWidth
          ? 'absolute top-0 w-full bg-white z-20 transition-all flex flex-col md:justify-between h-full'
          : 'w-full md:max-w-[300px] xl:max-w-[384px] hidden lg:block',
        isBelowDesktopWidth && isOpen ? 'left-0' : 'left-full',
      )}
    >
      <div className="flex flex-col items-center lg:items-start pb-6 lg:pb-0 px-4 lg:px-0 pt-6 lg:pt-0">
        <p className="leading-[18px] font-medium text-[16px] mb-4 lg:mb-2 mt-4 lg:mt-0">
          {isBelowDesktopWidth
            ? t('datePicker:selectedDaysAndTimeslots')
            : t('datePicker:selectedDates')}
        </p>
        <div className="flex items-center text-gray-500 pb-[19px] md:pb-0">
          <ClockIcon className="w-6 h-6 mr-2 text-gray-400" />
          <p className="text-[16px] leading-[18px] font-medium lg:font-normal">
            {t('datePicker:meetingDuration')}
            {t('datePicker:oneHour')}
          </p>
        </div>
        {isBelowDesktopWidth && (
          <p className="text-[14px] leading-[22.5px] text-gray-400 text-center">
            {t('datePicker:meetingHourSelect.placeholder.subtitle')}
          </p>
        )}
      </div>
      <div className="md:mt-6 h-full space-y-4 lg:max-h-[466px] px-4 lg:px-0 overflow-auto pb-64 lg:pb-5">
        {futureDays.length ? selectedDaysItems : <NoItemsPlaceholder />}
      </div>
      <div className="pb-[15px] flex flex-col gap-4 pt-6 shadow-[0_-4px_20px_-0_rgba(3,20,82,0.12)] px-4 pb-8 bg-white lg:hidden fixed bottom-0 w-full">
        <Button
          className="w-full"
          isDisabled={sendLoading}
          isLoading={sendLoading}
          label={t('datePicker:scheduleMeetingModal.actions.send')}
          onClick={() => onSubmit()}
        />
        <Button
          className="w-full border-2 border-black"
          label={t('datePicker:meetingHourSelect.addAnotherDate')}
          labelClassName="text-black"
          onClick={onAddDayClick}
          variant="white"
        />
      </div>
    </div>
  );
};
