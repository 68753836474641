import { useRef } from 'react';

export const useScrollIntoView = <TRef extends HTMLElement>() => {
  const ref = useRef<TRef>(null);

  const scrollIntoView = () => {
    if (!ref.current) return;
    ref.current.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return { ref, scrollIntoView };
};
