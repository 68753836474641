import MobileBasicInfoImgSrc from 'assets/images/onboarding/basic-info-mobile.svg';
import BasicInfoImgSrc from 'assets/images/onboarding/basic-info.svg';
import MobileExperienceImgSrc from 'assets/images/onboarding/experience-mobile.svg';
import ExperienceImgSrc from 'assets/images/onboarding/experience.svg';
import MobilePreferencesImgSrc from 'assets/images/onboarding/preferences-mobile.svg';
import PreferencesImgSrc from 'assets/images/onboarding/preferences.svg';
import { FC } from 'react';
import {
  AvailabilityStep,
  BasicInfoTitleStep,
  CertificateStep,
  ExpectationsStep,
  ExperienceStep,
  ExperienceTitleStep,
  LanguagesStep,
  NameStep,
  PreferencesStep,
  PreferencesTitleStep,
  PreviewStep,
  RoleStep,
  SalaryStep,
  SkillsStep,
  SuperSkillsStep,
} from './steps';
import {
  availabilityStepSchema,
  languageStepSchema,
  nameStepSchema,
  preferencesStepSchema,
  roleStepSchema,
  salaryStepSchema,
  skillsStepSchema,
} from './validationSchemas';

export enum OnboardingStepsEnum {
  BasicInfoTitle = 'basicInfoTitleStep',
  Name = 'nameStep',
  Role = 'roleStep',
  Skills = 'skillsStep',
  SuperSkills = 'superSkillsStep',
  Languages = 'languagesStep',
  PreferencesTitle = 'preferencesTitleStep',
  Preferences = 'preferencesStep',
  Salary = 'salaryStep',
  Availability = 'availabilityStep',
  Expectations = 'expectationsStep',
  ExperienceTitle = 'experienceTitleStep',
  Experience = 'experienceStep',
  Certificates = 'certificatesStep',
  Preview = 'previewStep',
}

export interface StepComponentProps {
  onNextClick: () => void;
  onPrevClick: () => void;
  onSkipClick: () => void;
  onToggleNextDisabled: (value?: boolean) => void;
}

export interface OnboardingStep {
  name: string;
  component: FC<StepComponentProps>;
  stepNumber: 0 | 1 | 2;
  showStepNumber?: boolean;
  hideFooter?: boolean;
  validationSchema?: any;
  sideImg?: string;
  sideImgMobile?: string;
  skipTo?: number;
}

export const stepsConfig: OnboardingStep[] = [
  {
    name: OnboardingStepsEnum.BasicInfoTitle,
    stepNumber: 0,
    component: BasicInfoTitleStep,
    showStepNumber: true,
    sideImg: BasicInfoImgSrc,
    sideImgMobile: MobileBasicInfoImgSrc,
    hideFooter: true,
  },
  {
    name: OnboardingStepsEnum.Name,
    stepNumber: 0,
    component: NameStep,
    validationSchema: nameStepSchema,
  },
  {
    name: OnboardingStepsEnum.Role,
    stepNumber: 0,
    component: RoleStep,
    validationSchema: roleStepSchema,
  },
  {
    name: OnboardingStepsEnum.Skills,
    stepNumber: 0,
    component: SkillsStep,
    validationSchema: skillsStepSchema,
  },
  {
    name: OnboardingStepsEnum.SuperSkills,
    stepNumber: 0,
    component: SuperSkillsStep,
  },
  {
    name: OnboardingStepsEnum.Languages,
    stepNumber: 0,
    component: LanguagesStep,
    validationSchema: languageStepSchema,
  },
  {
    name: OnboardingStepsEnum.PreferencesTitle,
    showStepNumber: true,
    stepNumber: 1,
    component: PreferencesTitleStep,
    sideImg: PreferencesImgSrc,
    sideImgMobile: MobilePreferencesImgSrc,
    hideFooter: true,
  },
  {
    name: OnboardingStepsEnum.Preferences,
    stepNumber: 1,
    component: PreferencesStep,
    validationSchema: preferencesStepSchema,
  },
  {
    name: OnboardingStepsEnum.Salary,
    stepNumber: 1,
    component: SalaryStep,
    validationSchema: salaryStepSchema,
  },
  {
    name: OnboardingStepsEnum.Availability,
    stepNumber: 1,
    component: AvailabilityStep,
    validationSchema: availabilityStepSchema,
  },
  {
    name: OnboardingStepsEnum.Expectations,
    stepNumber: 1,
    component: ExpectationsStep,
    validationSchema: availabilityStepSchema,
    skipTo: 11,
  },
  {
    name: OnboardingStepsEnum.ExperienceTitle,
    showStepNumber: true,
    stepNumber: 2,
    component: ExperienceTitleStep,
    sideImg: ExperienceImgSrc,
    sideImgMobile: MobileExperienceImgSrc,
    hideFooter: true,
    skipTo: 14,
  },
  {
    name: OnboardingStepsEnum.Experience,
    stepNumber: 2,
    component: ExperienceStep,
    skipTo: 13,
  },
  {
    name: OnboardingStepsEnum.Certificates,
    stepNumber: 2,
    component: CertificateStep,
    skipTo: 14,
  },
  {
    name: OnboardingStepsEnum.Preview,
    stepNumber: 2,
    component: PreviewStep,
  },
];
