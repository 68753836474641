import { Skill } from 'components';
import { useFormContext } from 'react-hook-form';
import { filterTechnicalSkills, toggleStringOnArray } from 'utils';
import { useOnboardingDataQuery } from '../../../hooks/api/onboardingData/onboardingData.generated';
import { MAX_SUPERPOWERS_ALLOWED } from '../utils/inputLimitations';
import { SkillsStepValues } from './SkillsStep';

export interface SuperSkillsStepValues extends SkillsStepValues {
  tagsSuperPowers: string[];
}

export const SuperSkillsStep = () => {
  const { data } = useOnboardingDataQuery();
  const { tags: skills } = data ?? { tags: [] };
  const { watch, setValue } = useFormContext<SuperSkillsStepValues>();

  const [tagsValue, tagsSuperPowersValue] = watch(['tags', 'tagsSuperPowers']) ?? [[], []];

  const technicalTagsValue = filterTechnicalSkills(skills, tagsValue);

  const handleToggleSuperSkill = (tagId: string) => {
    const updatedSuperPower = toggleStringOnArray(
      tagsSuperPowersValue,
      tagId,
      MAX_SUPERPOWERS_ALLOWED,
    );
    setValue('tagsSuperPowers', updatedSuperPower, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <div className="w-full m-0 md:mt-6 lg:mt-14">
      <div className="flex md:grid flex-col md:grid-cols-3 gap-2 md:gap-8 mt-4 md:mt-14 mb-32 lg:mb-12">
        {technicalTagsValue?.map(({ tagId, weight }) => (
          <Skill
            key={`superSkillItem_${tagId}`}
            id={tagId}
            isReadOnly
            isSuperPower={tagsSuperPowersValue?.includes(tagId)}
            level={weight}
            levelDiscName="skillLevels"
            name={skills.find(({ id }) => id === tagId)?.name ?? tagId}
            onClick={handleToggleSuperSkill}
          />
        ))}
      </div>
    </div>
  );
};
