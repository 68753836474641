import { OnboardingForm } from 'components';
import { LogoLayout } from 'layouts';
import { useTranslation } from 'react-i18next';

export const OnboardingPage = () => {
  const { t } = useTranslation();

  return (
    <LogoLayout title={t('pageTitles:onboarding.yourProfile')}>
      <OnboardingForm />
    </LogoLayout>
  );
};
