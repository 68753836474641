import { SmallButton } from 'components';
import { ArrowRightIcon } from 'icons';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'utils';
import discordLogo from '../../assets/images/discord-logo.svg';
import { EnvironmentConfig } from '../../environment-config';

interface DiscordCardProps {
  isMobileMenuOpen?: boolean;
}

export const DiscordCard = ({ isMobileMenuOpen }: DiscordCardProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={mergeClasses(
        'lg:flex bg-gray-100 rounded-2xl relative  justify-center align-middle text-center flex-col  px-2 pt-8 pb-6',
        isMobileMenuOpen ? 'mr-4 ml-2' : 'mx-5',
      )}
    >
      <img
        alt="Discord"
        className={mergeClasses(
          'opacity-70 absolute top-[-15px] left-[-35px] max-h-12 lg:block lg:w-[120px] lg:h-12',
          isMobileMenuOpen ? 'top-[-18px] left-[-5px]' : 'top-[-15px] left-[-35px]',
        )}
        src={discordLogo}
      />
      <p className="mb-2 text-sm">{t('common:actions.joinDiscord')}</p>
      <div className="flex justify-center">
        <SmallButton
          className="whitespace-nowrap text-primary-500 !gap-1 text-[14px] md:text-[16px] leading-[19px] md:leading-[22px] font-normal !h-auto"
          icon={ArrowRightIcon}
          iconClassName="text-primary-500 w-4 min-w-4 !text-sm md:!text-base !leading-[19px] md:!leading-[22px] mr-0"
          iconPosition="right"
          label={t('common:actions.join')}
          labelClassName="text-sm font-medium md:!leading-[22px]"
          onClick={() => window.open(EnvironmentConfig.discordUrl)}
        />
      </div>
    </div>
  );
};
