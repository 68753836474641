import { useReactiveVar } from '@apollo/client';
import { Button, PageLoadingSpinner } from 'components';
import { useSitemap } from 'hooks';
import { LogoLayout } from 'layouts';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { profileVar } from 'reactive-vars';
import { useCompleteEmailVerificationMutation } from '../../hooks/api/completeEmailVerification/completeEmailVerification.generated';
import { useResendEmailVerificationMutation } from '../../hooks/api/resendEmailVerification/resendEmailVerification.generated';
import { useEffectOnce } from '../../hooks/useEffectOnce';
import { isExpertIdentityVerifiedVar } from '../../reactive-vars/isExpertIdentityVerified';

export const EmailVerificationPage = () => {
  const { t } = useTranslation();
  const [isError, setIsError] = useState(true);
  const query = useParams();
  const { expertId } = useReactiveVar(profileVar) ?? {};

  const [completeEmailVerification] = useCompleteEmailVerificationMutation();
  const [resendEmailVerification, { loading: isResendLoading }] =
    useResendEmailVerificationMutation();

  const sitemap = useSitemap();
  const navigate = useNavigate();

  const handleCompleteEmailVerification = async (token: string) => {
    const res = await completeEmailVerification({
      onError: () => setIsError(true),
      variables: {
        token,
      },
    });
    const completeEmailVerificationRes = res.data?.completeEmailVerification;
    if (!completeEmailVerificationRes) {
      return;
    }

    isExpertIdentityVerifiedVar(true);

    toast.success(t('auth:pleaseVerifyEmail.successToast'));
    navigate(sitemap.expertOnboarding());
  };

  const handleResendEmailVerification = async () => {
    if (!expertId) return toast.error(t('toasts:error'));
    await resendEmailVerification({
      onCompleted: () => toast.success(t('auth:pleaseVerifyEmail.resendSuccessToast')),
      onError: () => toast.error(t('toasts:error')),
    });
  };

  useEffectOnce(() => {
    if (!query.token) return;
    handleCompleteEmailVerification(query.token);
  });

  return isError ? (
    <LogoLayout title={t('pageTitles:emailConfirmation')}>
      <div className="flex justify-center items-center flex-col mx-auto h-full">
        <p className="text-xl font-medium text-red-500 mb-4">
          {t('auth:pleaseVerifyEmail.wrongOrExpiredToken')}
        </p>
        {expertId ? (
          <Button
            className="w-[206px]"
            isDisabled={isResendLoading}
            isLoading={isResendLoading}
            label={t('auth:pleaseVerifyEmail.resendEmail')}
            onClick={handleResendEmailVerification}
          />
        ) : (
          <Button label={t('pageTitles:homePage')} onClick={() => navigate(sitemap.main)} />
        )}
      </div>
    </LogoLayout>
  ) : (
    <PageLoadingSpinner />
  );
};
