import { useSitemap, useUrlTabs } from 'hooks';
import { useTranslation } from 'react-i18next';
import { mapArrayIntoOptions } from 'utils';
import useMobile from '../../hooks/useMobile';
import { Select } from '../Inputs';
import { JobOrderModalController } from '../JobOrderModalController';
import { TabsHeader } from '../Tabs';
import { ActiveApplications, EndedApplications } from './tabs';

export enum MyApplicationsTabEnum {
  Active = 'active',
  Ended = 'ended',
}

const renderTab = (tab: MyApplicationsTabEnum) => {
  switch (tab) {
    case MyApplicationsTabEnum.Active:
      return <ActiveApplications />;
    case MyApplicationsTabEnum.Ended:
      return <EndedApplications />;
    default: {
      const unknownTab: MyApplicationsTabEnum = tab;
      console.warn('Unknown tab:', unknownTab);
    }
  }
};

export const MyApplications = () => {
  const { t } = useTranslation();
  const sitemap = useSitemap();
  const { isTablet } = useMobile();

  const { handleTabChange, currentTab } = useUrlTabs<MyApplicationsTabEnum>(
    MyApplicationsTabEnum.Active,
    sitemap.myApplications,
  );

  const tabOptions = mapArrayIntoOptions(
    Object.values(MyApplicationsTabEnum),
    (name) =>
      `${isTablet ? `${t(`pageTitles:myApplications`)}: ` : ''}${t(`applications:tabs.${name}`)}`,
  );

  return (
    <div className="relative w-screen pb-10 md:w-auto">
      <div className="w-screen md:w-auto lg:pb-6">
        <div className="hidden lg:block w-screen md:w-[calc(100%-80px)] absolute -top-24 left-10 mt-0">
          <TabsHeader
            className="!text-xs md:!text-sm"
            onChange={handleTabChange}
            options={tabOptions}
            value={currentTab}
          />
        </div>
      </div>
      <JobOrderModalController />
      <div className="px-4 md:px-0 mx-0 md:mx-10 mt-6 pb-24 lg:pb-14 space-y-6 w-screen md:w-[calc(100vw-80px)] lg:w-[calc(100vw-24rem)] xl:w-auto">
        <Select
          className="block lg:hidden"
          isSearchable={false}
          onChange={handleTabChange}
          options={tabOptions}
          placeholder={`${t('offers:tabs.all')}`}
          value={currentTab}
        />
        {renderTab(currentTab)}
      </div>
    </div>
  );
};
