import { Button, Modal } from 'components';
import { useSitemap } from 'hooks';
import { WorkHistoryIcon } from 'icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface WorkHistoryModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const WorkHistoryModal: FC<WorkHistoryModalProps> = ({ onClose, isOpen }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const sitemap = useSitemap();

  return (
    <Modal isOpen={isOpen}>
      <div className="max-w-[788px] mx-auto py-4 px-2 flex flex-col justify-between md:block">
        <div className="flex flex-col items-center">
          <WorkHistoryIcon className="w-[37px]" />
          <p className="text-2xl lg:text-[32px] font-bold py-4">
            {t('datePicker:workHistoryBox.title')}
          </p>
          <p className="pb-0 md:pb-8 text-center text-base md:whitespace-pre-line lg:text-lg">
            {t('datePicker:workHistoryBox.subtitle')}
          </p>
          <div className="flex flex-col-reverse justify-center w-full gap-4 pt-8 sm:flex-row lg:items-center md:gap-8">
            <Button
              label={t('datePicker:workHistoryBox.noFill')}
              onClick={onClose}
              variant="outline"
            />
            <Button
              label={t('datePicker:workHistoryBox.fill')}
              onClick={() => navigate(sitemap.profile('experience'))}
              variant="primary"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
