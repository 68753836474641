export const removeEmptyFilters = (obj: Record<any, any>, filterEmptyArrays = false) => {
  return Object.keys(obj).reduce((acc, curr) => {
    const value = obj[curr];

    if (filterEmptyArrays && Array.isArray(value) && !value.length) {
      return acc;
    }

    if (value || typeof value === 'boolean') {
      acc[curr] = value;
    }

    return acc;
  }, {} as Record<any, any>);
};
