import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
const defaultOptions = {} as const;
export type SignExpertCvUploadCredentialsWithRecaptchaMutationVariables = Types.Exact<{
  params: Types.SignExpertCvUploadCredentialsWithRecaptchaInputType;
}>;

export type SignExpertCvUploadCredentialsWithRecaptchaMutation = {
  __typename?: 'Mutation';
  signExpertCvUploadCredentialsWithRecaptcha: {
    __typename?: 'SignExpertCvUploadCredentialsResultType';
    fields: Array<Array<string>>;
    url: string;
    fileId: string;
    expertId: string;
  };
};

export const SignExpertCvUploadCredentialsWithRecaptchaDocument = gql`
  mutation signExpertCvUploadCredentialsWithRecaptcha(
    $params: SignExpertCvUploadCredentialsWithRecaptchaInputType!
  ) {
    signExpertCvUploadCredentialsWithRecaptcha(params: $params) {
      fields
      url
      fileId
      expertId
    }
  }
`;
export type SignExpertCvUploadCredentialsWithRecaptchaMutationFn = Apollo.MutationFunction<
  SignExpertCvUploadCredentialsWithRecaptchaMutation,
  SignExpertCvUploadCredentialsWithRecaptchaMutationVariables
>;

/**
 * __useSignExpertCvUploadCredentialsWithRecaptchaMutation__
 *
 * To run a mutation, you first call `useSignExpertCvUploadCredentialsWithRecaptchaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignExpertCvUploadCredentialsWithRecaptchaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signExpertCvUploadCredentialsWithRecaptchaMutation, { data, loading, error }] = useSignExpertCvUploadCredentialsWithRecaptchaMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSignExpertCvUploadCredentialsWithRecaptchaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignExpertCvUploadCredentialsWithRecaptchaMutation,
    SignExpertCvUploadCredentialsWithRecaptchaMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SignExpertCvUploadCredentialsWithRecaptchaMutation,
    SignExpertCvUploadCredentialsWithRecaptchaMutationVariables
  >(SignExpertCvUploadCredentialsWithRecaptchaDocument, options);
}
export type SignExpertCvUploadCredentialsWithRecaptchaMutationHookResult = ReturnType<
  typeof useSignExpertCvUploadCredentialsWithRecaptchaMutation
>;
export type SignExpertCvUploadCredentialsWithRecaptchaMutationResult =
  Apollo.MutationResult<SignExpertCvUploadCredentialsWithRecaptchaMutation>;
export type SignExpertCvUploadCredentialsWithRecaptchaMutationOptions = Apollo.BaseMutationOptions<
  SignExpertCvUploadCredentialsWithRecaptchaMutation,
  SignExpertCvUploadCredentialsWithRecaptchaMutationVariables
>;
