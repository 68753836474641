import { Toast } from 'components';
import { AuthContainer } from 'containers';
import 'dayjs/locale/pl';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <>
      <Helmet titleTemplate="%s | Connectis Hub" />
      <Toast />
      <AuthContainer />
    </>
  );
}

export default App;
