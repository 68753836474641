import { BadgeIcon } from 'icons';
import { useTranslation } from 'react-i18next';

export const VerifiedBadge = () => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center text-primary-500 bg-white py-1 px-2 rounded-md space-x-2 md:self-start my-2">
      <BadgeIcon className="w-4 h-4" />
      <p className="text-xs font-medium whitespace-nowrap">
        {t('profile:profileHeader.profileVerified')}
      </p>
    </div>
  );
};
