import { useEffect, useRef, useState } from 'react';

export const useScrollVisibility = <ElementType extends HTMLElement>() => {
  const ref = useRef<ElementType>(null);
  const [isScrollPastElement, setIsScrollPastElement] = useState(false);

  const handleScroll = () => {
    if (!ref.current) return;
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const elementBottom = ref.current.offsetTop + ref.current.offsetHeight;
    setIsScrollPastElement(scrollTop > elementBottom);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return { ref, isScrollPastElement };
};
