import { Button, Modal, ModalProps } from 'components';
import { useTranslation } from 'react-i18next';
import ConnectisLogo from '../../../assets/images/connectis-logo.svg';
import backgroundImg from '../../../assets/images/expired-offer-background.svg';
import expiredIllustration from '../../../assets/images/expired-offer-illustration.svg';

interface ExpiredOfferModalProps extends ModalProps {
  onSeeMoreOffers: () => void;
  offerNotFound?: boolean;
}

export const ExpiredOfferModal = ({
  onSeeMoreOffers,
  offerNotFound,
  ...modalProps
}: ExpiredOfferModalProps) => {
  const { t } = useTranslation();
  const handleSeeMoreOffers = () => {
    if (modalProps.onClose) {
      onSeeMoreOffers();
      modalProps.onClose();
    }
  };

  return (
    <Modal
      className="rounded-lg w-full md:w-10/12 max-w-6xl h-auto py-8 md:pt-[87px] md:pb-[126px] px-4 flex flex-col items-center content-center"
      {...modalProps}
      backgroundClassName="px-4 !md:p-4 bottom-0 lg:py-[50px]"
      iconButtonClassName="top-8 right-4 md:right-8 p-0"
      iconClassName="w-8 h-8"
      onClose={modalProps.onClose}
    >
      <img alt="logo" className="w-[93px] md:w-[150px] pb-12" src={ConnectisLogo} />
      <div className="relative w-fit">
        <img alt="" className="w-[151px] md:w-[281px]" src={backgroundImg} />
        <img
          alt=""
          className="w-[151px] md:w-[271px] absolute bottom-0"
          src={expiredIllustration}
        />
      </div>

      <p className="pt-[87px] md:pt-12 pb-2 text-[18px] md:text-[24px] leading-[30px] md:leading-[40px] font-medium">
        {offerNotFound ? t('offers:expiredOffer.notFoundTitle') : t('offers:expiredOffer.title')}
      </p>
      <p className="text-[14px] md:text-[18px] leading-[20px] md:leading-[40px] font-medium pb-8 text-center">
        {t('offers:expiredOffer.subtitle')}
      </p>
      <Button
        className="w-full md:w-[360px]"
        label={t('offers:expiredOffer.checkOffers')}
        labelClassName="text-[14px] md:text-[16px] leading-[19px] font-semibold"
        onClick={handleSeeMoreOffers}
        variant="primary"
      />
    </Modal>
  );
};
