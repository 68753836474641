import { Modal, ModalProps } from 'components';
import { t } from 'i18next';
import { ArrowIcon } from 'icons';
import { JobOrdersQuerySortByEnum } from 'interfaces';
import { Dispatch, FC, SetStateAction } from 'react';

interface SortModalProps extends ModalProps {
  className?: string;
  setSortBy?: Dispatch<SetStateAction<JobOrdersQuerySortByEnum>>;
  sortOptions?: { label: string; value: JobOrdersQuerySortByEnum }[];
}

const SortModal: FC<SortModalProps> = ({ className, setSortBy, sortOptions, ...modalProps }) => {
  const handleOptionClick = (value: JobOrdersQuerySortByEnum) => {
    if (setSortBy) {
      setSortBy(value);
    }
    modalProps.onClose?.();
  };

  return (
    <Modal
      className="w-screen h-[95vh] rounded-t-lg rounded-b-none"
      fromBottom
      {...modalProps}
      backgroundClassName="p-0 pt-10"
      icon={ArrowIcon}
      iconButtonClassName="text-gray-400 !left-4 right-auto"
    >
      <div className="text-center text-[16px] leading-[18px] pb-3 font-medium">
        {t('forms:placeholders.sort')}
      </div>
      <ul className="space-y-4 pt-4">
        {sortOptions?.map((option) => {
          return (
            <li
              key={option.value}
              className="rounded-lg border border-gray-200 p-4 font-medium"
              onClick={() => handleOptionClick(option.value)}
            >
              {option.label}
            </li>
          );
        })}
      </ul>
    </Modal>
  );
};

export default SortModal;
