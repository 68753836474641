import { TimeslotsData } from 'components/Modals/MeetingScheduleModal/MeetingScheduleModal';

export const sortTimeslotsDataAscending = (timeslotsData: TimeslotsData) => {
  return Object.keys(timeslotsData)
    .sort((dateA, dateB) => new Date(dateA).getTime() - new Date(dateB).getTime())
    .reduce((obj: Record<string, (string | null)[]>, key: string) => {
      obj[key] = timeslotsData[key];
      return obj;
    }, {});
};
