import { TFunction } from 'i18next';
import { Shape } from 'interfaces';
import * as yup from 'yup';
import { MAX_CERTIFICATE_NAME_CHARACTERS } from '../Onboarding/utils/inputLimitations';
import { CertificateFormValues } from './CertificateForm';

export const certificatesFormSchema = (t: TFunction, isCheckboxChecked = false) =>
  yup.object().shape<Shape<CertificateFormValues>>({
    name: isCheckboxChecked
      ? yup.string()
      : yup
          .string()
          .required(
            t('forms:validationMessages.required', {
              fieldName: t('forms:labels.certificateName'),
            }) ?? '',
          )
          .max(
            MAX_CERTIFICATE_NAME_CHARACTERS,
            t('forms:validationMessages.atMax', {
              fieldName: t('forms:labels.certificateName').toLowerCase(),
              max: 100,
            }) ?? '',
          ),
    issuer: yup.string(),
    issueDate: yup
      .date()
      .nullable()
      .max(new Date(), t('forms:validationMessages.maxDate') as string),
    url: yup.string().url(t('forms:validationMessages.urlNotValid') as string),
  });
