import { ContainerWithSidebox } from 'layouts';
import { useTranslation } from 'react-i18next';
import { AuthGenericForm } from '../../components/Auth/forms/AuthGenericForm';

export const SignInPage = () => {
  const { t } = useTranslation();

  return (
    <ContainerWithSidebox title={t('pageTitles:signIn')}>
      <AuthGenericForm mode="signIn" />
    </ContainerWithSidebox>
  );
};
