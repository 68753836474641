import { yupResolver } from '@hookform/resolvers/yup';
import {
  ConfirmationModal,
  Paper,
  SmallButton,
  workExperienceFormSchema,
  WorkHistoryForm,
  WorkHistoryItem,
} from 'components';
import { WorkExperienceFormValues } from 'components/WorkHistory/WorkHistoryForm';
import { ConfirmationModalConfirmFn, useModalState } from 'hooks';
import { PlusIcon } from 'icons';
import { useEffect } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mapObjectInArray } from 'utils';

interface ExperienceValues {
  workHistory: WorkExperienceFormValues[];
}

export const Experience = () => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<ExperienceValues>();

  const formMethods = useForm<WorkExperienceFormValues>({
    resolver: yupResolver(workExperienceFormSchema(t)),
  });

  const workHistoryValue = watch('workHistory');

  const handleRemoveWorkHistory: ConfirmationModalConfirmFn<number | null> = async (
    { data: workHistoryIndex },
    closeModal,
  ) => {
    setValue(
      'workHistory',
      workHistoryValue.filter((_, i) => i !== workHistoryIndex),
      { shouldDirty: true, shouldValidate: true },
    );
    closeModal();
  };

  const {
    modalState: confirmationModalState,
    openModal: openConfirmationModal,
    closeModal: closeConfirmationModal,
    confirmModal: confirmConfirmationModal,
  } = useModalState<number>({
    onConfirm: handleRemoveWorkHistory,
  });

  const {
    modalState: formState,
    openModal: openForm,
    closeModal: closeForm,
  } = useModalState<number | null>();

  const handleCancelClick = () => {
    closeForm();
    formMethods.reset({});
  };

  const handleSave = (workHistory: WorkExperienceFormValues) => {
    let updatedWorkHistory = [...workHistoryValue, workHistory];
    if (formState.data !== null) {
      updatedWorkHistory = mapObjectInArray(
        workHistoryValue,
        (_, index) => index === formState.data,
        () => workHistory,
      );
    }
    setValue('workHistory', updatedWorkHistory, { shouldDirty: true, shouldValidate: true });
    handleCancelClick();
  };

  useEffect(() => {
    if (typeof workHistoryValue !== 'undefined' && !workHistoryValue.length) {
      openForm(null);
    }
  }, [workHistoryValue]);

  return (
    <>
      <ConfirmationModal
        isOpen={confirmationModalState.isOpen}
        onClose={closeConfirmationModal}
        onConfirm={confirmConfirmationModal}
        text={t('profile:papers.workHistory.removeModal.text')}
      />
      <Paper
        className="!p-4 xl:!p-8 !mt-0"
        id="workHistory"
        subtitle={t('profile:papers.workHistory.subtitle')}
        subtitleClassName="!mb-4 lg:!mb-6"
        title={t('profile:papers.workHistory.title')}
        titleClassName="!mb-0 xl:!mb-0.5"
      >
        <form onSubmit={formMethods.handleSubmit(handleSave)}>
          <FormProvider {...formMethods}>
            <div className="space-y-2">
              {workHistoryValue?.map((wh, i) => {
                const isEdited = formState.isOpen && formState.data === i;
                const key = `workHistory_${wh.jobPositionName}_${wh.companyName}_${wh.dateStart}`;
                return !isEdited ? (
                  <WorkHistoryItem
                    {...wh}
                    key={key}
                    onEdit={() => openForm(i)}
                    onRemove={() => openConfirmationModal(i)}
                  />
                ) : (
                  <WorkHistoryForm
                    key={key}
                    className="!mt-6"
                    initialData={wh}
                    onCancelClick={handleCancelClick}
                  />
                );
              })}
            </div>
            {!formState.isOpen && !workHistoryValue?.length && (
              <p className="text-sm font-medium">
                {t('profile:papers.workHistory.noDataPlaceholder')}
              </p>
            )}
            {formState.isOpen && formState.data === null && (
              <WorkHistoryForm className="!mt-6" onCancelClick={handleCancelClick} />
            )}
          </FormProvider>
        </form>
        {!formState.isOpen && (
          <SmallButton
            className="text-xs !mt-8"
            icon={PlusIcon}
            label={t('profile:papers.workHistory.addNewButton')}
            onClick={() => openForm(null)}
            variant="primary"
          />
        )}
      </Paper>
    </>
  );
};
