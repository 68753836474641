import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
const defaultOptions = {} as const;
export type OnboardingDataQueryVariables = Types.Exact<{ [key: string]: never }>;

export type OnboardingDataQuery = {
  __typename?: 'Query';
  jobPositions: Array<{
    __typename?: 'JobPositionType';
    jobPositionId: string;
    name: string;
    isOnMainList: boolean;
    availableMainTechnologies: Array<{
      __typename?: 'AvailableMainTechnologiesResolvedType';
      name?: string | null;
      mainTechnologies: Array<{
        __typename?: 'MainTechnologiesResolvedType';
        defaultTagIds?: Array<string> | null;
        tag: { __typename?: 'ResolvedTagType'; id: string; name: string };
      }>;
    }>;
  }>;
  languages: Array<{ __typename?: 'LanguageType'; name: string; languageId: string }>;
  industries: Array<{ __typename?: 'IndustryType'; industryId: string; name: string }>;
  tags: Array<{
    __typename?: 'TagType';
    id: string;
    name: string;
    type: Types.TagTypeEnum;
    displayName?: string | null;
  }>;
};

export const OnboardingDataDocument = gql`
  query onboardingData {
    jobPositions {
      jobPositionId
      name
      isOnMainList
      availableMainTechnologies {
        name
        mainTechnologies {
          tag {
            id
            name
          }
          defaultTagIds
        }
      }
    }
    languages {
      name
      languageId
    }
    industries {
      industryId
      name
    }
    tags {
      id
      name
      type
      displayName
    }
  }
`;

/**
 * __useOnboardingDataQuery__
 *
 * To run a query within a React component, call `useOnboardingDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingDataQuery(
  baseOptions?: Apollo.QueryHookOptions<OnboardingDataQuery, OnboardingDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OnboardingDataQuery, OnboardingDataQueryVariables>(
    OnboardingDataDocument,
    options,
  );
}
export function useOnboardingDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OnboardingDataQuery, OnboardingDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OnboardingDataQuery, OnboardingDataQueryVariables>(
    OnboardingDataDocument,
    options,
  );
}
export type OnboardingDataQueryHookResult = ReturnType<typeof useOnboardingDataQuery>;
export type OnboardingDataLazyQueryHookResult = ReturnType<typeof useOnboardingDataLazyQuery>;
export type OnboardingDataQueryResult = Apollo.QueryResult<
  OnboardingDataQuery,
  OnboardingDataQueryVariables
>;
export function refetchOnboardingDataQuery(variables?: OnboardingDataQueryVariables) {
  return { query: OnboardingDataDocument, variables: variables };
}
