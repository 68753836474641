import { CheckIcon } from 'icons';
import { FC } from 'react';

interface CheckedListItemProps {
  label: string;
}

export const CheckedListItem: FC<CheckedListItemProps> = ({ label }) => (
  <li className="flex items-center text-gray-500 leading-[22.5px] text-[15px] md:text-base">
    <CheckIcon className="w-4 h-4 mr-2 text-primary-500" />
    {label}
  </li>
);
