import { JsonObject } from 'type-fest';

export const home: JsonObject = {
  recentApplications: {
    title: 'Twoje ostatnie aplikacje',
    subtitle: 'Dopasowane oferty na podstawie Twojego profilu',
  },
  discord: {
    title: 'Dołącz do Discord Connectis',
    description:
      'Na Discordzie stworzyliśmy miejsce do współpracy nad dalszym rozwojem platformy C_hub.\n\nDołącz do naszej społeczności i czuj się jak u siebie!',
  },
  news: {
    title: 'Aktualności',
    added: 'Dodano',
    notFoundPlaceholder: 'Nie znaleziono żadnych aktualności',
  },
};
