import { FC } from 'react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { mergeClasses } from 'utils';
import { EnvironmentConfig } from '../../environment-config';

interface CircularProgressProps {
  className?: string;
  textClassName?: string;
  value: number;
  alternativeText?: string;
  staticColor?: 'green' | 'blue' | 'gray';
}

const colors = {
  green: '#40DD19',
  blue: '#0C00FF',
  gray: '#949494',
};

export const CircularProgress: FC<CircularProgressProps> = ({
  textClassName = '',
  className = '',
  value,
  alternativeText,
  staticColor,
}) => {
  const isMeetingJobOrderRequirements = value >= EnvironmentConfig.minimalScoreToApply;
  const isBelowJobOrderRequirements = value < EnvironmentConfig.minimalScoreToApply && value >= 50;

  const determineStrokeColor = () => {
    if (staticColor) {
      return colors[staticColor];
    }

    if (isMeetingJobOrderRequirements) {
      return colors.green;
    }
    if (isBelowJobOrderRequirements) {
      return colors.blue;
    }
    return colors.gray;
  };

  return (
    <div className={className}>
      <CircularProgressbarWithChildren
        strokeWidth={5}
        styles={{
          ...buildStyles({
            textColor: '#fff',
            trailColor: 'rgba(12, 0, 255, 0.1)',
          }),
          path: {
            stroke: determineStrokeColor(),
            filter: `drop-shadow(0px 2px 3px ${
              isMeetingJobOrderRequirements ? 'rgba(64, 221, 25, 0.3)' : 'rgba(12, 0, 255, 0.3)'
            })`,
          },
        }}
        value={value}
      >
        <span
          className={mergeClasses('text-sm text-white font-medium leading-5', textClassName)}
        >{`${alternativeText || value}%`}</span>
      </CircularProgressbarWithChildren>
    </div>
  );
};
