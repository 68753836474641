import { IconButton } from 'components';
import { ConnectisLogo, CrossIcon } from 'icons';
import { FC } from 'react';
import { EnvironmentConfig } from '../../../../../environment-config';

export const MobileMenuHeader: FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <div className="flex justify-between items-center mb-7">
      <a href={EnvironmentConfig.connectisWebsiteUrl}>
        <ConnectisLogo className="w-[120px]" />
      </a>
      <IconButton
        className="p-0"
        icon={CrossIcon}
        iconClassName="text-gray-600 w-6 h-6"
        onClick={onClose}
      />
    </div>
  );
};
