import { Button, Modal, ModalProps } from 'components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'utils';

interface ConfirmationModalProps extends ModalProps {
  text?: string | null;
  onConfirm?: () => void;
  isConfirmButtonDisabled?: boolean;
  isConfirmButtonLoading?: boolean;
  confirmButtonLabel?: string;
  onCancel?: () => void;
  isCancelButtonDisabled?: boolean;
  cancelButtonLabel?: string;
  showHeader?: boolean;
  headerText?: string;
  headerClassName?: string;
  modalClassName?: string;
  buttonClassName?: string;
  buttonsContainerClassName?: string;
}

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  text,
  cancelButtonLabel,
  confirmButtonLabel,
  isCancelButtonDisabled = false,
  isConfirmButtonDisabled = false,
  isConfirmButtonLoading = false,
  onCancel,
  onConfirm,
  showHeader = false,
  headerText,
  headerClassName,
  modalClassName,
  buttonClassName,
  buttonsContainerClassName,
  ...modalProps
}) => {
  const { t } = useTranslation();

  return (
    <Modal {...modalProps}>
      <div className={mergeClasses('max-w-sm px-4', modalClassName)}>
        {showHeader && (
          <p
            className={mergeClasses(
              'text-[32px] leading-[40px] font-bold text-center pb-2',
              headerClassName,
            )}
          >
            {headerText}
          </p>
        )}
        <p className="mb-8 text-lg text-center">{text}</p>
        <div className={mergeClasses('flex justify-center space-x-4', buttonsContainerClassName)}>
          <Button
            className={buttonClassName}
            isDisabled={isCancelButtonDisabled}
            label={cancelButtonLabel ?? t('common:actions.cancel')}
            onClick={onCancel || modalProps.onClose}
            variant="outline"
          />
          <Button
            className={buttonClassName}
            isDisabled={isConfirmButtonDisabled}
            isLoading={isConfirmButtonLoading}
            label={confirmButtonLabel ?? t('common:actions.confirm')}
            onClick={onConfirm}
          />
        </div>
      </div>
    </Modal>
  );
};
