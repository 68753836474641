import { Button } from 'components';
import { useAsyncCallback } from 'hooks';
import { LogoLayout } from 'layouts';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useResendEmailVerificationMutation } from '../../hooks/api/resendEmailVerification/resendEmailVerification.generated';

export const PleaseVerifyEmailPage = () => {
  const { t } = useTranslation();
  const [resendEmailVerification] = useResendEmailVerificationMutation();

  const handleResendEmailVerification = async () => {
    await resendEmailVerification({
      onCompleted: () => toast.success(t('auth:pleaseVerifyEmail.resendSuccessToast')),
      onError: () => toast.error(t('toasts:error')),
    });
  };

  const [resendEmailVerificationCallback, { loading }] = useAsyncCallback(
    handleResendEmailVerification,
  );

  return (
    <LogoLayout title={t('pageTitles:emailConfirmation')}>
      <div className="max-w-[538px] mx-auto mt-10 flex flex-col items-center text-center">
        <p className="text-3xl md:text-[40px] font-bold">{t('auth:pleaseVerifyEmail.title')}</p>
        <p className="text-base md:text-lg text-gray-500 my-4">
          {t('auth:pleaseVerifyEmail.subtitle')}
        </p>
        <Button
          className="mt-4 w-[206px]"
          isDisabled={loading}
          isLoading={loading}
          label={t('auth:pleaseVerifyEmail.resendEmail')}
          onClick={() => resendEmailVerificationCallback()}
        />
      </div>
    </LogoLayout>
  );
};
