import { t } from 'i18next';
import privacyPolicy from '../../../assets/pdfs/Regulations-and-Privacy-Policy-v2.pdf';

export const ExternalOfferLegalNotes = () => {
  return (
    <div className="text-xs text-light-blue-200 mt-6">
      <p>
        <span>{t('auth:signUpForm.legalAgreement.byCreatingAccount')}</span>
        <a className="hover:underline" href={privacyPolicy} rel="noreferrer" target="_blank">
          {t('auth:signUpForm.legalAgreement.terms')}
        </a>
        <span> {t('auth:signUpForm.legalAgreement.and')} </span>
        <a
          className="hover:underline"
          href={`${privacyPolicy}#page=11`}
          rel="noreferrer"
          target="_blank"
        >
          {t('auth:signUpForm.legalAgreement.policy')}
        </a>
        <span>. {t('auth:signUpForm.legalAgreement.accountRegistration')}</span>
      </p>
      <p className="mt-4">
        <span>{t('auth:signUpForm.legalAgreement.administrativeInfo')}</span>
        <a
          className="hover:underline"
          href={`mailto:${t('auth:signUpForm.legalAgreement.legalEmail')}`}
        >
          {t('auth:signUpForm.legalAgreement.legalEmail')}
        </a>
        <span>. {t('auth:signUpForm.legalAgreement.contactInfo')}</span>
        <a
          className="hover:underline"
          href={`mailto:${t('auth:signUpForm.legalAgreement.legalEmail')}`}
        >
          {t('auth:signUpForm.legalAgreement.legalEmail')}
        </a>
        <span> {t('auth:signUpForm.legalAgreement.dataInspectorCopy')} </span>
        <a className="hover:underline" href="mailto:iod@connectis.pl">
          {t('auth:signUpForm.legalAgreement.dataInspectorEmail')}
        </a>
        <span>.</span>
      </p>
    </div>
  );
};
