import dayjs from 'dayjs';
import { JobOrderQueryType } from 'interfaces';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter, sortHoursAscending } from 'utils';

interface JobOrderPickedMeetingDatesProps {
  jobOrder: JobOrderQueryType | null;
}

type GroupedTimings = {
  [date: string]: string[];
};

const groupTimingsByDate = (meetingDates: string[]) => {
  return meetingDates.reduce((groupedTimings: GroupedTimings, meetingDate) => {
    const date = dayjs(meetingDate).format('dddd, D MMMM');
    if (!groupedTimings[date]) {
      groupedTimings[date] = [];
    }
    groupedTimings[date].push(dayjs(meetingDate).format('HH:mm'));
    return groupedTimings;
  }, {});
};

export const JobOrderPickedMeetingDates: FC<JobOrderPickedMeetingDatesProps> = ({ jobOrder }) => {
  const { t } = useTranslation();

  const meetingDates = jobOrder?.application?.meetingDates ?? [];
  const groupedTimings: GroupedTimings = groupTimingsByDate(meetingDates);

  return (
    <div className="mt-8">
      <p>{t('datePicker:successBox.selectedTimeslots')}:</p>
      <ul>
        {Object.entries(groupedTimings).map(([date, timings]) => {
          const hoursWithEndHour = sortHoursAscending(timings).map((start) => [
            start,
            dayjs(start, 'HH:mm').add(1, 'hour').format('HH:mm'),
          ]);
          return (
            <li key={date} className="mt-4">
              <div className="flex flex-col gap-2">
                <span className="text-[16px] leading-[18px] capitalize font-medium">
                  {capitalizeFirstLetter(date)}
                </span>
                {hoursWithEndHour.map(([start, end]) => `${start}-${end}`).join(', ')}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
