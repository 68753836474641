import {
  AVAILABLE_MEETING_LENGTHS,
  HOURS_STEP,
} from 'components/Modals/MeetingScheduleModal/constants';
import dayjs from 'dayjs';

export type Option = {
  value: string | number | null;
  label: string;
};

export const getTotalHoursBetween = (startHour: string, endHour: string) => {
  const hours: string[] = [];

  let currentHour = dayjs(startHour, 'HH:mm');
  do {
    hours.push(currentHour.format('HH:mm'));
    currentHour = currentHour.add(HOURS_STEP, 'minute');
  } while (dayjs(currentHour).isSameOrBefore(dayjs(endHour, 'HH:mm')));

  return hours;
};

export const getHourMeetingOptions = (
  startHour: string,
  endHour: string,
  meetingLength: MeetingLength,
) => {
  const hours = getTotalHoursBetween(startHour, endHour);

  const hourOptions = hours.map((hour) => {
    const endHourString = dayjs(hour, 'HH:mm').add(meetingLength, 'minute').format('HH:mm');

    return {
      value: hour,
      label: `${hour} - ${endHourString}`,
    };
  });

  return hourOptions;
};

export type MeetingLength = typeof AVAILABLE_MEETING_LENGTHS[number];

interface FilterAlreadyUsedTimeslotsParams {
  options: Option[];
  alreadyUsedTimeslots: (string | null)[];
  filterHoursBeforeNow: boolean;
  meetingLength: MeetingLength;
}

export const filterAlreadyUsedTimeslots = ({
  options,
  alreadyUsedTimeslots,
  filterHoursBeforeNow,
  meetingLength = 60,
}: FilterAlreadyUsedTimeslotsParams) => {
  const notConflictingOptions = options
    .map(({ value }) => value)
    .filter((value) => {
      const isStartHourInConflict = alreadyUsedTimeslots.some((ts) => {
        return dayjs(value, 'HH:mm').isBetween(
          dayjs(ts, 'HH:mm'),
          dayjs(ts, 'HH:mm').add(meetingLength, 'minute'),
          'minute',
          '[)',
        );
      });

      const isEndHourInConflict = alreadyUsedTimeslots.some((ts) => {
        return dayjs(value, 'HH:mm')
          .add(meetingLength, 'minute')
          .isBetween(
            dayjs(ts, 'HH:mm'),
            dayjs(ts, 'HH:mm').add(meetingLength, 'minute'),
            'minute',
            '(]',
          );
      });

      return !isStartHourInConflict && !isEndHourInConflict;
    })
    .filter((value) => {
      if (!filterHoursBeforeNow) {
        return true;
      }

      return dayjs(value, 'HH:mm').isAfter(dayjs());
    });

  return options.filter(({ value }) => notConflictingOptions.includes(value));
};
