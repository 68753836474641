import { CheckIcon } from 'icons';
import { forwardRef, MouseEventHandler } from 'react';
import { mergeClasses } from 'utils';

interface RadioItemProps {
  name: string;
  value?: string;
  isChecked?: boolean;
  id: string;
  label: string;
  isError?: boolean;
  isHighlighted?: boolean;
  onChange: (value: string) => void;
  className?: string;
}

export const RadioItem = forwardRef<HTMLInputElement, RadioItemProps>(
  (
    { label, name, isError, isHighlighted, onChange, id, value, className = '', isChecked },
    ref,
  ) => {
    const handleChange: MouseEventHandler<HTMLLabelElement> = (e) => {
      e.preventDefault();
      if (onChange) {
        onChange(id);
      }
    };

    return (
      <label
        className={mergeClasses(
          'flex items-center border p-4 rounded-lg cursor-pointer transition-all gap-4',
          isError ? '!border-red-500' : '',
          isHighlighted ? 'border-primary-500' : 'border-gray-200',
          isHighlighted
            ? 'bg-primary-500 bg-opacity-10 hover:bg-opacity-20'
            : 'bg-white hover:bg-gray-100 hover:bg-opacity-20',
          className,
        )}
        htmlFor={id}
        onClick={handleChange}
      >
        <input
          ref={ref}
          checked={value === id || isChecked || false}
          className="peer sr-only"
          id={id}
          name={name}
          onChange={() => onChange && onChange(id)}
          type="radio"
          value={value}
        />
        <div
          className={mergeClasses(
            'rounded-full border min-w-[24px] min-h-[24px] w-6 h-6 relative flex justify-center items-center cursor-pointer peer-checked:border-primary-500 text-transparent transition-all peer-checked:text-primary-500',
            isError ? 'border-red-500' : 'border-gray-200',
          )}
        >
          <CheckIcon className="transition-all w-6 bg-white rounded-full" />
        </div>
        <p className="text-gray-500 peer-checked:text-black transition-all leading-4">{label}</p>
      </label>
    );
  },
);

RadioItem.displayName = 'RadioItem';
