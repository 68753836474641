import { Button, CalendarDatePicker, MeetingHourSelect, Modal, ModalProps } from 'components';
import dayjs from 'dayjs';
import { useAsyncCallback, useExpertProfile, useScrollIntoView } from 'hooks';
import { JobOrderQueryType } from 'interfaces';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useWindowSize from '../../../hooks/useWindowSize';
import { hasNoFalsyItems } from '../../../utils/hasNoFalsyItems';
import { mapObjIndexed } from '../../../utils/mapObjIndexed';
import { mapObjValues } from '../../../utils/mapObjValues';
import { SuccessBox, WorkHistoryModal } from './subcomponents';

interface MeetingScheduleModalProps extends Omit<ModalProps, 'children' | 'title'> {
  onSendSuccess: (suggestedMeetingHours: string[]) => Promise<boolean>;
  jobOrder: JobOrderQueryType | null;
}

export type TimeslotsData = Record<string, (string | null)[]>;

export interface MobileMeetingHourSelectState {
  isOpen: boolean;
  currentDate: null | string;
}

const defaultMobileMeetingHourSelectState: MobileMeetingHourSelectState = {
  isOpen: false,
  currentDate: null,
};

const mapTimeslotsDataToISOStrings = (timeslotsData: TimeslotsData): string[] =>
  Object.entries(timeslotsData)
    .map(([date, timeslots]) =>
      timeslots
        .filter((hour) => hour)
        .map((hour) => {
          const dayDate: string = dayjs(date).format('YYYY-MM-DD');
          return dayjs(`${dayDate}-${hour}`, 'YYYY-MM-DD-HH:mm').toISOString();
        }),
    )
    .flat();

export const extractTime = (time: string | null) => {
  return Number(time?.replace(':', ''));
};

export const MeetingScheduleModal: FC<MeetingScheduleModalProps> = ({
  onClose,
  onSendSuccess,
  jobOrder,
  ...props
}) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const { ref } = useScrollIntoView<HTMLDivElement>();

  const isBelowDesktopWidth = width ? width <= 1023 : undefined;

  const { profile } = useExpertProfile();

  const [success, setSuccess] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [timeslotError, setTimeslotError] = useState(false);
  const [datesLimitError, setDatesLimitError] = useState(false);
  const [isWorkHistoryModalOpen, setIsWorkHistoryModalOpen] = useState(false);

  const [newestDayElementId, setNewestDayElementId] = useState<string | null>(null);
  const [mobileMeetingHourSelectState, setMobileMeetingHourSelectState] =
    useState<MobileMeetingHourSelectState>(defaultMobileMeetingHourSelectState);
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [timeslotsData, setTimeslotsData] = useState<TimeslotsData>({});

  const isTimingSet = useMemo(() => mapObjValues(hasNoFalsyItems)(timeslotsData), [timeslotsData]);

  const hasWorkHistory = !!profile?.workHistory.length;

  const totalDatesLength = Object.keys(timeslotsData).flat().length;
  const isSubmittingBlocked = totalDatesLength >= 6;

  const handleSend = async () => {
    const hasTiming = Object.values(isTimingSet).every((timingSet) => timingSet);
    if (!hasTiming) {
      setTimeslotError(true);
      return;
    }
    if (!selectedDays.length) {
      setDateError(true);
      return;
    }

    const isSuccess = await onSendSuccess(mapTimeslotsDataToISOStrings(timeslotsData));
    if (isSuccess) {
      setSuccess(true);
    }
  };

  const [handleSendCallback, { loading: sendLoading }] = useAsyncCallback(handleSend);

  const handleCloseModal = () => {
    setSuccess(false);
    setTimeslotError(false);
    setDateError(false);
    setDatesLimitError(false);
    setSelectedDays([]);
    setTimeslotsData({});
    setIsWorkHistoryModalOpen(false);
    if (onClose) onClose();
    setMobileMeetingHourSelectState(defaultMobileMeetingHourSelectState);
  };

  const handleRemoveDay = useCallback((date: string) => {
    setSelectedDays((prev) => prev.filter((el) => el !== date));
    setTimeslotsData((prev) => {
      const { [date]: removedDate, ...rest } = prev;
      return rest;
    });
  }, []);

  const handleDayClick = (date: string) => {
    if (selectedDays.includes(date)) {
      setNewestDayElementId(date);
    }
    if (!selectedDays.includes(date)) {
      setNewestDayElementId(date);
      return setSelectedDays((prev) => [...prev, date]);
    }
  };

  const handleAddDayClick = () =>
    setMobileMeetingHourSelectState(defaultMobileMeetingHourSelectState);

  useEffect(() => {
    if (timeslotError) {
      setTimeslotError(false);
    }
    if (dateError) {
      setDateError(false);
    }
  }, [timeslotsData, selectedDays]);

  useEffect(() => {
    if (!newestDayElementId || isBelowDesktopWidth) {
      return;
    }

    const newestDayElement = document.getElementById(newestDayElementId);
    if (newestDayElement) {
      newestDayElement.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [newestDayElementId]);

  const sortedTimeslotsData = mapObjIndexed((timings: (string | null)[]) => {
    const timingsToBeSorted = [...timings];
    const sortedTimings = timingsToBeSorted.sort((a, b) =>
      Math.sign(extractTime(a) - extractTime(b)),
    );
    return sortedTimings;
  })(timeslotsData);

  const referredDiv = document.getElementById('refDiv');

  const handleAddTimeslotsButtonClick = () => {
    if (isSubmittingBlocked) {
      return;
    }
    if (!selectedDays.length) {
      setDateError(true);
      referredDiv?.scrollIntoView();
      return;
    }
    setMobileMeetingHourSelectState({
      isOpen: true,
      currentDate: mobileMeetingHourSelectState.currentDate,
    });
  };

  useEffect(() => {
    if (selectedDays.length >= 5) {
      setDatesLimitError(true);
      return;
    }
    if (datesLimitError) {
      setDatesLimitError(false);
    }
  }, [totalDatesLength]);

  if (isWorkHistoryModalOpen) {
    return <WorkHistoryModal isOpen={isWorkHistoryModalOpen} onClose={handleCloseModal} />;
  }

  return (
    <Modal
      {...props}
      backgroundClassName="p-0 pt-10 lg:p-4"
      className="w-screen rounded-t-lg rounded-b-none h-full lg:h-[840px] lg:w-full lg:max-w-[1108px] lg:rounded-lg lg:flex lg:flex-col p-0"
      closeModalOnBackgroundClick={hasWorkHistory}
      fromBottom={isBelowDesktopWidth}
      iconButtonClassName="p-0 text-gray-400 lg:top-8 lg:right-8"
      iconClassName="!w-6 !h-6"
      onClose={handleCloseModal}
      showCloseButton={!success}
    >
      {success ? (
        <SuccessBox
          hasWorkHistory={hasWorkHistory}
          jobOrder={jobOrder}
          meetingLength={60}
          onClose={handleCloseModal}
          openWorkHistoryModal={() => setIsWorkHistoryModalOpen(true)}
          timeslotsData={timeslotsData}
        />
      ) : (
        <div className="bottom-0 flex flex-col w-full h-full max-w-full fixed lg:relative">
          <div className="h-full overflow-auto pb-36 sm:pb-24 lg:pb-0 lg:mb-6">
            <div className="pt-[56px] lg:pt-10 px-4 md:px-10">
              <p className="text-[24px] xl:text-[32px] font-bold leading-8 xl:leading-[40px]">
                {t('datePicker:scheduleMeetingModal.title')}
              </p>
              <p className="text-[16px] xl:text-[18px] leading-[22px] xl:leading-[24px] pt-2 font-medium">
                {t('datePicker:scheduleMeetingModal.subtitle')}
              </p>
            </div>
            <div className="flex md:px-16 mt-6 lg:mt-8 justify-center lg:gap-[72px]">
              <CalendarDatePicker
                error={dateError}
                isDatesLimitExceeded={selectedDays.length >= 6}
                onDayClick={handleDayClick}
                onRemove={handleRemoveDay}
                selectedDays={selectedDays}
              />
              <MeetingHourSelect
                {...mobileMeetingHourSelectState}
                error={timeslotError}
                isBelowDesktopWidth={isBelowDesktopWidth}
                isTimingSet={isTimingSet}
                meetingLength={60}
                onAddDayClick={handleAddDayClick}
                onChange={setTimeslotsData}
                onRemoveDay={handleRemoveDay}
                onSubmit={handleSendCallback}
                selectedDays={selectedDays}
                sendLoading={sendLoading}
                timeslotsData={sortedTimeslotsData}
              />
              <div ref={ref} id="refDiv" />
            </div>
          </div>
          <div className="bg-white shadow-[0_-4px_20px_-0_rgba(3,20,82,0.12)] lg:bg-gray-200 lg:bg-opacity-20 md:py-6 px-4 md:px-16 pt-6 lg:pt-0 pb-8 lg:pb-6 lg:pt-6 fixed lg:relative bottom-0 w-full z-10 lg:z-0">
            {datesLimitError && (
              <p className="mb-2 text-sm font-medium text-center text-red-500">
                {t('datePicker:timeslotsLimitExceeded')}
              </p>
            )}
            <Button
              className="w-full lg:hidden"
              label={t('datePicker:meetingHourSelect.addTimeslots')}
              onClick={() => handleAddTimeslotsButtonClick()}
            />
            <Button
              className="hidden w-full lg:block"
              isDisabled={selectedDays.length >= 6}
              isLoading={sendLoading}
              label={t('datePicker:scheduleMeetingModal.actions.send')}
              labelClassName="justify-center"
              onClick={() => handleSendCallback()}
            />
            {Object.values(isTimingSet).every((timingSet) => timingSet) && timeslotError && (
              <p className="mt-2 text-sm font-medium text-center text-red-500">
                {t('datePicker:errorMessage')}
              </p>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};
