import { useReactiveVar } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Modal, ModalProps } from 'components';
import { useAuth } from 'hooks';
import { TFunction } from 'i18next';
import { CognitoErrorCodesEnum, Shape } from 'interfaces';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { profileVar } from 'reactive-vars';
import { mergeClasses } from 'utils';
import * as yup from 'yup';
import { CurrentExpertIdentityQuery } from '../../../../../hooks/api/currentExpertIdentity/currentExpertIdentity.generated';
import { useDeleteExpertAccountMutation } from '../../../../../hooks/api/deleteExpertAccount/deleteExpertAccount.generated';
import { useSendGenericPurposeOtpMutation } from '../../../../../hooks/api/sendGenericPurposeOtp/sendGenericPurposeOtp.generated';
import { useVerifyGenericPurposeOtpMutation } from '../../../../../hooks/api/verifyGenericPurposeOtp/verifyGenericPurposeOtp.generated';
import useMobile from '../../../../../hooks/useMobile';
import { SettingsOTPInputStep } from './SettingsOTPInput';

export interface RemoveAccountModalProps extends ModalProps {
  expertIdentity: CurrentExpertIdentityQuery['currentExpertIdentity'] | undefined;
}

export interface RemoveAccountModalFormValues {
  password: string;
}

export const accountRemoveSchema = (t: TFunction) =>
  yup.object().shape<Shape<RemoveAccountModalFormValues>>({
    password: yup.string().required(
      t('forms:validationMessages.required', {
        fieldName: t('forms:labels.password').toLowerCase(),
      }) ?? '',
    ),
  });

export const RemoveAccountModal: FC<RemoveAccountModalProps> = ({
  expertIdentity,
  ...modalProps
}) => {
  const { t } = useTranslation();
  const { deleteUser, authLoading } = useAuth();
  const [deleteExpertAccount] = useDeleteExpertAccountMutation({
    errorPolicy: 'ignore',
  });
  const [verifyGenericPurposeOtp, { loading: verifyOTPLoading }] =
    useVerifyGenericPurposeOtpMutation();
  const [sendGenericPurposeOtp] = useSendGenericPurposeOtpMutation();
  const { email } = useReactiveVar(profileVar) ?? {};
  const { isMobile } = useMobile();

  const [isOTPInputVisible, setIsOTPInputVisible] = useState(false);

  const { reset, setError } = useForm<RemoveAccountModalFormValues>({
    resolver: yupResolver(accountRemoveSchema(t)),
  });

  const handleDeleteExpertAccount = async (props?: RemoveAccountModalFormValues) => {
    try {
      if (!email) {
        return;
      }
      await deleteExpertAccount();

      const res = await deleteUser({
        email,
        password: props ? props.password : undefined,
      });

      if (res?.code === CognitoErrorCodesEnum.NotAuthorizedException) {
        return setError('password', { message: t('forms:validationMessages.wrongPassword') ?? '' });
      }

      if (typeof res?.code !== 'undefined') {
        return toast.error(t('toasts:error'));
      }

      modalProps.onClose?.();

      reset({
        password: '',
      });

      toast.success(t('settings:removeAccountModal.successToast'));
    } catch (err) {
      toast.error(t('toasts:error'));
      console.error(err);
    }
  };

  const handleOnDeleteButtonClick = async () => {
    await sendGenericPurposeOtp();
    setIsOTPInputVisible(true);
  };

  const handleOnOTPSubmit = async (otp: string) => {
    try {
      const verificationResult = await verifyGenericPurposeOtp({
        variables: { otp },
      });

      if (verificationResult.data?.verifyGenericPurposeOtp) {
        await handleDeleteExpertAccount();
        return;
      }
      toast.warn(t('settings:removeAccountModal.incorrectOTP'));
      modalProps.onClose?.();
      setIsOTPInputVisible(false);
    } catch (err) {
      toast.error(t('toasts:error'));
      console.error(err);
    }
  };

  const handleModalClose = () => {
    modalProps.onClose?.();
    setIsOTPInputVisible(false);
  };

  return (
    <Modal
      showCloseButton={false}
      {...modalProps}
      backgroundClassName="p-0 top-0 bottom-0 !bg-white md:!bg-black md:!bg-opacity-80"
      className={mergeClasses(
        'flex flex-col md:items-center md:justify-center w-screen h-screen lg:rounded-2xl md:h-auto md:max-w-[800px]',
      )}
      fromBottom={isMobile}
      iconButtonClassName="hidden md:block"
      onClose={handleModalClose}
      showBackButton={isMobile}
      showLogo={isMobile}
      titleClassName="!text-[24px] !leading-[32.5px] lg:!leading-[40px] !font-bold mb-2"
    >
      <p className="text-[24px] leading-[32.5px] md:leading-[40px] font-bold mb-2">
        {t('settings:OTPcode.confirmEmail')}
      </p>
      <div className="flex flex-col md:max-w-[650px]">
        <p className="md:text-center text-[16px] md:text-[18px] leading-[24px] md:leading-[27px]">
          {t('settings:OTPcode.codeSubtitle')}
        </p>
        <p className="md:text-center md:mb-2 text-[16px] md:text-[18px] leading-[24px] md:leading-[27px]">
          {t('settings:OTPcode.codeSubtitle2')}
        </p>
        <p className="md:text-center text-[16px] md:text-[18px] leading-[24px] md:leading-[27px]">
          {t('settings:OTPcode.codeSubtitle3')}
        </p>
      </div>

      {isOTPInputVisible ? (
        <SettingsOTPInputStep
          loading={authLoading}
          onCancel={handleModalClose}
          onOTPSubmit={handleOnOTPSubmit}
          onResendCodeClick={sendGenericPurposeOtp}
        />
      ) : (
        <div className="w-full flex flex-col gap-6 md:flex-row-reverse md:justify-center md:mt-8">
          <Button
            className="mt-8 md:mt-0 w-full md:w-48"
            isDisabled={verifyOTPLoading}
            isLoading={verifyOTPLoading}
            label={t('settings:actions.deleteAccount')}
            onClick={() => handleOnDeleteButtonClick()}
            variant="danger"
          />
          <Button
            className="w-full md:w-48"
            label={t('settings:actions.cancel')}
            onClick={() => handleModalClose()}
            variant="outline"
          />
        </div>
      )}
    </Modal>
  );
};
