import { FC, useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import { AuthenticatedRoutes, DefaultRoutes } from './routes';

export const AppRouter: FC<{ isAuthenticated: boolean }> = ({ isAuthenticated }) => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location.pathname, location.search]);

  return isAuthenticated ? <AuthenticatedRoutes /> : <DefaultRoutes />;
};
