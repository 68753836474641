import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Button, PasswordRequirementsList, PasswordTextField, SmallButton } from 'components';
import { useAuth } from 'hooks';
import { TFunction } from 'i18next';
import { CognitoErrorCodesEnum, Shape } from 'interfaces';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { PASSWORD_REQUIREMENTS_REGEX } from 'utils';
import * as yup from 'yup';
import { PasswordChangeValues } from './AccountTab';

interface ChangePasswordFormValues {
  currentPassword: string;
  newPassword: string;
}

export const changePasswordFormSchema = (t: TFunction) =>
  yup.object().shape<Shape<ChangePasswordFormValues>>({
    currentPassword: yup.string().required(
      t('forms:validationMessages.required', {
        fieldName: t('forms:labels.currentPassword').toLowerCase(),
      }) ?? '',
    ),
    newPassword: yup
      .string()
      .required(
        t('forms:validationMessages.required', {
          fieldName: t('forms:labels.newPassword').toLowerCase(),
        }) ?? '',
      )
      .matches(
        PASSWORD_REQUIREMENTS_REGEX,
        t('forms:validationMessages.fieldDoesntMeetRequirements', {
          fieldName: t('forms:labels.currentPassword').toLowerCase(),
        }) ?? '',
      )
      .min(
        8,
        t('forms:validationMessages.fieldDoesntMeetRequirements', {
          fieldName: t('forms:labels.currentPassword').toLowerCase(),
        }) ?? '',
      ),
  });

export const ChangePasswordForm = () => {
  const { t } = useTranslation();

  const { changeUserPassword, authLoading } = useAuth();

  const [isChangePasswordOptionOpen, setIsChangePasswordOptionOpen] = useState(false);

  const {
    register,
    watch,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isValid },
  } = useForm<ChangePasswordFormValues>({
    resolver: yupResolver(changePasswordFormSchema(t)),
    mode: 'onChange',
  });

  const handleChangePassword = async ({ currentPassword, newPassword }: PasswordChangeValues) => {
    const res = await changeUserPassword({
      newPassword,
      currentPassword,
    });

    if (res?.code === CognitoErrorCodesEnum.NotAuthorizedException) {
      return setError('currentPassword', {
        message: t('forms:validationMessages.wrongPassword') ?? '',
      });
    }

    if (newPassword === currentPassword) {
      return setError('newPassword', {
        message: t('forms:validationMessages.newPasswordMustBeUnique') ?? '',
      });
    }

    toast.success(t('settings:passwordChangeSuccessToast'));

    setIsChangePasswordOptionOpen(false);
    reset({
      newPassword: '',
      currentPassword: '',
    });
  };

  const passwordValue = watch('newPassword');

  return (
    <>
      <div>
        <p className="text-[16px] lg:text-[18px] font-bold pt-6 pb-4">
          {isChangePasswordOptionOpen
            ? t('forms:labels.changeOfPassword')
            : t('forms:labels.currentLoginMethod')}
        </p>
        {!isChangePasswordOptionOpen && (
          <p className="text-[14px] lg:text-[16px] font-medium pb-4">
            {t('forms:labels.passwordLoginMethod')}
          </p>
        )}
      </div>
      {!isChangePasswordOptionOpen && (
        <SmallButton
          className="text-[14px] lg:text-[16px] text-primary-500 font-medium pb-4 xl:pb-0"
          label={t('forms:labels.changePassword')}
          onClick={() => setIsChangePasswordOptionOpen(true)}
        />
      )}

      {isChangePasswordOptionOpen && (
        <form onSubmit={handleSubmit(handleChangePassword)}>
          <div className="transition-all duration-300 pb-6">
            <PasswordTextField
              error={errors.currentPassword}
              {...register('currentPassword')}
              autoComplete="current-password"
              label={t('forms:labels.currentPassword')}
              wrapperClassName="xl:w-96"
            />
          </div>
          <PasswordTextField
            {...register('newPassword')}
            autoComplete="new-password"
            error={errors.newPassword}
            hideErrorMessage
            label={t('forms:labels.setUpPassword')}
            wrapperClassName="xl:w-96 mb-2"
          />
          <PasswordRequirementsList
            isActive={!!passwordValue || !!errors.newPassword}
            password={passwordValue}
          />
          <div className="mt-6 pb-6 flex flex-col-reverse lg:flex-row gap-6 lg:gap-0 lg:space-x-8 lg:justify-end items-center">
            <Button
              className="w-full lg:!w-[176px]"
              label={t('common:actions.cancel')}
              onClick={() => setIsChangePasswordOptionOpen(false)}
              variant="outline"
            />
            <Button
              className="w-full lg:!w-[176px] lg:!ml-2 whitespace-nowrap"
              isDisabled={!isValid}
              isLoading={authLoading}
              label={t('settings:actions.saveNewPassword')}
              type="submit"
            />
          </div>
        </form>
      )}
    </>
  );
};
