import { Tooltip } from 'components';
import Slider, { SliderProps } from 'rc-slider';
import { FC, useEffect, useState } from 'react';
import { mergeClasses } from 'utils';

import { useScrollIntoView } from 'hooks';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '../ErrorMessage';
import './RangeSlider.css';

interface RangeSliderProps {
  label?: string | null;
  onChange: (value: number) => void;
  value?: number;
  marks?: SliderProps['marks'];
  min?: number;
  max?: number;
  className?: string;
  error?: FieldError;
  scrollIntoViewOnError?: boolean;
}

export const RangeSlider: FC<RangeSliderProps> = ({
  label,
  onChange,
  className = '',
  error,
  scrollIntoViewOnError = false,
  ...sliderProps
}) => {
  const { ref, scrollIntoView } = useScrollIntoView<HTMLDivElement>();
  const [mouseIsOver, setMouseIsOver] = useState(false);
  const { t } = useTranslation();

  const ariaLabel = t('aria:rangeSlider') ?? '';

  useEffect(() => {
    if (scrollIntoViewOnError && error) {
      scrollIntoView();
    }
  }, [error]);

  const tipHandleRender: SliderProps['handleRender'] = (node, { dragging, value }) => {
    return (
      <div
        {...node.props}
        aria-label={ariaLabel}
        className="w-3 h-3 flex justify-center items-center absolute cursor-grab top-0.5"
      >
        <Tooltip
          additionalIsOpen={dragging || mouseIsOver}
          content={<p className="font-medium">{value}</p>}
          name="range-handle"
          variant="primary"
        >
          <span className="w-3 h-3 border border-gray-200 shadow-md rounded-full bg-white inline-block" />
        </Tooltip>
      </div>
    );
  };

  return (
    <div
      ref={ref}
      className={mergeClasses('pb-10', className)}
      onMouseEnter={() => setMouseIsOver(true)}
      onMouseLeave={() => setMouseIsOver(false)}
    >
      {label && <p className="font-medium leading-[18px] mb-12">{label}</p>}
      <Slider
        {...sliderProps}
        dotStyle={{
          width: 1,
          height: 4,
          backgroundColor: '#AAAFC2',
          borderRadius: 1,
          border: 'none',
        }}
        handleRender={tipHandleRender}
        onChange={(value) => onChange(value as number)}
        railStyle={{
          borderRadius: 100,
          background: error ? '#E5252530' : '#DCE2E8',
        }}
        trackStyle={{
          background: '#0C00FF',
        }}
      />
      <ErrorMessage className="pt-8" error={error} />
    </div>
  );
};
