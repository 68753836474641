import HTMLReactParser from 'html-react-parser';
import { FC } from 'react';
import { mergeClasses } from 'utils';

interface HtmlRendererProps {
  html: string;
  className?: string;
}
export const HtmlRenderer: FC<HtmlRendererProps> = ({ html, className = '' }) => {
  return (
    <div className={mergeClasses('rich-text-editor', className)}>
      {HTMLReactParser(html.replaceAll('<p></p>', '<br/>'))}
    </div>
  );
};
