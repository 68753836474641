import { EnvironmentConfig } from './environment-config';

export const initLogger = () => {
  if (EnvironmentConfig.disableConsoleLog) {
    global.console.log = () => {};
    global.console.error = () => {};
    global.console.warn = () => {};
    global.console.debug = () => {};
  }
};
