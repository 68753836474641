import { TFunction } from 'i18next';
import { ExpertAvailabilityEnum } from 'interfaces';
import { getDayLabels } from './getDayLabels';
import { getMonthLabels } from './getMonthLabels';

export const getAvailabilityString = (
  t: TFunction,
  availability?: string | null,
  noticeLength?: number | null,
) => {
  if (availability === ExpertAvailabilityEnum.Immediately) {
    return t(`dictionaries:availability.Immediately`);
  }
  if (availability === ExpertAvailabilityEnum.Days) {
    return `${noticeLength} ${getDayLabels(t, noticeLength ?? 0)}`;
  }
  return `${noticeLength} ${getMonthLabels(t, noticeLength ?? 0)}`;
};
