import { useEffect, useRef, useState } from 'react';
import { mergeClasses } from 'utils';

export type Value = string | number;

type Color = 'white' | 'primary-500';

export interface TabOption<TValue> {
  value: TValue;
  label: string;
}

interface TabsHeaderProps<TValue> {
  value: TValue;
  onChange: (value: TValue) => void;
  options: TabOption<TValue>[];
  className?: string;
  color?: Color;
}

export const TabsHeader = <TValue extends Value>({
  onChange,
  value,
  options,
  className = '',
  color = 'white',
}: TabsHeaderProps<TValue>) => {
  const [underlinePosition, setUnderlinePosition] = useState([0, 60]);
  const activeTabRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (!activeTabRef.current) return;
    const { offsetLeft, offsetWidth } = activeTabRef.current;
    setUnderlinePosition([offsetLeft, offsetWidth]);
  }, [value, activeTabRef.current, options]);

  return (
    <div>
      <ul className="flex relative mt-6 xl:mt-0 justify-start">
        {options.map(({ label, value: optValue }) => {
          const isActive = value === optValue;
          return (
            <li
              key={JSON.stringify({ value, optValue })}
              ref={isActive ? activeTabRef : null}
              className={mergeClasses(
                'text-sm font-medium px-1.5 md:px-4 pb-2 cursor-pointer transition-all',
                isActive ? `text-${color}` : 'text-gray-300',
                className,
              )}
              onClick={() => onChange(optValue)}
            >
              {label}
            </li>
          );
        })}
      </ul>
      <div className="relative">
        <span
          className={`absolute h-[3px] bg-${color} rounded-tl-lg rounded-tr-lg bottom-0 transition-all`}
          style={{
            left: underlinePosition[0],
            width: underlinePosition[1],
          }}
        />
        <div className="w-full bg-gray-300 h-px opacity-30" />
      </div>
    </div>
  );
};
