import { yupResolver } from '@hookform/resolvers/yup';
import {
  CertificateForm,
  CertificateItem,
  certificatesFormSchema,
  Checkbox,
  ConfirmationModal,
  Paper,
  SmallButton,
} from 'components';
import { CertificateFormValues } from 'components/Certificate/CertificateForm';
import { ConfirmationModalConfirmFn, useModalState } from 'hooks';
import { PlusIcon } from 'icons';
import { useEffect } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mapObjectInArray } from 'utils';
import { ProfileValues } from '../../../../interfaces';

interface CertificatesValues {
  certificates: CertificateFormValues[];
}

export const Certificates = () => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<ProfileValues>();

  const formMethods = useForm<CertificateFormValues>({
    resolver: yupResolver(certificatesFormSchema(t)),
  });

  const certificatesValue = watch('certificates');
  const isCertificatesSkippedValue = watch('isCertificatesSkipped');

  const handleRemoveCertificate: ConfirmationModalConfirmFn<number | null> = async (
    { data: workHistoryIndex },
    closeModal,
  ) => {
    setValue(
      'certificates',
      certificatesValue.filter((_, i) => i !== workHistoryIndex),
      { shouldDirty: true, shouldValidate: true },
    );
    closeModal();
  };

  const {
    modalState: confirmationModalState,
    openModal: openConfirmationModal,
    closeModal: closeConfirmationModal,
    confirmModal: confirmConfirmationModal,
  } = useModalState<number>({
    onConfirm: handleRemoveCertificate,
  });

  const {
    modalState: formState,
    openModal: openForm,
    closeModal: closeForm,
  } = useModalState<number | null>();

  const handleCancelClick = () => {
    closeForm();
    formMethods.reset({});
  };

  const handleSave = (certificate: CertificateFormValues) => {
    let updatedCertificates = [...certificatesValue, certificate];
    if (formState.data !== null) {
      updatedCertificates = mapObjectInArray(
        certificatesValue,
        (_, index) => index === formState.data,
        () => certificate,
      );
    }
    setValue('certificates', updatedCertificates, { shouldDirty: true, shouldValidate: true });
    handleCancelClick();
  };

  useEffect(() => {
    if (typeof certificatesValue !== 'undefined' && !certificatesValue.length) {
      openForm(null);
    }
  }, [certificatesValue]);

  return (
    <>
      <ConfirmationModal
        isOpen={confirmationModalState.isOpen}
        onClose={closeConfirmationModal}
        onConfirm={confirmConfirmationModal}
        text={t('profile:papers.certificates.removeModal.text')}
      />
      <Paper
        className="!p-4 xl:!p-8"
        id="certificates"
        subtitle={t('profile:papers.certificates.subtitle')}
        subtitleClassName="!mb-4 lg:!mb-6"
        title={t('profile:papers.certificates.title')}
        titleClassName="!mb-0 xl:!mb-0.5"
      >
        <form onSubmit={formMethods.handleSubmit(handleSave)}>
          <FormProvider {...formMethods}>
            <div className="pb-6 flex">
              <Checkbox
                checked={isCertificatesSkippedValue}
                label={t('profile:papers.certificates.noCertificates')}
                name="isCertificatesSkipped"
                onChange={({ target }) =>
                  setValue('isCertificatesSkipped', target.checked, { shouldDirty: true })
                }
              />
            </div>
            <div className="flex gap-2 lg:gap-6 items-center md:items-start lg:items-center xl:items-start flex-col md:flex-row lg:flex-col xl:flex-row md:flex-wrap">
              {!isCertificatesSkippedValue &&
                certificatesValue?.map((cert, i) => {
                  const isEdited = formState.isOpen && formState.data === i;
                  const key = `certificate_${cert.name}_${cert.issueDate}_${i}`;

                  return !isEdited ? (
                    <CertificateItem
                      {...cert}
                      key={key}
                      className="w-full md:w-[235px] lg:w-full xl:max-w-[235px]"
                      onEdit={() => openForm(i)}
                      onRemove={() => openConfirmationModal(i)}
                    />
                  ) : (
                    <CertificateForm
                      key={key}
                      className="!mt-6"
                      initialData={cert}
                      onCancelClick={handleCancelClick}
                    />
                  );
                })}
            </div>
            {!formState.isOpen && !certificatesValue?.length && (
              <p className="text-sm font-medium">
                {t('profile:papers.certificates.noDataPlaceholder')}
              </p>
            )}
            {!isCertificatesSkippedValue && formState.isOpen && formState.data === null && (
              <CertificateForm
                className="!mt-6"
                isDisabled={isCertificatesSkippedValue}
                onCancelClick={handleCancelClick}
              />
            )}
          </FormProvider>
        </form>
        {!isCertificatesSkippedValue && !formState.isOpen && (
          <SmallButton
            className="text-xs !mt-8"
            icon={PlusIcon}
            label={t('profile:papers.certificates.addNewButton')}
            onClick={() => openForm(null)}
            variant="primary"
          />
        )}
      </Paper>
    </>
  );
};
