import banosoftSrc from 'assets/images/job-board/trustedCompanies/banosoft.avif';
import bshSrc from 'assets/images/job-board/trustedCompanies/bsh.avif';
import circleSrc from 'assets/images/job-board/trustedCompanies/circle-k.avif';
import filttrSrc from 'assets/images/job-board/trustedCompanies/filttr.avif';
import flotaSrc from 'assets/images/job-board/trustedCompanies/flota-online.avif';
import jetsonSrc from 'assets/images/job-board/trustedCompanies/jetson.avif';
import kicksterentSrc from 'assets/images/job-board/trustedCompanies/kicksterent.avif';
import localizedSrc from 'assets/images/job-board/trustedCompanies/localized.avif';
import sapSrc from 'assets/images/job-board/trustedCompanies/sap.avif';
import tamigoSrc from 'assets/images/job-board/trustedCompanies/tamigo.avif';
import vatenfallSrc from 'assets/images/job-board/trustedCompanies/vatenfall.avif';
import wennectSrc from 'assets/images/job-board/trustedCompanies/wennect.avif';

export const trustedCompanies = [
  {
    src: bshSrc,
    name: 'bsh',
  },
  {
    src: banosoftSrc,
    name: 'banosoft',
  },
  {
    src: circleSrc,
    name: 'circle',
  },
  {
    src: filttrSrc,
    name: 'filttr',
  },
  {
    src: jetsonSrc,
    name: 'jetson',
  },
  {
    src: flotaSrc,
    name: 'flota',
  },
  {
    src: localizedSrc,
    name: 'localized',
  },
  {
    src: kicksterentSrc,
    name: 'kicksterent',
  },
  {
    src: sapSrc,
    name: 'sap',
  },
  {
    src: tamigoSrc,
    name: 'tamigo',
  },
  {
    src: vatenfallSrc,
    name: 'vatenfall',
  },
  {
    src: wennectSrc,
    name: 'wennect',
  },
];
