import { RefObject, useEffect, useState } from 'react';

const paddingToImproveVisibility = 64;
export const useDynamicCollapsedHeight = (
  listRef: RefObject<HTMLElement>,
  numberOfVisibleItems: number,
  itemClassName = 'offer-list-item',
) => {
  const [collapsedHeight, setCollapsedHeight] = useState(300);

  const calculateCollapsedHeight = () => {
    if (!listRef.current) {
      return 100;
    }
    const listChildren = listRef.current.querySelectorAll<HTMLLIElement>(`.${itemClassName}`);

    const heights = Array.from(listChildren ?? []).map(({ offsetHeight }) => offsetHeight);
    const heightsSum = heights.reduce((partialSum, height) => partialSum + height, 0);
    const listHeight = listRef.current.offsetHeight;

    const margin = (listHeight - heightsSum) / (listChildren.length - 1);

    const visibleItemsHeightSum = heights
      .splice(0, numberOfVisibleItems)
      .reduce((partialSum, height) => partialSum + height, 0);

    setCollapsedHeight(
      visibleItemsHeightSum + margin * (numberOfVisibleItems - 1) + paddingToImproveVisibility,
    );
  };

  useEffect(() => {
    window.addEventListener('resize', calculateCollapsedHeight);

    return () => {
      window.removeEventListener('resize', calculateCollapsedHeight);
    };
  }, [listRef]);

  return { collapsedHeight, calculateCollapsedHeight };
};
