import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectisWebsiteMenuItem } from '../../../configs';

export const PortfolioItem: FC<ConnectisWebsiteMenuItem> = ({ thumbnail, url, name }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: `connectisWebsite:portfolioProject.${name}`,
  });

  return (
    <a href={url}>
      <div
        className="rounded-2xl h-48 bg-center bg-cover space-y-2 text-white px-4 py-6 flex flex-col justify-end"
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(180deg, rgba(0, 0, 0, 0) 25.82%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(304.86deg, rgba(0, 0, 0, 0) 43.37%, rgba(0, 0, 0, 0.4) 80.13%), url(${thumbnail})`,
        }}
      >
        <p className="font-medium text-2xl">{t('title')}</p>
        <p className="text-xs">{t('description')}</p>
      </div>
    </a>
  );
};
