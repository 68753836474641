import { AngleIcon } from 'icons';
import { FC } from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactSelect, { ControlProps, MenuPosition } from 'react-select';
import { mergeClasses } from 'utils';
import { InputLabel } from '../../InputLabel';

const CustomDropdownIndicator = () => {
  return (
    <div>
      <AngleIcon className="w-4 h-4" />
    </div>
  );
};

type Value = string | number | null;

export type Option<TValue> = {
  value: TValue;
  label: string;
};

export type ControlComponentType = FC<ControlProps<Option<Value>>>;
const CustomControl: ControlComponentType = ({ children, innerRef, innerProps, selectProps }) => {
  const { className } = selectProps;
  const isError = className?.includes('error');

  return (
    <div
      ref={innerRef}
      className={mergeClasses(
        'border px-6 py-2.5 flex rounded-lg transition-all bg-white min-h-[56px]',
        isError ? 'border-red-500' : 'border-gray-200',
      )}
      {...innerProps}
    >
      {children}
    </div>
  );
};

export const getOptionStyles = (isSelected: boolean, isFocused: boolean) => {
  if (isSelected) {
    return {
      backgroundColor: '#817AFF',
      color: '#fff',
    };
  }
  if (isFocused) {
    return {
      backgroundColor: '#A8A3FF30',
      color: '#888888',
    };
  }
  return {
    backgroundColor: 'transparent',
    color: '#888888',
  };
};

export interface SelectProps<TValue> {
  value?: Option<TValue>['value'] | Option<TValue>;
  options: Option<TValue>[];
  name?: string;
  label?: string | null;
  placeholder?: string;
  menuPosition?: MenuPosition;
  error?: FieldError;
  onChange: (value: TValue) => void;
  className?: string;
  customControlComponent?: ControlComponentType;
  isSearchable?: boolean;
}

export const Select = <TValue extends Value>({
  onChange,
  options,
  name,
  value,
  placeholder,
  menuPosition = 'absolute',
  label,
  error,
  className = '',
  customControlComponent = CustomControl,
  isSearchable,
}: SelectProps<TValue>) => {
  const { t } = useTranslation();
  const selectedOption =
    typeof value !== 'object' ? options.find((opt) => opt.value === value) : value;

  return (
    <label className={mergeClasses('flex flex-col', className)} htmlFor={name}>
      {label && <InputLabel label={label} />}
      <ReactSelect
        className={mergeClasses('w-full', error ? 'error' : '')}
        components={{
          Control: customControlComponent,
          IndicatorSeparator: undefined,
          DropdownIndicator: CustomDropdownIndicator,
        }}
        isSearchable={isSearchable}
        menuPlacement="auto"
        menuPosition={menuPosition}
        name={name}
        onChange={(opt) =>
          (opt as Option<TValue>)?.value && onChange((opt as Option<TValue>).value)
        }
        options={options}
        placeholder={placeholder ?? t('forms:placeholders.select')}
        styles={{
          valueContainer: (provided) => ({
            ...provided,
            padding: 0,
            paddingRight: 2,
          }),
          option: (provided, { isSelected, isFocused }) => ({
            ...provided,
            ...getOptionStyles(isSelected, isFocused),
          }),
        }}
        value={selectedOption}
      />
      {error && <p className="text-red-500 leading-[18px] mt-2">{error.message}</p>}
    </label>
  );
};
