import { useReactiveVar } from '@apollo/client';
import { Button } from 'components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { profileVar } from 'reactive-vars';
import { StepComponentProps } from '../stepsConfig';
import { CheckedListItem } from '../subcomponents';

export const BasicInfoTitleStep: FC<StepComponentProps> = ({ onNextClick }) => {
  const { t } = useTranslation();

  const { crmId } = useReactiveVar(profileVar) ?? {};

  const isExpertAddedByCrm = !!crmId;

  return (
    <div className="flex lg:block grow flex-col">
      <p className="3text-gray-500 text-[15px] leading-[22.5px] lg:text-lg mt-2 lg:mt-4">
        {isExpertAddedByCrm
          ? t(`onboarding:basicInfoTitleStep.fillOrConfirmData`)
          : t(`onboarding:basicInfoTitleStep.weWillAskYou`)}
      </p>
      {!isExpertAddedByCrm && (
        <ul className="mt-6 lg:mt-10 space-y-4">
          <CheckedListItem
            label={t('onboarding:basicInfoTitleStep.stepContent.personalInformation')}
          />
          <CheckedListItem label={t('onboarding:basicInfoTitleStep.stepContent.technologies')} />
          <CheckedListItem label={t('onboarding:basicInfoTitleStep.stepContent.languages')} />
        </ul>
      )}
      <Button
        className="mt-[65px] lg:mt-16 w-full text-sm mt-0 py-3.5 lg:py-5 lg:w-auto"
        label={t('onboarding:basicInfoTitleStep.nextButtonText')}
        onClick={onNextClick}
      />
    </div>
  );
};
