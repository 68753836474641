import { ExpertTag, TagTypeEnum } from '../interfaces';

export const filterTechnicalSkills = <T extends { type: TagTypeEnum; id: string }>(
  skills: T[],
  tags: ExpertTag[],
): ExpertTag[] => {
  const technicalSkills: T[] = skills.filter(({ type }) => type === TagTypeEnum.TechnicalSkill);
  const technicalTags = tags.filter(({ tagId }) => technicalSkills.find(({ id }) => tagId === id));
  return technicalTags;
};
