import domToImage from 'dom-to-image';

export const useBase64 = () => {
  const getBase64FromHtml = async (element: HTMLElement) => domToImage.toPng(element);
  const getBase64FromFile = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event?.target?.result as string);
      };

      reader.onerror = (err) => {
        reject(err);
      };

      reader.readAsDataURL(file);
    });
  };

  return {
    getBase64FromHtml,
    getBase64FromFile,
  };
};
