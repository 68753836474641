import { AngleIcon } from 'icons';
import { FC } from 'react';

export interface ConnectisWebsiteHeaderLinkProps {
  href: string;
  label: string;
  arrow?: boolean;
}

export const ConnectisWebsiteHeaderLink: FC<ConnectisWebsiteHeaderLinkProps> = ({
  label,
  href,
  arrow = false,
}) => {
  return (
    <a
      className="flex items-center space-x-2 lg:inline-block text-2xl lg:text-sm lg:font-medium"
      href={href}
    >
      {label}
      {arrow && <AngleIcon className="w-6 -rotate-90" />}
    </a>
  );
};
