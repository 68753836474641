import { Select } from 'components';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { PlusIcon, RemoveIcon } from 'icons';
import { forwardRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter, mergeClasses } from 'utils';
import {
  MEETING_HOUR_OPTION_END,
  MEETING_HOUR_OPTION_START,
} from '../../Modals/MeetingScheduleModal/constants';
import { filterAlreadyUsedTimeslots, getHourMeetingOptions, MeetingLength } from '../utils';

dayjs.extend(customParseFormat);

interface MeetingDayItemProps {
  date: string;
  timeslots: (string | null)[];
  onAdd: () => void;
  onRemove: (timeslotIndex: number) => void;
  onChange: (timeslotIndex: number, value: string) => void;
  onRemoveDay: (date: string) => void;
  meetingLength: MeetingLength;
  isTimingSet: { [p: string]: boolean };
  error: boolean;
}

export const MeetingDayItem = forwardRef<HTMLDivElement, MeetingDayItemProps>(
  ({
    date,
    timeslots,
    onAdd,
    onChange,
    onRemove,
    onRemoveDay,
    meetingLength,
    isTimingSet,
    error,
  }) => {
    const { t } = useTranslation();

    const hourOptions = getHourMeetingOptions(
      MEETING_HOUR_OPTION_START,
      MEETING_HOUR_OPTION_END,
      meetingLength,
    );
    const dayName = dayjs(date).format('dddd, D MMMM');
    const isToday = dayjs(date).format('DD.MM.YYYY') === dayjs().format('DD.MM.YYYY');
    const isAnyNull = timeslots.some((ts) => !ts);

    const firstKeyWithFalseValue = Object.keys(isTimingSet).find((key) => !isTimingSet[key]);

    useEffect(() => {
      if (error && date) {
        const item = document.getElementById(date === firstKeyWithFalseValue ? date : '');
        if (item) {
          item.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [error, timeslots]);

    return (
      <div className="w-full border pt-6 pb-3 px-3 md:p-8 rounded-xl border-gray-200" id={date}>
        <p className="font-medium text-sm mb-7 text-center leading-[18px]">
          {capitalizeFirstLetter(dayName)}
        </p>
        <div className="space-y-2">
          {timeslots.map((timeslot, i) => {
            const isNull = !!timeslot;
            const isLastItem = i === timeslots.length - 1;
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`${date}${i}`} className="flex items-center flex-col">
                <div className="w-full flex gap-4 items-center">
                  <Select
                    className={mergeClasses(
                      'w-full',
                      !isTimingSet[date] && error && !isNull
                        ? 'border-[1px] rounded-lg border-red-500'
                        : '',
                    )}
                    isSearchable={false}
                    menuPosition="fixed"
                    onChange={(value) => onChange(i, value as string)}
                    options={filterAlreadyUsedTimeslots({
                      options: hourOptions,
                      alreadyUsedTimeslots: timeslots,
                      filterHoursBeforeNow: isToday,
                      meetingLength,
                    })}
                    placeholder={t('datePicker:meetingHourSelect.addTimeslot') ?? ''}
                    value={hourOptions.find(({ value }) => value === timeslot)}
                  />
                  <button
                    className="hover:bg-gray-100 transition-all rounded-lg max-h-[36px] p-1.5"
                    onClick={() => (timeslots.length > 1 ? onRemove(i) : onRemoveDay(date))}
                  >
                    <RemoveIcon className="w-6 h-6" />
                  </button>
                </div>

                {(isTimingSet[date] && isAnyNull) ||
                  (isLastItem && timeslot === null && error && (
                    <p key={date} className="font-medium text-red-500 mt-2 text-sm w-full">
                      {t('datePicker:meetingHourSelect.addTimeOfMeeting')}
                    </p>
                  ))}
              </div>
            );
          })}
        </div>
        {!isAnyNull && (
          <button
            className="text-primary-500 flex items-center font-semibold text-xs mt-2 transition-all hover:text-primary-700 leading-7"
            onClick={onAdd}
          >
            <PlusIcon className="w-3 h-3 mr-2" />
            {t('datePicker:meetingHourSelect.addAnotherTime')}
          </button>
        )}
      </div>
    );
  },
);

MeetingDayItem.displayName = 'MeetingDayItem';
