import { ThinArrowIcon } from 'icons';
import { ButtonHTMLAttributes, FC } from 'react';
import { mergeClasses } from 'utils';
import { LoadingSpinner } from '../../LoadingSpinner';

type Variant = 'primary' | 'neon' | 'white';
interface VariantClassNames {
  buttonClassName: string;
  arrowWrapperClassName: string;
  hoverClassName: string;
  spinnerClassName: string;
  arrowClassName: string;
}

const variantClassNames: Record<Variant, VariantClassNames> = {
  white: {
    buttonClassName: 'bg-white text-black',
    arrowWrapperClassName: 'bg-primary-500',
    hoverClassName: 'hover:bg-gray-200 active:bg-gray-300',
    spinnerClassName: 'border-white',
    arrowClassName: 'text-white',
  },
  primary: {
    buttonClassName: 'bg-primary-500 text-white',
    arrowWrapperClassName: 'bg-white',
    hoverClassName: 'hover:bg-primary-600 active:bg-primary-700',
    spinnerClassName: 'border-black',
    arrowClassName: 'text-black',
  },
  neon: {
    buttonClassName: '',
    arrowWrapperClassName: 'bg-neon-green-500 hover:bg-neon-600 active:bg-neon-700',
    hoverClassName: '',
    spinnerClassName: 'border-white',
    arrowClassName: 'text-black',
  },
};

interface RoundedButtonProps {
  label?: string | null;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  className?: string;
  onClick?: () => void;
  variant?: Variant;
  disabled?: boolean;
  loading?: boolean;
  arrowSize?: string;
}

export const RoundedButton: FC<RoundedButtonProps> = ({
  label,
  type = 'button',
  variant = 'white',
  className = '',
  onClick,
  loading = false,
  disabled = false,
  arrowSize = '12',
}) => {
  const {
    buttonClassName,
    arrowClassName,
    arrowWrapperClassName,
    spinnerClassName,
    hoverClassName,
  } = variantClassNames[variant];

  return (
    <button
      className={mergeClasses(
        'relative flex items-center justify-between rounded-full font-medium transition-colors',
        label ? 'w-full pl-6 pr-2 py-2' : '',
        buttonClassName,
        disabled ? 'opacity-70 cursor-not-allowed' : hoverClassName,
        className,
      )}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {label && <span className="mr-8">{label}</span>}
      <span
        className={mergeClasses(
          'flex justify-center items-center rounded-3xl',
          `w-${arrowSize} h-${arrowSize}`,
          arrowWrapperClassName,
        )}
      >
        {loading ? (
          <LoadingSpinner className={spinnerClassName} size={20} />
        ) : (
          <ThinArrowIcon className={mergeClasses('w-6 h-6', arrowClassName)} />
        )}
      </span>
    </button>
  );
};
