import { IconType } from 'icons';
import { forwardRef, MouseEvent, MouseEventHandler, ReactNode, TouchEventHandler } from 'react';
import { mergeClasses } from 'utils';
import { LoadingSpinner } from '../../LoadingSpinner';

type SmallButtonVariant = 'gray' | 'primary' | 'black' | 'white' | 'danger';

interface SmallButtonProps {
  label: string;
  className?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  icon?: IconType;
  iconClassName?: string;
  variant?: SmallButtonVariant;
  iconPosition?: 'left' | 'right';
  isDisabled?: boolean;
  labelClassName?: string;
  onMouseDown?: MouseEventHandler<HTMLButtonElement>;
  onTouchEnd?: TouchEventHandler;
  children?: ReactNode;
  isLoading?: boolean;
}

const getVariantClassName = (variant: SmallButtonVariant) => {
  switch (variant) {
    case 'primary':
      return 'text-primary-500 hover:text-primary-700';
    case 'black':
      return 'hover:text-gray-400';
    case 'gray':
      return 'text-xs text-gray-400 hover:opacity-80';
    case 'white':
      return 'text-xs text-white hover:text-gray-200';
    case 'danger':
      return 'text-red-500 hover:text-red-600 active:text-red-700';
    default: {
      const unknownVariant: never = variant;
      throw Error(`Unknown SmallButton variant: ${unknownVariant}`);
    }
  }
};

export const SmallButton = forwardRef<HTMLButtonElement, SmallButtonProps>(
  (
    {
      label,
      onClick,
      icon: Icon,
      iconClassName = '',
      className = '',
      variant = 'gray',
      iconPosition = 'left',
      isDisabled,
      labelClassName,
      onTouchEnd,
      onMouseDown,
      children,
      isLoading = false,
    },
    ref,
  ) => {
    const iconsClassName = mergeClasses(
      'w-4 h-4 transition-all',
      iconPosition === 'left' ? 'mr-2' : 'ml-2',
      iconClassName,
    );

    const iconComponent = (() => {
      if (isLoading) return <LoadingSpinner size={12} />;
      if (Icon) return <Icon className={iconsClassName} />;
    })();

    return (
      <button
        ref={ref}
        className={mergeClasses(
          'font-semibold flex items-center transition-all duration-150 ease-in-out',
          getVariantClassName(variant),
          isDisabled ? '!opacity-40 cursor-not-allowed' : '',
          className,
        )}
        onClick={(e) => !isDisabled && onClick && onClick(e)}
        onMouseDown={onMouseDown}
        onTouchEnd={onTouchEnd}
        style={{ WebkitTapHighlightColor: 'transparent' }}
        type="button"
      >
        <span className={mergeClasses('flex items-center', labelClassName)}>
          {Icon && iconPosition === 'left' && iconComponent}
          {label}
          {Icon && iconPosition === 'right' && iconComponent}
        </span>
        {children}
      </button>
    );
  },
);

SmallButton.displayName = 'SmallButton';
