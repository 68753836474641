import { t } from 'i18next';
import { FC } from 'react';

interface ExternalOfferHeaderRateProps {
  rateFrom: number;
  rateTo: number;
}

export const ExternalOfferHeaderRate: FC<ExternalOfferHeaderRateProps> = ({ rateFrom, rateTo }) => {
  const roundedRateRangeFrom = Math.round(rateFrom);
  const roundedRateRangeTo = Math.round(rateTo);

  return (
    <p className="text-lg lg:text-2xl mb-4">
      <span className="font-bold mr-1">{`${roundedRateRangeFrom}-${roundedRateRangeTo} PLN`}</span>
      <span className="text-gray-350">/{t('common:hourShort')} - B2B</span>
    </p>
  );
};
