import { BackButton, CertificateForm, FullPageModal, ModalProps } from 'components';
import { FC } from 'react';
import { CertificateFormValues } from '../../Certificate/CertificateForm';

interface CertificateModalProps extends ModalProps {
  currentCertificate: CertificateFormValues | null;
  onSave: () => void;
}

export const CertificateModal: FC<CertificateModalProps> = ({
  currentCertificate,
  onSave,
  onClose,
  ...modalProps
}) => {
  return (
    <FullPageModal {...modalProps}>
      <div className="max-w-2xl mx-auto !my-0 w-full">
        <BackButton className="mb-14" onClick={onClose} />
        <CertificateForm
          initialData={currentCertificate ?? undefined}
          onCancelClick={onClose}
          onSaveClick={onSave}
        />
      </div>
    </FullPageModal>
  );
};
