import dayjs from 'dayjs';
import { AngleIcon } from 'icons';
import { FC } from 'react';
import { mergeClasses } from 'utils';

interface ArrowButtonProps {
  onClick: () => void;
  className?: string;
  isDisabled?: boolean;
}

const ArrowButton: FC<ArrowButtonProps> = ({ className = '', onClick, isDisabled }) => (
  <button
    className={mergeClasses(
      'rotate-90 w-6 h-6 flex justify-center items-center transition-all rounded-md',
      className,
      isDisabled ? 'cursor-not-allowed opacity-20' : 'hover:bg-gray-100 active:bg-gray-200',
    )}
    onClick={() => !isDisabled && onClick()}
  >
    <AngleIcon className="w-6" />
  </button>
);

interface MonthHeaderProps {
  currentDate: string;
  onNext: () => void;
  onPrev: () => void;
}

export const MonthHeader: FC<MonthHeaderProps> = ({ currentDate, onNext, onPrev }) => {
  const monthName = dayjs(currentDate).format('MMMM YYYY');

  const isPrevDisabled = dayjs(currentDate).subtract(1, 'month').isBefore(dayjs().startOf('month'));

  return (
    <div className="flex justify-center items-center mb-2.5 gap-6">
      <ArrowButton isDisabled={isPrevDisabled} onClick={onPrev} />
      <div className="!m-0 w-[160px] text-center capitalize text-[13px] lg:text-[16px] leading-[18px] font-medium">
        {monthName}
      </div>
      <ArrowButton className="!-rotate-90" onClick={onNext} />
    </div>
  );
};
