import { FC } from 'react';
import { stepsConfig } from '../stepsConfig';

const STEPS_NUMBER = 3;

interface StepProgressBarProps {
  currentStepIndex: number;
}

const steps = Array.from(Array(STEPS_NUMBER).keys());

const getBarProgress = (currentStepIndex: number, schema: number[]) => {
  const max = schema.length ? Math.max(...schema) : 99;
  if (currentStepIndex > max) {
    return 100;
  }
  const progressIndex = schema.findIndex((n) => n === currentStepIndex);
  if (progressIndex === -1) {
    return 0;
  }
  const process = (100 * progressIndex) / schema.length;
  return process;
};

export const StepProgressBar: FC<StepProgressBarProps> = ({ currentStepIndex }) => {
  const substepsSchema = steps.map((n) =>
    stepsConfig
      .map(({ stepNumber }, index) => ({ stepNumber, index }))
      .filter(({ stepNumber }) => stepNumber === n)
      .map(({ index }) => index),
  );

  return (
    <div className="flex gap-x-2 md:gap-x-4 mb-8 md:m-0 lg:w-full">
      {substepsSchema.map((schema) => {
        return (
          <div
            key={`stepProgressBar_${schema.join('')}`}
            className="w-full h-1 md:h-1.5 rounded-[32px] bg-gray-200 overflow-hidden"
          >
            <div
              className="h-full bg-primary-500 transition-all duration-300"
              style={{
                width: `${getBarProgress(currentStepIndex, schema)}%`,
              }}
            />
          </div>
        );
      })}
    </div>
  );
};
