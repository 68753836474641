import { FC } from 'react';
import { IconType } from '../../../../../icons';

interface ExternalOfferHeaderDetailProps {
  icon: IconType;
  value?: string | null;
}

export const ExternalOfferHeaderDetail: FC<ExternalOfferHeaderDetailProps> = ({
  icon: Icon,
  value,
}) => {
  return (
    <div className="flex items-center gap-2">
      <Icon className="h-4 w-4 text-gray-350" />
      <p className="font-medium">{value || '-'}</p>
    </div>
  );
};
