import {
  ChangeEvent,
  ChangeEventHandler,
  forwardRef,
  KeyboardEvent,
  useCallback,
  useState,
} from 'react';
import {
  JobBoardSearchBarMenu,
  JobBoardSearchBarMenuOption,
  JobBoardSelectedOptions,
} from './subcomponents';

interface JobBoardSearchBarProps {
  name: string;
  value?: JobBoardSearchBarMenuOption[];
  placeholder?: string | null;
  className?: string;
  onInputChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onChange: (selectedOptions: JobBoardSearchBarMenuOption[]) => void;
}

export const JobBoardSearchBar = forwardRef<HTMLInputElement, JobBoardSearchBarProps>(
  ({ placeholder, name, value, className, onInputChange, onChange }, ref) => {
    const [inputValue, setInputValue] = useState('');
    const trimmedInputValue = inputValue.trim();
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);

    const inputValueNotAlreadySelected =
      trimmedInputValue &&
      !value?.find((otp) => otp.value.toLowerCase().trim() === inputValue.toLowerCase().trim());

    const options: JobBoardSearchBarMenuOption[] = inputValueNotAlreadySelected
      ? [
          {
            value: trimmedInputValue,
            label: trimmedInputValue,
            type: 'keyword',
          },
        ]
      : [];

    const handleInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
      (e) => setInputValue(e.target.value),
      [],
    );

    const handleOptionSelect = useCallback(
      (option: JobBoardSearchBarMenuOption) => {
        setInputValue('');
        setSelectedOptionIndex(-1);
        onChange([...(value ?? []), option]);
      },
      [onChange, value],
    );

    const handleSelectedOptionIndexIncrement = useCallback(
      () =>
        setSelectedOptionIndex((prev) => {
          if (prev === 0) {
            return prev;
          }
          return prev + 1;
        }),
      [],
    );

    const handleSelectedOptionIndexDecrement = useCallback(
      () =>
        setSelectedOptionIndex((prev) => {
          if (prev === -1) {
            return prev;
          }
          return prev - 1;
        }),
      [],
    );

    const handleKeyPress = useCallback(
      (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'ArrowDown') {
          e.preventDefault();
          handleSelectedOptionIndexIncrement();
        }
        if (e.key === 'ArrowUp') {
          e.preventDefault();
          handleSelectedOptionIndexDecrement();
        }
        if (e.key === 'Enter') {
          e.preventDefault();
          const selectedOption = options[Math.max(0, selectedOptionIndex)];
          if (!selectedOption || !selectedOption.value) {
            return;
          }
          handleOptionSelect(selectedOption);
        }
      },
      [handleOptionSelect, options],
    );

    return (
      <div className={className}>
        <div className="relative">
          <label
            className="block border border-new-gray-200 rounded-lg overflow-hidden bg-white focus-within:outline outline-primary-500"
            htmlFor={name}
          >
            <input
              ref={ref}
              autoComplete="off"
              className="w-full px-6 pb-4.5 pt-6 focus:outline-none text-gray-500 peer"
              id={name}
              onChange={handleInputChange}
              onKeyUp={handleKeyPress}
              placeholder={placeholder || undefined}
              type="text"
              value={inputValue}
            />
            <JobBoardSearchBarMenu
              onSelect={handleOptionSelect}
              options={options}
              selectedOptionIndex={selectedOptionIndex}
              visible={!!options.length}
            />
          </label>
        </div>
        <JobBoardSelectedOptions onChange={onChange} selectedOptions={value ?? []} />
      </div>
    );
  },
);

JobBoardSearchBar.displayName = 'JobBoardSearchBar';
