import { SearchInput, SmallButton } from 'components';
import { PlusIcon } from 'icons';
import { FC, useEffect, useState } from 'react';
import { mergeClasses } from 'utils';
import useMobile from '../../../hooks/useMobile';

interface AddItemButtonProps {
  label: string;
  onChange: (value: string) => void;
  options: Array<{ value: string; label: string }>;
  className?: string;
  buttonClassName?: string;
  usedInRadioComponent?: boolean;
  centerButtonForGrid?: boolean;
}
export const AddItemButton: FC<AddItemButtonProps> = ({
  label,
  options,
  onChange,
  centerButtonForGrid,
  className = '',
  buttonClassName = '',
  usedInRadioComponent,
}) => {
  const [isActive, setIsActive] = useState(false);
  const { isMobile } = useMobile();

  useEffect(() => {
    if (isMobile) {
      setIsActive(true);
    }
  }, []);

  const handleToggleInput = () => setIsActive(true);
  const handleInputChange = (value: string) => {
    onChange(value);
    setIsActive(false);
  };

  return (
    <div
      className={mergeClasses(
        'w-full',
        className,
        centerButtonForGrid && !isActive && 'flex justify-center',
      )}
    >
      {isActive ? (
        <SearchInput
          {...(usedInRadioComponent ? { placeholder: label } : { label })}
          onChange={handleInputChange}
          options={options}
        />
      ) : (
        <SmallButton
          className={buttonClassName}
          icon={PlusIcon}
          label={label}
          onClick={handleToggleInput}
          variant="primary"
        />
      )}
    </div>
  );
};
