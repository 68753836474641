import { useState } from 'react';
import { useWindowListener } from './useWindowListener';

const lightSectionClassName = 'light-section';

export const useLightBackgroundObserver = () => {
  const [lightSectionsRange, setLightSectionsRange] = useState<number[][]>([]);
  const [isLightBackground, setIsLightBackground] = useState(false);

  const handleScroll = () => {
    if (!lightSectionsRange.length) {
      return;
    }

    const currentPosition = window.scrollY;
    let isLight = false;
    for (const [top, bottom] of lightSectionsRange) {
      if (currentPosition >= top && currentPosition <= bottom) {
        isLight = true;
      }
    }
    setIsLightBackground(isLight);
  };

  useWindowListener({
    scroll: handleScroll,
  });

  const measureDimensions = () => {
    const elements = document.getElementsByClassName(lightSectionClassName);
    const elementsRange = Array.from(elements).map((el) => {
      const { top, bottom } = el.getBoundingClientRect();
      const { scrollTop } = document.documentElement;

      const startY = top + scrollTop;
      const endY = bottom + scrollTop;

      const dimensions = [startY, endY];
      return dimensions;
    });
    setLightSectionsRange(elementsRange);
  };

  return { isLightBackground, measureDimensions };
};
