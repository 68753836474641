import { JsonObject } from 'type-fest';

export const jobBoard: JsonObject = {
  title: 'Aktualne oferty',
  filters: {
    title: 'Filtry',
    clearFilters: 'Wyczyść filtry',
    filter: 'Filtruj',
  },
  vatB2b: '+ VAT (B2B)',
  noItemsPlaceholder: 'Nie znaleziono ofert dla podanych filtrów',
  returnToAllOffers: 'Powrót do wszystkich ofert',
  searchBar: {
    placeholder: 'Wyszukaj po roli, technologi, umiejętnościach',
  },
  offerThankYou: {
    heading: 'Dziękujemy za przesłanie zgłoszenia',
    description:
      'Dołączenia do C_hub, gdzie będziesz mógł na bieżąco śledzić postęp procesu rekrutacji oraz odkrywać inne, dopasowane oferty.',
    checkOffers: 'Sprawdź oferty w C_hub',
  },
};
