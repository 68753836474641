import { FC } from 'react';
import { mergeClasses } from 'utils';

interface InputLabelProps {
  label?: string | null;
  className?: string;
}

export const InputLabel: FC<InputLabelProps> = ({ label, className = '' }) => {
  if (!label) {
    return null;
  }

  return (
    <p className={mergeClasses('font-medium leading-[18px] mb-2 text-sm lg:text-base', className)}>
      {label}
    </p>
  );
};
