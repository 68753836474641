import { Select, SmallButton } from 'components';
import { useModalState } from 'hooks';
import { t } from 'i18next';
import { SortingIcon } from 'icons';
import { ExpertNotificationSortEnum } from 'interfaces';
import { useState } from 'react';
import { useExpertNotificationsQuery } from '../../hooks/api/expertNotifications/expertNotifications.generated';
import NotificationsSortModal from '../Modals/NotificationsSortModal';
import { ButtonComponent } from '../Offers/tabs/AllOffersTab/papers/OtherOffers';
import { NotificationsList } from './subcomponents';

export const sortNotificationTypes = Object.values(ExpertNotificationSortEnum);

const Notifications = () => {
  const [sortBy, setSortBy] = useState(sortNotificationTypes[1]);

  const {
    modalState: sortModalState,
    closeModal: closeSortModal,
    openModal: openSortModal,
  } = useModalState<null>();

  const sortOptions = sortNotificationTypes.map((sort) => ({
    label: t(`dictionaries:notificationsSorting.${sort}`) || sort,
    value: sort,
  }));

  const { data: expertNotificationData, loading } = useExpertNotificationsQuery({
    variables: {
      sort: sortBy,
    },
  });
  const notifications = expertNotificationData?.expertNotifications.notifications ?? [];

  return (
    <>
      <NotificationsSortModal
        isOpen={sortModalState.isOpen}
        onClose={closeSortModal}
        setSortBy={setSortBy}
        sortOptions={sortOptions}
      />
      <div className="relative w-screen md:w-auto flex">
        <div className="py-8 md:p-8 w-[calc(100vw-32px)] md:w-[calc(100vw-80px)] lg:w-[calc(100vw-21rem)]  rounded-2xl bg-white mx-4 md:mx-10">
          <div className="px-7 md:px-0 pb-6 relative">
            <p className="test-lg font-bold">{t('pageTitles:notifications')}</p>
            <SmallButton
              className="flex lg:hidden absolute right-6 md:right-0 top-[4px]"
              icon={SortingIcon}
              iconClassName="text-gray-400 mr-1.5"
              label={t('forms:placeholders.sort')}
              labelClassName="text-[16px] leading-[20px] text-black font-medium"
              onClick={() => openSortModal(null)}
            />
            <div className="hidden lg:flex absolute right-0 top-[4px]">
              <Select
                className="max-w-[305px] md:w-[305px]"
                customControlComponent={ButtonComponent}
                name="sort"
                onChange={setSortBy}
                options={sortOptions}
                value={sortBy}
              />
            </div>
          </div>
          <NotificationsList loading={loading} notifications={notifications} />
        </div>
      </div>
    </>
  );
};

export default Notifications;
