import { TFunction } from 'i18next';
import { Shape } from 'interfaces';
import * as yup from 'yup';
import { WorkExperienceFormValues } from './WorkHistoryForm';

export const workExperienceFormSchema = (t: TFunction) =>
  yup.object().shape<Shape<WorkExperienceFormValues>>({
    jobPositionName: yup.string().required(
      t('forms:validationMessages.required', {
        fieldName: t('forms:labels.yourRole'),
      }) ?? '',
    ),
    dateStart: yup.string().required(
      t('forms:validationMessages.required', {
        fieldName: t('forms:labels.dateStart'),
      }) ?? '',
    ),
    dateEnd: yup
      .string()
      .nullable()
      .when('isCurrentJob', {
        is: (val?: boolean) => !val,
        then: yup
          .string()
          .typeError(
            t('forms:validationMessages.required', {
              fieldName: t('forms:labels.dateEnd'),
            }) ?? '',
          )
          .required(
            t('forms:validationMessages.required', {
              fieldName: t('forms:labels.dateEnd'),
            }) ?? '',
          ),
      }),
    description: yup.string().max(
      2000,
      t('forms:validationMessages.atMax', {
        max: 2000,
      }) ?? '',
    ),
  });
