import { useLogoNavigation } from 'hooks/useLogoNavigation';
import { CrossIcon } from 'icons';
import { FC, memo, useEffect } from 'react';
import { mergeClasses } from 'utils';
import connectisLogo from '../../../assets/images/connectis-logo.svg';
import useWindowSize from '../../../hooks/useWindowSize';
import { IconButton } from '../../Buttons';
import { DiscordCard } from '../../DiscordCard';
import { UserPanel } from '../UserPanel';
import { Menu } from './subcomponents';

interface SidebarProps {
  isMobileMenuOpen?: boolean;
  onMobileMenuToggle?: () => void;
}
const Sidebar: FC<SidebarProps> = ({ isMobileMenuOpen, onMobileMenuToggle }) => {
  const { handleLogoClick } = useLogoNavigation();
  const { width } = useWindowSize();

  useEffect(() => {
    if (isMobileMenuOpen && (width ?? 0) < 1023) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMobileMenuOpen, width]);

  return (
    <>
      <div
        className={mergeClasses(
          'flex-col pt-20 z-20 fixed w-full inset-x-0 inset-y-0 bg-white transition-all duration-300',
          isMobileMenuOpen ? 'flex l-full lg:hidden' : '-left-full',
        )}
      >
        <div className="flex justify-center w-full mb-11">
          <button className="cursor-pointer" onClick={handleLogoClick}>
            <img alt="Connectis" className="w-[120px] h-4" src={connectisLogo} />
          </button>
          {isMobileMenuOpen && (
            <IconButton
              className="!p-0 text-gray-400 w-6 md:!w-6 absolute right-6 top-20 md:!min-h-[24px] md:!min-w-[24px]"
              icon={CrossIcon}
              iconClassName="w-8 transition-all md:min-h-[24px] md:min-w-[24px] md:w-full absolute -top-1"
              onClick={onMobileMenuToggle}
            />
          )}
        </div>
        <Menu onMobileMenuToggle={onMobileMenuToggle} />
        <div className="w-full h-1.5 bg-gray-100 border border-gray-200" />
        <UserPanel isMobileMenuOpen={isMobileMenuOpen} />
      </div>
      <div className="hidden lg:flex lg:fixed lg:top-0 lg:left-0 lg:flex-col lg:w-[240px] lg:py-10">
        <div className="hidden lg:!flex lg:justify-center lg:w-full lg:mb-10">
          <button className="md:cursor-pointer" onClick={handleLogoClick}>
            <img
              alt="Connectis"
              className="hidden lg:block lg:w-[120px] lg:h-4"
              src={connectisLogo}
            />
          </button>
        </div>
        <Menu />
        <div className="fixed bottom-16 max-w-[240px]">
          <DiscordCard />
        </div>
      </div>
    </>
  );
};

export const MemorizedSidebar = memo(Sidebar);
