export const useUrlObjectParams = <TObject = Record<string, any>>() => {
  const setUrlObject = (obj: TObject) => {
    const paramsString = new URLSearchParams(obj as any).toString();
    const newUrl = [window.location.pathname, paramsString].filter(Boolean).join('?');
    window.history.replaceState({}, '', newUrl);
  };

  const getUrlObject = () => {
    const urlObj = new URL(window.location.href);
    const searchParamsEntries = Array.from(urlObj.searchParams.entries());
    return searchParamsEntries.reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {} as Record<string, string>);
  };

  return {
    setUrlObject,
    getUrlObject,
  };
};
