import { CopyrightIcon } from 'icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FooterLink } from './FooterMenu';

export interface FooterBottomMenuProps {
  bottomMenu: FooterLink[];
}

export const FooterBottomMenu: FC<FooterBottomMenuProps> = ({ bottomMenu }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: `connectisWebsite:bottomMenu`,
  });

  return (
    <ul className="space-y-4 md:space-y-0 md:space-x-2 lg:space-x-8 flex md:items-center flex-col md:flex-row text-sm text-light-blue-400 mb-12 md:mb-0">
      <li className="flex space-x-1 whitespace-nowrap">
        <CopyrightIcon className="w-[18px]" />
        <span>Connectis_ {new Date().getFullYear()}</span>
      </li>
      {bottomMenu.map(({ url, labelKey }) => (
        <li key={`footerMenuItem_${labelKey}`} className="text-sm">
          <a href={url}>{t(`${labelKey}`)}</a>
        </li>
      ))}
    </ul>
  );
};
