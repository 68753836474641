import { Settings } from 'components';
import { PanelLayout } from 'layouts';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface SettingsPageProps {
  isMobileMenuOpen?: boolean;
  onMobileMenuToggle: () => void;
}

export const SettingsPage: FC<SettingsPageProps> = ({ isMobileMenuOpen, onMobileMenuToggle }) => {
  const { t } = useTranslation();

  return (
    <PanelLayout
      isMobileMenuOpen={isMobileMenuOpen}
      onMobileMenuToggle={onMobileMenuToggle}
      title={t('pageTitles:settings')}
    >
      <Settings onMobileMenuToggle={onMobileMenuToggle} />
    </PanelLayout>
  );
};
