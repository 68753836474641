export const insertItemToArray = <A extends any[], I extends A[number]>(
  array: A,
  index: number,
  item: I,
): A => {
  const arrayLength = index + 1 > array.length ? index + 1 : array.length;
  // eslint-disable-next-line prefer-spread
  return Array.apply(null, Array(arrayLength)).map((v, i) => {
    return i === index ? item : array[i];
  }) as A;
};
