import { FC, ReactNode } from 'react';
import { mergeClasses } from 'utils';

interface InfoBoxProps {
  text: string | ReactNode;
  className?: string;
}

export const InfoBox: FC<InfoBoxProps> = ({ text, className = '' }) => {
  return (
    <div
      className={mergeClasses(
        'flex items-center text-black bg-gray-200 bg-opacity-50 py-2 px-4 rounded-[6px] gap-x-2 font-medium text-sm',
        className,
      )}
    >
      <p>{text}</p>
    </div>
  );
};
