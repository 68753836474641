import { ExpertCvSourceEnum } from 'interfaces';
import { useCompleteExpertCvUploadMutation } from './api/completeExpertCvUpload/completeExpertCvUpload.generated';
import { useSignExpertCvUploadCredentialsMutation } from './api/signExpertCvUploadCredentials/signExpertCvUploadCredentials.generated';
import { useSignExpertCvUploadCredentialsWithRecaptchaMutation } from './api/signExpertCvUploadCredentialsWithRecaptcha/signExpertCvUploadCredentialsWithRecaptcha.generated';
import { useAsyncCallback } from './useAsyncCallback';

interface UploadExpertCvWithEmailParams {
  recaptchaAction: string;
  recaptchaToken: string;
  expertEmail: string;
  file: File;
  offerId?: string;
}

export const useFileUpload = () => {
  const [signExpertCvUploadCredentials] = useSignExpertCvUploadCredentialsMutation();
  const [signExpertCvUploadCredentialsWithRecaptcha] =
    useSignExpertCvUploadCredentialsWithRecaptchaMutation();
  const [completeExpertCvUpload] = useCompleteExpertCvUploadMutation();

  const uploadFileToUrl = async (
    url: string,
    file: File,
    fields?: string[][],
  ): Promise<boolean> => {
    const formData = new FormData();
    for (const [key, value] of fields ?? []) {
      formData.append(key, value);
    }
    formData.append('file', file);

    const fileUploadRes = await fetch(url, {
      method: 'POST',
      body: formData,
    });

    return fileUploadRes.status === 204;
  };

  const uploadExpertCv = async (
    expertId: string,
    file: File,
    offerId?: string,
  ): Promise<string> => {
    const { name, size, type: mimeType } = file;
    const uploadCredentials = await signExpertCvUploadCredentials({
      variables: {
        params: {
          name,
          size,
          mimeType,
          expertId,
          offerId: offerId || null,
          source: ExpertCvSourceEnum.Hub,
        },
      },
    });
    if (!uploadCredentials.data?.signExpertCvUploadCredentials) {
      throw new Error('An error occurred during signExpertCvUploadCredentials');
    }
    const { url, fields, fileId } = uploadCredentials.data?.signExpertCvUploadCredentials ?? {};

    await uploadFileToUrl(url, file, fields);
    await completeExpertCvUpload({
      variables: {
        fileId,
      },
    });
    return fileId;
  };
  const [uploadExpertCvCallback, { loading: uploadExpertCvLoading }] =
    useAsyncCallback(uploadExpertCv);

  const uploadExpertCvWithEmail = async (params: UploadExpertCvWithEmailParams) => {
    const { file, expertEmail, offerId, recaptchaToken, recaptchaAction } = params;
    const { name, size, type: mimeType } = file;
    const uploadCredentials = await signExpertCvUploadCredentialsWithRecaptcha({
      variables: {
        params: {
          name,
          size,
          mimeType,
          expertEmail,
          offerId: offerId || null,
          source: ExpertCvSourceEnum.External,
          recaptchaAction,
          recaptchaToken,
        },
      },
    });
    if (!uploadCredentials.data?.signExpertCvUploadCredentialsWithRecaptcha) {
      throw new Error('An error occurred during signExpertCvUploadCredentials');
    }
    const { url, fields, fileId, expertId } =
      uploadCredentials.data?.signExpertCvUploadCredentialsWithRecaptcha ?? {};

    await uploadFileToUrl(url, file, fields);
    await completeExpertCvUpload({
      variables: {
        fileId,
      },
    });
    return { fileId, expertId };
  };
  const [uploadExpertCvWithEmailCallback, { loading: uploadExpertCvWithEmailLoading }] =
    useAsyncCallback(uploadExpertCvWithEmail);

  return {
    uploadExpertCv: uploadExpertCvCallback,
    uploadExpertCvWithEmail: uploadExpertCvWithEmailCallback,
    uploadExpertCvLoading: uploadExpertCvLoading || uploadExpertCvWithEmailLoading,
  };
};
