import { yupResolver } from '@hookform/resolvers/yup';
import { Button, LinkButton, PasswordTextField } from 'components';
import { TFunction } from 'i18next';
import { CognitoErrorCodesEnum, Shape } from 'interfaces';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

interface SignInPasswordInputStepFormValues {
  password: string;
}

const signInPasswordInputStepSchema = (t: TFunction) =>
  yup.object().shape<Shape<SignInPasswordInputStepFormValues>>({
    password: yup.string().required(
      t('forms:validationMessages.required', {
        fieldName: t('forms:labels.password'),
      }) ?? '',
    ),
  });

interface SignInPasswordInputStepProps {
  onPasswordSubmit: (password: string) => any;
  onResetPasswordStartClick?: () => any;
  loading: boolean;
}

export const SignInPasswordInputStep = ({
  onPasswordSubmit,
  loading,
  onResetPasswordStartClick,
}: SignInPasswordInputStepProps) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<SignInPasswordInputStepFormValues>({
    resolver: yupResolver(signInPasswordInputStepSchema(t)),
  });

  const onSubmit = async (values: SignInPasswordInputStepFormValues) => {
    const res = await onPasswordSubmit(values.password);
    if (res === CognitoErrorCodesEnum.NotAuthorizedException) {
      return setError('password', {
        message: t('forms:validationMessages.wrongEmailOrPassword') ?? '',
      });
    }
  };

  return (
    <div className="flex flex-col items-stretch justify-center w-full max-w-[538px] pt-2 pb-0 lg:py-10">
      <p className="font-bold text-[24px] lg:text-[40px] mb-2 lg:mb-4">
        {t('auth:OTPcode.typePassword')}
      </p>
      <p className="text-[14px] lg:text-[16px] text-gray-500 mb-6 lg:mb-8">
        {t('auth:OTPcode.passwordSubtitle')}
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PasswordTextField
          {...register('password')}
          autoComplete="current-password"
          error={errors.password}
          label={t('forms:labels.password')}
          labelClassName="text-[14px] lg:text-[16px]"
        />

        {onResetPasswordStartClick && (
          <LinkButton
            className="pt-6 lg:pt-8"
            label={t('auth:passwordResetForm.title')}
            onClick={onResetPasswordStartClick}
          />
        )}
        <Button
          className="w-full mt-8 !rounded-lg"
          isLoading={loading}
          label={t('auth:OTPcode.checkOffers')}
          labelClassName="text-[14px] lg:text-[16px]"
          type="submit"
        />
      </form>
    </div>
  );
};
