import { IconButton } from 'components';
import { NotificationIcon } from 'icons';
import { MouseEventHandler } from 'react';
import { mergeClasses } from 'utils';

interface NotificationButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
  isNotificationUnread?: boolean;
  isNotificationModuleOpen: boolean;
  className?: string;
  isMobileMenuOpen?: boolean;
  hover?: boolean;
}

const NotificationButton = ({
  isNotificationUnread,
  isNotificationModuleOpen,
  onClick,
  className,
  isMobileMenuOpen,
  hover = true,
}: NotificationButtonProps) => {
  return (
    <div className={isMobileMenuOpen ? 'hidden' : 'relative'}>
      <IconButton
        className={mergeClasses(
          'rounded-full w-8 h-8',
          isNotificationModuleOpen ? '!bg-gray-200' : 'bg-none',
        )}
        hover={hover}
        icon={NotificationIcon}
        iconClassName={mergeClasses(
          'min-w-4 max-w-4 w-4 text-gray-400 relative cursor-pointer z-10',
          className,
        )}
        onClick={onClick}
      />
      {isNotificationUnread && (
        <div className="lg:w-2 lg:h-2 h-1.5 w-1.5 bg-red-500 lg:-mt-[26px] -mt-[28px] ml-5 border border-red-500 rounded-full z-10 absolute" />
      )}
    </div>
  );
};

export default NotificationButton;
