import { JobOrderList, Paper, ShowMore } from 'components';
import { useDynamicCollapsedHeight } from 'hooks';
import { useEffect, useRef } from 'react';
import AnimateHeight from 'react-animate-height';
import { useTranslation } from 'react-i18next';
import { usePublicOfferAppliedJobOrdersQuery } from '../../../../../hooks/api/publicOfferAppliedJobOrders/publicOfferAppliedJobOrders.generated';
import { useDynamicShowMoreLabel } from '../../../../../hooks/useDynamicShowMoreLabel';
import { calculatePublicOfferAppliedVisibleOffersNumber } from '../utils';

export const PublicOfferAppliedOffers = () => {
  const { t } = useTranslation();

  const { data, loading } = usePublicOfferAppliedJobOrdersQuery();
  const jobOrders = data?.publicOfferAppliedJobOrders ?? [];
  const isActive = !!jobOrders.length;

  const listRef = useRef<HTMLDivElement | null>(null);

  const visibleJobOrdersNumber = calculatePublicOfferAppliedVisibleOffersNumber(jobOrders);
  const { collapsedHeight, calculateCollapsedHeight } = useDynamicCollapsedHeight(
    listRef,
    visibleJobOrdersNumber,
  );
  const { showMoreValue, updateListHeightExternally } = useDynamicShowMoreLabel(
    listRef,
    collapsedHeight,
  );

  useEffect(() => {
    updateListHeightExternally();
    calculateCollapsedHeight();
  }, [jobOrders, calculateCollapsedHeight, updateListHeightExternally]);

  return (
    <AnimateHeight height={isActive ? 'auto' : 0}>
      <Paper
        className="relative"
        subtitle={t('offers:publicOfferAppliedOffers.subtitle')}
        title={t('offers:publicOfferAppliedOffers.title')}
      >
        <ShowMore
          hiddenHeight={collapsedHeight}
          isHiddenPadding="pb-8 -mb-3"
          isShowedPadding="pb-16 lg:pb-8 -mb-10"
          numberOfListItems={showMoreValue}
          overlayClassName="-mx-8 w-[calc(100%+64px)]"
        >
          <div ref={listRef}>
            <JobOrderList jobOrders={jobOrders} loading={loading} name="publicOfferAppliedOffers" />
          </div>
        </ShowMore>
      </Paper>
    </AnimateHeight>
  );
};
