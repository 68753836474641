import { ItemBadge, Paper, SearchInput } from 'components';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { findElementByKey, mapArrayIntoOptions, toggleStringOnArray } from 'utils';
import { useOnboardingDataQuery } from '../../../../../../hooks/api/onboardingData/onboardingData.generated';

interface DesiredIndustriesValues {
  desiredIndustries: string[];
}

export const DesiredIndustries = () => {
  const { t } = useTranslation();
  const { data } = useOnboardingDataQuery();
  const { industries } = data ?? { industries: [] };

  const {
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<DesiredIndustriesValues>();

  const desiredIndustriesValue = watch('desiredIndustries') ?? [];

  const handleToggleTechnology = (technologyId: string) =>
    setValue('desiredIndustries', toggleStringOnArray(desiredIndustriesValue, technologyId), {
      shouldValidate: true,
      shouldDirty: true,
    });

  const industriesOptions = mapArrayIntoOptions(
    industries.filter(({ industryId }) => !desiredIndustriesValue.includes(industryId)),
    'name',
    'industryId',
  );

  return (
    <Paper
      className="!p-4 xl:!p-8"
      id="desiredIndustries"
      subtitle={t('profile:papers.desiredIndustries.subtitle')}
      subtitleClassName="!mb-4 lg:!mb-6"
      title={t('profile:papers.desiredIndustries.title')}
      titleClassName="!mb-0 xl:!mb-0.5"
    >
      <SearchInput
        label={t('profile:papers.desiredIndustries.desiredIndustriesInputLabel') ?? ''}
        menuPlacement="bottom"
        onChange={handleToggleTechnology}
        options={industriesOptions}
        placeholder={t('profile:papers.desiredIndustries.desiredIndustriesInputPlaceholder') ?? ''}
      />
      <div className="flex gap-2 md:gap-4 mt-4 flex-wrap">
        {desiredIndustriesValue.map((industryId) => {
          const industryName = findElementByKey(industries, 'industryId', industryId)?.name;
          const translatedIndustryName = industryName || industryId;

          return (
            <ItemBadge
              key={`desiredIndustry_${industryId}`}
              label={translatedIndustryName}
              onRemove={() => handleToggleTechnology(industryId)}
            />
          );
        })}
      </div>
    </Paper>
  );
};
