import { IconButton, TextField } from 'components';
import { CrossedEyeIcon, EyeIcon } from 'icons';
import { forwardRef, useState } from 'react';
import { mergeClasses } from 'utils';
import { TextFieldProps } from './TextField';

export const PasswordTextField = forwardRef<HTMLInputElement, Omit<TextFieldProps, 'type'>>(
  ({ ...props }, ref) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const handlePasswordToggle = () => setIsPasswordVisible((prev) => !prev);

    return (
      <div className={mergeClasses('relative w-full', props.wrapperClassName)}>
        <TextField
          ref={ref}
          autoComplete="new-password"
          error={props.error}
          type={isPasswordVisible ? 'text' : 'password'}
          {...props}
        />
        <IconButton
          className="absolute z-10 top-[34px] lg:top-10 right-4"
          hover={false}
          icon={isPasswordVisible ? CrossedEyeIcon : EyeIcon}
          iconClassName="h-6 h-6"
          onClick={handlePasswordToggle}
        />
      </div>
    );
  },
);

PasswordTextField.displayName = 'PasswordTextField';
