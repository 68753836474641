import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
const defaultOptions = {} as const;
export type MarkExpertApplicationsAsViewedMutationVariables = Types.Exact<{
  jobOrderIds: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;

export type MarkExpertApplicationsAsViewedMutation = {
  __typename?: 'Mutation';
  markExpertApplicationsAsViewed: string;
};

export const MarkExpertApplicationsAsViewedDocument = gql`
  mutation markExpertApplicationsAsViewed($jobOrderIds: [String!]!) {
    markExpertApplicationsAsViewed(jobOrderIds: $jobOrderIds)
  }
`;
export type MarkExpertApplicationsAsViewedMutationFn = Apollo.MutationFunction<
  MarkExpertApplicationsAsViewedMutation,
  MarkExpertApplicationsAsViewedMutationVariables
>;

/**
 * __useMarkExpertApplicationsAsViewedMutation__
 *
 * To run a mutation, you first call `useMarkExpertApplicationsAsViewedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkExpertApplicationsAsViewedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markExpertApplicationsAsViewedMutation, { data, loading, error }] = useMarkExpertApplicationsAsViewedMutation({
 *   variables: {
 *      jobOrderIds: // value for 'jobOrderIds'
 *   },
 * });
 */
export function useMarkExpertApplicationsAsViewedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkExpertApplicationsAsViewedMutation,
    MarkExpertApplicationsAsViewedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkExpertApplicationsAsViewedMutation,
    MarkExpertApplicationsAsViewedMutationVariables
  >(MarkExpertApplicationsAsViewedDocument, options);
}
export type MarkExpertApplicationsAsViewedMutationHookResult = ReturnType<
  typeof useMarkExpertApplicationsAsViewedMutation
>;
export type MarkExpertApplicationsAsViewedMutationResult =
  Apollo.MutationResult<MarkExpertApplicationsAsViewedMutation>;
export type MarkExpertApplicationsAsViewedMutationOptions = Apollo.BaseMutationOptions<
  MarkExpertApplicationsAsViewedMutation,
  MarkExpertApplicationsAsViewedMutationVariables
>;
