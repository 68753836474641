import { EnvironmentConfig } from '../environment-config';

export const useRecaptcha = () => {
  const executeRecaptcha = async (actionName: string, callback: (token: string) => void) => {
    if (!EnvironmentConfig.googleRecaptchaKey) {
      throw new Error('Missing Recaptcha key');
    }

    window.grecaptcha.ready(async () => {
      const token = await window.grecaptcha.execute(
        EnvironmentConfig.googleRecaptchaKey as string,
        {
          action: actionName,
        },
      );
      callback(token);
    });
  };

  return {
    executeRecaptcha,
  };
};
