/* eslint-disable jsx-a11y/label-has-associated-control */
import { FC } from 'react';
import ReactCodeInput from 'react-code-input';
import { FieldError } from 'react-hook-form';
import { mergeClasses } from 'utils';
import { InputLabel } from '../../InputLabel';

interface CodeInputProps {
  name: string;
  error?: FieldError;
  label?: string | null;
  onChange: (value: string) => void;
  disabled?: boolean;
}

export const CodeInput: FC<CodeInputProps> = ({
  name,
  error,
  onChange,
  label,
  disabled = false,
}) => {
  return (
    <label>
      {label && <InputLabel label={label} />}
      <ReactCodeInput
        className={mergeClasses(
          '!grid grid-cols-6 max-w-xl border rounded-lg overflow-hidden [&>input:not(:last-child)]:border-r [&>input]:!bg-white/10',
          error
            ? 'border-red-500 [&>input]:border-r-red-500'
            : 'border-light-blue-200 [&>input]:!border-r-light-blue-200',
          disabled ? 'opacity-50' : '',
        )}
        disabled={disabled}
        fields={6}
        inputMode="latin"
        inputStyle={{
          height: 56,
          textAlign: 'center',
        }}
        inputStyleInvalid={{
          height: 56,
          background: 'rgba(255, 255, 255, 0.1)',
          textAlign: 'center',
        }}
        isValid={!error}
        name={name}
        onChange={onChange}
      />
      {error && <p className="text-red-500 leading-[18px] mt-2">{error.message}</p>}{' '}
    </label>
  );
};
