import { useReactiveVar } from '@apollo/client';
import { Paper } from 'components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { profileVar } from 'reactive-vars';
import { omitObjectKeys } from 'utils';
import { useUpdateExpertNotificationConsentsMutation } from '../../../../hooks/api/updateExpertNotificationConsents/updateExpertNotificationConsents.generated';
import { ProfileType } from '../../../../reactive-vars/profile';
import { NotificationListItem } from './subcomponents';

export enum ExpertNotificationConsentsEnum {
  OffersAndProfiles = 'OffersAndProfiles',
  ApplicationAndMeetings = 'ApplicationAndMeetings',
  News = 'News',
}

export const NotificationsTab = () => {
  const { t } = useTranslation();
  const { notificationConsents, ...profile } = useReactiveVar(profileVar) ?? {};

  const [updateExpertNotificationConsentsMutation] = useUpdateExpertNotificationConsentsMutation();

  const handleUpdateExpertNotificationConsents = async (name: ExpertNotificationConsentsEnum) => {
    if (!notificationConsents) return;
    try {
      const currentNotificationConsents = omitObjectKeys(notificationConsents, ['__typename']);
      const res = await updateExpertNotificationConsentsMutation({
        variables: {
          notificationConsents: {
            ...currentNotificationConsents,
            [name]: !notificationConsents[name] ?? false,
          },
        },
      });
      const { notificationConsents: updatedNotificationConsents } =
        res.data?.updateExpertNotificationConsents ?? {};
      if (!updatedNotificationConsents) return;
      profileVar({
        ...(profile as ProfileType),
        notificationConsents: updatedNotificationConsents,
      });
    } catch (err) {
      toast.error(t('toasts:error'));
      console.error(err);
    }
  };

  return (
    <Paper>
      <div className="space-y-3">
        {Object.values(ExpertNotificationConsentsEnum).map((name) => (
          <NotificationListItem
            key={`notification_${name}`}
            isChecked={notificationConsents?.[name] ?? false}
            name={name}
            onCheck={() => handleUpdateExpertNotificationConsents(name)}
          />
        ))}
      </div>
    </Paper>
  );
};
