import { IconButton } from 'components/Buttons';
import { CrossIcon } from 'icons';
import { FC } from 'react';

interface ItemBadgeProps {
  onRemove?: () => void;
  label: string;
}

export const ItemBadge: FC<ItemBadgeProps> = ({ label, onRemove }) => {
  return (
    <div className="rounded-lg p-2 pl-4 flex justify-center items-center bg-gray-100 text-gray-500 font-medium text-sm">
      {label}
      {onRemove && (
        <IconButton
          className="ml-4 bg-gray-300 hover:!bg-gray-300 hover:opacity-70 text-white"
          icon={CrossIcon}
          onClick={onRemove}
          size="sm"
        />
      )}
    </div>
  );
};
