import { AvailabilitySelect, Paper } from 'components';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface AvailabilityValues {
  availability: string;
  noticeLength?: number;
}

export const Availability = () => {
  const { t } = useTranslation();

  const {
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<AvailabilityValues>();

  const availabilityValue = watch('availability');
  const noticeLengthValue = watch('noticeLength');

  const handleAvailabilityChange = (value: string) =>
    setValue('availability', value, {
      shouldValidate: true,
      shouldDirty: true,
    });

  const handleNoticeLengthChange = (value: number) =>
    setValue('noticeLength', value, {
      shouldValidate: true,
      shouldDirty: true,
    });

  return (
    <Paper
      className="!p-4 xl:!p-8"
      id="availability"
      subtitle={t('profile:papers.availability.subtitle')}
      subtitleClassName="!mb-4 lg:!mb-6"
      title={t('profile:papers.availability.title')}
      titleClassName="!mb-0 xl:!mb-0.5"
    >
      <AvailabilitySelect
        availabilityError={errors.availability}
        availabilityValue={availabilityValue}
        noticeLengthError={errors.noticeLength}
        noticeLengthValue={noticeLengthValue}
        onAvailabilityChange={handleAvailabilityChange}
        onNoticeLengthChange={handleNoticeLengthChange}
      />
    </Paper>
  );
};
