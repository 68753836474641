import { Collapse } from 'components';
import { ExpertTag } from 'interfaces';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { insertItemToArray, mergeClasses, unique } from 'utils';
import { OnboardingDataQuery } from '../../../../hooks/api/onboardingData/onboardingData.generated';
import { RoleStepValues } from '../../steps';
import { MainTechnologyItem } from './MainTechnologyItem';

export type AvailableMainTechnologies =
  OnboardingDataQuery['jobPositions'][number]['availableMainTechnologies'];

interface MainTechnologySelectProps {
  availableMainTechnologies: AvailableMainTechnologies;
  isCollapsed: boolean;
  jobPositionId: string;
}
export const MainTechnologySelect: FC<MainTechnologySelectProps> = ({
  availableMainTechnologies,
  isCollapsed,
  jobPositionId,
}) => {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<RoleStepValues>();
  const mainTechnologyIdsValue = watch('mainTechnologyIds');
  const tagsValue = watch('tags');

  const handleMainTechnologiesChange = (value: string) => {
    const [tagId, index] = value.split('_');

    const newValue = insertItemToArray(mainTechnologyIdsValue ?? [], parseInt(index, 10), tagId);
    setValue('mainTechnologyIds', newValue, { shouldValidate: !errors.mainTechnologyIds });

    if (tagsValue.length) {
      return;
    }

    const allDefaultTagIds = unique(
      newValue
        .map((mainTechId, i) => {
          const { mainTechnologies } = availableMainTechnologies[i];
          return mainTechnologies.find(({ tag }) => tag.id === mainTechId)?.defaultTagIds ?? [];
        })
        .flat(),
    );

    const defaultTags: ExpertTag[] = allDefaultTagIds.map((id) => ({
      tagId: id,
      weight: null,
    }));

    setValue('tags', defaultTags);
    setValue('tagsSuperPowers', []);
  };

  return (
    <Collapse
      className={mergeClasses('grid-area-mainTech transition-all', !isCollapsed ? 'mb-4' : '')}
      isCollapsed={isCollapsed}
    >
      <div className="border border-gray-200 bg-gray-100 rounded-lg p-6 space-y-4">
        {availableMainTechnologies.map((mainTech, index) => (
          <MainTechnologyItem
            {...mainTech}
            // eslint-disable-next-line react/no-array-index-key
            key={`mainTechItem_${index}_${jobPositionId}`}
            index={index}
            jobPositionId={jobPositionId}
            onChange={handleMainTechnologiesChange}
          />
        ))}
      </div>
    </Collapse>
  );
};
