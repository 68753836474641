import dayjs from 'dayjs';

function getEasterDate(year: number) {
  const f = Math.floor;
  const G = year % 19;
  const C = f(year / 100);
  const H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30;
  const I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11));
  const J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7;
  const L = I - J;
  const month = 3 + f((L + 40) / 44);
  const day = L + 28 - 31 * f(month / 4);

  return dayjs(`${year}-${month}-${day}`);
}

function getPolishHolidays(year: number) {
  const easterDate = getEasterDate(year);
  return [
    easterDate.format('DD-MM'), // wielkanoc
    easterDate.add(1, 'days').format('DD-MM'), // drugi dzien wielkanocy
    easterDate.add(49, 'days').format('DD-MM'), // zielone swiatki = wielkanoc + 49
    easterDate.add(60, 'days').format('DD-MM'), // boze cialo = wielkanoc + 60
    '01-01',
    '06-01',
    '01-05',
    '03-05',
    '19-06',
    '15-08',
    '01-11',
    '11-11',
    '25-12',
    '26-12',
  ];
}

export const isPolishHoliday = (date: string) => {
  const year = Number(dayjs(date).format('YYYY'));
  return getPolishHolidays(year).includes(dayjs(date).format('DD-MM'));
};
