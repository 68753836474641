import {
  BackButton,
  ConnectisWebsiteFooter,
  ConnectisWebsiteHeader,
  ConnectisWebsiteHero,
  ExternalOffer,
  PageLoadingSpinner,
} from 'components';
import { useLightBackgroundObserver, useSitemap } from 'hooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { usePublicOfferQuery } from '../../hooks/api/publicOffer/publicOffer.generated';
import { ExternalOfferNotFoundPage } from '../ExternalOffer/ExternalOfferNotFoundPage';

export const JobBoardOfferPage = () => {
  const { t } = useTranslation();
  const { offerId } = useParams();
  const { isLightBackground, measureDimensions } = useLightBackgroundObserver();

  const location = useLocation();
  const navigate = useNavigate();
  const sitemap = useSitemap();

  const {
    data: offerData,
    loading: offerLoading,
    error,
  } = usePublicOfferQuery({
    variables: {
      offerId: offerId ?? '',
    },
  });

  const handleBackRedirect = () => {
    navigate(location.state?.prevUrl || sitemap.jobBoard, {
      state: {
        scrollToAnchor: 'jobBoard',
      },
    });
  };

  useEffect(() => {
    measureDimensions();
  }, [offerData]);

  useEffect(() => {
    const htmlEl = document.querySelector('html');
    if (!htmlEl) {
      return;
    }
    htmlEl.classList.add('job-board');
    return () => {
      htmlEl.classList.remove('job-board');
    };
  }, []);

  if (!offerId) {
    return <ExternalOfferNotFoundPage />;
  }

  if (offerLoading) {
    return <PageLoadingSpinner />;
  }

  const { publicOffer } = offerData ?? {};

  if (!publicOffer || !!error) {
    return <ExternalOfferNotFoundPage />;
  }

  return (
    <main className="font-dmSans">
      <ConnectisWebsiteHeader isLightBackground={isLightBackground} />
      <ConnectisWebsiteHero />
      <div className="max-w-container-rem mx-auto px-4 lg:px-16 mb-6 mt-13">
        <BackButton
          className="font-medium text-sm"
          label={t('jobBoard:returnToAllOffers') || ''}
          onClick={handleBackRedirect}
        />
      </div>
      <ExternalOffer
        className="light-section pb-8 [&>div]:!pt-0 !max-w-[100vw] lg:!max-w-container-rem"
        displayThankYou
        offer={publicOffer}
      />
      <ConnectisWebsiteFooter />
    </main>
  );
};
