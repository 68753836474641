export const basicInformationLimits = {
  MIN_HOURLY_RATE: 1,
  MAX_HOURLY_RATE: 1000,
};

export const MAX_SUPERPOWERS_ALLOWED = 5;

export const MAX_NAME_CHARACTERS = 50;

export const MAX_CERTIFICATE_NAME_CHARACTERS = 100;

export const NO_SPECIAL_CHARACTERS = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ]+$/;

export const MAX_FILE_SIZE = 5 * 1024 * 1024;
