import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export const useCopyToClipboard = () => {
  const { t } = useTranslation();
  const copyToClipboard = async (
    textToCopy: string,
    event?: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event?.stopPropagation();
    try {
      await navigator.clipboard.writeText(textToCopy);
      toast.info(t('offers:offerListItem.copiedToClipboard'));
    } catch (err) {
      console.error(t('offers:offerListItem.failCopyToClipboard'), err);
    }
  };

  return { copyToClipboard };
};
