import { RoundedButton } from 'components';
import dayjs from 'dayjs';
import { useSitemap } from 'hooks';
import { ChartIcon, LanguageIcon, LevelsIcon, LocationIcon, OfficeIcon } from 'icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { mergeClasses } from 'utils';
import { PublicJobBoardOffersQuery } from '../../../../../../hooks/api/publicJobBoardOffers/publicJobBoardOffers.generated';
import { determineRequiredLanguageLevel } from '../../../../../Modals/OfferDetailsModal/subcomponents/JobOrderDetailsHeader';
import {
  JobBoardOfferIconDetail,
  JobBoardOfferIconDetailProps,
  JobBoardOfferSkills,
} from './subcomponents';

export type JobBoardOfferType = PublicJobBoardOffersQuery['publicJobBoardOffers'][0];
type JobBoardOfferProps = JobBoardOfferType;

export const JobBoardOffer: FC<JobBoardOfferProps> = ({ jobOrder, id }) => {
  const { t } = useTranslation();
  const sitemap = useSitemap();
  const navigate = useNavigate();

  const {
    leadingTechnologyName,
    role,
    rateRangeFrom,
    rateRangeTo,
    skillsMustHave,
    skillsNiceToHave,
    minEnglishLevel,
    seniority,
    locationPreference,
    experienceYears,
    workMode,
  } = jobOrder;

  const offerName = [role, leadingTechnologyName].join(' ');
  const skills = [...skillsMustHave, ...skillsNiceToHave];

  const details: Array<JobBoardOfferIconDetailProps & { key: string }> = [
    {
      icon: LocationIcon,
      label: locationPreference || '',
      key: 'location',
    },
    {
      icon: OfficeIcon,
      label: workMode ? t(`dictionaries:workTypes.${workMode}`) : '-',
      key: 'workTypes',
    },
    { icon: LevelsIcon, label: seniority || '', key: 'seniority' },
    {
      icon: ChartIcon,
      label: !experienceYears
        ? t('offers:offerListItem.noExperience')
        : dayjs().subtract(experienceYears, 'year').fromNow(true),
      key: 'experienceYears',
    },
    {
      icon: LanguageIcon,
      label: `${t('forms:labels.english')} ${determineRequiredLanguageLevel(minEnglishLevel)}`,
      key: 'languages',
    },
  ];

  const handleOfferRedirect = () => {
    const { pathname, search } = window.location;
    const prevUrl = [pathname, search, '#jobBoard'].join('');

    navigate(sitemap.jobBoardOffer(id), {
      state: {
        prevUrl,
      },
    });
    window.scrollTo(0, 0);
  };

  const isRateVisible = rateRangeFrom !== null && rateRangeTo !== null;
  const rateString = `${Math.round(rateRangeFrom || 0)} - ${Math.round(rateRangeTo || 0)} zł/h`;

  return (
    <li
      className="shadow-lg p-6 rounded-3xl bg-white relative min-h-[150px] flex justify-between items-end md:items-stretch cursor-pointer"
      onClick={handleOfferRedirect}
    >
      <div className="space-y-6">
        <p className="font-bold text-lg lg:text-2xl pr-4">{offerName}</p>
        {isRateVisible && (
          <p className="block md:hidden">
            {rateString}
            <span className="font-medium text-gray-350 pl-1">{t('jobBoard:vatB2b')}</span>
          </p>
        )}
        <ul className="flex items-start flex-wrap gap-4 lg:gap-5">
          {details
            .filter(({ label }) => label)
            .map(({ key, label, icon }) => (
              <JobBoardOfferIconDetail key={`${id}.${key}`} icon={icon} label={label} />
            ))}
        </ul>
        <JobBoardOfferSkills skills={skills} />
      </div>
      <div
        className={mergeClasses(
          'flex flex-col items-end',
          isRateVisible ? 'justify-between' : 'justify-end',
        )}
      >
        {isRateVisible && (
          <div className="text-right hidden md:block">
            <p className="text-base lg:text-lg font-medium whitespace-nowrap">{rateString}</p>
            <p className="font-medium text-gray-350">{t('jobBoard:vatB2b')}</p>
          </div>
        )}
        <RoundedButton variant="neon" />
      </div>
    </li>
  );
};
