import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
const defaultOptions = {} as const;
export type SetCognitoPasswordMutationVariables = Types.Exact<{
  password: Types.Scalars['String'];
}>;

export type SetCognitoPasswordMutation = { __typename?: 'Mutation'; setCognitoPassword: string };

export const SetCognitoPasswordDocument = gql`
  mutation setCognitoPassword($password: String!) {
    setCognitoPassword(password: $password)
  }
`;
export type SetCognitoPasswordMutationFn = Apollo.MutationFunction<
  SetCognitoPasswordMutation,
  SetCognitoPasswordMutationVariables
>;

/**
 * __useSetCognitoPasswordMutation__
 *
 * To run a mutation, you first call `useSetCognitoPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCognitoPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCognitoPasswordMutation, { data, loading, error }] = useSetCognitoPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSetCognitoPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetCognitoPasswordMutation,
    SetCognitoPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetCognitoPasswordMutation, SetCognitoPasswordMutationVariables>(
    SetCognitoPasswordDocument,
    options,
  );
}
export type SetCognitoPasswordMutationHookResult = ReturnType<typeof useSetCognitoPasswordMutation>;
export type SetCognitoPasswordMutationResult = Apollo.MutationResult<SetCognitoPasswordMutation>;
export type SetCognitoPasswordMutationOptions = Apollo.BaseMutationOptions<
  SetCognitoPasswordMutation,
  SetCognitoPasswordMutationVariables
>;
