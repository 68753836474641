import { EnvironmentConfig } from '../../../environment-config';

export const bottomFooterMenu = [
  {
    labelKey: 'officeConnectis',
    url: 'mailto:office.pl@connectis.pl',
  },
  {
    labelKey: 'sitemap',
    url: `${EnvironmentConfig.connectisWebsiteUrl}connectis-sitemap`,
  },
  {
    labelKey: 'privacy',
    url: `${EnvironmentConfig.connectisWebsiteUrl}privacy`,
  },
];
