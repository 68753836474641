import { FC, useMemo } from 'react';
import ReactSelect from 'react-select';
import { getRegionsList } from '../utils';

const CustomControl: FC<{ children: any; innerRef: any; innerProps: any; selectProps: any }> = ({
  children,
  innerRef,
  innerProps,
}) => {
  return (
    <div ref={innerRef} className="flex items-center text-gray-400" {...innerProps}>
      {children}
    </div>
  );
};

const regions = getRegionsList();
const prefixOptions = regions.map(({ prefix, name }) => ({
  label: `${prefix} (${name})`,
  value: prefix,
}));

interface PhoneNumberPrefixSelectProps {
  selectedPrefix: number;
  onChange: (prefix: number) => void;
}

export const PhoneNumberPrefixSelect: FC<PhoneNumberPrefixSelectProps> = ({
  onChange,
  selectedPrefix,
}) => {
  const selectedOption = useMemo(
    () => prefixOptions.find(({ value }) => value === selectedPrefix),
    [selectedPrefix, prefixOptions],
  );

  return (
    <ReactSelect
      className="w-[58px] cursor-pointer"
      components={{
        Control: CustomControl,
        IndicatorSeparator: undefined,
        DropdownIndicator: undefined,
      }}
      formatOptionLabel={({ label: optionLabel, value }, { context }) =>
        context === 'menu' ? optionLabel : `+${value}`
      }
      menuPlacement="top"
      onChange={(newSelectedOption) => onChange(newSelectedOption?.value ?? 48)}
      options={prefixOptions}
      placeholder=""
      styles={{
        menuList: (provided) => ({ ...provided, width: 200, backgroundColor: 'white' }),
        singleValue: (provided) => ({ ...provided, color: '#999999' }),
      }}
      value={selectedOption}
    />
  );
};
