import { FC } from 'react';

interface MenuItemCounterProps {
  value: number;
}

export const MenuItemCounter: FC<MenuItemCounterProps> = ({ value }) => {
  return (
    <div className="ml-3 bg-primary-500 text-xs text-white font-medium py-[3px] px-2.5 rounded-xl">
      {value}
    </div>
  );
};
