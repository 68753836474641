import { JobOrderDetailsModal, MeetingScheduleModal } from 'components';
import { useJobOrderModalsHandlers, useSitemap } from 'hooks';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCheckIfOfferExistsLazyQuery } from '../../hooks/api/checkIfOfferExists/checkIfOfferExists.generated';
import { ExpiredOfferModal } from '../Modals/Expired Offer Modal/ExpiredOfferModal';

export const JobOrderModalController = () => {
  const [publicOfferIdFromUrlState, setPublicOfferIdFromUrlState] = useState('');
  const [searchParams] = useSearchParams();
  const jobOrderIdFromUrl = searchParams.get('jobOrderId') ?? '';
  const publicOfferIdFromUrl = searchParams.get('offerId') ?? '';
  const navigate = useNavigate();
  const sitemap = useSitemap();

  const [checkIfOfferExists, { data: checkedJobOrder, loading, refetch }] =
    useCheckIfOfferExistsLazyQuery();
  const {
    closeDetailsModalAndClearJobOrderId,
    closeExpiredOfferModalAndClearJobOrderId,
    handleScheduleMeetingClick,
    handleFavouriteClick,
    applyForJobOrder,
    calendarModalState,
    detailsModalState,
    openDetailsModal,
    openExpiredOfferModal,
    expiredOfferModalState,
    closeCalendarModal,
  } = useJobOrderModalsHandlers(refetch);

  const foundJobOrder = checkedJobOrder?.checkIfOfferExists?.content;
  const offerNotFound = checkedJobOrder?.checkIfOfferExists?.status === 'Offer not found';

  const handleSeeMoreOffers = () => {
    navigate(sitemap.offers('all'));
    closeExpiredOfferModalAndClearJobOrderId();
  };

  const handleApplyForJob = async (meetingDates: string[]): Promise<boolean> => {
    return !!(await applyForJobOrder(meetingDates, publicOfferIdFromUrlState));
  };

  const handleCheckJobOrder = async (id: string) => {
    const { data } = await checkIfOfferExists({
      variables: {
        id,
      },
    });
    const { status } = data?.checkIfOfferExists ?? {};
    if (status === 'Offer found') {
      openDetailsModal(jobOrderIdFromUrl);
      return;
    }
    if (status !== 'Offer found') {
      openExpiredOfferModal(null);
    }
  };

  useEffect(() => {
    if (publicOfferIdFromUrl) {
      setPublicOfferIdFromUrlState(publicOfferIdFromUrl);
    }

    const jobOrderId = searchParams.get('jobOrderId');
    if (loading || !jobOrderId || detailsModalState.isOpen) {
      return;
    }
    handleCheckJobOrder(jobOrderId);
  }, [searchParams, loading]);

  return (
    <>
      <ExpiredOfferModal
        isOpen={expiredOfferModalState.isOpen}
        offerNotFound={offerNotFound}
        onClose={closeExpiredOfferModalAndClearJobOrderId}
        onSeeMoreOffers={handleSeeMoreOffers}
      />
      {foundJobOrder && (
        <>
          <JobOrderDetailsModal
            isMeetingScheduled={
              !!checkedJobOrder?.checkIfOfferExists?.content?.application?.meetingDates.length
            }
            isOpen={detailsModalState.isOpen}
            jobOrder={foundJobOrder}
            onClose={closeDetailsModalAndClearJobOrderId}
            onFavouriteClick={handleFavouriteClick}
            onScheduleMeetingClick={handleScheduleMeetingClick}
          />
          <MeetingScheduleModal
            isOpen={calendarModalState.isOpen}
            jobOrder={foundJobOrder}
            onClose={closeCalendarModal}
            onSendSuccess={handleApplyForJob}
          />
        </>
      )}
    </>
  );
};
