import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  Button,
  ConfirmationModal,
  PasswordRequirementsList,
  PasswordTextField,
  SmallButton,
} from 'components';
import { useAuth, useModalState } from 'hooks';
import { TFunction } from 'i18next';
import { Shape } from 'interfaces';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PASSWORD_REQUIREMENTS_REGEX } from 'utils';
import * as yup from 'yup';
import { useSetCognitoPasswordMutation } from '../../../../hooks/api/setCognitoPassword/setCognitoPassword.generated';

export interface SetPasswordFormValues {
  newPassword: string;
}

const setPasswordFormSchema = (t: TFunction) =>
  yup.object().shape<Shape<SetPasswordFormValues>>({
    newPassword: yup
      .string()
      .required(
        t('forms:validationMessages.required', {
          fieldName: t('forms:labels.setUpPassword').toLowerCase(),
        }) ?? '',
      )
      .matches(
        PASSWORD_REQUIREMENTS_REGEX,
        t('forms:validationMessages.fieldDoesntMeetRequirements', {
          fieldName: t('forms:labels.currentPassword').toLowerCase(),
        }) ?? '',
      )
      .min(
        8,
        t('forms:validationMessages.fieldDoesntMeetRequirements', {
          fieldName: t('forms:labels.currentPassword').toLowerCase(),
        }) ?? '',
      ),
  });

interface SetPasswordFormProps {
  onComplete: () => any;
}
export const SetPasswordForm = ({ onComplete }: SetPasswordFormProps) => {
  const { t } = useTranslation();

  const { authLoading } = useAuth();

  const [isSetPasswordOptionOpen, setIsSetPasswordOptionOpen] = useState(false);

  const {
    register,
    watch,
    reset,
    formState: { isValid, errors },
    getValues,
    handleSubmit,
  } = useForm<SetPasswordFormValues>({
    resolver: yupResolver(setPasswordFormSchema(t)),
  });

  const {
    modalState: confirmationModal,
    closeModal: closeConfirmationModal,
    openModal: openConfirmationModal,
  } = useModalState<null>();

  const [setCognitoPasswordMutation, { loading: setPasswordLoading }] =
    useSetCognitoPasswordMutation();

  const handlePasswordSetConfirmation = useCallback(async () => {
    const { newPassword } = getValues();

    try {
      await setCognitoPasswordMutation({
        variables: {
          password: newPassword,
        },
      });

      onComplete();
      closeConfirmationModal();
    } catch (error) {
      console.error(error);
    }
  }, [getValues, closeConfirmationModal, onComplete]);

  const passwordValue = watch('newPassword');

  const handleSetNewPassword = () => {
    openConfirmationModal(null);
  };
  const handleCancelClick = () => {
    setIsSetPasswordOptionOpen(false);
    reset();
  };

  return (
    <>
      <ConfirmationModal
        buttonClassName="lg:w-[176px] px-0"
        buttonsContainerClassName="space-x-0 lg:space-x-6 flex-col-reverse lg:flex-row gap-6 lg:gap-0"
        className="lg:w-[800px] lg:py-12 lg:px-[89px]"
        confirmButtonLabel={`${t('common:actions.saveChanges')}`}
        headerClassName="lg:text-2xl lg:leading-10 lg:pb-0"
        headerText={`${t('forms:validationMessages.setPasswordConfirmation')}`}
        iconButtonClassName="hidden"
        isCancelButtonDisabled={setPasswordLoading}
        isConfirmButtonDisabled={setPasswordLoading}
        isConfirmButtonLoading={setPasswordLoading}
        isOpen={confirmationModal.isOpen}
        modalClassName="max-w-full px-0"
        onClose={closeConfirmationModal}
        onConfirm={handlePasswordSetConfirmation}
        showHeader
        text={`${t('forms:validationMessages.setPasswordExplanation')}`}
      />
      <form onSubmit={handleSubmit(handleSetNewPassword)}>
        <div className="lg:min-h-[360px] lg:flex lg:flex-col lg:justify-between">
          <div>
            <div>
              <p className="text-[16px] lg:text-[18px] font-bold pt-6 pb-4">
                {isSetPasswordOptionOpen
                  ? t('forms:labels.password')
                  : t('forms:labels.currentLoginMethod')}
              </p>
              <div className="flex gap-4">
                {!isSetPasswordOptionOpen && (
                  <p className="text-[14px] lg:text-[16px] font-medium items-center">
                    {t('forms:labels.OTPLoginMethod')}
                  </p>
                )}
                {!isSetPasswordOptionOpen && (
                  <SmallButton
                    className="text-primary-500 text-[14px] lg:text-[16px]"
                    label={t('common:actions.change')}
                    onClick={() => setIsSetPasswordOptionOpen(true)}
                  />
                )}
              </div>
            </div>
            {isSetPasswordOptionOpen && (
              <>
                <PasswordTextField
                  {...register('newPassword')}
                  autoComplete="new-password"
                  error={errors.newPassword}
                  hideErrorMessage
                  label={t('forms:labels.setUpPassword')}
                  wrapperClassName="xl:w-96 mb-2"
                />
                <PasswordRequirementsList
                  isActive={!!passwordValue || !!errors.newPassword}
                  password={passwordValue}
                />
              </>
            )}
          </div>
          {isSetPasswordOptionOpen && (
            <div className="flex justify-end pt-8 lg:pt-0">
              <Button
                className="w-full md:w-48"
                label={t('settings:actions.cancel')}
                onClick={handleCancelClick}
                variant="outline"
              />
              <Button
                className="w-full lg:!w-[176px] lg:!ml-2 whitespace-nowrap"
                isLoading={authLoading}
                label={t('common:actions.saveChanges')}
                onClick={handleSubmit(handleSetNewPassword)}
              />
            </div>
          )}
        </div>
      </form>
    </>
  );
};
