import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
const defaultOptions = {} as const;
export type SignExpertCvUploadCredentialsMutationVariables = Types.Exact<{
  params: Types.SignExpertCvUploadCredentialsInputType;
}>;

export type SignExpertCvUploadCredentialsMutation = {
  __typename?: 'Mutation';
  signExpertCvUploadCredentials: {
    __typename?: 'SignExpertCvUploadCredentialsResultType';
    fields: Array<Array<string>>;
    url: string;
    fileId: string;
    expertId: string;
  };
};

export const SignExpertCvUploadCredentialsDocument = gql`
  mutation signExpertCvUploadCredentials($params: SignExpertCvUploadCredentialsInputType!) {
    signExpertCvUploadCredentials(params: $params) {
      fields
      url
      fileId
      expertId
    }
  }
`;
export type SignExpertCvUploadCredentialsMutationFn = Apollo.MutationFunction<
  SignExpertCvUploadCredentialsMutation,
  SignExpertCvUploadCredentialsMutationVariables
>;

/**
 * __useSignExpertCvUploadCredentialsMutation__
 *
 * To run a mutation, you first call `useSignExpertCvUploadCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignExpertCvUploadCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signExpertCvUploadCredentialsMutation, { data, loading, error }] = useSignExpertCvUploadCredentialsMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSignExpertCvUploadCredentialsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignExpertCvUploadCredentialsMutation,
    SignExpertCvUploadCredentialsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SignExpertCvUploadCredentialsMutation,
    SignExpertCvUploadCredentialsMutationVariables
  >(SignExpertCvUploadCredentialsDocument, options);
}
export type SignExpertCvUploadCredentialsMutationHookResult = ReturnType<
  typeof useSignExpertCvUploadCredentialsMutation
>;
export type SignExpertCvUploadCredentialsMutationResult =
  Apollo.MutationResult<SignExpertCvUploadCredentialsMutation>;
export type SignExpertCvUploadCredentialsMutationOptions = Apollo.BaseMutationOptions<
  SignExpertCvUploadCredentialsMutation,
  SignExpertCvUploadCredentialsMutationVariables
>;
