import { JsonObject } from 'type-fest';

export const toasts: JsonObject = {
  loading: 'Proszę czekać...',
  error: 'Wystąpił błąd, spróbuj ponownie później',
  errorTryAgain: 'Wystąpił błąd, spróbuj ponownie',
  success: 'Akcja zakończona pomyślnie',
  tryAgain: 'Spróbuj ponownie',
  limitExceeded: 'Przekroczono limit prób, spróbuj ponownie później',
  signInForm: {
    wrongEmailOrPassword: 'E-mail lub hasło są nieprawidłowe',
    signInSuccess: 'Zalogowano pomyślnie!',
    invalidCode: 'Niepoprawny kod',
    codeResend: 'Kod wysłany ponownie',
  },
  signUpForm: {
    wrongEmailOrPassword: 'E-mail lub hasło są nieprawidłowe',
    signUpSuccess: 'Konto zostało utworzone pomyślnie',
  },
  passwordResetForm: {
    verificationEmailSent: 'E-mail weryfikacyjny został wysłany pomyślnie',
    passwordChangeSuccess: 'Hasło zostało zmienione pomyślnie',
  },
};
