import {
  IconButton,
  jobBoardFiltersDefaultValues,
  JobBoardFiltersValues,
  LinkButton,
  RoundedButton,
} from 'components';
import { useScrollLock } from 'hooks';
import { ArrowIcon } from 'icons';
import { SeniorityLevelEnum, WorkModeEnum } from 'interfaces';
import { FC, FormEvent, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'utils';
import { Checkbox, TextField } from '../../redesignedInputs';
import { FilterWrapper } from './subcomponents';

const mapNumberValue = (val: number | undefined) => (val ? Number(val) : 0);

interface JobBoardFiltersProps {
  onSubmit: () => void;
  loading?: boolean;
  isFiltersOpen: boolean;
  closeFilters: () => void;
}

export const JobBoardFilters: FC<JobBoardFiltersProps> = ({
  onSubmit,
  loading = false,
  isFiltersOpen = false,
  closeFilters,
}) => {
  const { t } = useTranslation();
  const { lockPageScroll, unlockPageScroll } = useScrollLock();
  const {
    register,
    reset,
    formState: { errors },
    trigger,
  } = useFormContext<JobBoardFiltersValues>();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
    if (isFiltersOpen) {
      closeFilters();
    }
  };

  const handleClearFilters = () => {
    reset(jobBoardFiltersDefaultValues);
    onSubmit();
  };

  useEffect(() => {
    if (isFiltersOpen) {
      lockPageScroll();
      return;
    }
    unlockPageScroll();
    return () => {
      unlockPageScroll();
    };
  }, [isFiltersOpen]);

  return (
    <div
      className={mergeClasses(
        'w-screen h-screen lg:h-auto lg:max-w-[362px] fixed left-0 z-50 lg:z-20 lg:sticky lg:top-14',
        isFiltersOpen ? 'bottom-0' : '-bottom-full',
      )}
    >
      <div
        className={mergeClasses(
          'w-screen h-screen bg-black/70 z-10 absolute top-0 left-0 lg:hidden transition-opacity duration-150',
          isFiltersOpen ? 'opacity-100' : 'opacity-0',
        )}
        onClick={closeFilters}
      />
      <div
        className={mergeClasses(
          'w-full bg-white shadow-lg z-50 bottom-0 left-0 absolute lg:relative py-6 px-4 lg:p-6 rounded-t-3xl lg:rounded-b-3xl transition-all duration-150 max-h-[95dvh] lg:max-h-full overflow-auto lg:overflow-hidden',
          isFiltersOpen ? 'bottom-0' : '-bottom-full',
        )}
      >
        <div className="flex flex-row items-center justify-center lg:justify-between relative mb-10">
          <IconButton
            className="lg:hidden absolute -left-2"
            icon={ArrowIcon}
            iconClassName="w-5 h-5 text-gray-400"
            onClick={closeFilters}
          />
          <h3 className="font-medium text-2xl leading-7">{t('jobBoard:filters.title')}</h3>
          <LinkButton
            className="no-underline absolute right-0"
            label={t('jobBoard:filters.clearFilters')}
            onClick={handleClearFilters}
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="lg:max-h-[calc(100vh-272px)] overflow-auto space-y-8 mb-8">
            <FilterWrapper className="space-y-4" title={t('forms:labels.seniority')}>
              {Object.values(SeniorityLevelEnum).map((seniorityLevel) => (
                <Checkbox
                  {...register(`seniority.${seniorityLevel}`)}
                  key={seniorityLevel}
                  label={seniorityLevel}
                />
              ))}
            </FilterWrapper>
            <FilterWrapper
              className="flex space-x-4"
              error={errors.rateRangeFrom || errors.rateRangeTo}
              title={t('forms:labels.ratePerHour')}
            >
              <TextField
                error={errors.rateRangeFrom}
                hideErrorMessage
                label={t('forms:labels.from')}
                type="number"
                {...register('rateRangeFrom', {
                  setValueAs: mapNumberValue,
                  onChange: () => {
                    trigger(['rateRangeTo', 'rateRangeFrom']);
                  },
                })}
              />
              <TextField
                error={errors.rateRangeTo}
                hideErrorMessage
                label={t('forms:labels.to')}
                type="number"
                {...register('rateRangeTo', {
                  setValueAs: mapNumberValue,
                  onChange: () => {
                    trigger(['rateRangeTo', 'rateRangeFrom']);
                  },
                })}
              />
            </FilterWrapper>
            <FilterWrapper className="space-y-4" title={t('forms:labels.location')}>
              <TextField label={t('forms:labels.town')} {...register('location')} />
            </FilterWrapper>
            <FilterWrapper className="space-y-4" title={t('forms:labels.workType')}>
              {Object.values(WorkModeEnum).map((workType) => (
                <Checkbox
                  {...register(`workMode.${workType}`)}
                  key={workType}
                  label={t(`dictionaries:workTypes.${workType}`)}
                />
              ))}
            </FilterWrapper>
          </div>
          <RoundedButton
            disabled={loading}
            label={t('jobBoard:filters.filter')}
            loading={loading}
            type="submit"
            variant="primary"
          />
        </form>
      </div>
    </div>
  );
};
