import { FC } from 'react';
import { mergeClasses } from 'utils';

export interface LoadingSpinnerProps {
  size?: number;
  className?: string;
}

export const LoadingSpinner: FC<LoadingSpinnerProps> = ({ size = 32, className = '' }) => {
  return (
    <div
      className={mergeClasses(
        'inline-block border-2 border-primary-500 border-b-transparent border-l-transparent animate-spin-fast rounded-full',
        className,
      )}
      style={{
        width: size,
        height: size,
      }}
    />
  );
};
