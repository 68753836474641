import { JsonObject } from 'type-fest';

export const languages: JsonObject = {
  Language: 'Language:',
  Polish: 'Polish',
  German: 'German',
  Ukrainian: 'Ukrainian',
  English: 'English',
  Arabic: 'Arabic',
  Spanish: 'Spanish',
  Norwegian: 'Norwegian',
  French: 'French',
  Swedish: 'Swedish',
  Italian: 'Italian',
  Japanese: 'Japanese',
  MandarinChinese: 'Mandarin Chinese',
  Hebrew: 'Hebrew',
};

export default languages;
