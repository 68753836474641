import { ItemBadge, Paper, SearchInput } from 'components';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { findElementByKey, mapArrayIntoOptions, toggleStringOnArray } from 'utils';
import { useOnboardingDataQuery } from '../../../../../../hooks/api/onboardingData/onboardingData.generated';

interface DesiredFieldsValues {
  desiredFields: string[];
}

export const DesiredFields = () => {
  const { t } = useTranslation();
  const { data } = useOnboardingDataQuery();
  const { tags } = data ?? { tags: [] };

  const { setValue, watch } = useFormContext<DesiredFieldsValues>();

  const desiredFieldsValue = watch('desiredFields') ?? [];

  const handleToggleTechnology = (technologyId: string) =>
    setValue('desiredFields', toggleStringOnArray(desiredFieldsValue, technologyId), {
      shouldValidate: true,
      shouldDirty: true,
    });

  const industriesOptions = mapArrayIntoOptions(
    tags.filter(({ id }) => !desiredFieldsValue.includes(id)),
    'name',
    'id',
  );

  return (
    <Paper
      className="!p-4 xl:!p-8"
      id="desiredFields"
      subtitle={t('profile:papers.desiredFields.subtitle')}
      subtitleClassName="!mb-4 lg:!mb-6"
      title={t('profile:papers.desiredFields.title')}
      titleClassName="!mb-0 xl:!mb-0.5"
    >
      <SearchInput
        label={t('profile:papers.desiredFields.desiredFieldsInputLabel') ?? ''}
        onChange={handleToggleTechnology}
        options={industriesOptions}
        placeholder={t('profile:papers.desiredFields.desiredFieldsInputPlaceholder') ?? ''}
      />
      <div className="flex gap-2 md:gap-4 mt-4 flex-wrap">
        {desiredFieldsValue.map((tagId) => {
          const tagName = findElementByKey(tags, 'id', tagId)?.name;

          return (
            <ItemBadge
              key={`desiredField_${tagId}`}
              label={tagName}
              onRemove={() => handleToggleTechnology(tagId)}
            />
          );
        })}
      </div>
    </Paper>
  );
};
