/* eslint-disable jsx-a11y/label-has-associated-control */
import { Checkbox } from 'components';
import { FC } from 'react';
import { FieldError } from 'react-hook-form';
import { mergeClasses } from 'utils';

interface CheckboxGroupOption {
  label: string;
  value: string;
}

interface CheckboxGroupProps {
  name: string;
  label?: string;
  options: CheckboxGroupOption[];
  onChange: (value: string[]) => void;
  error?: FieldError;
  value?: string[];
  columns?: number;
}

export const CheckboxGroup: FC<CheckboxGroupProps> = ({
  name,
  onChange,
  options,
  label,
  error,
  value = [],
  columns = 1,
}) => {
  const handleChange = (newItem: string) => {
    if (value.includes(newItem)) {
      return onChange(value.filter((item) => item !== newItem));
    }
    onChange([...value, newItem]);
  };

  return (
    <div className="flex flex-col">
      {label && <p className="font-medium leading-[18px] mb-6">{label}</p>}
      <div className={`grid grid-cols-1 md:grid-cols-${columns} gap-2 md:gap-4`}>
        {options.map((opt) => {
          const isChecked = value.includes(opt.value) ?? false;

          return (
            <label
              key={`checkboxItem_${name}_${opt.value}`}
              className={mergeClasses(
                'p-4 rounded-lg border border-gray-200 cursor-pointer transition-all',
                isChecked ? '' : 'text-gray-400',
              )}
              htmlFor={opt.value}
            >
              <Checkbox
                checked={isChecked}
                error={error}
                hideErrorMessage
                label={opt.label}
                name={opt.value}
                onChange={() => handleChange(opt.value)}
              />
            </label>
          );
        })}
      </div>
      {error && <p className="text-red-500 leading-[18px] mt-2">{error.message}</p>}
    </div>
  );
};
