import { ShowMore } from 'components';
import { t } from 'i18next';
import { PanelLayout } from 'layouts';
import Notifications from '../../components/Notifications/Notifications';

interface NotificationsPageProps {
  isMobileMenuOpen?: boolean;
  onMobileMenuToggle: () => void;
}

const NotificationsPage = ({ isMobileMenuOpen, onMobileMenuToggle }: NotificationsPageProps) => {
  return (
    <PanelLayout
      isMobileMenuOpen={isMobileMenuOpen}
      onMobileMenuToggle={onMobileMenuToggle}
      title={t('pageTitles:notifications')}
    >
      <ShowMore className="mt-8 lg:-mt-8 xl:-mt-12" hiddenHeight={1000}>
        <Notifications />
      </ShowMore>
    </PanelLayout>
  );
};

export default NotificationsPage;
