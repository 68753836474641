import { FC } from 'react';
import { mergeClasses } from 'utils';

interface UserPanelDropdownListItemProps {
  label: string;
  className?: string;
  onClick: () => void;
}

export const UserPanelDropdownListItem: FC<UserPanelDropdownListItemProps> = ({
  className = '',
  label,
  onClick,
}) => {
  return (
    <li
      className={mergeClasses(
        'py-2 px-4 cursor-pointer hover:bg-gray-100 font-medium border-t border-t-gray-100',
        className,
      )}
      onClick={onClick}
    >
      {label}
    </li>
  );
};
