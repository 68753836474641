import { FC } from 'react';
import { FieldError } from 'react-hook-form';
import { mergeClasses } from 'utils';

interface ErrorMessageProps {
  className?: string;
  error?: FieldError;
}

export const ErrorMessage: FC<ErrorMessageProps> = ({ error, className = '' }) => {
  if (!error) return null;
  return (
    <p className={mergeClasses('text-red-500 leading-[18px] mt-2', className)}>{error.message}</p>
  );
};
