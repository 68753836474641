import { JsonObject } from 'type-fest';

export const settings: JsonObject = {
  tabs: {
    account: 'Konto',
    notifications: 'Powiadomienia',
  },
  passwordChangeSuccessToast: 'Twoje hasło zostało zmienione pomyślnie',
  actions: {
    deleteAccount: 'Usuń konto',
    saveNewPassword: 'Zapisz nowe hasło',
    cancel: 'Anuluj',
  },
  notifications: {
    OffersAndProfiles: {
      title: 'Oferty i profil',
      description:
        'Powiadomienia o nowych ofertach, przypomnienia dotyczące zapisanych ofert i aplikacji oraz Twojego profilu ',
    },
    ApplicationAndMeetings: {
      title: 'Aplikacja i spotkania',
      description: 'Powiadomienia o umówionych spotkaniach i kolejnych etapach rekrutacji',
    },
    News: {
      title: 'Nowości i aktualizacje',
      description:
        'Bądź na bieżąco z najnowszymi usprawnieniami aplikacji i aktualnościami Connectis',
    },
  },
  removeAccountModal: {
    title: 'Usunąć konto?',
    subtitle: 'Twoje dane zostaną usunięte, a aplikacje i procesy rekrutacyjne anulowane.',
    description: 'Aby usunąć konto wprowadź poniżej swoje hasło i wybierz “Usuń konto”',
    successToast: 'Twoje konto zostało usunięte pomyślnie',
    incorrectOTP: 'Niepoprawny kod weryfikacyjny',
  },
  OTPcode: {
    confirmDelete: 'Potwierdź usunięcie',
    codeSubtitle: 'Na Twój adres email wysłaliśmy wiadomość z kodem.',
    codeSubtitle2: 'Jeżeli chcesz usunąć konto, wpisz poniżej otrzymany kod.',
    codeSubtitle3: 'Usuwając konto stracisz dostęp do wszystkich danych i umówionych spotkań.',
    confirmEmail: 'Potwierdź adres email',
  },
};
