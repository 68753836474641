import { SkillDots } from 'components';
import { OfficeIcon } from 'icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'utils';
import { Detail } from '../../../../JobOrderList/subcomponents/JobOrderListItem/JobOrderListItem';

interface SkillProps {
  name: string;
  level: number;
  isPrimary?: boolean;
  hideSkillLevel?: boolean;
}

export const Skill: FC<SkillProps> = ({
  name,
  level,
  hideSkillLevel = false,
  isPrimary = false,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={mergeClasses(
        'p-4 rounded-lg w-full md:max-w-[265px]',
        isPrimary ? 'bg-light-blue-500' : 'border border-gray-200',
      )}
    >
      <p className="mb-3 font-bold">{name}</p>
      {!hideSkillLevel && (
        <>
          <SkillDots level={level} />
          <Detail
            className="text-[14px] font-normal lowercase"
            icon={OfficeIcon}
            iconClassName="!w-4 !h-4 mt-0.5"
            value={t(`dictionaries:skillLevels.${level}`)}
          />
        </>
      )}
    </div>
  );
};
