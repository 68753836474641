import { ChangeEvent, FC, useEffect, useState } from 'react';
import { TextField, TextFieldProps } from '../TextField';
import { PhoneNumberPrefixSelect } from './subcomponents';
import { splitPhoneNumber } from './utils';

interface PhoneNumberInputProps extends Omit<TextFieldProps, 'onChange' | 'value'> {
  onChange: (value: string) => void;
  value: string;
}

export const DEFAULT_PHONE_NUMBER_PREFIX = 48;
export const PhoneNumberInput: FC<PhoneNumberInputProps> = ({
  value,
  onChange,
  ...textFieldProps
}) => {
  const [selectedPrefix, setSelectedPrefix] = useState(DEFAULT_PHONE_NUMBER_PREFIX);
  const [phoneNumberValue, setPhoneNumberValue] = useState('');

  const handlePhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setPhoneNumberValue(newValue);
    if (!newValue) return onChange(newValue);
    onChange(`${selectedPrefix}${newValue}`);
  };

  const handlePrefixChange = (newPrefix: number) =>
    setSelectedPrefix((prevPrefix) => {
      onChange(value.replace(prevPrefix.toString(), newPrefix.toString()));
      return newPrefix;
    });

  useEffect(() => {
    if (phoneNumberValue || !value) return;
    try {
      const { number, prefix } = splitPhoneNumber(value);
      setPhoneNumberValue(number?.toString() ?? '');
      setSelectedPrefix(prefix ?? DEFAULT_PHONE_NUMBER_PREFIX);
    } catch (err) {
      console.log('Unable to parse phone number');
    }
  }, [value]);

  return (
    <TextField
      {...textFieldProps}
      leftSideComponent={
        <PhoneNumberPrefixSelect onChange={handlePrefixChange} selectedPrefix={selectedPrefix} />
      }
      leftSideComponentClassNames="py-2.5 px-0 w-[58px]"
      onChange={handlePhoneNumberChange}
      type="number"
      value={phoneNumberValue}
    />
  );
};
