import { Marquee, RoundedButton } from 'components';
import { useTranslation } from 'react-i18next';
import { EnvironmentConfig } from '../../../../environment-config';
import { trustedCompanies } from '../trustedCompanies';

export const ConnectisWebsiteHeroTrustedUs = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'connectisWebsite:hero',
  });

  return (
    <div className="cursor-pointer bg-white/10 backdrop-blur md:rounded-2xl px-4 md:pl-8 md:pr-6 w-full flex flex-col lg:flex-row items-center justify-between text-white lg:h-16 md:mb-8 pb-4.5 lg:pb-0 absolute md:relative left-0 bottom-0 hover:scale-[103%] transition-all">
      <div className="grid grid-cols-[90px_1fr] items-center xl:gap-[3rem] absolute md:relative top-0 right-0 pl-2 w-full overflow-hidden">
        <p key="trustedUs" className="text-white/50 whitespace-nowrap">
          {t('trustedUs')}:
        </p>
        <Marquee className="w-[1700px] lg:w-[2010px] h-20">
          {trustedCompanies.map(({ name, src }) => (
            <img key={name} alt={name} className="h-20" src={src} />
          ))}
        </Marquee>
      </div>
      <a
        className="flex items-center justify-center space-x-2 border-t lg:border-t-0 border-t-white/10 w-full lg:w-auto pt-3 lg:pt-0 mt-20 md:mt-0 ml-[3rem]"
        href={`${EnvironmentConfig.connectisWebsiteUrl}/case-studies`}
      >
        <p className="font-medium whitespace-nowrap">{t('ourPortfolio')}</p>
        <RoundedButton arrowSize="10" variant="neon" />
      </a>
    </div>
  );
};
