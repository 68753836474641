import { Button } from 'components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const ExternalOfferFinishOnboarding: FC<{ onFinishOnboardingClick?: () => void }> = ({
  onFinishOnboardingClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className="p-4 lg:p-8 border border-gray-200 rounded-xl w-full">
      <p className="text-base lg:text-xl font-bold mb-2 lg:mb-6">
        {t('offers:externalOffer.onboardingBox.title')}
      </p>
      <p className="text-sm lg:text-base mb-6">
        {t('offers:externalOffer.onboardingBox.description')}
      </p>
      <Button
        className="w-full"
        label={t('offers:externalOffer.onboardingBox.fillInProfile')}
        onClick={onFinishOnboardingClick}
      />
    </div>
  );
};
