import { Paper, Radio } from 'components';
import { WorkModeEnum } from 'interfaces';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface WorkTypeValues {
  workType: string;
}

export const WorkType = () => {
  const { t } = useTranslation();

  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<WorkTypeValues>();

  const workTypeValue = watch('workType');

  const workTypeOptions = Object.values(WorkModeEnum).map((workType) => ({
    label: t(`dictionaries:workTypes.${workType}`),
    value: workType,
  }));

  return (
    <Paper
      className="!p-4 xl:!p-8"
      id="workType"
      title={t('profile:papers.workType.title')}
      titleClassName="!mb-4 xl:!mb-6"
    >
      <Radio
        columns={3}
        error={errors.workType}
        gridClassName="md:!grid-cols-3 lg:!grid-cols-1 xl:!grid-cols-3"
        name="workType"
        onChange={(value) =>
          setValue('workType', value, { shouldValidate: true, shouldDirty: true })
        }
        options={workTypeOptions}
        value={workTypeValue}
      />
    </Paper>
  );
};
