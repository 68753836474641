import { IconButton } from 'components';
import { EditorState } from 'draft-js';
import { BoldIcon, IconType, ItalicIcon, ListIcon, UnderlineIcon } from 'icons';
import { FC } from 'react';
import { mergeClasses } from 'utils';
import { RichTextEditorInlineStyleEnum } from '../RichTextEditor';

interface ActionButtonProps {
  onClick: () => void;
  icon: IconType;
  isActive?: boolean;
}

const ActionButton: FC<ActionButtonProps> = ({ onClick, icon: Icon, isActive = false }) => (
  <IconButton
    className={mergeClasses('', isActive ? 'bg-gray-100' : '')}
    icon={Icon}
    onMouseDown={(e) => {
      e.preventDefault();
      e.stopPropagation();
      onClick();
    }}
    size="sm"
  />
);

interface LengthCounterProps {
  editorState: EditorState;
  limit: number;
}
const LengthCounter: FC<LengthCounterProps> = ({ editorState, limit }) => {
  const charsNumber = editorState.getCurrentContent().getPlainText(' ').length;
  return (
    <div className="text-gray-500 bg-gray-200 px-1 py-0.5 rounded text-[10px]">
      {charsNumber}/{limit}
    </div>
  );
};

interface RichTextEditorHeaderProps {
  onBold: () => void;
  onItalic: () => void;
  onUnderline: () => void;
  onList: () => void;
  limit?: number;
  editorState: EditorState;
}

const RichTextEditorHeader: FC<RichTextEditorHeaderProps> = ({
  onBold,
  onItalic,
  onList,
  onUnderline,
  limit,
  editorState,
}) => {
  const inlineStyle = editorState.getCurrentInlineStyle();
  const isBold = inlineStyle.has(RichTextEditorInlineStyleEnum.BOLD);
  const isItalic = inlineStyle.has(RichTextEditorInlineStyleEnum.ITALIC);
  const isUnderline = inlineStyle.has(RichTextEditorInlineStyleEnum.UNDERLINE);

  return (
    <div className="sticky top-0 border-b border-b-gray-200 bg-white">
      <div className="flex justify-between items-center pr-2">
        <div className="flex items-center">
          <div className="flex items-center border-r border-r-gray-200">
            <ActionButton icon={BoldIcon} isActive={isBold} onClick={onBold} />
            <ActionButton icon={ItalicIcon} isActive={isItalic} onClick={onItalic} />
            <ActionButton icon={UnderlineIcon} isActive={isUnderline} onClick={onUnderline} />
          </div>
          <ActionButton icon={ListIcon} onClick={onList} />
        </div>
        {limit && <LengthCounter editorState={editorState} limit={limit} />}
      </div>
    </div>
  );
};

export default RichTextEditorHeader;
