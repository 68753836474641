import { yupResolver } from '@hookform/resolvers/yup';
import { Button, LinkButton, TextField } from 'components';
import { useAuth, useSitemap } from 'hooks';
import { TFunction } from 'i18next';
import { GoogleIcon, LinkedInBlueIcon } from 'icons';
import { Shape } from 'interfaces';
import { useLayoutEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { LegalAgreements } from '../LegalAgreements';

interface SignInEmailInputStepFormValues {
  email: string;
  loading: boolean;
}

const signInEmailInputStepSchema = (t: TFunction) =>
  yup.object().shape<Shape<SignInEmailInputStepFormValues>>({
    email: yup
      .string()
      .email(t('forms:validationMessages.wrongEmail') ?? '')
      .required(
        t('forms:validationMessages.required', {
          fieldName: t('forms:labels.yourEmail'),
        }) ?? '',
      ),
  });

interface SignInEmailInputStepProps {
  onEmailSubmit: (email: string) => Promise<void>;
  loading: boolean;
}

export const SignInEmailInputStep = ({ onEmailSubmit, loading }: SignInEmailInputStepProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const sitemap = useSitemap();

  const { signInWithGoogle, signInWithLinkedIn, authLoading } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<SignInEmailInputStepFormValues>({
    resolver: yupResolver(signInEmailInputStepSchema(t)),
  });

  useLayoutEffect(() => {
    const email = params.get('email');
    if (!email) {
      return;
    }
    try {
      const encodedBase64Email = atob(email);
      setValue('email', encodedBase64Email, { shouldValidate: true });
    } catch (error) {
      // eslint-disable-next-line no-empty
    }
  }, []);

  const onSubmit = (values: SignInEmailInputStepFormValues) => onEmailSubmit(values.email);

  return (
    <div className="flex flex-col items-stretch w-full max-w-[538px] pt-2 pb-0 lg:py-10">
      <p className="font-bold text-3xl md:text-[40px] mb-2">{t('auth:welcomeToChub')}</p>
      <p className="text-base md:text-lg text-gray-500 mb-8">{t('auth:signInForm.subtitleOTP')}</p>
      <Button
        className="w-full !rounded-lg"
        label={t('auth:signInForm.browseWithGoogle')}
        leftIcon={GoogleIcon}
        leftIconClassName="w-6 h-6 mr-4"
        onClick={() => signInWithGoogle()}
        variant="outline"
      />
      <Button
        className="w-full !rounded-lg mt-4"
        label={t('auth:signInForm.browseWithLinkedIn')}
        leftIcon={LinkedInBlueIcon}
        leftIconClassName="w-6 h-6 mr-4"
        onClick={() => signInWithLinkedIn()}
        variant="outline"
      />
      <div className="flex items-center space-x-4 py-6 md:py-8">
        <span className="border-t border-gray-200 w-full" />
        <span className="text-sm text-gray-400 whitespace-nowrap">
          {t('auth:continueWithEmail')}
        </span>
        <span className="border-t border-gray-200 w-full" />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-6">
          <TextField
            {...register('email')}
            error={errors.email}
            label={t('forms:labels.yourEmail')}
          />
        </div>
        <Button
          className="w-full mt-8 !rounded-lg"
          isLoading={loading || authLoading}
          label={t('auth:actions.next')}
          type="submit"
        />
      </form>
      <div className="flex mt-6 md:mt-8 pt-6 md:pt-8 border-t border-t-gray-200 justify-center text-sm md:text-base">
        <p>{t('auth:signInForm.signUpLinkText')}</p>
        <LinkButton
          className="ml-1"
          label={t('auth:actions.signUp') ?? ''}
          onClick={() => navigate(sitemap.signUp)}
        />
      </div>
      <LegalAgreements className="pt-6 lg:pt-10" textClassName="text-[14px] lg:text-[16px]" />
    </div>
  );
};
