import React from 'react';
import ReactDOM from 'react-dom/client';

import i18n from 'locale/i18n';
import { GraphQLProvider } from 'providers';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './dayjs-config';
import reportWebVitals from './reportWebVitals';
import './sdk/cognito';

import 'rc-slider/assets/index.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import { initGoogleAnalytics } from './initGoogleAnalytics';
import { initLogger } from './initLogger';
import './styles/index.css';

initLogger();
initGoogleAnalytics();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <GraphQLProvider>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </I18nextProvider>
    </GraphQLProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
