import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
const defaultOptions = {} as const;
export type CreateExpertMutationVariables = Types.Exact<{ [key: string]: never }>;

export type CreateExpertMutation = { __typename?: 'Mutation'; createExpertIdentity: string };

export const CreateExpertDocument = gql`
  mutation createExpert {
    createExpertIdentity
  }
`;
export type CreateExpertMutationFn = Apollo.MutationFunction<
  CreateExpertMutation,
  CreateExpertMutationVariables
>;

/**
 * __useCreateExpertMutation__
 *
 * To run a mutation, you first call `useCreateExpertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExpertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExpertMutation, { data, loading, error }] = useCreateExpertMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateExpertMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateExpertMutation, CreateExpertMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateExpertMutation, CreateExpertMutationVariables>(
    CreateExpertDocument,
    options,
  );
}
export type CreateExpertMutationHookResult = ReturnType<typeof useCreateExpertMutation>;
export type CreateExpertMutationResult = Apollo.MutationResult<CreateExpertMutation>;
export type CreateExpertMutationOptions = Apollo.BaseMutationOptions<
  CreateExpertMutation,
  CreateExpertMutationVariables
>;
