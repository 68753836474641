import { JsonObject } from 'type-fest';

export const languages: JsonObject = {
  Language: 'Język',
  Polish: 'polski',
  German: 'niemiecki',
  Ukrainian: 'ukraiński',
  English: 'angielski',
  Arabic: 'arabski',
  Spanish: 'hiszpański',
  Norwegian: 'norweski',
  French: 'francuski',
  Swedish: 'szwedzki',
  Italian: 'włoski',
  Japanese: 'japoński',
  MandarinChinese: 'mandaryński chiński',
  Hebrew: 'hebrajski',
};

export default languages;
