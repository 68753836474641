import { Certificates, Experience } from './papers';

export const ExperienceTab = () => {
  return (
    <div className="space-y-4 lg:space-y-6">
      <Experience />
      <Certificates />
    </div>
  );
};
