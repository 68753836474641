import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
const defaultOptions = {} as const;
export type SendGenericPurposeOtpMutationVariables = Types.Exact<{ [key: string]: never }>;

export type SendGenericPurposeOtpMutation = {
  __typename?: 'Mutation';
  sendGenericPurposeOtp: number;
};

export const SendGenericPurposeOtpDocument = gql`
  mutation sendGenericPurposeOtp {
    sendGenericPurposeOtp
  }
`;
export type SendGenericPurposeOtpMutationFn = Apollo.MutationFunction<
  SendGenericPurposeOtpMutation,
  SendGenericPurposeOtpMutationVariables
>;

/**
 * __useSendGenericPurposeOtpMutation__
 *
 * To run a mutation, you first call `useSendGenericPurposeOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendGenericPurposeOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendGenericPurposeOtpMutation, { data, loading, error }] = useSendGenericPurposeOtpMutation({
 *   variables: {
 *   },
 * });
 */
export function useSendGenericPurposeOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendGenericPurposeOtpMutation,
    SendGenericPurposeOtpMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendGenericPurposeOtpMutation, SendGenericPurposeOtpMutationVariables>(
    SendGenericPurposeOtpDocument,
    options,
  );
}
export type SendGenericPurposeOtpMutationHookResult = ReturnType<
  typeof useSendGenericPurposeOtpMutation
>;
export type SendGenericPurposeOtpMutationResult =
  Apollo.MutationResult<SendGenericPurposeOtpMutation>;
export type SendGenericPurposeOtpMutationOptions = Apollo.BaseMutationOptions<
  SendGenericPurposeOtpMutation,
  SendGenericPurposeOtpMutationVariables
>;
