import { Button, SmallButton } from 'components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'utils';

interface SubformFooterProps {
  onCancel?: () => void;
  onSave?: () => void;
  isCancelButtonVisible?: boolean;
  className?: string;
  confirmButtonClassName?: string;
}

export const SubformFooter: FC<SubformFooterProps> = ({
  onCancel,
  onSave,
  isCancelButtonVisible = true,
  className,
  confirmButtonClassName,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={mergeClasses(
        'flex items-center justify-end space-x-6 !mt-4 md:!mt-8 sm:justify-between',
        className,
      )}
    >
      <SmallButton
        className={isCancelButtonVisible ? 'flex' : 'hidden'}
        label={t('common:actions.cancel')}
        onClick={onCancel}
        variant="black"
      />
      <Button
        className={mergeClasses('sm:!ml-0', confirmButtonClassName)}
        label={t('common:actions.save')}
        onClick={onSave}
        type={onSave ? 'button' : 'submit'}
      />
    </div>
  );
};
