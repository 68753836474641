import { useScrollIntoView } from 'hooks';
import { FC, useEffect } from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'utils';
import useMobile from '../../../hooks/useMobile';
import { AddItemButton } from '../../Buttons';
import { InputLabel } from '../../InputLabel';
import { RadioItem } from './RadioItem';

interface RadioOption {
  label: string;
  value: string;
  isHighlighted?: boolean;
}

interface RadioProps {
  name: string;
  label?: string | null;
  options: RadioOption[];
  onChange: (value: RadioOption['value']) => void;
  error?: FieldError;
  value?: string;
  columns?: number;
  className?: string;
  radioItemClassName?: string;
  addNewItemLabel?: string;
  onAddNewItemChange?: (value: string) => void;
  addNewItemOptions?: Omit<RadioOption, 'isHighlighted'>[];
  scrollIntoViewOnError?: boolean;
  isUsingAddButton?: boolean;
  gridClassName?: string;
}

export const Radio: FC<RadioProps> = ({
  name,
  onChange,
  options,
  label,
  error,
  value,
  columns = 1,
  className = '',
  radioItemClassName = '',
  addNewItemLabel,
  onAddNewItemChange,
  addNewItemOptions,
  scrollIntoViewOnError = false,
  isUsingAddButton,
  gridClassName,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useMobile();
  const { ref, scrollIntoView } = useScrollIntoView<HTMLLabelElement>();

  useEffect(() => {
    if (scrollIntoViewOnError && error) {
      scrollIntoView();
    }
  }, [error]);

  return (
    <label ref={ref} className={mergeClasses('flex flex-col', className)} htmlFor={name}>
      {label && <InputLabel label={label} />}
      <div
        className={mergeClasses('grid gap-2 md:gap-4 items-center w-full md:w-auto', gridClassName)}
        style={{
          gridTemplateColumns: isMobile ? `repeat(1, 1fr)` : `repeat(${columns}, 1fr)`,
        }}
      >
        {options.map((opt) => (
          <RadioItem
            key={`radioItem_${opt.value}`}
            className={radioItemClassName}
            id={opt.value}
            isError={!!error}
            isHighlighted={opt.isHighlighted}
            label={opt.label}
            name={`${name}_${opt.value}`}
            onChange={onChange}
            value={value ?? ''}
          />
        ))}
        {onAddNewItemChange && (
          <AddItemButton
            className="text-xs"
            label={addNewItemLabel ?? t('common:actions.addOther')}
            onChange={onAddNewItemChange}
            options={addNewItemOptions ?? []}
            usedInRadioComponent={isUsingAddButton}
          />
        )}
      </div>
    </label>
  );
};
