import { yupResolver } from '@hookform/resolvers/yup';
import {
  CertificateForm,
  CertificateItem,
  certificatesFormSchema,
  ConfirmationModal,
  LinkButton,
} from 'components';
import { PlusIcon } from 'icons';
import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'utils';
import useMobile from '../../../hooks/useMobile';
import { CertificateFormValues } from '../../Certificate/CertificateForm';
import { CertificateModal } from '../modals';
import { StepComponentProps } from '../stepsConfig';

export interface CertificateStepValues {
  certificates: CertificateFormValues[];
}

interface EditModalState {
  isOpen: boolean;
  currentCertificateIndex: number | null;
  currentCertificate: CertificateFormValues | null;
}

type ConfirmModalState = Omit<EditModalState, 'currentCertificate'>;

const defaultEditModalState: EditModalState = {
  isOpen: false,
  currentCertificateIndex: null,
  currentCertificate: null,
};

const defaultConfirmModalState: ConfirmModalState = {
  isOpen: false,
  currentCertificateIndex: null,
};

const nestedFormDefaultValues: CertificateFormValues = {
  identifier: '',
  imageUrl: '',
  issueDate: undefined,
  issuer: '',
  name: '',
  url: '',
};

export const CertificateStep: FC<StepComponentProps> = ({ onToggleNextDisabled }) => {
  const { t } = useTranslation();
  const { isMobile } = useMobile();
  const [editModalState, setEditModalState] = useState<EditModalState>(defaultEditModalState);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [confirmModalState, setConfirmModalState] =
    useState<ConfirmModalState>(defaultConfirmModalState);

  const { setValue, watch } = useFormContext<CertificateStepValues>();

  const certificatesValue = watch('certificates') ?? [];

  const certificateFormMethods = useForm({
    defaultValues: nestedFormDefaultValues,
    resolver: yupResolver(certificatesFormSchema(t)),
  });
  const { isDirty } = certificateFormMethods.formState;

  const handleSaveCertificate = async (hideForm = true) => {
    const isValid = await certificateFormMethods.trigger();
    if (!isValid) {
      return;
    }

    const newCertificate = certificateFormMethods.getValues();
    setValue('certificates', [...certificatesValue, newCertificate]);
    certificateFormMethods.reset(nestedFormDefaultValues);

    if (hideForm) {
      setIsAddFormOpen(false);
    }
  };

  const handleAddCertificate = () => {
    if (!isAddFormOpen) {
      return setIsAddFormOpen(true);
    }
    handleSaveCertificate(false);
  };

  const handleEditModalOpen = (index: number) => {
    const editedCertificate = certificatesValue[index];
    if (!editedCertificate) {
      return;
    }

    setEditModalState({
      isOpen: true,
      currentCertificateIndex: index,
      currentCertificate: editedCertificate,
    });
  };

  const handleEditModalClose = () => {
    setEditModalState(defaultEditModalState);
    certificateFormMethods.reset(nestedFormDefaultValues);
  };

  const handleEditCertificate = async () => {
    const isValid = await certificateFormMethods.trigger();
    if (!isValid) {
      return;
    }

    const updatedCertificate = certificateFormMethods.getValues();
    setValue(
      'certificates',
      certificatesValue.map((workExperience, index) =>
        index === editModalState.currentCertificateIndex ? updatedCertificate : workExperience,
      ),
    );
    certificateFormMethods.reset(nestedFormDefaultValues);
    handleEditModalClose();
  };

  const handleConfirmModalOpen = (index: number) => {
    setConfirmModalState({
      isOpen: true,
      currentCertificateIndex: index,
    });
  };

  const handleConfirmModalClose = () => setConfirmModalState(defaultConfirmModalState);

  const handleRemoveCertificate = () => {
    setValue(
      'certificates',
      certificatesValue.filter((_, i) => i !== confirmModalState.currentCertificateIndex),
    );
    handleConfirmModalClose();
  };

  const handleCancelClick = () => {
    setIsAddFormOpen(false);
    certificateFormMethods.reset(nestedFormDefaultValues);
  };

  useEffect(() => {
    if (!certificatesValue.length && !isAddFormOpen) {
      setIsAddFormOpen(true);
    }
  }, [certificatesValue]);

  useEffect(() => {
    return onToggleNextDisabled(isDirty);
  }, [isDirty]);

  return (
    <div className="mt-4 md:mt-14 mb-20">
      <ConfirmationModal
        {...confirmModalState}
        onCancel={handleConfirmModalClose}
        onClose={handleConfirmModalClose}
        onConfirm={handleRemoveCertificate}
        text={t('onboarding:certificatesStep.removeCertificateConfirmText') ?? ''}
      />
      <FormProvider {...certificateFormMethods}>
        <CertificateModal
          {...editModalState}
          onClose={handleEditModalClose}
          onSave={handleEditCertificate}
        />
        <div className={mergeClasses('space-y-4 mb-0', certificatesValue.length ? 'md:mb-14' : '')}>
          {certificatesValue.map((wh, i) => (
            <CertificateItem
              key={JSON.stringify(wh)}
              {...wh}
              onEdit={() => handleEditModalOpen(i)}
              onRemove={() => handleConfirmModalOpen(i)}
            />
          ))}
        </div>
        <hr className="md:hidden block !my-6 w-full" />
        {isAddFormOpen && !editModalState.isOpen && (
          <CertificateForm onCancelClick={handleCancelClick} onSaveClick={handleSaveCertificate} />
        )}
      </FormProvider>
      {!isAddFormOpen && (
        <div className="flex items-center justify-between mt-8 mb-12 md:mb-0">
          <LinkButton
            className="text-xs"
            icon={PlusIcon}
            label={t('onboarding:certificatesStep.addAnotherCertificate')}
            onClick={handleAddCertificate}
            underline={false}
          />
        </div>
      )}
    </div>
  );
};
