import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
const defaultOptions = {} as const;
export type CompleteEmailVerificationMutationVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;

export type CompleteEmailVerificationMutation = {
  __typename?: 'Mutation';
  completeEmailVerification: string;
};

export const CompleteEmailVerificationDocument = gql`
  mutation completeEmailVerification($token: String!) {
    completeEmailVerification(token: $token)
  }
`;
export type CompleteEmailVerificationMutationFn = Apollo.MutationFunction<
  CompleteEmailVerificationMutation,
  CompleteEmailVerificationMutationVariables
>;

/**
 * __useCompleteEmailVerificationMutation__
 *
 * To run a mutation, you first call `useCompleteEmailVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteEmailVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeEmailVerificationMutation, { data, loading, error }] = useCompleteEmailVerificationMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useCompleteEmailVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteEmailVerificationMutation,
    CompleteEmailVerificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteEmailVerificationMutation,
    CompleteEmailVerificationMutationVariables
  >(CompleteEmailVerificationDocument, options);
}
export type CompleteEmailVerificationMutationHookResult = ReturnType<
  typeof useCompleteEmailVerificationMutation
>;
export type CompleteEmailVerificationMutationResult =
  Apollo.MutationResult<CompleteEmailVerificationMutation>;
export type CompleteEmailVerificationMutationOptions = Apollo.BaseMutationOptions<
  CompleteEmailVerificationMutation,
  CompleteEmailVerificationMutationVariables
>;
