import { FC, ReactNode, useEffect, useState } from 'react';
import AnimateHeight, { Height } from 'react-animate-height';

interface CollapseProps {
  isCollapsed: boolean;
  children: ReactNode;
  className?: string;
}

export const Collapse: FC<CollapseProps> = ({ children, isCollapsed, className }) => {
  const [height, setHeight] = useState<Height>(0);

  useEffect(() => {
    setHeight(isCollapsed ? 0 : 'auto');
  }, [isCollapsed]);

  return (
    <AnimateHeight className={className} duration={150} height={height}>
      {children}
    </AnimateHeight>
  );
};
