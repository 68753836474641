import { useSitemap } from 'hooks';
import { useNavigate } from 'react-router-dom';
import ConnectisLogo from '../../../assets/images/connectis-logo-v2.svg';

export const ConnectisHeader = () => {
  const sitemap = useSitemap();
  const navigate = useNavigate();

  return (
    <div className="shadow-lg sticky top-0 w-full bg-white z-40 flex justify-center">
      <div className="max-w-container-lg w-full px-4 md:px-12 py-6">
        <img
          alt="logo"
          className="w-[120px] cursor-pointer"
          onClick={() => navigate(sitemap.main)}
          src={ConnectisLogo}
        />
      </div>
    </div>
  );
};
