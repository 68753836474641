import { FC } from 'react';
// eslint-disable-next-line import/no-named-default
import { default as ReactSwitch } from 'react-switch';

interface SwitchProps {
  value?: boolean;
  onChange: (value: boolean) => void;
  className?: string;
}

export const Switch: FC<SwitchProps> = ({ value = false, onChange, className }) => {
  return (
    <ReactSwitch
      checked={value}
      checkedIcon={false}
      className={className}
      handleDiameter={14}
      height={18}
      offColor="#AAAFC2"
      onChange={onChange}
      onColor="#0C00FF"
      uncheckedIcon={false}
      width={32}
    />
  );
};
