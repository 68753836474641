import OffersLoader from 'assets/images/onboarding/offers-loader.gif';
import { useTranslation } from 'react-i18next';

export const OffersLoading = () => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-center items-center flex-col max-w-container-xs text-center mx-auto min-h-[calc(100vh-116px)] md:min-h-[calc(100vh-96px-132px-8px)] grow md:grow-0">
      <img alt="" className="h-10 md:h-14 mb-4" src={OffersLoader} />
      <p className="text-2xl md:text-[40px] font-bold mb-2 md:mb-4">
        {t('onboarding:offersLoading.title')}
      </p>
      <p className="text-sm md:text-lg text-gray-500">{t('onboarding:offersLoading.subtitle')}</p>
    </div>
  );
};
