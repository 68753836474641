import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
const defaultOptions = {} as const;
export type IsExpertIdentityVerifiedQueryVariables = Types.Exact<{ [key: string]: never }>;

export type IsExpertIdentityVerifiedQuery = {
  __typename?: 'Query';
  isExpertIdentityVerified: boolean;
};

export const IsExpertIdentityVerifiedDocument = gql`
  query isExpertIdentityVerified {
    isExpertIdentityVerified
  }
`;

/**
 * __useIsExpertIdentityVerifiedQuery__
 *
 * To run a query within a React component, call `useIsExpertIdentityVerifiedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsExpertIdentityVerifiedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsExpertIdentityVerifiedQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsExpertIdentityVerifiedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IsExpertIdentityVerifiedQuery,
    IsExpertIdentityVerifiedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsExpertIdentityVerifiedQuery, IsExpertIdentityVerifiedQueryVariables>(
    IsExpertIdentityVerifiedDocument,
    options,
  );
}
export function useIsExpertIdentityVerifiedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsExpertIdentityVerifiedQuery,
    IsExpertIdentityVerifiedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IsExpertIdentityVerifiedQuery, IsExpertIdentityVerifiedQueryVariables>(
    IsExpertIdentityVerifiedDocument,
    options,
  );
}
export type IsExpertIdentityVerifiedQueryHookResult = ReturnType<
  typeof useIsExpertIdentityVerifiedQuery
>;
export type IsExpertIdentityVerifiedLazyQueryHookResult = ReturnType<
  typeof useIsExpertIdentityVerifiedLazyQuery
>;
export type IsExpertIdentityVerifiedQueryResult = Apollo.QueryResult<
  IsExpertIdentityVerifiedQuery,
  IsExpertIdentityVerifiedQueryVariables
>;
export function refetchIsExpertIdentityVerifiedQuery(
  variables?: IsExpertIdentityVerifiedQueryVariables,
) {
  return { query: IsExpertIdentityVerifiedDocument, variables: variables };
}
