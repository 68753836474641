import { SeniorityLevelEnum } from 'interfaces';
import { FC, ReactNode, useState } from 'react';
import { PlacesType, Tooltip as ReactTooltip } from 'react-tooltip';
import { mergeClasses } from 'utils';
import useMobile from '../../hooks/useMobile';

interface TooltipProps {
  content?: ReactNode;
  place?: PlacesType;
  name: string;
  children?: ReactNode;
  variant?: 'primary' | 'default';
  additionalIsOpen?: boolean;
  delayShow?: number;
  delayHide?: number;
  isHighlighted?: SeniorityLevelEnum;
  className?: string;
}

const getVariantClassName = (variant: TooltipProps['variant']) => {
  switch (variant) {
    case 'default':
      return 'bg-white text-gray-400 shadow-md';
    case 'primary':
      return 'bg-primary-500 text-white';
    default:
      return '';
  }
};

const getHighlightedClassName = (
  isHighlighted: TooltipProps['isHighlighted'],
  isMobile: boolean,
) => {
  if (!isMobile) {
    switch (isHighlighted) {
      case 'Junior':
        return '!left-0';
      case 'Regular':
        return '!left-[156.5px]';
      case 'Senior':
        return '!left-[313px]';
      default:
        return '';
    }
    return '!left-[156.5px]';
  }
};

export const Tooltip: FC<TooltipProps> = ({
  content,
  place,
  name,
  children,
  variant = 'default',
  additionalIsOpen,
  delayHide,
  delayShow,
  isHighlighted,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useMobile();

  return (
    <div className="relative">
      <ReactTooltip
        anchorId={name}
        className={mergeClasses(
          'rounded-lg text-xs w-auto',
          getVariantClassName(variant),
          getHighlightedClassName(isHighlighted, isMobile),
          className,
        )}
        content=" "
        delayHide={delayHide}
        delayShow={delayShow}
        isOpen={isOpen || additionalIsOpen}
        place={place}
        setIsOpen={setIsOpen}
      >
        {content}
      </ReactTooltip>
      <span id={name}>{children}</span>
    </div>
  );
};
