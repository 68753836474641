import React, { useEffect, useRef, useState } from 'react';

interface AnimatedAutoHeightProps {
  children: React.ReactNode;
}

const AnimatedAutoHeight: React.FC<AnimatedAutoHeightProps> = ({ children }) => {
  const [height, setHeight] = useState(0);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const measuredHeight = containerRef.current?.getBoundingClientRect()?.height || 0;
    setHeight(measuredHeight);
  }, [children, height]);

  return (
    <div className="invisible">
      <div ref={containerRef} className={height ? 'hidden' : 'block'}>
        {children}
      </div>
      {height ? (
        <div ref={containerRef} className={`h-${height} visible`}>
          {children}
        </div>
      ) : null}
    </div>
  );
};

export default AnimatedAutoHeight;
