import { Excluded, Nullable, Omit } from '../interfaces/commons';

export const compact = <T>(array: Array<Nullable<T>>) => {
  return array.filter((item: Nullable<T>) => item !== null && item !== undefined) as Array<
    NonNullable<T>
  >;
};

export const compactByValue = <T, U extends T>(array: Array<Nullable<T>>, valueToExclude: U) => {
  return array.filter((item: Nullable<T>) => item !== valueToExclude) as Array<
    Omit<T, Excluded<T, U>>
  >;
};
