import { Animation, LocationInput, Radio } from 'components';
import { GoogleLocationObjectInput, WorkModeEnum } from 'interfaces';
import AnimateHeight from 'react-animate-height';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface PreferencesStepValues {
  location: string;
  locationObject: GoogleLocationObjectInput;
  workType: string;
}

export const PreferencesStep = () => {
  const { t } = useTranslation();

  const {
    setValue,
    formState: { errors },
    watch,
  } = useFormContext<PreferencesStepValues>();

  const workTypeOptions = Object.values(WorkModeEnum).map((workType) => ({
    label: t(`dictionaries:workTypes.${workType}`),
    value: workType,
  }));

  const handleLocationChange = (value: GoogleLocationObjectInput) => {
    setValue('location', value.description, { shouldValidate: true });
    setValue('locationObject', value, { shouldValidate: true });
  };

  const locationValue = watch('location');
  const workTypeValue = watch('workType');

  return (
    <AnimateHeight
      className="w-full mt-6 md:mt-14 !overflow-visible"
      duration={150}
      height={locationValue ? 215 : 80}
    >
      <div className="space-y-6 md:space-y-10">
        <LocationInput
          error={errors.location}
          label={t('onboarding:preferencesStep.locationLabel') ?? ''}
          name="location"
          onChange={handleLocationChange}
          placeholder={t('forms:placeholders.locationInput') ?? ''}
          value={locationValue}
        />
        <Animation animationName="fade-in-up" isShowed={!!locationValue}>
          <Radio
            columns={3}
            error={errors.workType}
            label={t('onboarding:preferencesStep.workTypeLabel') ?? ''}
            name="workType"
            onChange={(value) => setValue('workType', value, { shouldValidate: true })}
            options={workTypeOptions}
            value={workTypeValue}
          />
        </Animation>
      </div>
    </AnimateHeight>
  );
};
