import { useReactiveVar } from '@apollo/client';
import { profileVar } from 'reactive-vars';
import { ProfileType } from '../reactive-vars/profile';

export const useExpertProfile = () => {
  const profile = useReactiveVar(profileVar);

  const setProfileField = <F extends keyof ProfileType, V extends ProfileType[F]>(
    field: F,
    value: V,
  ) => {
    const oldProfile = profileVar() as ProfileType;
    profileVar({
      ...oldProfile,
      [field]: value,
    });
  };

  return {
    profile,
    setProfileField,
  };
};
