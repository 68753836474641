import { useMemo } from 'react';
import { GroupBase } from 'react-select';

/**
 * Custom hook to sort and filter options based on user input.
 *
 * @template TOptionValue - The type of the option value.
 * @param {string} input - The user input to filter the options.
 * @param {Option<TOptionValue>[]} options - The list of options to be sorted and filtered.
 * @returns {Option<TOptionValue>} - The sorted and filtered options.
 */

export type Option<TOptionValue> =
  | {
      value: TOptionValue;
      label: string;
    }
  | GroupBase<Option<TOptionValue>>;

const useSortedOptions = <TOptionValue>(input: string, options: Option<TOptionValue>[]) => {
  const sortedOptions = useMemo<Option<TOptionValue>[]>(
    function () {
      const matchingOptions = options.filter((option) =>
        option.label?.toLowerCase().startsWith(input.toLowerCase()),
      );

      const remainingOptions = options.filter(
        (option) =>
          option.label?.toLowerCase().includes(input.toLowerCase()) &&
          !option.label?.toLowerCase().startsWith(input.toLowerCase()),
      );

      return [...matchingOptions, ...remainingOptions].sort((a, b) => {
        const labelA = a.label?.toLowerCase();
        const labelB = b.label?.toLowerCase();

        if (labelA?.startsWith(input.toLowerCase()) && !labelB?.startsWith(input.toLowerCase())) {
          return -1;
        }
        if (!labelA?.startsWith(input.toLowerCase()) && labelB?.startsWith(input.toLowerCase())) {
          return 1;
        }
        return labelA?.localeCompare(labelB ?? '') ?? 0;
      });
    },
    [input, options],
  );

  return sortedOptions;
};

export default useSortedOptions;
