import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
const defaultOptions = {} as const;
export type SignCvAccessUrlMutationVariables = Types.Exact<{
  cvId: Types.Scalars['String'];
}>;

export type SignCvAccessUrlMutation = { __typename?: 'Mutation'; signCVAccessURL: string };

export const SignCvAccessUrlDocument = gql`
  mutation signCVAccessURL($cvId: String!) {
    signCVAccessURL(cvId: $cvId)
  }
`;
export type SignCvAccessUrlMutationFn = Apollo.MutationFunction<
  SignCvAccessUrlMutation,
  SignCvAccessUrlMutationVariables
>;

/**
 * __useSignCvAccessUrlMutation__
 *
 * To run a mutation, you first call `useSignCvAccessUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignCvAccessUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signCvAccessUrlMutation, { data, loading, error }] = useSignCvAccessUrlMutation({
 *   variables: {
 *      cvId: // value for 'cvId'
 *   },
 * });
 */
export function useSignCvAccessUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignCvAccessUrlMutation,
    SignCvAccessUrlMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignCvAccessUrlMutation, SignCvAccessUrlMutationVariables>(
    SignCvAccessUrlDocument,
    options,
  );
}
export type SignCvAccessUrlMutationHookResult = ReturnType<typeof useSignCvAccessUrlMutation>;
export type SignCvAccessUrlMutationResult = Apollo.MutationResult<SignCvAccessUrlMutation>;
export type SignCvAccessUrlMutationOptions = Apollo.BaseMutationOptions<
  SignCvAccessUrlMutation,
  SignCvAccessUrlMutationVariables
>;
