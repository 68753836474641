import { yupResolver } from '@hookform/resolvers/yup';
import { CodeInput, RoundedButton } from 'components';
import { useAsyncCallback } from 'hooks';
import { TFunction } from 'i18next';
import { CognitoErrorCodesEnum } from 'interfaces';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ResendCodeButton } from '../../../Auth/forms/ResendCodeButton';

const validationSchema = (t: TFunction) =>
  yup.object().shape({
    otpCode: yup.string().required(
      t('forms:validationMessages.required', {
        fieldName: t('forms:labels.oneTimeCode'),
      }) ?? '',
    ),
  });

interface ExternalOfferCodeConfirmationFormValues {
  otpCode: string;
}

interface ExternalOfferCodeConfirmationFormProps {
  onOTPSubmit: (otp: string) => Promise<any>;
  onResendCodeClick: () => Promise<any>;
  onConfirmationSuccess: () => void;
}

export const ExternalOfferCodeConfirmationForm: FC<ExternalOfferCodeConfirmationFormProps> = ({
  onOTPSubmit,
  onResendCodeClick,
  onConfirmationSuccess,
}) => {
  const { t } = useTranslation();
  const {
    setValue,
    formState: { errors },
    handleSubmit: prepareSubmit,
    setError,
  } = useForm<ExternalOfferCodeConfirmationFormValues>({
    resolver: yupResolver(validationSchema(t)),
  });

  const handleSubmit = async ({ otpCode }: ExternalOfferCodeConfirmationFormValues) => {
    const res = await onOTPSubmit(otpCode);
    if (res.code === CognitoErrorCodesEnum.NotAuthorizedException) {
      setError('otpCode', { message: t('forms:validationMessages.expiredCode') ?? '' });
      return;
    }
    if (res.challengeName) {
      setError('otpCode', { message: t('forms:validationMessages.wrongCode') ?? '' });
      return;
    }
    onConfirmationSuccess();
  };

  const [submitCallback, { loading }] = useAsyncCallback(handleSubmit);

  return (
    <div className="bg-gradient-blue rounded-3xl p-6 text-white">
      <p className="text-2xl mb-6 text-center px-10">
        {t('offers:externalOffer.codeConfirmationBox.title')}
      </p>
      <p>{t('offers:externalOffer.codeConfirmationBox.subtitle')}</p>
      <form className="mt-8 space-y-8" onSubmit={prepareSubmit(submitCallback)}>
        <div className="space-y-6">
          <CodeInput
            disabled={loading}
            error={errors.otpCode}
            label={t('forms:labels.oneTimeCode')}
            name="otpCode"
            onChange={(otpCode) => setValue('otpCode', otpCode)}
          />
          <ResendCodeButton
            autoStart
            className="text-light-blue-200 !pt-0"
            color="white"
            isInColumn
            onClick={onResendCodeClick}
          />
        </div>
        <RoundedButton
          disabled={loading}
          label={t('common:actions.confirm')}
          loading={loading}
          type="submit"
        />
      </form>
    </div>
  );
};
