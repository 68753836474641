import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
const defaultOptions = {} as const;
export type UploadExpertWordCloudMutationVariables = Types.Exact<{
  expertId: Types.Scalars['String'];
  params: Types.UploadExpertWordCloudInputType;
}>;

export type UploadExpertWordCloudMutation = {
  __typename?: 'Mutation';
  uploadExpertWordCloud: string;
};

export const UploadExpertWordCloudDocument = gql`
  mutation uploadExpertWordCloud($expertId: String!, $params: UploadExpertWordCloudInputType!) {
    uploadExpertWordCloud(params: $params, expertId: $expertId)
  }
`;
export type UploadExpertWordCloudMutationFn = Apollo.MutationFunction<
  UploadExpertWordCloudMutation,
  UploadExpertWordCloudMutationVariables
>;

/**
 * __useUploadExpertWordCloudMutation__
 *
 * To run a mutation, you first call `useUploadExpertWordCloudMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadExpertWordCloudMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadExpertWordCloudMutation, { data, loading, error }] = useUploadExpertWordCloudMutation({
 *   variables: {
 *      expertId: // value for 'expertId'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUploadExpertWordCloudMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadExpertWordCloudMutation,
    UploadExpertWordCloudMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadExpertWordCloudMutation, UploadExpertWordCloudMutationVariables>(
    UploadExpertWordCloudDocument,
    options,
  );
}
export type UploadExpertWordCloudMutationHookResult = ReturnType<
  typeof useUploadExpertWordCloudMutation
>;
export type UploadExpertWordCloudMutationResult =
  Apollo.MutationResult<UploadExpertWordCloudMutation>;
export type UploadExpertWordCloudMutationOptions = Apollo.BaseMutationOptions<
  UploadExpertWordCloudMutation,
  UploadExpertWordCloudMutationVariables
>;
