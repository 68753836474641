import { AvatarFileInput } from 'components';
import { ExpertAvatarEnum } from 'interfaces';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'utils';
import { SelectItemWrapper } from './SelectItemWrapper';

export interface SelectItemProps {
  isActive: boolean;
  imageUrl?: string | null;
  expertId?: string;
  onSelect: (imageUrl?: string | null) => void;
  onRestrictionsErrorChange: (error: boolean) => void;
  setTemporaryImageUrl?: Dispatch<SetStateAction<string | null>>;
  setAvatarEnum: Dispatch<SetStateAction<ExpertAvatarEnum>>;
  temporaryPhotoUrl?: string | null;
}

export const PhotoSelectItem: FC<Omit<SelectItemProps, 'children'>> = ({
  imageUrl,
  isActive,
  onSelect,
  expertId,
  onRestrictionsErrorChange,
  setTemporaryImageUrl,
  temporaryPhotoUrl,
  setAvatarEnum,
}) => {
  const { t } = useTranslation();
  const [restrictionsError, setRestrictionsError] = useState(false);

  const handleRestrictionsErrorChange = (error: boolean) => {
    setRestrictionsError(error);
    onRestrictionsErrorChange(error);
  };

  const handleSelect = () => {
    if (!imageUrl) {
      onSelect(temporaryPhotoUrl);
    }
    onSelect(imageUrl);
    setAvatarEnum(ExpertAvatarEnum.Photo);
  };

  return (
    <SelectItemWrapper isActive={isActive} onClick={() => handleSelect()}>
      <button onClick={(e) => e.stopPropagation()}>
        <AvatarFileInput
          expertId={expertId}
          imageUrl={imageUrl}
          maxFileResolution={500}
          maxFileSize={2 * 1024 * 1024}
          name="expertPhoto"
          onRestrictionsErrorChange={handleRestrictionsErrorChange}
          onUploadSuccess={setTemporaryImageUrl}
        />
      </button>
      <p className="font-bold text-[18px] leading-normal mt-6 mb-4">
        {t('profile:avatarChangeModal.addPhoto')}
      </p>
      <p
        className={mergeClasses(
          'text-[12px] md:text-[14px] leading-[20px] text-center',
          restrictionsError ? 'text-red-500' : '',
        )}
      >
        {t('profile:avatarChangeModal.avatarRequirements')}
      </p>
    </SelectItemWrapper>
  );
};
