import { SmallButton } from 'components';
import { useElementSize } from 'hooks';
import { AngleIcon } from 'icons';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'utils';

type Height = number | 'auto';

interface ShowMoreProps {
  children: ReactNode;
  className?: string;
  overlayClassName?: string;
  hiddenHeight?: Height;
  showedHeight?: Height;
  padding?: string;
  isShowedPadding?: string;
  isHiddenPadding?: string;
  numberOfListItems?: number;
}

export const ShowMore: FC<ShowMoreProps> = ({
  children,
  hiddenHeight = 550,
  showedHeight = 'auto',
  className = '',
  overlayClassName = '',
  padding = 'pb-16',
  isShowedPadding = 'pb-16 lg:pb-8',
  isHiddenPadding = 'pb-8',
  numberOfListItems,
}) => {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [, wrapperHeight] = useElementSize(wrapperRef);
  const [isShowed, setIsShowed] = useState(false);
  const [height, setHeight] = useState(hiddenHeight);

  const isActive = Math.floor(wrapperHeight) > hiddenHeight;

  useEffect(() => {
    if (isShowed) {
      setHeight(showedHeight);
    } else {
      setHeight(hiddenHeight);
    }
  }, [isShowed, hiddenHeight, showedHeight]);

  const determineShowMoreLabel = () => {
    if (numberOfListItems) {
      return `${t('common:showMore')} (${numberOfListItems})`;
    }
    return `${t('common:showMore')}`;
  };

  return (
    <AnimateHeight
      className={mergeClasses('relative', className)}
      height={isActive ? height : wrapperHeight || 350}
    >
      <div ref={wrapperRef} className={padding}>
        {children}
      </div>
      {isActive && (
        <div
          className={mergeClasses(
            'absolute bottom-0 flex justify-center items-end w-full h-32 pb-8 transition-all',
            !isShowed ? 'bg-gradient-white bg-opacity-0' : 'bg-opacity-100',
            isShowed ? isShowedPadding : isHiddenPadding,
            overlayClassName,
          )}
        >
          <SmallButton
            className="text-sm"
            icon={AngleIcon}
            iconClassName={isShowed ? 'rotate-180' : ''}
            iconPosition="right"
            label={isShowed ? t('common:showLess') : determineShowMoreLabel()}
            onClick={() => setIsShowed((prev) => !prev)}
            variant="primary"
          />
        </div>
      )}
    </AnimateHeight>
  );
};
