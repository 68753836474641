import { IconType } from 'icons';
import { FC } from 'react';
import { mergeClasses } from 'utils';

interface ActionButtonProps {
  icon: IconType;
  isError?: boolean;
  onClick: () => void;
  ariaLabel?: string | null;
}

export const ActionButton: FC<ActionButtonProps> = ({
  icon: Icon,
  isError,
  onClick,
  ariaLabel,
}) => {
  return (
    <button
      aria-label={ariaLabel ?? undefined}
      className={mergeClasses(
        'rounded-lg border  w-14 min-w-[56px] h-14 flex justify-center items-center transition-all hover:bg-gray-100',
        isError ? 'border-red-500' : 'border-gray-200',
      )}
      onClick={onClick}
      type="button"
    >
      <Icon className="w-4 h-4" />
    </button>
  );
};
