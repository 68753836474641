import { useAuthWithOTP } from '../../../hooks/useAuthWithOTP';
import { ErrorScreen } from '../ErrorScreen';
import { SignInEmailInputStep } from './SignInEmailInputStep';
import { SignInOTPInputStep } from './SignInOTPInputStep';
import { SignInPasswordInputStep } from './SignInPasswordInputStep';
import { SignUpEmailInputStep } from './SignUpEmailInputStep';

interface AuthGenericFormProps {
  mode: 'signIn' | 'signUp';
}

export const AuthGenericForm = ({ mode }: AuthGenericFormProps) => {
  const {
    flowType,
    loading,
    onEmailSubmit,
    onOTPSubmit,
    onPasswordSubmit,
    onResendCodeClick,
    isPasswordResetFlowStarted,
    onResetPasswordOtpSubmit,
    onResetPasswordStartClick,
    isInErrorState,
    onStartAgainClick,
  } = useAuthWithOTP();

  if (isInErrorState) {
    return <ErrorScreen onClick={onStartAgainClick} />;
  }

  if (flowType === 'EMAIL_OTP') {
    return (
      <SignInOTPInputStep
        loading={loading}
        onOTPSubmit={onOTPSubmit}
        onResendCodeClick={onResendCodeClick}
      />
    );
  }

  if (flowType === 'USER_PASSWORD') {
    if (isPasswordResetFlowStarted) {
      return (
        <SignInOTPInputStep
          loading={loading}
          onOTPSubmit={onResetPasswordOtpSubmit}
          onResendCodeClick={onResetPasswordStartClick}
        />
      );
    }

    return (
      <SignInPasswordInputStep
        loading={loading}
        onPasswordSubmit={onPasswordSubmit}
        onResetPasswordStartClick={onResetPasswordStartClick}
      />
    );
  }

  if (mode === 'signUp') {
    return <SignUpEmailInputStep loading={loading} onEmailSubmit={onEmailSubmit} />;
  }

  return <SignInEmailInputStep loading={loading} onEmailSubmit={onEmailSubmit} />;
};
