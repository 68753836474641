import { useReactiveVar } from '@apollo/client';
import { LoadingSpinner, Modal, ModalProps, SmallButton, WordCloud } from 'components';
import { useAsyncCallback, useBase64 } from 'hooks';
import { useUploadExpertWordCloudMutation } from 'hooks/api/uploadExpertWordCloud/uploadExpertWordCloud.generated';
import { DownloadIcon, RefreshIcon } from 'icons';

import { FC, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { profileVar } from 'reactive-vars';
import { v4 as uuidv4 } from 'uuid';
import { downloadDOMasPNG } from '../../../../utils/downloadDOMasPNG';
import { ExpertProfile } from '../../Profile';

interface ChubIdGenerateModalProps extends ModalProps {
  profile?: ExpertProfile;
  onWordCloudGenerationSuccess: () => void;
}

interface ChangeExpertAvatarParams {
  expertId: string;
  fileBase64: string;
  name: string;
}

const getInitialSeed = (wordCloudUrl?: string | null) =>
  wordCloudUrl ? wordCloudUrl?.split('_')[2]?.replace('.png', '') : null;

export const ChubIdGenerateModal: FC<ModalProps> = ({ onClose, ...modalProps }) => {
  const { t } = useTranslation();
  const { getBase64FromHtml } = useBase64();
  const [uploadExpertWordCloud] = useUploadExpertWordCloudMutation();

  const wordCloudRef = useRef(null);
  const [initialSeed, setInitialSeed] = useState<string | null>(null);
  const [seed, setSeed] = useState<string | null>(null);
  const profile = useReactiveVar(profileVar);
  const handleRegenerateWordCloud = () => setSeed(uuidv4());

  const uploadWordCloud = async ({ expertId, fileBase64, name }: ChangeExpertAvatarParams) =>
    uploadExpertWordCloud({
      variables: {
        expertId,
        params: {
          name,
          fileBase64,
        },
      },
    });

  const [uploadWordCloudCallback, { loading }] = useAsyncCallback(uploadWordCloud);
  const handleSaveWordCloud = async () => {
    if (!wordCloudRef.current || !profile) return;
    const { expertId } = profile;
    const wordCloudBase64 = await getBase64FromHtml(wordCloudRef.current);
    toast.loading(t('profile:papers.chubId.toast.loading'), { toastId: 'wordCloudLoading' });
    onClose?.();
    const res = await uploadWordCloudCallback({
      expertId,
      name: `wordCloud_${expertId}_${seed}.png`,
      fileBase64: wordCloudBase64,
    });
    const { uploadExpertWordCloud: uploadedWordCloudUrl } =
      (res as { data: { uploadExpertWordCloud: string | null } }).data ?? {};
    profileVar({
      ...profile,
      fileUrls: {
        ...profile.fileUrls,
        wordCloudUrl: uploadedWordCloudUrl,
      },
    });

    toast.update('wordCloudLoading', {
      render: t('profile:papers.chubId.toast.success'),
      type: 'success',
      autoClose: 3000,
      isLoading: false,
      closeButton: true,
    });
  };

  const handleModalClose = async () => {
    if (initialSeed !== seed) {
      return handleSaveWordCloud();
    }
    onClose?.();
  };

  useLayoutEffect(() => {
    if (!profile || seed) return;
    const {
      fileUrls: { wordCloudUrl },
    } = profile ?? { fileUrls: {} };
    const initSeed = getInitialSeed(wordCloudUrl);
    setInitialSeed(initSeed);
    setSeed(initSeed || uuidv4());
  }, [profile]);

  const handleDownloadPng = async () => {
    if (!wordCloudRef.current) return;
    const fileName = `${profile?.firstName}_${profile?.lastName}_chub_avatar`;
    await downloadDOMasPNG(wordCloudRef.current, fileName);
  };

  return (
    <Modal
      {...modalProps}
      className="w-full max-w-container-sm"
      onClose={handleModalClose}
      title={t('profile:papers.chubId.title')}
    >
      {profile && seed ? (
        <div className="flex flex-col justify-center items-center mt-8">
          <div
            ref={wordCloudRef}
            className="flex justify-center items-center bg-primary-700 w-full h-[696px] rounded-3xl"
          >
            <WordCloud profile={profile} seed={seed} />
          </div>
          <div className="flex flex-col md:flex-row md:gap-5 items-center justify-center">
            <SmallButton
              className="mt-6"
              icon={RefreshIcon}
              isDisabled={loading}
              label={t('profile:papers.chubId.generateAgain')}
              onClick={handleRegenerateWordCloud}
              variant="primary"
            />
            <SmallButton
              className="mt-6"
              icon={DownloadIcon}
              isDisabled={loading}
              label={t('profile:papers.chubId.downloadAvatar')}
              onClick={handleDownloadPng}
              variant="primary"
            />
          </div>
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </Modal>
  );
};
