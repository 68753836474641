import { SideBox } from 'components';
import { LogoLayout } from 'layouts/LogoLayout';
import { FC, ReactNode } from 'react';
import { Helmet } from 'react-helmet';

interface ContainerWithSideboxProps {
  title: string;
  children: ReactNode;
  showBackgroundImage?: boolean;
}

export const ContainerWithSidebox: FC<ContainerWithSideboxProps> = ({
  title,
  children,
  showBackgroundImage = true,
}) => {
  return (
    <LogoLayout>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="flex lg:grid flex-col lg:grid-cols-2 items-center lg:items-stretch max-w-2xl lg:max-w-7xl mx-auto gap-0 lg:gap-24 xl:gap-36 w-full h-auto lg:h-full my-28">
        <SideBox showBackgroundImage={showBackgroundImage} />
        {children}
      </div>
    </LogoLayout>
  );
};
