import dayjs from 'dayjs';
import { AuthFlowType } from 'hooks/useAuthWithOTP';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useTimer } from 'react-timer-hook';
import { mergeClasses } from 'utils';

type Color = 'primary' | 'white';

interface ResendCodeButtonProps {
  onClick: () => Promise<any>;
  isInColumn?: boolean;
  className?: string;
  flowType?: AuthFlowType;
  onResetPasswordStartClick?: () => Promise<void>;
  autoStart?: boolean;
  color?: Color;
}

export const ResendCodeButton = ({
  onClick,
  isInColumn = false,
  className = '',
  flowType,
  onResetPasswordStartClick,
  autoStart = true,
  color = 'primary',
}: ResendCodeButtonProps) => {
  const { t } = useTranslation();

  const { seconds, isRunning, restart } = useTimer({
    autoStart,
    expiryTimestamp: dayjs().add(59, 'seconds').toDate(),
  });

  const handleClick = useCallback(async () => {
    if (isRunning) {
      return;
    }

    if (flowType === 'USER_PASSWORD' && onResetPasswordStartClick) {
      await onResetPasswordStartClick();
      restart(dayjs().add(59, 'seconds').toDate(), true);
      return;
    }

    onClick();
    restart(dayjs().add(59, 'seconds').toDate(), true);
  }, [flowType, isRunning, onClick, onResetPasswordStartClick, restart]);

  const buttonColorClassName =
    color === 'white'
      ? 'text-white hover:opacity-90 active:opacity-80'
      : 'text-primary-500 hover:text-primary-600 active:text-primary-700';

  return (
    <div
      className={mergeClasses(
        'font-medium pt-6 lg:pt-8',
        isInColumn ? 'flex flex-col items-start' : '',
        className,
      )}
    >
      <span>
        {flowType !== 'USER_PASSWORD' && !isRunning && t('auth:OTPcode.didNotReceiveCode')}{' '}
      </span>
      <button
        className={mergeClasses(
          color === 'primary' ? 'underline' : 'hover:underline',
          isRunning ? 'text-gray-300 cursor-not-allowed' : buttonColorClassName,
        )}
        onClick={handleClick}
        type="button"
      >
        {flowType === 'USER_PASSWORD' && !isRunning && t('auth:signInForm.forgotPassword')}
        {isRunning &&
          t('auth:OTPcode.resendCodeInSeconds', {
            seconds,
          })}
        {flowType !== 'USER_PASSWORD' && !isRunning && t('auth:OTPcode.resendCode')}
      </button>
    </div>
  );
};
