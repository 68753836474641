import { RecommendedJobOrdersQuery } from '../hooks/api/recommendedJobOrders/recommendedJobOrders.generated';

export type NonEmptyArray<A> = Array<A> & {
  0: A;
};

export type Nullable<T> = T | null;

export type JobOrderQueryType = RecommendedJobOrdersQuery['recommendedJobOrders'][number];

export type Omit<T, V> = Pick<T, { [K in keyof T]-?: T[K] extends V ? never : K }[keyof T]>;

export type Excluded<T, V> = T extends V ? never : T;

export const isNil = (val: unknown): val is undefined | null =>
  val === null || typeof val === 'undefined';
