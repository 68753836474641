import { useState } from 'react';
import { useAsyncCallback } from './useAsyncCallback';

export interface ModalState<T = string> {
  isOpen: boolean;
  data: T | null;
}

const defaultState = {
  isOpen: false,
  data: null,
};

export type ConfirmationModalConfirmFn<T = string> = (
  modalState: ModalState<T>,
  closeModal: () => void,
) => Promise<unknown>;

interface UseConfirmationModalProps<T> {
  onConfirm: ConfirmationModalConfirmFn<T>;
}

const defaultProps = {
  onConfirm: async () => undefined,
  data: null,
};

export function useModalState<TData = string>({
  onConfirm,
}: UseConfirmationModalProps<TData> = defaultProps) {
  const [modalState, setModalState] = useState<ModalState<TData>>(defaultState);
  const [submitModalCallback, { loading: isLoading }] = useAsyncCallback<any, any>(onConfirm);

  const openModal = (data: TData) =>
    setModalState({
      isOpen: true,
      data,
    });

  const closeModal = () => setModalState(defaultState);

  const confirmModal = () => submitModalCallback(modalState, closeModal);

  return {
    modalState,
    openModal,
    closeModal,
    isLoading,
    confirmModal,
  };
}
