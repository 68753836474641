import useWindowSize from './useWindowSize';

const BREAKPOINTS = ['sm', 'md', 'lg', 'xl'] as const;

export type BreakpointType = typeof BREAKPOINTS[number];

export const isMinimumBreakpoint =
  (minBreakpoint: BreakpointType) => (currentBreakpoint: BreakpointType) => {
    const minBreakpointIndex = BREAKPOINTS.indexOf(minBreakpoint);
    const currentBreakpointIndex = BREAKPOINTS.indexOf(currentBreakpoint);
    return currentBreakpointIndex >= minBreakpointIndex;
  };

const useBreakpoint = (): BreakpointType => {
  const { width } = useWindowSize();

  if (width === undefined) {
    return 'sm';
  }
  switch (true) {
    case width === undefined:
      return 'sm';
    case width >= 1280:
      return 'xl';
    case width >= 1024:
      return 'lg';
    case width >= 768:
      return 'md';
    default:
      return 'sm';
  }
};

export default useBreakpoint;
