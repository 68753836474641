import { CheckIcon } from 'icons';
import { ChangeEvent, forwardRef, ReactNode } from 'react';
import { FieldError } from 'react-hook-form';
import { mergeClasses } from 'utils';

interface CheckboxProps {
  label?: string | ReactNode;
  name: string;
  className?: string;
  checked?: boolean;
  error?: FieldError;
  hideErrorMessage?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ name, label, className = '', checked, error, hideErrorMessage, onChange }, ref) => {
    return (
      <label className={mergeClasses('block', className)} htmlFor={name}>
        <div className="grid items-start grid-cols-[24px_1fr]">
          <div
            className={mergeClasses(
              'rounded border w-6 h-6 relative flex justify-center items-center cursor-pointer',
              error ? 'border-red-500' : 'border-gray-200',
            )}
          >
            <input
              ref={ref}
              checked={checked}
              className="peer sr-only"
              id={name}
              name={name}
              onChange={onChange}
              type="checkbox"
            />
            <CheckIcon className="text-transparent w-6 peer-checked:text-primary-500 transition-all" />
          </div>
          <span className="ml-4 cursor-pointer select-none">{label}</span>
        </div>
        {error && !hideErrorMessage && (
          <p className="text-red-500 leading-[18px] mt-2">{error.message}</p>
        )}
      </label>
    );
  },
);

Checkbox.displayName = 'Checkbox';
