import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'utils';

interface ConnectisWebsiteHubSectionStepProps {
  label: string;
  index: number;
  primary?: boolean;
}

export const ConnectisWebsiteHubSectionStep: FC<ConnectisWebsiteHubSectionStepProps> = ({
  label,
  primary,
  index,
}) => {
  const { t } = useTranslation();

  return (
    <li
      className={mergeClasses(
        'relative rounded-2xl text-white p-6 pb-[72px] w-full',
        primary ? 'bg-gradient-blue' : 'bg-white/5',
      )}
    >
      {primary && (
        <div className="absolute top-0 lg:-top-3 xl:-top-px right-0 font-medium text-sm text-gray-150 rounded-3xl bg-white/25 p-2.5">
          {t('connectisWebsite:hubSection.startWithHub')}
        </div>
      )}
      <p className="text-[32px] font-medium mb-10">{`${t('connectisWebsite:hubSection.step')} ${
        index + 1
      }`}</p>
      <p className="text-[#E9EAE9]">{label}</p>
    </li>
  );
};
