import { TFunction } from 'i18next';

export const getMonthLabels = (t: TFunction, length: number): string => {
  if (length === 1) return t('dictionaries:lengthUnits.month');
  // Check if length is greater than 1 and ends in 2, 3, or 4, but not 12, 13, or 14 in order to determine the right declination of word 'months'
  if (
    length > 1 &&
    (length % 10 === 2 || length % 10 === 3 || length % 10 === 4) &&
    (length % 100 < 10 || length % 100 >= 20)
  ) {
    return t('dictionaries:lengthUnits.fewMonths');
  }
  // Default case when length is not 1 or the specific condition is not met
  return t('dictionaries:lengthUnits.months');
};
