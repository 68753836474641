import { yupResolver } from '@hookform/resolvers/yup';
import { LinkButton, workExperienceFormSchema, WorkHistoryForm, WorkHistoryItem } from 'components';
import { ConfirmationModal } from 'components/Modals';
import { PlusIcon } from 'icons';
import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'utils';
import { WorkExperienceFormValues } from '../../WorkHistory/WorkHistoryForm';
import { WorkExperienceModal } from '../modals';
import { StepComponentProps } from '../stepsConfig';

export interface ExperienceStepValues {
  workHistory: WorkExperienceFormValues[];
}

interface EditModalState {
  isOpen: boolean;
  currentExperienceIndex: number | null;
  currentExperience: WorkExperienceFormValues | null;
}

type ConfirmModalState = Omit<EditModalState, 'currentExperience'>;

const defaultEditModalState: EditModalState = {
  isOpen: false,
  currentExperienceIndex: null,
  currentExperience: null,
};

const defaultConfirmModalState: ConfirmModalState = {
  isOpen: false,
  currentExperienceIndex: null,
};

const nestedFormDefaultValues: WorkExperienceFormValues = {
  companyName: '',
  jobPositionName: '',
  dateStart: '',
  dateEnd: null,
  isCurrentJob: false,
  tagsIds: [],
  description: '',
  isCompanyNameSkipped: false,
  industry: '',
};

export const ExperienceStep: FC<StepComponentProps> = ({ onToggleNextDisabled }) => {
  const { t } = useTranslation();
  const [editModalState, setEditModalState] = useState<EditModalState>(defaultEditModalState);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [confirmModalState, setConfirmModalState] =
    useState<ConfirmModalState>(defaultConfirmModalState);

  const workExperienceFormMethods = useForm<WorkExperienceFormValues>({
    defaultValues: nestedFormDefaultValues,
    resolver: yupResolver(workExperienceFormSchema(t)),
  });
  const { setValue, watch } = useFormContext<ExperienceStepValues>();

  const workHistoryValue = watch('workHistory') ?? [];
  const { isDirty } = workExperienceFormMethods.formState;

  const handleSaveWorkExperience = async (hideForm = true) => {
    const isValid = await workExperienceFormMethods.trigger();
    if (!isValid) {
      return;
    }

    const newWorkExperience = workExperienceFormMethods.getValues();
    setValue('workHistory', [...workHistoryValue, newWorkExperience]);
    workExperienceFormMethods.reset(nestedFormDefaultValues);
    if (hideForm) {
      setIsAddFormOpen(false);
    }
  };

  const handleAddWorkExperience = () => {
    if (!isAddFormOpen) {
      return setIsAddFormOpen(true);
    }
    handleSaveWorkExperience(false);
  };

  const handleEditModalOpen = (index: number) => {
    const editedWorkExperience = workHistoryValue[index];
    if (!editedWorkExperience) {
      return;
    }

    setEditModalState({
      isOpen: true,
      currentExperienceIndex: index,
      currentExperience: editedWorkExperience,
    });
  };

  const handleEditModalClose = () => {
    setEditModalState(defaultEditModalState);
    workExperienceFormMethods.reset(nestedFormDefaultValues);
  };

  const handleEditWorkExperience = async () => {
    const isValid = await workExperienceFormMethods.trigger();
    if (!isValid) {
      return;
    }

    const updatedWorkExperience = workExperienceFormMethods.getValues();
    setValue(
      'workHistory',
      workHistoryValue.map((workExperience, index) =>
        index === editModalState.currentExperienceIndex ? updatedWorkExperience : workExperience,
      ),
    );
    workExperienceFormMethods.reset(nestedFormDefaultValues);
    handleEditModalClose();
  };

  const handleConfirmModalOpen = (index: number) => {
    setConfirmModalState({
      isOpen: true,
      currentExperienceIndex: index,
    });
  };

  const handleConfirmModalClose = () => setConfirmModalState(defaultConfirmModalState);

  const handleRemoveWorkExperience = () => {
    setValue(
      'workHistory',
      workHistoryValue.filter((_, i) => i !== confirmModalState.currentExperienceIndex),
    );
    handleConfirmModalClose();
  };

  const handleCancelClick = () => {
    setIsAddFormOpen(false);
    workExperienceFormMethods.reset(nestedFormDefaultValues);
  };

  useEffect(() => {
    if (!workHistoryValue.length && !isAddFormOpen) {
      setIsAddFormOpen(true);
    }
  }, [workHistoryValue]);

  useEffect(() => {
    return onToggleNextDisabled(isDirty);
  }, [isDirty]);

  return (
    <div className="mt-4 md:mt-14 mb-20 md:-mb-8 lg:mb-12">
      <ConfirmationModal
        {...confirmModalState}
        onCancel={handleConfirmModalClose}
        onClose={handleConfirmModalClose}
        onConfirm={handleRemoveWorkExperience}
        text={t('onboarding:experienceStep.removeExperienceConfirmText') ?? ''}
      />
      <FormProvider {...workExperienceFormMethods}>
        <WorkExperienceModal
          {...editModalState}
          onClose={handleEditModalClose}
          onSave={handleEditWorkExperience}
        />
        <div className={mergeClasses('space-y-4 mb-0', workHistoryValue.length ? 'md:mb-14' : '')}>
          {workHistoryValue.map((wh, i) => (
            <WorkHistoryItem
              key={JSON.stringify(wh)}
              {...wh}
              onEdit={() => handleEditModalOpen(i)}
              onRemove={() => handleConfirmModalOpen(i)}
            />
          ))}
          <hr className="md:hidden block !my-6 w-full" />
        </div>
        {isAddFormOpen && !editModalState.isOpen && (
          <WorkHistoryForm
            onCancelClick={handleCancelClick}
            onSaveClick={handleSaveWorkExperience}
          />
        )}
        {!isAddFormOpen && (
          <div className="flex items-center justify-between mt-8 mb-12 md:mb-24 lg:mb-0">
            <LinkButton
              className="text-xs"
              icon={PlusIcon}
              label={t('onboarding:experienceStep.addAnotherExperience')}
              onClick={handleAddWorkExperience}
              underline={false}
            />
          </div>
        )}
      </FormProvider>
    </div>
  );
};
