import { JsonObject } from 'type-fest';

export const pageTitles: JsonObject = {
  signIn: 'Zaloguj się',
  signUp: 'Zarejestruj się',
  passwordReset: 'Reset hasła',
  newPassword: 'Stwórz nowe hasło',
  emailConfirmation: 'Potwierdzenie adresu',
  homePage: 'Strona główna',
  offers: 'Oferty',
  profile: 'Profil',
  settings: 'Ustawienia',
  onboarding: {
    yourProfile: 'Twój profil',
  },
  notifications: 'Powiadomienia',
  myApplications: 'Moje aplikacje',
};
