import { ChangeEvent, forwardRef, ReactNode } from 'react';
import { FieldError } from 'react-hook-form';
import { mergeClasses } from 'utils';
import { InputError } from '../../InputError';
import { InputLabel } from '../../InputLabel';

export interface TextFieldProps {
  name: string;
  label?: string | null;
  value?: string;
  type?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  error?: FieldError;
  className?: string;
  wrapperClassName?: string;
  hideErrorMessage?: boolean;
  autoComplete?: string;
  placeholder?: string;
  inputAdornment?: string | ReactNode;
  disabled?: boolean;
  leftSideComponent?: ReactNode;
  leftSideComponentClassNames?: string;
  labelClassName?: string;
  variant?: 'default' | 'transparent';
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      onChange,
      label,
      type = 'text',
      value,
      name,
      error,
      className = '',
      wrapperClassName = '',
      hideErrorMessage = false,
      autoComplete,
      placeholder,
      inputAdornment,
      disabled,
      leftSideComponent,
      leftSideComponentClassNames,
      labelClassName,
      variant = 'default',
    },
    ref,
  ) => {
    return (
      <label className={mergeClasses('flex flex-col', wrapperClassName)} htmlFor={name}>
        <InputLabel className={labelClassName} label={label} />
        <span className="relative flex items-center">
          {leftSideComponent && (
            <div
              className={mergeClasses(
                'border border-gray-200 h-full rounded-l-lg p-4',
                error ? 'border-red-500' : 'border-gray-200',
                leftSideComponentClassNames,
              )}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              {leftSideComponent}
            </div>
          )}
          <input
            ref={ref}
            autoComplete={autoComplete}
            className={mergeClasses(
              'border rounded-lg px-6 py-4 transition-all w-full sm:px-[24px] sm:py-[19px] sm:leading-[18px]',
              error ? 'border-red-500' : 'border-gray-200',
              disabled ? 'cursor-not-allowed opacity-50' : '',
              leftSideComponent ? 'rounded-l-none border-l-0' : '',
              variant === 'transparent'
                ? 'bg-white/10 autofill:transition-colors autofill:duration-[5000000ms]'
                : '',
              className,
            )}
            disabled={disabled}
            id={name}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            type={type}
            value={value}
          />
          <span className="absolute right-4 text-gray-400">{inputAdornment}</span>
        </span>
        {!hideErrorMessage && <InputError error={error} />}
      </label>
    );
  },
);

TextField.displayName = 'TextField';
