import { JsonObject } from 'type-fest';

export const dictionaries: JsonObject = {
  skillLevels: {
    0: 'Brak',
    1: 'podstawowy',
    2: 'początkujący',
    3: 'średnio zaawansowany',
    4: 'zaawansowany',
    5: 'ekspert',
  },
  languageLevels: {
    0: 'Brak',
    1: 'Początkujący (A0)',
    2: 'Podstawowy (A1)',
    3: 'Przed średnio zaawansowany (A2)',
    4: 'Średnio zaawansowany (B1)',
    5: 'Ponad średnio zaawansowany (B2)',
    6: 'Zaawansowany (C1)',
  },
  languageLevelsShort: {
    none: 'A0',
    1: 'A0',
    2: 'A1',
    3: 'A2',
    4: 'B1',
    5: 'B2',
    6: 'C1',
  },
  workTypes: {
    Office: 'W biurze',
    Remote: 'Zdalnie',
    Hybrid: 'Hybrydowo',
  },
  availability: {
    Immediately: 'Od zaraz',
    Days: 'W dniach',
    Months: 'W miesiącach',
    Notice: 'Obowiązuje mnie okres wypowiedzenia',
  },
  lengthUnits: {
    days: 'dni',
    day: 'dzień',
    month: 'miesiąc',
    months: 'miesięcy',
    fewMonths: 'miesiące',
  },
  offersSorting: {
    bestMatch: 'Najlepiej dopasowane',
    assignmentDateAsc: 'Data dodania - od najstarszej',
    assignmentDateDesc: 'Data dodania - od najnowszej',
    createdAtAsc: 'Data utworzenia - od najstarszej',
    createdAtDesc: 'Data utworzenia - od najnowszej',
    rateAsc: 'Stawka - od najwyższej',
    rateDesc: 'Stawka - od najniższej',
  },
  notificationsSorting: {
    UnviewedFirst: 'Nieprzeczytane',
    CreatedAtAsc: 'Data dodania - od najstarszej',
    CreatedAtDesc: 'Data dodania - od najnowszej',
  },
};
