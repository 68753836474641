import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
const defaultOptions = {} as const;
export type VerifyPasswordResetOtpMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  otp: Types.Scalars['String'];
}>;

export type VerifyPasswordResetOtpMutation = {
  __typename?: 'Mutation';
  verifyPasswordResetOtp: boolean;
};

export const VerifyPasswordResetOtpDocument = gql`
  mutation verifyPasswordResetOtp($email: String!, $otp: String!) {
    verifyPasswordResetOtp(email: $email, otp: $otp)
  }
`;
export type VerifyPasswordResetOtpMutationFn = Apollo.MutationFunction<
  VerifyPasswordResetOtpMutation,
  VerifyPasswordResetOtpMutationVariables
>;

/**
 * __useVerifyPasswordResetOtpMutation__
 *
 * To run a mutation, you first call `useVerifyPasswordResetOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyPasswordResetOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyPasswordResetOtpMutation, { data, loading, error }] = useVerifyPasswordResetOtpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      otp: // value for 'otp'
 *   },
 * });
 */
export function useVerifyPasswordResetOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyPasswordResetOtpMutation,
    VerifyPasswordResetOtpMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VerifyPasswordResetOtpMutation,
    VerifyPasswordResetOtpMutationVariables
  >(VerifyPasswordResetOtpDocument, options);
}
export type VerifyPasswordResetOtpMutationHookResult = ReturnType<
  typeof useVerifyPasswordResetOtpMutation
>;
export type VerifyPasswordResetOtpMutationResult =
  Apollo.MutationResult<VerifyPasswordResetOtpMutation>;
export type VerifyPasswordResetOtpMutationOptions = Apollo.BaseMutationOptions<
  VerifyPasswordResetOtpMutation,
  VerifyPasswordResetOtpMutationVariables
>;
