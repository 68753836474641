import { LoadingSpinner } from 'components';
import { t } from 'i18next';
import { FC } from 'react';
import { ExpertNotificationsQuery } from '../../../hooks/api/expertNotifications/expertNotifications.generated';
import NotificationItem from '../../Navigation/NotificationModule/subcomponents/NotificationItem';

interface NotificationsListProps {
  loading?: boolean;
  notifications: ExpertNotificationsQuery['expertNotifications']['notifications'];
}

export const NotificationsList: FC<NotificationsListProps> = ({
  notifications,
  loading = false,
}) => {
  if (loading) {
    return (
      <div className="flex justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="border-t border-gray-200">
      {notifications.length ? (
        notifications.map((notification) => {
          const hasViewDate = !!notification.viewedAt;
          const metadata = JSON.parse(notification.metadata || '{}');
          return (
            <NotificationItem
              key={notification.id}
              date={notification.createdAt}
              hasViewDate={hasViewDate}
              jobOrderId={metadata.jobOrderId}
              jobOrderName={metadata.jobOrderName}
              matchScore={metadata.matchScore}
              notificationId={notification.id}
              relatedTo={notification.relatesTo}
              type={notification.type}
            />
          );
        })
      ) : (
        <p className="py-6 text-gray-400">{t('notifications:noNotifications')}</p>
      )}
    </div>
  );
};
