import dayjs from 'dayjs';
import { LanguageLevelEnum, SeniorityLevelEnum, WorkModeEnum } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'utils';
import { LanguageIcon, LevelsIcon, LocationIcon, OfficeIcon } from '../../../../icons';
import { determineRequiredLanguageLevel } from '../../../Modals/OfferDetailsModal/subcomponents/JobOrderDetailsHeader';
import { ExternalOfferCTASection } from '../ExternalOfferCTASection';
import { ExternalOfferHeaderDetail, ExternalOfferHeaderRate } from './subcomponents';

interface ExternalOfferHeaderProps {
  role?: string | null;
  leadingTechnologyName?: string | null;
  rateFrom?: number | null;
  rateTo?: number | null;
  locationPreference?: string | null;
  workMode?: WorkModeEnum | null;
  seniority?: SeniorityLevelEnum | null;
  experienceYears?: number | null;
  minEnglishLevel?: LanguageLevelEnum | null;
  isRateDisplayed: boolean;
  className?: string;
}

export const ExternalOfferHeader = ({
  role,
  leadingTechnologyName,
  rateFrom,
  rateTo,
  locationPreference,
  workMode,
  seniority,
  experienceYears,
  minEnglishLevel,
  isRateDisplayed,
  className,
}: ExternalOfferHeaderProps) => {
  const { t } = useTranslation();

  const experienceYearsParsed = dayjs()
    .subtract(experienceYears || 0, 'year')
    .fromNow(true);

  return (
    <div className={mergeClasses('p-6 shadow-lg rounded-3xl', className)}>
      <p className="text-2xl lg:text-[40px] font-bold mb-6">
        {role} {leadingTechnologyName}
      </p>
      {isRateDisplayed && <ExternalOfferHeaderRate rateFrom={rateFrom || 0} rateTo={rateTo || 0} />}
      <div className="flex flex-wrap gap-x-4 gap-y-1 pb-6 mb-6 border-b border-gray-200">
        <ExternalOfferHeaderDetail icon={LocationIcon} value={locationPreference} />
        <ExternalOfferHeaderDetail
          icon={OfficeIcon}
          value={workMode ? t(`dictionaries:workTypes.${workMode}`) : '-'}
        />
        <ExternalOfferHeaderDetail icon={LevelsIcon} value={seniority} />
        <ExternalOfferHeaderDetail
          icon={LevelsIcon}
          value={experienceYears ? experienceYearsParsed : '-'}
        />
        <ExternalOfferHeaderDetail
          icon={LanguageIcon}
          value={
            minEnglishLevel
              ? `${t('forms:labels.english')} ${determineRequiredLanguageLevel(minEnglishLevel)}`
              : '-'
          }
        />
      </div>
      <ExternalOfferCTASection />
    </div>
  );
};
