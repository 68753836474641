import { SearchIcon } from 'icons';
import { FC, PropsWithChildren, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactSelect, { GroupBase } from 'react-select';
import { escapeRegExp, mergeClasses } from 'utils';
import useSortedOptions from '../../../hooks/api/useSortedOptions';
import { InputLabel } from '../../InputLabel';
import { getOptionStyles } from '../Select/Select';

const CustomControl: FC<{ children: any; innerRef: any; innerProps: any; selectProps: any }> = ({
  children,
  innerRef,
  innerProps,
  selectProps,
}) => {
  const { className } = selectProps;
  const isError = className.includes('error');

  return (
    <div
      ref={innerRef}
      className={mergeClasses(
        'border p-4 flex items-center rounded-lg transition-all bg-white',
        isError ? 'border-red-500' : 'border-gray-200',
      )}
      {...innerProps}
    >
      <SearchIcon className="w-4 h-4 mr-4" />
      {children}
    </div>
  );
};

const handleHighlightText = (label: string, inputValue: string) => {
  const escapedInputValue = escapeRegExp(inputValue);
  const highlightedText = label.match(new RegExp(escapedInputValue, 'i'))?.[0] ?? '';
  const highlightedOption = label.replace(
    highlightedText,
    `<span style="font-weight:500;color:#000">${highlightedText}</span>`,
  );

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: highlightedOption,
      }}
    />
  );
};

export type Option<TOptionValue> = {
  value: TOptionValue;
  label: string;
};

interface SearchInputProps<TOptionValue> {
  options: Option<TOptionValue>[];
  name?: string;
  label?: string | null;
  placeholder?: string | null;
  error?: FieldError;
  onChange: (value: TOptionValue) => void;
  className?: string;
  onInputChange?: (value: string) => void;
  value?: Option<TOptionValue>;
  menuPlacement?: 'bottom' | 'auto' | 'top';
}

export function SearchInput<TOptionValue = string>({
  onChange,
  options,
  name,
  placeholder,
  label,
  error,
  className = '',
  onInputChange,
  value,
  menuPlacement = 'auto',
}: PropsWithChildren<SearchInputProps<TOptionValue>>) {
  const { t } = useTranslation();
  const [input, setInput] = useState('');

  const handleInputChange = (inputValue: string) => {
    setInput(inputValue);
    if (onInputChange) {
      onInputChange(inputValue);
    }
  };

  const sortedOptions = useSortedOptions(input, options) as readonly (
    | Option<TOptionValue>
    | GroupBase<Option<TOptionValue>>
  )[];

  return (
    <label className={mergeClasses('flex flex-col', className)} htmlFor={name}>
      {label && <InputLabel label={label} />}
      <ReactSelect
        aria-sort="descending"
        className={mergeClasses('w-full', error ? 'error' : '')}
        components={{
          Control: CustomControl,
          IndicatorSeparator: undefined,
          DropdownIndicator: undefined,
        }}
        controlShouldRenderValue={!!value}
        formatOptionLabel={({ label: optionLabel }, { inputValue }) =>
          handleHighlightText(optionLabel, inputValue)
        }
        isOptionSelected={value ? undefined : () => false}
        menuPlacement={menuPlacement}
        name={name}
        onChange={(opt) => onChange(opt?.value as TOptionValue)}
        onInputChange={handleInputChange}
        options={sortedOptions}
        placeholder={placeholder ?? t('forms:placeholders.select') ?? ''}
        styles={{
          valueContainer: (provided) => ({
            ...provided,
            padding: 0,
            paddingRight: 2,
          }),
          option: (provided, { isSelected, isFocused }) => ({
            ...provided,
            ...getOptionStyles(isSelected, isFocused),
          }),
        }}
        value={value}
      />
      {error && <p className="text-red-500 leading-[18px] mt-2">{error.message}</p>}
    </label>
  );
}
