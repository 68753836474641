import i18n from 'i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { initReactI18next } from 'react-i18next';
import en from './en';
import pl from './pl';

const resources = {
  en,
  pl,
};

let lang;
if (typeof window !== 'undefined') {
  lang = localStorage.getItem('lang');
}

i18n
  .use(intervalPlural)
  .use(initReactI18next)
  .init({
    resources,
    lng: lang ?? 'pl',
  });

export default i18n;
