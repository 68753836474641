import { useSitemap } from 'hooks';
import { ApplicationIcon, IconType, OfferIcon, SettingsIcon, UserIcon } from 'icons';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router-dom';
import { useExpertApplicationCountQuery } from '../../../../hooks/api/expertApplicationCount/expertApplicationCount.generated';
import { MenuItem } from './MenuItem';

interface Path {
  label: string;
  href: string;
  icon: IconType;
  activePath?: string;
  scrollToTop?: boolean;
  counter?: number;
}

interface MenuProps {
  onMobileMenuToggle?: () => void;
}

const checkIfPathIsActive = (pathname: string, { activePath, href }: Path) => {
  if (activePath) {
    return !!matchPath({ path: activePath }, pathname);
  }
  return pathname === href;
};

export const Menu: FC<MenuProps> = ({ onMobileMenuToggle }) => {
  const { t } = useTranslation();
  const sitemap = useSitemap();
  const { pathname } = useLocation();

  const { data } = useExpertApplicationCountQuery();
  const expertApplicationCount = data?.expertApplicationCount;

  const expertPaths: Path[] = useMemo(
    () => [
      {
        label: t('pageTitles:offers'),
        href: sitemap.offers(),
        activePath: '/offers/*',
        icon: OfferIcon,
        scrollToTop: true,
      },
      {
        label: t('pageTitles:myApplications'),
        href: sitemap.myApplications(),
        activePath: '/applications/*',
        icon: ApplicationIcon,
        scrollToTop: true,
        counter: expertApplicationCount,
      },
      {
        label: t('pageTitles:profile'),
        href: sitemap.profile(),
        activePath: '/profile/*',
        icon: UserIcon,
        scrollToTop: true,
      },
      {
        label: t('pageTitles:settings'),
        href: sitemap.settings(),
        activePath: '/settings/*',
        icon: SettingsIcon,
        scrollToTop: true,
      },
    ],
    [t, expertApplicationCount],
  );

  return (
    <ul className="xl:px-2 xl:pb-2">
      {expertPaths.map((path) => (
        <MenuItem
          key={JSON.stringify(path)}
          {...path}
          isActive={checkIfPathIsActive(pathname, path)}
          onMobileMenuToggle={onMobileMenuToggle}
          scrollToTop={path.scrollToTop}
        />
      ))}
    </ul>
  );
};
