import { Profile } from 'components';
import { PanelLayout } from 'layouts';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ProfilePageProps {
  isMobileMenuOpen?: boolean;
  onMobileMenuToggle: () => void;
}

export const ProfilePage: FC<ProfilePageProps> = ({ isMobileMenuOpen, onMobileMenuToggle }) => {
  const { t } = useTranslation();

  return (
    <PanelLayout
      isMobileMenuOpen={isMobileMenuOpen}
      onMobileMenuToggle={onMobileMenuToggle}
      title={t('pageTitles:profile')}
    >
      <Profile />
    </PanelLayout>
  );
};
