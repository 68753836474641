export const omitObjectKeys = <O extends Record<string, any>>(
  object: O,
  keysToOmit: Array<keyof O>,
) =>
  Object.keys(object)
    .filter((key) => !keysToOmit.includes(key))
    .reduce((acc: any, key) => {
      acc[key] = object[key];
      return acc;
    }, {});
