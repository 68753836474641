import { FC } from 'react';
import { JobBoardOfferType } from '../JobBoardOffer';

const Skill: FC<{ label: string }> = ({ label }) => (
  <li className="text-sm bg-new-gray-100 px-5 py-1 rounded-full">{label}</li>
);

export const JobBoardOfferSkills: FC<{
  skills: JobBoardOfferType['jobOrder']['skillsNiceToHave'];
}> = ({ skills }) => {
  const visibleSkills = skills.slice(0, 5);
  const restSkills = skills.slice(5);

  return (
    <ul className="flex flex-wrap gap-2">
      {visibleSkills.map(({ id, name }) => (
        <Skill key={id} label={name} />
      ))}
      {!!restSkills.length && <Skill label={`+${restSkills.length}`} />}
    </ul>
  );
};
