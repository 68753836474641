import { ArrowIcon } from 'icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'utils';

interface BackButtonProps {
  className?: string;
  onClick?: () => void;
  label?: string;
}

export const BackButton: FC<BackButtonProps> = ({ className = '', onClick, label }) => {
  const { t } = useTranslation();

  return (
    <button
      className={mergeClasses('flex items-center text-gray-500 ', className)}
      onClick={onClick}
    >
      <ArrowIcon className="w-4 h-4 mr-2" />
      {label ?? t('common:return')}
    </button>
  );
};
