import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
const defaultOptions = {} as const;
export type MyProfileQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MyProfileQuery = {
  __typename?: 'Query';
  myProfile: {
    __typename?: 'ExpertType';
    expertId: string;
    crmId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    jobPositionName?: string | null;
    jobPositionId?: string | null;
    experienceYears?: number | null;
    createdAt: string;
    rateDesired?: number | null;
    location?: string | null;
    workType?: Types.WorkModeEnum | null;
    desiredIndustries?: Array<string> | null;
    desiredOfficeLocations?: Array<string> | null;
    desiredTechnicalSkills?: Array<string> | null;
    desiredFields?: Array<string> | null;
    seniority?: Types.SeniorityLevelEnum | null;
    availability?: Types.ExpertAvailabilityEnum | null;
    tagsSuperPowers?: Array<string> | null;
    mainTechnologyIds?: Array<string> | null;
    noticeLength?: number | null;
    manuallyAddedJobPositions: Array<string>;
    manuallyAddedDesiredTechnicalSkills: Array<string>;
    manuallyAddedMainTechnologies: Array<string>;
    avatar?: Types.ExpertAvatarEnum | null;
    isOnboardingFinished: boolean;
    isCertificatesSkipped: boolean;
    isVerified: boolean;
    isVerifiedNotificationDisplayed: boolean;
    githubUrl?: string | null;
    linkedInUrl?: string | null;
    phoneNumber?: string | null;
    cvId?: string | null;
    locationObject?: {
      __typename?: 'GoogleLocationObject';
      place_id: string;
      description: string;
    } | null;
    tags: Array<{ __typename?: 'ExpertTagType'; id: string; weight: number; name: string }>;
    languages?: Array<{
      __typename?: 'ExpertLanguageType';
      languageId: string;
      level: Types.LanguageLevelEnum;
    }> | null;
    workHistory: Array<{
      __typename?: 'ExpertWorkHistoryType';
      jobPositionName?: string | null;
      industry?: string | null;
      companyName?: string | null;
      dateEnd?: string | null;
      dateStart: string;
      isCurrentJob?: boolean | null;
      tagsIds?: Array<string> | null;
      description?: string | null;
    }>;
    certificates: Array<{
      __typename?: 'ExpertCertificateType';
      name: string;
      issuer?: string | null;
      description?: string | null;
      url?: string | null;
      identifier?: string | null;
      issueDate?: string | null;
    }>;
    fileUrls: {
      __typename?: 'ExpertFilesUrlsType';
      wordCloudUrl?: string | null;
      photoUrl?: string | null;
      avatarUrl?: string | null;
    };
    notificationConsents: {
      __typename?: 'ExpertNotificationConsentsType';
      OffersAndProfiles: boolean;
      ApplicationAndMeetings: boolean;
      News: boolean;
    };
    cv?: { __typename?: 'ExpertCvType'; id: string; name: string; size: number } | null;
  };
};

export const MyProfileDocument = gql`
  query myProfile {
    myProfile {
      expertId
      crmId
      firstName
      lastName
      email
      jobPositionName
      jobPositionId
      experienceYears
      createdAt
      rateDesired
      location
      workType
      location
      locationObject {
        place_id
        description
      }
      desiredIndustries
      desiredOfficeLocations
      desiredTechnicalSkills
      desiredFields
      seniority
      availability
      tags {
        id
        weight
        name
      }
      tagsSuperPowers
      languages {
        languageId
        level
      }
      workHistory {
        jobPositionName
        industry
        companyName
        dateEnd
        dateStart
        isCurrentJob
        tagsIds
        description
      }
      certificates {
        name
        issuer
        description
        url
        issuer
        identifier
        issueDate
      }
      jobPositionId
      mainTechnologyIds
      noticeLength
      manuallyAddedJobPositions
      manuallyAddedDesiredTechnicalSkills
      manuallyAddedMainTechnologies
      avatar
      fileUrls {
        wordCloudUrl
        photoUrl
        avatarUrl
      }
      isOnboardingFinished
      isCertificatesSkipped
      notificationConsents {
        OffersAndProfiles
        ApplicationAndMeetings
        News
      }
      isVerified
      isVerifiedNotificationDisplayed
      githubUrl
      linkedInUrl
      phoneNumber
      cvId
      cv {
        id
        name
        size
      }
    }
  }
`;

/**
 * __useMyProfileQuery__
 *
 * To run a query within a React component, call `useMyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<MyProfileQuery, MyProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyProfileQuery, MyProfileQueryVariables>(MyProfileDocument, options);
}
export function useMyProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyProfileQuery, MyProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyProfileQuery, MyProfileQueryVariables>(MyProfileDocument, options);
}
export type MyProfileQueryHookResult = ReturnType<typeof useMyProfileQuery>;
export type MyProfileLazyQueryHookResult = ReturnType<typeof useMyProfileLazyQuery>;
export type MyProfileQueryResult = Apollo.QueryResult<MyProfileQuery, MyProfileQueryVariables>;
export function refetchMyProfileQuery(variables?: MyProfileQueryVariables) {
  return { query: MyProfileDocument, variables: variables };
}
