import { MyProfileQuery } from 'hooks/api/myProfile/myProfile.generated';
import { prepareMainTechLabel } from 'hooks/useProfilePreview';
import i18n, { TFunction } from 'i18next';
import { ExpertAvailabilityEnum, PreviewProfileDto } from 'interfaces';
import { findElementByKey, getDayLabels, getMonthLabels, stringifyObjectArrays } from 'utils';
import { OnboardingDataQuery } from '../../../hooks/api/onboardingData/onboardingData.generated';
import { DefaultSkillLevelType } from '../../Skill/Skill';
import { OnboardingFormValues } from '../OnboardingForm';
import { stepsConfig } from '../stepsConfig';

type ExpertProfile = MyProfileQuery['myProfile'];

const previewWorkTypeDisc: Record<string, string> = {
  Office: 'Office',
  Remote: 'Partly Remote',
  Hybrid: 'Fully Remote',
};

const prepareAvailabilityLabel = (t: TFunction, availability: string, noticeLength?: number) => {
  if (availability === ExpertAvailabilityEnum.Immediately) {
    return t(`dictionaries:availability.Immediately`);
  }
  if (availability === ExpertAvailabilityEnum.Days) {
    return `${noticeLength} ${getDayLabels(t, noticeLength ?? 0)}`;
  }
  return `${noticeLength} ${getMonthLabels(t, noticeLength ?? 0)}`;
};

export const mapOnboardingFormValuesValuesToPreviewProfile = (
  {
    firstName,
    lastName,
    jobPositionId,
    tags,
    tagsSuperPowers,
    seniority,
    workType,
    location,
    rateDesired,
    certificates,
    workHistory,
    availability,
    languages,
    experienceYears,
    noticeLength,
    mainTechnologyIds,
  }: OnboardingFormValues,
  { tags: skills, jobPositions, languages: languagesData, industries }: OnboardingDataQuery,
  t: TFunction,
) => {
  const mappedValues: PreviewProfileDto = {
    firstName,
    lastName,
    location,
    seniority,
    rateDesired,
    experienceYears,
    availability: prepareAvailabilityLabel(t, availability, noticeLength),
    workLocation: workType ? previewWorkTypeDisc[workType] : '-',
    jobPosition: prepareMainTechLabel(skills, jobPositions, jobPositionId, mainTechnologyIds),
    languages:
      languages?.map(({ languageId, level }) => ({
        languageId,
        level,
        languageName: languagesData.find((lang) => lang.languageId === languageId)?.name ?? '-',
      })) ?? [],
    skillSet:
      tags?.map(({ tagId, weight }) => ({
        skillName: skills?.find(({ id }) => id === tagId)?.name ?? '-',
        isSuperPower: tagsSuperPowers?.includes(tagId),
        skillLevelNumber: weight || 1,
      })) ?? [],
    certificates:
      certificates?.map((cert) => ({
        name: cert.name,
        issuer: cert.issuer,
      })) ?? [],
    workHistory:
      workHistory?.map((wh) => ({
        ...wh,
        dateEnd: wh.dateEnd ?? '',
        industry: wh.industry ? findElementByKey(industries, 'industryId', wh.industry).name : '',
        tagsIds: wh.tagsIds.map((tagId) => ({
          name: skills.find(({ id }) => id === tagId)?.name ?? '-',
          id: tagId,
        })),
      })) ?? [],
  };

  return stringifyObjectArrays(mappedValues);
};

export const mapExpertProfileToValues = (
  profile?: ExpertProfile | null,
): Partial<OnboardingFormValues> => {
  const {
    firstName,
    lastName,
    jobPositionId,
    certificates,
    workHistory,
    experienceYears,
    languages,
    rateDesired,
    location,
    seniority,
    workType,
    tags,
    tagsSuperPowers,
    desiredIndustries,
    desiredTechnicalSkills,
    availability,
    mainTechnologyIds,
    noticeLength,
    manuallyAddedMainTechnologies,
    manuallyAddedDesiredTechnicalSkills,
    manuallyAddedJobPositions,
  } = profile ?? {};

  const mappedTags =
    tags?.map(({ id, weight }) => ({ tagId: id, weight: weight as DefaultSkillLevelType })) ?? [];

  const mappedLanguages = languages?.map(({ __typename, ...lang }) => lang) ?? [];

  const mappedWorkHistory =
    workHistory?.map(({ __typename, ...wh }) => ({
      jobPositionName: wh.jobPositionName || '',
      companyName: wh.companyName || undefined,
      dateStart: wh.dateStart,
      dateEnd: wh.dateEnd,
      isCurrentJob: wh.isCurrentJob ?? false,
      tagsIds: wh.tagsIds ?? [],
      industry: wh.industry || undefined,
      description: wh.description || '',
    })) ?? [];

  const mappedCertificates =
    certificates?.map(({ __typename, ...cert }) => ({
      name: cert.name,
      issuer: cert.issuer || undefined,
      imageUrl: '',
      url: cert.url || undefined,
      identifier: cert.identifier || undefined,
      issueDate: cert.issueDate || undefined,
    })) ?? [];

  return {
    firstName: firstName || undefined,
    lastName: lastName || undefined,
    jobPositionId: jobPositionId || undefined,
    seniority: seniority || undefined,
    experienceYears: experienceYears || 0,
    availability: availability || undefined,
    tags: mappedTags,
    tagsSuperPowers: tagsSuperPowers || [],
    languages: mappedLanguages || [],
    location: location || undefined,
    workType: workType || undefined,
    rateDesired: rateDesired || undefined,
    desiredIndustries: desiredIndustries || [],
    desiredTechnicalSkills: desiredTechnicalSkills || [],
    workHistory: mappedWorkHistory || [],
    certificates: mappedCertificates || [],
    mainTechnologyIds: mainTechnologyIds || [],
    noticeLength: noticeLength || undefined,
    manuallyAddedJobPositions: manuallyAddedJobPositions || [],
    manuallyAddedMainTechnologies: manuallyAddedMainTechnologies || [],
    manuallyAddedDesiredTechnicalSkills: manuallyAddedDesiredTechnicalSkills || [],
  };
};

export const getAllowedInitialStepIndex = (values: Partial<OnboardingFormValues>): number => {
  const validationResults = stepsConfig.map(({ validationSchema }) => {
    let isValid = true;
    try {
      if (validationSchema) {
        validationSchema(i18n.t).validateSync(values, {
          strict: true,
        });
      }
    } catch (err) {
      isValid = false;
    }
    return isValid;
  });

  const lastValidStepIndex = validationResults.findIndex((isValid) => !isValid);
  return lastValidStepIndex !== -1 ? lastValidStepIndex : stepsConfig.length - 1;
};

export const getWrapperBottomPadding = (isFooterVisible: boolean, isOverflowVisible: boolean) => {
  if (!isFooterVisible) {
    return 'pb-14';
  }
  if (isOverflowVisible) {
    return 'pb-64';
  }
  return 'pb-36';
};
