import { LoadingSpinner } from 'components/LoadingSpinner';
import { IconType } from 'icons';
import { ButtonHTMLAttributes, FC, MouseEvent, ReactNode } from 'react';
import { mergeClasses } from 'utils';

type ButtonVariant = 'outline' | 'primary' | 'white' | 'danger';
type ButtonSize = 'md' | 'sm';

interface ButtonProps {
  className?: string;
  label?: string | ReactNode;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  variant?: ButtonVariant;
  size?: ButtonSize;
  leftIcon?: IconType;
  leftIconClassName?: string;
  rightIcon?: IconType;
  rightIconClassName?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  labelClassName?: string;
}

const getVariantClassNames = (variant: ButtonVariant, isDisabled: boolean) => {
  switch (variant) {
    case 'primary':
      if (isDisabled) {
        return 'text-white bg-primary-500 bg-opacity-30 cursor-not-allowed';
      }
      return 'text-white bg-primary-500 hover:bg-primary-600 active:bg-primary-700';
    case 'white':
      if (isDisabled) {
        return 'text-white bg-primary-500 bg-opacity-60 cursor-not-allowed';
      }
      return 'bg-white hover:bg-gray-200 text-primary-500';
    case 'danger':
      if (isDisabled) {
        return 'text-white bg-red-500 bg-opacity-60 cursor-not-allowed';
      }
      return 'bg-red-500 hover:bg-opacity-80 active:bg-red-700 text-white';
    case 'outline':
    default:
      if (isDisabled) {
        return 'border-2 border-black opacity-20 cursor-not-allowed';
      }
      return 'border-2 border-black hover:bg-gray-100 active:bg-gray-200';
  }
};

const getSizeClassNames = (size: ButtonSize) => {
  switch (size) {
    case 'sm':
      return 'px-4 text-sm h-12';
    case 'md':
    default:
      return 'px-10 h-14';
  }
};

export const Button: FC<ButtonProps> = ({
  label,
  type = 'button',
  onClick,
  className = '',
  variant = 'primary',
  size = 'md',
  leftIcon: LeftIcon,
  leftIconClassName = '',
  rightIcon: RightIcon,
  rightIconClassName = '',
  isLoading = false,
  isDisabled = false,
  labelClassName,
}) => {
  return (
    <button
      className={mergeClasses(
        'rounded-lg font-semibold transition-all flex justify-center items-center',
        isLoading ? 'leading-none' : '',
        getSizeClassNames(size),
        getVariantClassNames(variant, isDisabled || isLoading),
        className,
      )}
      disabled={isDisabled || isLoading}
      onClick={(e) => {
        if (isDisabled || isLoading || !onClick) {
          return;
        }
        onClick(e);
      }}
      type={type}
    >
      {isLoading ? (
        <LoadingSpinner className="!border-t-gray-100 !border-r-gray-100" size={22} />
      ) : (
        <span className={mergeClasses('flex items-center', labelClassName)}>
          {LeftIcon && <LeftIcon className={leftIconClassName} />}
          {label}
          {RightIcon && <RightIcon className={rightIconClassName} />}
        </span>
      )}
    </button>
  );
};
