import { FC } from 'react';
import { JobBoardSearchBarMenuOption } from './JobBoardSearchBarMenu';
import { JobBoardSelectedOption } from './JobBoardSelectedOption';

interface JobBoardSelectedOptionsProps {
  selectedOptions: JobBoardSearchBarMenuOption[];
  onChange: (selectedOptions: JobBoardSearchBarMenuOption[]) => void;
}

export const JobBoardSelectedOptions: FC<JobBoardSelectedOptionsProps> = ({
  selectedOptions,
  onChange,
}) => {
  const handleChange = (valueToRemove: string) =>
    onChange(selectedOptions.filter(({ value }) => value !== valueToRemove));

  return (
    <ul className="flex flex-wrap gap-3 mt-2.5">
      {selectedOptions.map((opt) => (
        <JobBoardSelectedOption key={opt.value} {...opt} onRemove={handleChange} />
      ))}
    </ul>
  );
};
