import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
const defaultOptions = {} as const;
export type ExpertApplicationCountQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ExpertApplicationCountQuery = { __typename?: 'Query'; expertApplicationCount: number };

export const ExpertApplicationCountDocument = gql`
  query expertApplicationCount {
    expertApplicationCount
  }
`;

/**
 * __useExpertApplicationCountQuery__
 *
 * To run a query within a React component, call `useExpertApplicationCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpertApplicationCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpertApplicationCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useExpertApplicationCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ExpertApplicationCountQuery,
    ExpertApplicationCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExpertApplicationCountQuery, ExpertApplicationCountQueryVariables>(
    ExpertApplicationCountDocument,
    options,
  );
}
export function useExpertApplicationCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExpertApplicationCountQuery,
    ExpertApplicationCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExpertApplicationCountQuery, ExpertApplicationCountQueryVariables>(
    ExpertApplicationCountDocument,
    options,
  );
}
export type ExpertApplicationCountQueryHookResult = ReturnType<
  typeof useExpertApplicationCountQuery
>;
export type ExpertApplicationCountLazyQueryHookResult = ReturnType<
  typeof useExpertApplicationCountLazyQuery
>;
export type ExpertApplicationCountQueryResult = Apollo.QueryResult<
  ExpertApplicationCountQuery,
  ExpertApplicationCountQueryVariables
>;
export function refetchExpertApplicationCountQuery(
  variables?: ExpertApplicationCountQueryVariables,
) {
  return { query: ExpertApplicationCountDocument, variables: variables };
}
