import { HtmlRenderer } from 'components';
import { t } from 'i18next';

interface ExternalOfferDescriptionProps {
  description: string;
  className?: string;
}

export const ExternalOfferDescription = ({
  description,
  className,
}: ExternalOfferDescriptionProps) => {
  return (
    <div className={className}>
      <p className="text-base lg:text-2xl font-bold mb-6 lg:mb-8">
        {t('offers:externalOffer.offerDescriptionHeader')}
      </p>
      <HtmlRenderer className="text-[16px] lg:text-[18px] leading-[27px]" html={description} />
    </div>
  );
};
