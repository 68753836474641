import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
const defaultOptions = {} as const;
export type CheckExpertVerifyStatusQueryVariables = Types.Exact<{ [key: string]: never }>;

export type CheckExpertVerifyStatusQuery = {
  __typename?: 'Query';
  checkExpertVerifyStatus: boolean;
};

export const CheckExpertVerifyStatusDocument = gql`
  query checkExpertVerifyStatus {
    checkExpertVerifyStatus
  }
`;

/**
 * __useCheckExpertVerifyStatusQuery__
 *
 * To run a query within a React component, call `useCheckExpertVerifyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckExpertVerifyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckExpertVerifyStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckExpertVerifyStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CheckExpertVerifyStatusQuery,
    CheckExpertVerifyStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckExpertVerifyStatusQuery, CheckExpertVerifyStatusQueryVariables>(
    CheckExpertVerifyStatusDocument,
    options,
  );
}
export function useCheckExpertVerifyStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckExpertVerifyStatusQuery,
    CheckExpertVerifyStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckExpertVerifyStatusQuery, CheckExpertVerifyStatusQueryVariables>(
    CheckExpertVerifyStatusDocument,
    options,
  );
}
export type CheckExpertVerifyStatusQueryHookResult = ReturnType<
  typeof useCheckExpertVerifyStatusQuery
>;
export type CheckExpertVerifyStatusLazyQueryHookResult = ReturnType<
  typeof useCheckExpertVerifyStatusLazyQuery
>;
export type CheckExpertVerifyStatusQueryResult = Apollo.QueryResult<
  CheckExpertVerifyStatusQuery,
  CheckExpertVerifyStatusQueryVariables
>;
export function refetchCheckExpertVerifyStatusQuery(
  variables?: CheckExpertVerifyStatusQueryVariables,
) {
  return { query: CheckExpertVerifyStatusDocument, variables: variables };
}
