import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
const defaultOptions = {} as const;
export type StartPasswordResetMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;

export type StartPasswordResetMutation = { __typename?: 'Mutation'; startPasswordReset: string };

export const StartPasswordResetDocument = gql`
  mutation startPasswordReset($email: String!) {
    startPasswordReset(email: $email)
  }
`;
export type StartPasswordResetMutationFn = Apollo.MutationFunction<
  StartPasswordResetMutation,
  StartPasswordResetMutationVariables
>;

/**
 * __useStartPasswordResetMutation__
 *
 * To run a mutation, you first call `useStartPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startPasswordResetMutation, { data, loading, error }] = useStartPasswordResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useStartPasswordResetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartPasswordResetMutation,
    StartPasswordResetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StartPasswordResetMutation, StartPasswordResetMutationVariables>(
    StartPasswordResetDocument,
    options,
  );
}
export type StartPasswordResetMutationHookResult = ReturnType<typeof useStartPasswordResetMutation>;
export type StartPasswordResetMutationResult = Apollo.MutationResult<StartPasswordResetMutation>;
export type StartPasswordResetMutationOptions = Apollo.BaseMutationOptions<
  StartPasswordResetMutation,
  StartPasswordResetMutationVariables
>;
