import { CrossRoundIcon } from 'icons';
import { FC } from 'react';
import { JobBoardSearchBarMenuOption } from './JobBoardSearchBarMenu';

interface JobBoardSelectedOptionProps extends JobBoardSearchBarMenuOption {
  onRemove: (value: string) => void;
}

export const JobBoardSelectedOption: FC<JobBoardSelectedOptionProps> = ({
  label,
  value,
  onRemove,
}) => {
  return (
    <li className="flex items-center text-gray-350 border border-gray-350 rounded-xl space-x-2 py-2 pl-3 pr-2 font-medium text-sm break-all">
      <span>{label}</span>
      <button onClick={() => onRemove(value)}>
        <CrossRoundIcon className="w-4 h-4" />
      </button>
    </li>
  );
};
