import {
  Availability,
  DesiredFields,
  DesiredIndustries,
  DesiredOfficeLocations,
  DesiredTechnologies,
  Location,
  Salary,
  WorkType,
} from './papers';

export const PreferencesTab = () => {
  return (
    <div className="space-y-4 lg:space-y-6">
      <Location />
      <WorkType />
      <DesiredOfficeLocations />
      <Salary />
      <Availability />
      <DesiredTechnologies />
      <DesiredIndustries />
      <DesiredFields />
    </div>
  );
};
