import { JobBoardFiltersValues } from 'components';
import { TFunction } from 'i18next';
import { Shape } from 'interfaces';
import * as yup from 'yup';

const numberPositiveOrNull = (v?: number | null) => {
  if (!v) {
    return true;
  }
  return v > 0;
};

export const jobBoardFiltersValidationSchema = (t: TFunction) =>
  yup.object().shape<Shape<JobBoardFiltersValues>>({
    rateRangeFrom: yup
      .number()
      .nullable()
      .test(
        'positiveOrNull',
        t('forms:validationMessages.rateBePositive') || '',
        numberPositiveOrNull,
      )
      .max(
        yup.ref('rateRangeTo', {
          map: (value) => {
            return (!value || Number.isNaN(value) ? 1000 : value) as number;
          },
        }),
        t('forms:validationMessages.maxRateLessThanMin') || '',
      ),
    rateRangeTo: yup
      .number()
      .nullable()
      .test(
        'positiveOrNull',
        t('forms:validationMessages.mustBePositive', {
          fieldName: t('forms:labels.from'),
        }) || '',
        numberPositiveOrNull,
      )
      .max(
        1000,
        t('forms:validationMessages.maxRate', {
          fieldName: t('forms:labels.to') || '',
          max: 1000,
        }) || '',
      ),
  });
