import { FC, ReactNode } from 'react';
import { mergeClasses } from 'utils';

interface PaperProps {
  className?: string;
  title?: string | null;
  subtitle?: string | null;
  id?: string;
  children: ReactNode;
  titleClassName?: string;
  subtitleClassName?: string;
}

export const Paper: FC<PaperProps> = ({
  className = '',
  title,
  subtitle,
  children,
  id,
  titleClassName,
  subtitleClassName,
}) => {
  return (
    <div
      className={mergeClasses('pb-0 pt-4 px-4 xl:p-4 xl:p-8 rounded-2xl bg-white', className)}
      id={id}
    >
      {title && (
        <p
          className={mergeClasses(
            'text-base md:text-lg font-bold',
            !subtitle ? 'mb-6' : '',
            titleClassName,
          )}
        >
          {title}
        </p>
      )}
      {subtitle && (
        <p
          className={mergeClasses(
            'text-[13px] leading-[20px] md:text-sm text-gray-500 mt-0 md:mt-0.5 mb-4 md:mb-6',
            subtitleClassName,
          )}
        >
          {subtitle}
        </p>
      )}
      {children}
    </div>
  );
};
