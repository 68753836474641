import { applications } from './applications';
import { aria } from './aria';
import { auth } from './auth';
import { common } from './common';
import { connectisWebsite } from './connectisWebsite';
import { datePicker } from './datePicker';
import { dictionaries } from './dictionaries';
import { forms } from './forms';
import { home } from './home';
import { jobBoard } from './jobBoard';
import languages from './languages';
import { notifications } from './notifications';
import { offers } from './offers';
import { onboarding } from './onboarding';
import { pageTitles } from './pageTitles';
import { profile } from './profile';
import { settings } from './settings';
import { toasts } from './toasts';

const pl = {
  aria,
  auth,
  common,
  datePicker,
  dictionaries,
  forms,
  home,
  languages,
  offers,
  onboarding,
  pageTitles,
  profile,
  settings,
  toasts,
  notifications,
  applications,
  jobBoard,
  connectisWebsite,
};

export default pl;
