import { TFunction } from 'i18next';
import { PreviewProfileDto } from 'interfaces';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { concatUrls, filterTechnicalSkills, findElementByKey, stringifyObjectArrays } from 'utils';
import { OnboardingFormValues } from '../components/Onboarding/OnboardingForm';
import { EnvironmentConfig } from '../environment-config';
import { getAvailabilityString } from '../utils/getAvailabilityString';
import {
  OnboardingDataQuery,
  useOnboardingDataQuery,
} from './api/onboardingData/onboardingData.generated';

const previewWorkTypeDisc: Record<string, string> = {
  Office: 'Office',
  Remote: 'Fully Remote',
  Hybrid: 'Partly Remote',
};

type JobPositionsType = OnboardingDataQuery['jobPositions'];
type SkillsType = OnboardingDataQuery['tags'];

export const prepareMainTechLabel = (
  skills: SkillsType,
  jobPositions: JobPositionsType,
  jobPositionId: string,
  mainTechnologyIds: string[],
) => {
  const jobPositionLabel =
    jobPositions.find((jp) => jp.jobPositionId === jobPositionId)?.name ?? '-';
  const mainTechnologiesLabel = mainTechnologyIds
    .map((skillId) => findElementByKey(skills, 'id', skillId)?.name ?? '-')
    .join(' + ');

  return `${jobPositionLabel} ${mainTechnologiesLabel}`;
};

const mapOnboardingFormValuesValuesToPreviewProfile = (
  {
    firstName,
    lastName,
    jobPositionId,
    tags,
    tagsSuperPowers,
    seniority,
    workType,
    location,
    rateDesired,
    certificates,
    workHistory,
    availability,
    languages,
    experienceYears,
    noticeLength,
    mainTechnologyIds,
  }: OnboardingFormValues,
  { tags: skills, jobPositions, languages: languagesData, industries }: OnboardingDataQuery,
  t: TFunction,
) => {
  const technicalTags = filterTechnicalSkills(skills, tags);

  const mappedValues: PreviewProfileDto = {
    firstName,
    lastName,
    location,
    seniority,
    rateDesired,
    experienceYears,
    availability: getAvailabilityString(t, availability, noticeLength),
    workLocation: workType ? previewWorkTypeDisc[workType] : '-',
    jobPosition: prepareMainTechLabel(skills, jobPositions, jobPositionId, mainTechnologyIds),
    languages:
      languages?.map(({ languageId, level }) => ({
        languageId,
        level,
        languageName: languagesData.find((lang) => lang.languageId === languageId)?.name ?? '-',
      })) ?? [],
    skillSet:
      technicalTags?.map(({ tagId, weight }) => ({
        skillName: skills?.find(({ id }) => id === tagId)?.name ?? '-',
        isSuperPower: tagsSuperPowers?.includes(tagId),
        skillLevelNumber: weight || 1,
      })) ?? [],
    certificates:
      certificates?.map((cert) => ({
        name: cert.name,
        issuer: cert.issuer,
        issueDate: cert.issueDate,
      })) ?? [],
    workHistory:
      workHistory?.map((wh) => ({
        ...wh,
        dateEnd: wh.dateEnd ?? '',
        industry: wh.industry
          ? findElementByKey(industries, 'industryId', wh.industry)?.name ?? '-'
          : '',
        tagsIds: wh.tagsIds.map((tagId) => ({
          name: skills.find(({ id }) => id === tagId)?.name ?? '-',
          id: tagId,
        })),
      })) ?? [],
  };

  return stringifyObjectArrays(mappedValues);
};

export const useProfilePreview = (profileValues: OnboardingFormValues) => {
  const { t } = useTranslation();
  const [profileUrl, setProfileUrl] = useState<string | null>(null);
  const [isError, setIsError] = useState(false);

  const { data: onboardingData, loading } = useOnboardingDataQuery();

  useEffect(() => {
    if (!onboardingData || loading) return;
    try {
      const mappedValues = mapOnboardingFormValuesValuesToPreviewProfile(
        profileValues,
        onboardingData,
        t,
      );
      const expertProfileQuery = new URLSearchParams(mappedValues).toString();
      const url = concatUrls(
        [EnvironmentConfig.webProfileUrl ?? '', 'expert/preview/prepare'],
        expertProfileQuery,
      );
      if (isError) {
        setIsError(false);
      }
      setProfileUrl(url);
    } catch (err) {
      setIsError(true);
      console.error(err);
    }
  }, [onboardingData, profileValues]);

  return {
    profileUrl,
    isError,
  };
};
