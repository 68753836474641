import { ItemBadge, Paper, SearchInput } from 'components';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { findElementByKey, mapArrayIntoOptions, toggleStringOnArray } from 'utils';
import { useOnboardingDataQuery } from '../../../../../../hooks/api/onboardingData/onboardingData.generated';

interface DesiredTechnologiesValues {
  desiredTechnicalSkills: string[];
}

export const DesiredTechnologies = () => {
  const { t } = useTranslation();
  const { data } = useOnboardingDataQuery();
  const { tags } = data ?? { tags: [] };

  const {
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<DesiredTechnologiesValues>();

  const desiredTechnicalSkillsValue = watch('desiredTechnicalSkills') ?? [];

  const handleToggleTechnology = (technologyId: string) =>
    setValue(
      'desiredTechnicalSkills',
      toggleStringOnArray(desiredTechnicalSkillsValue, technologyId),
      { shouldValidate: true, shouldDirty: true },
    );

  const technologyOptions = mapArrayIntoOptions(
    tags.filter(({ id }) => !desiredTechnicalSkillsValue.includes(id)),
    'name',
    'id',
  );

  return (
    <Paper
      className="!p-4 xl:!p-8"
      id="desiredTechnicalSkills"
      subtitle={t('profile:papers.desiredTechnologies.subtitle')}
      subtitleClassName="!mb-4 lg:!mb-6"
      title={t('profile:papers.desiredTechnologies.title')}
      titleClassName="!mb-0 xl:!mb-0.5"
    >
      <SearchInput
        label={t('profile:papers.desiredTechnologies.desiredTechnologiesInputLabel') ?? ''}
        onChange={handleToggleTechnology}
        options={technologyOptions}
        placeholder={t('profile:papers.skills.technologiesInputPlaceholder') ?? ''}
      />
      <div className="flex gap-2 md:gap-4 mt-4 flex-wrap">
        {desiredTechnicalSkillsValue.map((technologyId) => {
          const technologyName = findElementByKey(tags, 'id', technologyId)?.name;
          return (
            <ItemBadge
              key={`desiredIndustry_${technologyId}`}
              label={technologyName}
              onRemove={() => handleToggleTechnology(technologyId)}
            />
          );
        })}
      </div>
    </Paper>
  );
};
