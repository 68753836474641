import { t } from 'i18next';
import { ExpertNotificationTypeEnum } from 'interfaces';

export const generateNotificationContent = (
  type?: string,
  matchScore?: number,
  jobOrderName?: string,
) => {
  switch (type) {
    case ExpertNotificationTypeEnum.JobOrderMatched:
      return {
        label: t('notifications:offers'),
        body: `${t('notifications:matchScoreNotification.offerAdded')} ${matchScore}% ${t(
          'notifications:matchScoreNotification.checkOffer',
        )}.`,
      };
    case ExpertNotificationTypeEnum.JobOrderApplicationStatusChange:
      return {
        label: t('notifications:application'),
        body: `${t(
          'notifications:applicationStatusChangeNotification.statusChanged',
        )}: ${jobOrderName}`,
      };
    case ExpertNotificationTypeEnum.JobOrderApplicationCreated:
      return {
        label: t('notifications:application'),
        body: `${t('notifications:applicationStatusChangeNotification.created')}: ${jobOrderName}`,
      };
    default:
      return {};
  }
};
