import { HtmlRenderer, ShowMore } from 'components';
import { JobOrderQueryType } from 'interfaces';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface JobOrderDetailsDescriptionProps {
  jobOrder: JobOrderQueryType | null;
}

export const JobOrderDetailsDescription: FC<JobOrderDetailsDescriptionProps> = ({ jobOrder }) => {
  const { t } = useTranslation();
  const { description } = jobOrder ?? {};

  return description ? (
    <div>
      <p className="font-bold mt-12 md:mt-[60px] mb-4 md:mb-8">{t('forms:labels.description')}</p>
      <ShowMore hiddenHeight={250}>
        <HtmlRenderer
          className="text-[16px] md:text-[18px] leading-[24px] md:leading-[27px]"
          html={description}
        />
      </ShowMore>
    </div>
  ) : null;
};
