import { Skill } from 'components';
import { t } from 'i18next';
import { JobOrderQueryType } from 'interfaces';
import { FC } from 'react';
import { mergeClasses } from 'utils';

interface SkillGridProps {
  title: string;
  className?: string;
  isPrimary?: boolean;
  isInModal?: boolean;
  skills: JobOrderQueryType['skillsMustHave'];
  noLevelSkill?: boolean;
  areTechnicalDetailsVisible?: boolean;
  matchingMustHaveSkills?: {
    __typename?: 'ExpertTagType' | undefined;
    id: string;
    weight: number;
    name: string;
  }[];
  matchingNiceToHaveSkills?: {
    __typename?: 'ExpertTagType' | undefined;
    id: string;
    weight: number;
    name: string;
  }[];
}

export const SkillGrid: FC<SkillGridProps> = ({
  title,
  skills,
  className = '',
  isPrimary = false,
  noLevelSkill,
  areTechnicalDetailsVisible,
  matchingMustHaveSkills,
  matchingNiceToHaveSkills,
  isInModal = false,
}) => {
  const isMatchingMustHaveSkill = skills.map((skill) =>
    matchingMustHaveSkills?.some((matchingSkill) => matchingSkill.id === skill.id),
  );

  const isMatchingNiceToHaveSkill = skills.map((skill) =>
    matchingNiceToHaveSkills?.some((matchingSkill) => matchingSkill.id === skill.id),
  );

  const expertDetails = new Map<string, { dotsNumber: number; levelLabel: string }>();

  if (matchingMustHaveSkills) {
    matchingMustHaveSkills.forEach((matchingSkill) => {
      expertDetails.set(matchingSkill.id, {
        dotsNumber: matchingSkill.weight,
        levelLabel: t(`dictionaries:skillLevels.${matchingSkill.weight}`),
      });
    });
  }

  return (
    <div className={mergeClasses('transition-all duration-300 ease-in-out', className)}>
      <p className="mb-6 font-medium transition-all duration-300 ease-in-out">{title}</p>
      <div
        className={mergeClasses(
          'grid gap-4 transition-all duration-300 ease-in-out md:grid-cols-[repeat(auto-fit,minmax(250px,max-content))]',
          isInModal ? 'md:gap-7' : 'md:gap-8',
        )}
      >
        {skills.map(({ id, name, weight }, index) => {
          const currentExpertDetails = expertDetails.get(id);

          return (
            <Skill
              key={id}
              areTechnicalDetailsVisible={areTechnicalDetailsVisible}
              className={mergeClasses(
                'border transition-all duration-300 ease-in-out',
                areTechnicalDetailsVisible && isMatchingNiceToHaveSkill[index]
                  ? '!border-primary-500'
                  : '',
              )}
              expertLevel={currentExpertDetails?.dotsNumber}
              expertLevelLabel={currentExpertDetails?.levelLabel}
              id={id}
              isMatchingMustHaveSkill={isMatchingMustHaveSkill[index]}
              isMatchingNiceToHaveSkill={isMatchingNiceToHaveSkill[index]}
              isReadOnly
              level={weight}
              levelDiscName="skillLevels"
              name={name}
              noLevelSkill={noLevelSkill}
              variant={isPrimary ? 'primary' : 'white'}
            />
          );
        })}
      </div>
    </div>
  );
};
