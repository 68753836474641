import { useReactiveVar } from '@apollo/client';
import { Button, Modal, ModalProps } from 'components';
import { useExpertAvatar } from 'hooks';
import { ExpertAvatarEnum } from 'interfaces';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { profileVar } from 'reactive-vars';
import { CHubIdSelectItem, PhotoSelectItem } from './subcomponents';

export const ChangeAvatarModal: FC<ModalProps> = ({ ...modalProps }) => {
  const { t } = useTranslation();
  const [activeUrl, setActiveUrl] = useState<string | null>(null);
  const [activeEnum, setActiveEnum] = useState<ExpertAvatarEnum>(ExpertAvatarEnum.Photo);
  const [originalUrl, setOriginalUrl] = useState<string | null>(null);

  const [temporaryPhotoUrl, setTemporaryImageUrl] = useState<string | null>(null);

  const [restrictionsError, setRestrictionsError] = useState(false);
  const { changeExpertAvatar, avatarChangeLoading } = useExpertAvatar();

  const { fileUrls, expertId } = useReactiveVar(profileVar) ?? {};
  const { wordCloudUrl, photoUrl, avatarUrl } = fileUrls ?? {};

  const handleSaveClick = async () => {
    if (!expertId || activeUrl === null) return;

    await changeExpertAvatar({
      expertId,
      avatar: activeEnum,
    });
    setOriginalUrl(activeUrl);
    modalProps.onClose?.();
  };

  const handleActiveUrlChange = (newUrl?: string | null) => {
    if (!newUrl && temporaryPhotoUrl) {
      setActiveUrl(temporaryPhotoUrl);
    }
    if (!newUrl) return;
    setActiveUrl(newUrl);
  };

  const handleRestrictionsErrorChange = (error: boolean) => {
    setRestrictionsError(error);
  };

  const handleModalClose = () => {
    if (activeUrl !== originalUrl) {
      setActiveUrl(originalUrl);
    }
    modalProps.onClose?.();
  };

  useEffect(() => {
    if (!temporaryPhotoUrl) return;
    handleActiveUrlChange(temporaryPhotoUrl);
  }, [temporaryPhotoUrl]);

  useEffect(() => {
    if (!avatarUrl) return;
    setActiveUrl(avatarUrl);
    setOriginalUrl(avatarUrl);
  }, [avatarUrl]);

  return (
    <Modal
      {...modalProps}
      className="w-full max-w-container-sm px-4 pt-6 pb-6 md:px-10 md:pt-10 h-[90svh] md:h-auto"
      footerClassName="bg-transparent !px-0 !mx-0 md:!px-6"
      iconButtonClassName="top-6 md:top-8 right-4 md:right-8 p-0"
      iconClassName="min-h-[16px] md:min-h-[24px] text-gray-400"
      modalFooter={
        <div>
          <Button
            className="w-full"
            isDisabled={activeUrl === photoUrl && restrictionsError}
            isLoading={avatarChangeLoading}
            label={t('common:actions.save')}
            onClick={() => handleSaveClick()}
          />
        </div>
      }
      onClose={handleModalClose}
    >
      <p className="text-[20px] md:text-[32px] leading-normal md:leading-[40px] font-bold">
        {t('profile:avatarChangeModal.title')}
      </p>
      <div className="grid md:grid-cols-2 gap-4 md:gap-6 mt-5 md:mt-6 md:mb-6 md:h-[404px]">
        <PhotoSelectItem
          expertId={expertId}
          imageUrl={photoUrl}
          isActive={
            (!!photoUrl && activeUrl === photoUrl) ||
            (activeUrl === temporaryPhotoUrl && activeUrl !== null)
          }
          onRestrictionsErrorChange={handleRestrictionsErrorChange}
          onSelect={handleActiveUrlChange}
          setAvatarEnum={setActiveEnum}
          setTemporaryImageUrl={setTemporaryImageUrl}
          temporaryPhotoUrl={temporaryPhotoUrl}
        />
        <CHubIdSelectItem
          imageUrl={wordCloudUrl}
          isActive={!!wordCloudUrl && activeUrl === wordCloudUrl}
          onRestrictionsErrorChange={handleRestrictionsErrorChange}
          onSelect={handleActiveUrlChange}
          setAvatarEnum={setActiveEnum}
        />
      </div>
    </Modal>
  );
};
