import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { JobOrderQueryType } from '../interfaces';

export const useYearsOfExperience = ({ jobOrder }: { jobOrder: JobOrderQueryType | null }) => {
  const { t } = useTranslation();

  const yearsOfExperienceInRole: { value: number; formatted: string } = useMemo(() => {
    const yearsInRole = jobOrder?.score.yearsInRequiredRole ?? 0;
    if (!yearsInRole) {
      return { value: 0, formatted: t('offers:offerListItem.noExperience') };
    }

    const formatted =
      yearsInRole === 0
        ? t('offers:offerListItem.noExperience')
        : dayjs().subtract(yearsInRole, 'year').fromNow(true);

    return { value: yearsInRole, formatted };
  }, [jobOrder]);

  const requiredYearsOfExperienceInRoleFormatted: string = useMemo(() => {
    const required = jobOrder?.experienceYears;

    if (!required) {
      return t('offers:offerListItem.noExperience');
    }

    return required === 0
      ? t('offers:offerListItem.noExperience')
      : dayjs().subtract(required, 'year').fromNow(true);
  }, [jobOrder]);

  return { yearsOfExperienceInRole, requiredYearsOfExperienceInRoleFormatted, t };
};
