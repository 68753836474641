import { Collapse, ItemBadge, LocationInput, Paper } from 'components';
import { WorkModeEnum } from 'interfaces';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'utils';

interface DesiredOfficeLocationsValues {
  desiredOfficeLocations: string[];
  workType: string;
}

export const DesiredOfficeLocations = () => {
  const { t } = useTranslation();

  const { setValue, watch } = useFormContext<DesiredOfficeLocationsValues>();

  const desiredOfficeLocationsValue = watch('desiredOfficeLocations') ?? [];
  const workTypeValue = watch('workType');

  const isCollapsed = workTypeValue !== WorkModeEnum.Office;

  const handleLocationAdd = (location: string) =>
    setValue('desiredOfficeLocations', [...desiredOfficeLocationsValue, location], {
      shouldValidate: true,
      shouldDirty: true,
    });

  const handleRemoveLocation = (placeIndex: number) =>
    setValue(
      'desiredOfficeLocations',
      desiredOfficeLocationsValue.filter((_, i) => i !== placeIndex),
      {
        shouldValidate: true,
        shouldDirty: true,
      },
    );

  return (
    <Collapse
      className={mergeClasses('transition-all', isCollapsed ? '!-mb-6' : '')}
      isCollapsed={isCollapsed}
    >
      <Paper
        className="!p-4 xl:!p-8"
        id="desiredOfficeLocations"
        subtitle={t('profile:papers.desiredOfficeLocations.subtitle')}
        title={t('profile:papers.desiredOfficeLocations.title')}
        titleClassName="!mb-4 xl:!mb-6"
      >
        <LocationInput
          className="max-w-sm"
          label={t('profile:papers.location.locationInputLabel') ?? ''}
          name="location"
          onChange={({ description }) => handleLocationAdd(description)}
          placeholder={t('forms:placeholders.locationInput') ?? ''}
          renderValue={false}
        />
        <div className="flex gap-4 mt-4 flex-wrap">
          {desiredOfficeLocationsValue.map((place, i) => {
            return (
              <ItemBadge
                // eslint-disable-next-line react/no-array-index-key
                key={`desiredOfficeLocation_${place}_${i}`}
                label={place}
                onRemove={() => handleRemoveLocation(i)}
              />
            );
          })}
        </div>
      </Paper>
    </Collapse>
  );
};
