import { FC, ReactNode } from 'react';
import { mergeClasses } from 'utils';

type Variant = 'default' | 'primary' | 'secondary' | 'offerExpired' | 'success';

interface StatusBadgeProps {
  label: string | ReactNode;
  variant?: Variant;
  className?: string;
}

const getVariantClassName = (variant: Variant) => {
  switch (variant) {
    case 'primary':
      return 'bg-primary-500 bg-opacity-10 text-primary-500';
    case 'secondary':
      return 'bg-secondary-500 bg-opacity-10 text-secondary-500';
    case 'offerExpired':
      return 'bg-white border border-gray-350 text-gray-350';
    case 'success':
      return 'bg-green-600 text-green-600 bg-opacity-10';
    case 'default':
      return 'bg-gray-200';
    default: {
      const unknownVariant: never = variant;
      throw Error(`Unknown StatusBadge variant: ${unknownVariant}`);
    }
  }
};

export const StatusBadge: FC<StatusBadgeProps> = ({
  label,
  variant = 'default',
  className = '',
}) => {
  return (
    <div
      className={mergeClasses(
        'py-1 px-2 rounded-md font-medium text-xs leading-[18px] h-fit w-min lg:whitespace-nowrap',
        getVariantClassName(variant),
        className,
      )}
    >
      {label}
    </div>
  );
};
