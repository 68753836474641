import { Animation, InputLabel, Radio, RangeSlider, Tooltip } from 'components';
import { ExpertTag, SeniorityLevelEnum } from 'interfaces';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOnboardingDataQuery } from '../../../hooks/api/onboardingData/onboardingData.generated';
import { JobPositionSelect } from '../subcomponents/JobPositionSelect';

export interface RoleStepValues {
  jobPositionId: string;
  mainTechnologyIds: string[];
  requiredMainTechnologyLength: number;
  experienceYears: number;
  seniority: SeniorityLevelEnum;
  tags: ExpertTag[];
  manuallyAddedJobPositions: string[];
  manuallyAddedMainTechnologies: string[];
  tagsSuperPowers: string[];
}

const highlightFunc: Record<SeniorityLevelEnum, (value: number) => boolean> = {
  [SeniorityLevelEnum.Junior]: (value) => value <= 2,
  [SeniorityLevelEnum.Regular]: (value) => value > 2 && value <= 6,
  [SeniorityLevelEnum.Senior]: (value) => value >= 7,
};

const getSeniorityOptions = (experienceYearsValue = 0, seniorityValue?: SeniorityLevelEnum) => {
  const options = Object.values(SeniorityLevelEnum).map((seniority) => ({
    label: seniority,
    value: seniority,
    isHighlighted: false,
  }));

  if (!seniorityValue && experienceYearsValue > 0) {
    const highlightedOption = options.find((option) =>
      highlightFunc[option.value](experienceYearsValue),
    );
    if (highlightedOption) {
      highlightedOption.isHighlighted = true;
    }
  }
  return options;
};

const sliderMarks = {
  0: '0',
  1: ' ',
  2: '2',
  3: ' ',
  4: '4',
  5: ' ',
  6: '6',
  7: ' ',
  8: '8',
  9: ' ',
  10: '10+',
};

export const RoleStep = () => {
  const { t } = useTranslation();

  const { data } = useOnboardingDataQuery();
  const { jobPositions } = data ?? { jobPositions: [] };

  const {
    setValue,
    formState: { errors, touchedFields },
    watch,
  } = useFormContext<RoleStepValues>();

  const jobPositionIdValue = watch('jobPositionId');
  const experienceYearsValue = watch('experienceYears');
  const seniorityValue = watch('seniority');
  const requiredMainTechnologyLengthValue = watch('requiredMainTechnologyLength');
  const mainTechnologyIdsValue = watch('mainTechnologyIds');

  const seniorityOptions = getSeniorityOptions(experienceYearsValue, seniorityValue);
  const isHighlighted = seniorityOptions.find((highlight) => highlight.isHighlighted);

  const hasMainTechnologyErrors = errors.mainTechnologyIds && touchedFields.mainTechnologyIds;
  const isMainTechnologiesValid =
    mainTechnologyIdsValue.length === requiredMainTechnologyLengthValue ||
    requiredMainTechnologyLengthValue === 0 ||
    (!!hasMainTechnologyErrors && hasMainTechnologyErrors.length > 0);

  const handleChange = (name: keyof RoleStepValues, value: string | number) =>
    setValue(name, value, { shouldValidate: true });

  return (
    <div className="w-full mt-8 md:mt-14 space-y-8 md:space-y-10 mb-40 md:mb-32 lg:mb-28">
      <JobPositionSelect jobPositions={jobPositions ?? []} />
      <Animation
        animationName="fade-in-up"
        isShowed={typeof jobPositionIdValue !== 'undefined' && isMainTechnologiesValid}
        scrollIntoViewOnEnter={!errors.mainTechnologyIds}
      >
        <RangeSlider
          className="w-[97%] md:w-[98%] lg:w-full"
          error={errors.experienceYears}
          label={t('onboarding:roleStep.experienceYearsLabel')}
          marks={sliderMarks}
          max={10}
          onChange={(value) => handleChange('experienceYears', value)}
          scrollIntoViewOnError
          value={experienceYearsValue}
        />
      </Animation>
      <Animation
        animationName="fade-in-up"
        isShowed={isMainTechnologiesValid}
        scrollIntoViewOnEnter={!!errors.mainTechnologyIds}
      >
        <InputLabel label={t('onboarding:roleStep.seniority.label')} />
        <Tooltip
          className="w-full"
          content={t('onboarding:roleStep.seniority.tooltip')}
          isHighlighted={isHighlighted?.label}
          name="seniority-level-tooltip"
          place="bottom"
        >
          <Radio
            columns={3}
            error={errors.seniority}
            name="seniority"
            onChange={(value) =>
              setValue('seniority', value as SeniorityLevelEnum, {
                shouldValidate: true,
              })
            }
            options={seniorityOptions}
            scrollIntoViewOnError
            value={seniorityValue}
          />
        </Tooltip>
      </Animation>
    </div>
  );
};
