import { PhoneNumberUtil } from 'google-libphonenumber';

interface Region {
  name: string;
  prefix: number;
}
const utilInstance = PhoneNumberUtil.getInstance();

export const getRegionsList = (): Region[] => {
  const regions = utilInstance.getSupportedRegions();

  return regions.map((region) => ({
    name: region,
    prefix: utilInstance.getCountryCodeForRegion(region),
  }));
};

const checkIfPlusIsIncluded = (phoneNumber: string) =>
  phoneNumber.charAt(0) === '+' ? phoneNumber : `+${phoneNumber}`;

export const parsePhoneNumber = (phoneNumber: string) =>
  utilInstance.parse(checkIfPlusIsIncluded(phoneNumber));

export const splitPhoneNumber = (phoneNumber: string) => {
  const instance = parsePhoneNumber(phoneNumber);
  return {
    prefix: instance.getCountryCode(),
    number: instance.getNationalNumber(),
  };
};

export const validatePhoneNumber = (phoneNumber: string): boolean => {
  try {
    const instance = parsePhoneNumber(phoneNumber);
    const prefix = instance.getCountryCode();
    if (!prefix) return false;
    const region = utilInstance.getRegionCodeForCountryCode(prefix);
    return utilInstance.isValidNumberForRegion(instance, region);
  } catch (err) {
    return false;
  }
};
