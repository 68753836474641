export const useScrollLock = () => {
  const lockPageScroll = () => {
    if (document.body.style.overflow === 'hidden') return;
    document.body.style.overflow = 'hidden';
  };

  const unlockPageScroll = () => {
    if (document.body.style.overflow === 'auto') return;
    document.body.style.overflow = 'auto';
  };

  return {
    lockPageScroll,
    unlockPageScroll,
  };
};
