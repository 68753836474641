import { useEffect } from 'react';

interface EventCallbacks {
  resize?: () => void;
  scroll?: () => void;
}

export const useWindowListener = ({ resize, scroll }: EventCallbacks) => {
  useEffect(() => {
    if (resize) {
      window.addEventListener('resize', resize);
      resize();
    }
    if (scroll) {
      window.addEventListener('scroll', scroll);
      scroll();
    }

    return () => {
      if (resize) {
        window.removeEventListener('resize', resize);
      }
      if (scroll) {
        window.removeEventListener('scroll', scroll);
      }
    };
  }, [resize, scroll]);
};
