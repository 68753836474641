import { TFunction } from 'i18next';
import { Shape } from 'interfaces';
import * as yup from 'yup';
import { ApplicationFormValues } from './ExternalOfferApplicationForm';

const maxFileSize = 10000000; // 10mb
const minFileSize = 1;
export const externalOfferApplicationFormValidationSchema = (t: TFunction) =>
  yup.object().shape<Shape<ApplicationFormValues>>({
    email: yup
      .string()
      .email(t('forms:validationMessages.wrongEmail') ?? '')
      .required(t('forms:validationMessages.requiredThisField') ?? ''),
    expertCv: yup
      .mixed()
      .required(t('forms:validationMessages.documentRequired') ?? '')
      .test(
        'fileSize',
        t('forms:validationMessages.fileTooBig') ?? '',
        (file) => file?.size <= maxFileSize,
      )
      .test(
        'fileSize',
        t('forms:validationMessages.fileIsEmpty') ?? '',
        (file) => file?.size >= minFileSize,
      ),
  });
