import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { default as ReactWordCloud, OptionsProp } from 'react-wordcloud';
import { useOnboardingDataQuery } from '../../hooks/api/onboardingData/onboardingData.generated';
import { ExpertProfile } from '../Profile/Profile';
import { getWordsFromProfile } from './utils';

interface WordCloudProps {
  profile: ExpertProfile;
  seed?: string;
}

export const WordCloud: FC<WordCloudProps> = ({ profile, seed }) => {
  const { t } = useTranslation();
  const { data: onboardingData } = useOnboardingDataQuery();

  const { tags, tagsSuperPowers, firstName } = profile;
  const superPowerWords = tags
    .filter(({ id }) => tagsSuperPowers?.includes(id))
    .map(({ name }) => name);
  const words = useMemo(
    () => getWordsFromProfile(t, profile, onboardingData),
    [t, profile, onboardingData],
  );
  const isHighlighted = (text: string) => text === firstName || superPowerWords.includes(text);

  const options: OptionsProp = {
    colors: ['#fff'],
    fontSizes: [24, 88],
    padding: 2.5,
    rotationAngles: [0, 0],
    rotations: 1,
    deterministic: !!seed,
    randomSeed: seed,
    enableTooltip: false,
    scale: 'linear',
    fontFamily: 'Galano Grotesque',
    textAttributes: {
      'font-weight': ({ text }) => (isHighlighted(text) ? 600 : 'normal'),
    },
  };

  return (
    <div className="flex items-center justify-center w-fit h-fit">
      <div className="scale-50 sm:scale-75 md:scale-100">
        <ReactWordCloud options={options} size={[600, 600]} words={words} />
      </div>
    </div>
  );
};
