import { Amplify } from 'aws-amplify';
import { EnvironmentConfig } from '../../environment-config';

Amplify.configure({
  region: EnvironmentConfig.cognito.region,
  userPoolId: EnvironmentConfig.cognito.userPoolId,
  userPoolWebClientId: EnvironmentConfig.cognito.clientId,
  mandatorySignIn: false,
  ssr: true,
  Auth: {
    storage: Amplify.Cache,
  },
  oauth: {
    domain: EnvironmentConfig.cognito.domain,
    scope: ['profile', 'email', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: EnvironmentConfig.cognito.redirectSignIn,
    redirectSignOut: EnvironmentConfig.cognito.redirectSignOut,
    responseType: 'code',
  },
});
