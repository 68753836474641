import { JsonObject } from 'type-fest';

export const auth: JsonObject = {
  welcomeToChub: 'Witaj na C_hub',
  signUpForm: {
    title: 'Zarejestruj się',
    subtitle: 'Przeglądaj wybrane dla Ciebie oferty.',
    signUpWithGoogle: 'Zarejestruj się z Google',
    browseWithGoogle: 'Przeglądaj z Google',
    signUpWithLinkedIn: 'Zarejestruj się z LinkedIn',
    browseWithLinkedIn: 'Przeglądaj z LinkedIn',
    signInLinkText: 'Masz już konto?',
    marketingAgreementText: 'Tak, chcę otrzymywać informacje marketingowe od C_hub.',
    termsAgreement: {
      byCreatingAccount: 'Tworząc konto użytkownika potwierdzasz, że akceptujesz ',
      regulations: 'regulamin',
      provisionOfServices:
        ' świadczenia usług przez C_hub sp. z o.o. i że zapoznałeś/aś się z naszą ',
      informationAboutPersonalData: 'informacją o danych osobowych',
    },
    legalAgreement: {
      byCreatingAccount: 'Klikając przycisk "Dalej” akceptujesz nasz ',
      terms: 'Regulamin',
      and: 'i',
      policy: 'Politykę Prywatności',
      accountRegistration:
        'Zakładając bezpłatne konto w Serwisie hub.connectis.pl, zawierasz z Connectis sp. z o. o. umowę o świadczenie usług Konta i związanych z nim funkcjonalności na zasadach określonych w Regulaminie.',
      administrativeInfo:
        'Administratorem Twoich danych osobowych jest: Connectis sp. z o. o. z siedzibą w Warszawie, ul. Chmielna 71, 00-801 Warszawa; adres e-mail: ',
      officeEmail: 'office.pl@connectis.pl',
      legalEmail: 'office.pl@connectis.pl',
      contactInfo: 'W razie pytań skontaktuj się z nami: ',
      dataInspectorCopy: 'lub naszym Inspektorem Ochrony Danych: ',
      dataInspectorEmail: 'iod@connectis.pl',
    },
    passwordRequirements: {
      title: 'Bezpieczne hasło powinno zawierać:',
      minLength: 'minimum 8 znaków',
      atLeastOneBigLetter: 'minimum jedną wielką literę',
      atLeastOneSmallLetter: 'minimum jedną małą literę',
      atLeastOneNumber: 'minimum jedną cyfrę',
      atLeastOneSpecial: 'minimum jeden znak specjalny',
    },
  },
  signInForm: {
    title: 'Zaloguj się',
    subtitle: 'Sprawdź, co dla Ciebie przygotowaliśmy',
    subtitleOTP: 'Przeglądaj wybrane dla Ciebie oferty.',
    signInWithGoogle: 'Zaloguj się z Google',
    browseWithGoogle: 'Przeglądaj z Google',
    signInWithLinkedIn: 'Zaloguj się z LinkedIn',
    browseWithLinkedIn: 'Przeglądaj z LinkedIn',
    forgotPassword: 'Nie pamiętam hasła',
    signUpLinkText: 'Nie masz jeszcze konta?',
  },
  passwordResetForm: {
    title: 'Nie pamiętasz hasła?',
    subtitle:
      'Na Twój adres email wyślemy wiadomość z linkiem, za pomocą którego ustawisz nowe hasło.',
    submitButton: 'Zresetuj hasło',
    successToast: 'Email z linkiem został wysłany na Twój adres email',
  },
  newPasswordForm: {
    title: 'Stwórz nowe hasło',
    subtitle:
      'Wpisz 6-cyfrowy kod, który wysłaliśmy na Twój adres e-mail oraz ustaw nowe, bezpieczne hasło',
    submitButton: 'Ustaw nowe hasło',
  },
  actions: {
    signIn: 'Zaloguj się',
    signUp: 'Zarejestruj się',
    findOutMore: 'Dowiedz się więcej',
    next: 'Dalej',
  },
  continueWithEmail: 'lub kontynuuj z e-mailem',
  pleaseVerifyEmail: {
    title: 'Potwierdź swój adres email',
    subtitle:
      'Wysłaliśmy do Ciebie wiadomość z linkiem aktywacyjnym! Sprawdź swoją skrzynkę i potwierdź swój adres email',
    resendEmail: 'Wyślij ponownie',
    successToast: 'Twój adres email został zweryfikowany pomyślnie!',
    resendSuccessToast: 'Email został wysłany pomyślnie!',
    wrongOrExpiredToken: 'Twój link wygasł lub jest nieprawidłowy!',
  },
  OTPcode: {
    typeCode: 'Wpisz kod',
    codeSubtitle:
      'Na Twój adres e-mail, wysłaliśmy kod. Wpisz go poniżej i sprawdź jakie oferty dla Ciebie przygotowaliśmy.',
    didNotReceiveCode: 'Nie otrzymałeś kodu?',
    resendCode: 'Wyślij kod ponownie',
    resendCodeInSeconds: 'Wyślij ponownie za {{seconds}} sekund',
    checkOffers: 'Sprawdź oferty',
    typePassword: 'Wpisz hasło',
    passwordSubtitle:
      'Wpisz hasło do swojego konta i sprawdź jakie oferty dla Ciebie przygotowaliśmy.',
  },
  errorScreen: {
    title: 'Ups... Coś poszło nie tak',
    subtitle: 'Aby sprawdzić oferty na C_hub, wróć na stronę główną i\u00A0spróbuj\u00A0ponownie.',
    startAgain: 'Rozpocznij ponownie',
  },
};
