import { ExternalOffer, Modal } from 'components';
import { useModalState } from 'hooks';
import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { usePublicOfferLazyQuery } from '../../../hooks/api/publicOffer/publicOffer.generated';

export const ExternalOfferModal = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const offerId = searchParams.get('offerId');
  const {
    modalState: { isOpen },
    openModal,
    closeModal,
  } = useModalState<undefined>();

  const [getPublicOffer, { data }] = usePublicOfferLazyQuery();
  const offer = data?.publicOffer;

  const handleGetOffer = async (id: string) => {
    await getPublicOffer({
      onCompleted: ({ publicOffer }) => {
        const isJobOrderChubVisible = publicOffer.jobOrder?.isChubVisible ?? false;
        if (!isJobOrderChubVisible) {
          return;
        }

        openModal(undefined);
      },
      variables: {
        offerId: id,
        shouldCreateEvent: false,
      },
    });
  };

  const handleClose = () => {
    searchParams.delete('offerId');
    navigate(`${pathname}?${searchParams.toString()}`, {
      replace: true,
    });
    closeModal();
  };

  useEffect(() => {
    if (!offerId) {
      return;
    }
    handleGetOffer(offerId);
  }, [offerId]);

  return (
    <Modal className="w-full" isOpen={isOpen} onClose={handleClose}>
      {offer && (
        <ExternalOffer isOnOnboarding offer={offer} onFinishOnboardingClick={handleClose} />
      )}
    </Modal>
  );
};
