import { MutableRefObject, useEffect, useState } from 'react';

export const useDynamicShowMoreLabel = (
  listRef: MutableRefObject<HTMLDivElement | null>,
  initialHeight: number,
) => {
  const [showMoreValue, setShowMoreValue] = useState(0);
  const [listFullHeight, setListFullHeight] = useState(0);

  const updateListHeight = () => {
    const listHeight = listRef.current?.offsetHeight || 0;
    setListFullHeight(listHeight);
  };

  useEffect(() => {
    updateListHeight();

    window.addEventListener('resize', updateListHeight);

    return () => {
      window.removeEventListener('resize', updateListHeight);
    };
  }, [listRef]);

  useEffect(() => {
    const hiddenHeight = Math.max(listFullHeight - initialHeight, 0);

    const listChildren = listRef.current?.children[0].children[0].childNodes;
    const listChildrenArray = listChildren && Array.from(listChildren);

    const childrenOffsetHeightsArray = listChildrenArray?.map((element) => {
      if (element instanceof HTMLElement) {
        return element.offsetHeight;
      }
      return 0;
    });

    const totalNumberOfChildren = childrenOffsetHeightsArray?.length;

    const totalChildrenHeight = childrenOffsetHeightsArray?.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0,
    );

    const totalChildrenHeightIncludingPadding =
      totalNumberOfChildren &&
      totalChildrenHeight &&
      (totalNumberOfChildren - 1) * 16 + totalChildrenHeight;

    const averageChildHeight =
      totalNumberOfChildren &&
      totalChildrenHeightIncludingPadding &&
      totalChildrenHeightIncludingPadding / totalNumberOfChildren;

    const numberInitiallyHiddenChildren =
      averageChildHeight &&
      averageChildHeight < hiddenHeight &&
      Math.ceil(hiddenHeight / averageChildHeight);

    if (numberInitiallyHiddenChildren) {
      setShowMoreValue(numberInitiallyHiddenChildren);
    }
  }, [listFullHeight, initialHeight, listRef]);

  const updateListHeightExternally = () => {
    updateListHeight();
  };

  return { showMoreValue, updateListHeightExternally };
};
