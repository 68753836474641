import { CircularProgress, SmallButton } from 'components';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';

export const ExternalOfferCTASection = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col lg:flex-row gap-x-6 gap-y-1 lg:gap-y-0">
      <div className="flex flex-row gap-3 lg:block items-center">
        <CircularProgress
          alternativeText="?"
          className="min-w-[90px] lg:min-w-[144px] w-[90px] lg:w-36 h-[90px] lg:h-36"
          staticColor="green"
          textClassName="text-black text-base lg:text-[28px] leading-[15px] font-bold"
          value={40}
        />
        <p className="text-base font-bold lg:hidden">{t('offers:externalOffer.matchingHeader')}</p>
      </div>
      <div className="flex flex-col w-full">
        <p className="text-xl font-bold hidden lg:block mb-1">
          {t('offers:externalOffer.matchingHeader')}
        </p>
        <div>
          <p className="font-medium lg:font-bold">{t('offers:externalOffer.matchingSubheader')}</p>
          <ul className="list-disc my-4 lg:my-6 ml-4">
            <li>{t('offers:externalOffer.bulletPoints:skillsAndRequirements')}</li>
            <li>{t('offers:externalOffer.bulletPoints:newestOffers')}</li>
            <li>{t('offers:externalOffer.bulletPoints:meetings')}</li>
          </ul>
        </div>
        <SmallButton
          label={t('offers:externalOffer.checkOffersLink')}
          labelClassName="text-base text-primary-500 font-medium underline ml-4"
          onClick={() => navigate('/OTP-sign-up')}
        />
      </div>
    </div>
  );
};
