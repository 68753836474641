import { ExpertAvatarEnum } from 'interfaces';
import { profileVar } from 'reactive-vars';
import { useUpdateExpertProfileMutation } from './api/updateExpertProfile/updateExpertProfile.generated';
import { useAsyncCallback } from './useAsyncCallback';

export const useExpertAvatar = () => {
  const [updateExpertProfileMutation] = useUpdateExpertProfileMutation();
  const [changeExpertAvatar, { loading: avatarChangeLoading }] = useAsyncCallback(
    async ({ expertId, avatar }: { expertId: string; avatar: ExpertAvatarEnum }) => {
      const res = await updateExpertProfileMutation({
        variables: {
          expertId,
          params: {
            avatar,
          },
        },
      });
      const { updateExpertProfile: updatedExpertProfile } = res.data ?? {};
      profileVar(updatedExpertProfile);
    },
  );

  return {
    avatarChangeLoading,
    changeExpertAvatar,
  };
};
