import dayjs from 'dayjs';

const WEEK_DAYS_COUNT = 7;

const getPrevMonthDays = (currentDate: string) => {
  const prevMonthDays: string[] = [];

  const prevDaysCount = (dayjs(currentDate).startOf('month').day() || 7) - 1;
  let currentDay = dayjs(currentDate).startOf('month').subtract(1, 'day');
  for (let i = 1; i <= prevDaysCount; i += 1) {
    prevMonthDays.push(currentDay.toISOString());
    currentDay = currentDay.subtract(1, 'day');
  }
  return prevMonthDays.reverse();
};

const getNextMonthDays = (currentDate: string) => {
  const nextMonthDays: string[] = [];

  const nextMonthDaysCount = WEEK_DAYS_COUNT - (dayjs(currentDate).endOf('month').day() || 7);

  let currentDay = dayjs(currentDate).add(1, 'month').startOf('month');
  for (let i = 1; i <= nextMonthDaysCount; i += 1) {
    nextMonthDays.push(currentDay.toISOString());
    currentDay = currentDay.add(1, 'day');
  }

  return nextMonthDays;
};

const getMonthDays = (currentDate: string) => {
  const monthDays: string[] = [];

  getPrevMonthDays(currentDate);

  const daysInMonthCount = dayjs(currentDate).daysInMonth();

  let currentDay = dayjs(currentDate).startOf('month');
  for (let i = 1; i <= daysInMonthCount; i += 1) {
    monthDays.push(currentDay.toISOString());
    currentDay = currentDay.add(1, 'day');
  }

  return monthDays;
};

export const getAllCalendarDays = (currentDate: string) => {
  const prevMonthDays = getPrevMonthDays(currentDate);
  const currentMonthDays = getMonthDays(currentDate);
  const nextMonthDays = getNextMonthDays(currentDate);

  return {
    prevMonthDays,
    currentMonthDays,
    nextMonthDays,
  };
};
