import dayjs from 'dayjs';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'utils';

interface DayItemProps {
  date: string;
  isDisabled?: boolean;
  isActive?: boolean;
  onClick?: (date: string) => void;
  onRemove: (date: string) => void;
  isDatesLimitExceeded?: boolean;
}

export const DayItem: FC<DayItemProps> = ({
  date,
  isDisabled,
  isActive,
  onClick,
  onRemove,
  isDatesLimitExceeded,
}) => {
  const { t } = useTranslation();

  const dayNumber = dayjs(date).format('D');
  const currentWeekday = dayjs(date).day();
  const isWeekend = currentWeekday === 0 || currentWeekday === 6;
  const isToday = dayjs().format('DD.MM.YYYY') === dayjs(date).format('DD.MM.YYYY');
  const isClickable = !isDisabled && !isWeekend;

  const handleClick = () => {
    if (isDatesLimitExceeded && isActive) {
      onRemove(date);
    }

    if (isActive) {
      return onRemove(date);
    }

    if (isClickable && onClick) {
      return onClick(date);
    }
  };

  return (
    <div
      className={mergeClasses(
        'relative w-11 md:w-14 2xl:w-16 h-11 md:h-14 2xl:h-16 rounded-lg xs:rounded-xl flex justify-center items-center text-base border transition-all select-none',
        isClickable
          ? 'text-gray-500 border-gray-200 hover:bg-gray-100 hover:bg-opacity-30'
          : 'text-gray-400 bg-gray-100 border-gray-100 cursor-default',
        isActive ? 'md:!text-white font-semibold !border-primary-500' : '',
        isToday ? '!border-primary-500' : 'md:!border-gray-200',
        isDisabled && isActive ? 'bg-white !cursor-pointer' : '',
      )}
      onClick={handleClick}
      role="button"
      tabIndex={0}
    >
      <span className="z-10 text-[13px] md:text-base">{dayNumber}</span>
      <div
        className={mergeClasses(
          'transition-all absolute rounded-full left-0 right-0 md:top-0 bottom-1 md:bottom-0 m-auto',
          isActive ? 'w-1 md:w-10 h-1 md:h-10' : 'w-0 h-0',
          isDisabled ? 'bg-gray-300' : 'bg-primary-500',
          isDisabled && isActive ? 'bg-primary-500' : '',
        )}
      />
      {isToday && (
        <div
          className={mergeClasses(
            'rounded-full absolute left-0 right-0 text-center mx-auto bottom-0 md:bottom-0.5 transition-all text-[6px] xs:text-[8px] md:text-[10px] font-semibold leading-[11px] md:leading-[18px] text-primary-500',
            isActive ? 'bottom-0 xs:bottom-[2px] md:!-bottom-[3px]' : '',
          )}
        >
          {t('datePicker:today')}
        </div>
      )}
    </div>
  );
};
