import { useTranslation } from 'react-i18next';

export const CalendarDatePickerCaption = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-2 md:pt-4">
      <div className="flex gap-2 items-center">
        <div className="w-4 h-4 rounded-full bg-gray-100" />
        <p className="text-[14px] lg:text-[16px] leading-[18px]">
          {t('datePicker:scheduleMeetingModal.unavailableDays')}
        </p>
      </div>
      <div className="flex gap-2 items-center">
        <div className="w-4 h-4 rounded-full border-[1px] border-gray-200" />
        <p className="text-[14px] lg:text-[16px] leading-[18px]">
          {t('datePicker:scheduleMeetingModal.availableDays')}
        </p>
      </div>
    </div>
  );
};
