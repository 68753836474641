export const mapObjIndexed =
  <O extends Record<string, any>, V, R>(fn: (val: V, key: keyof O) => R) =>
  (obj: O): Record<keyof O, R> => {
    const result: Record<keyof O, R> = {} as Record<keyof O, R>;
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        result[key as keyof O] = fn(obj[key], key as keyof O);
      }
    }
    return result;
  };
