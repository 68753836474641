import { MyApplications } from 'components';
import { PanelLayout } from 'layouts';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface MyApplicationsPageProps {
  isMobileMenuOpen?: boolean;
  onMobileMenuToggle: () => void;
}

export const MyApplicationsPage: FC<MyApplicationsPageProps> = ({
  isMobileMenuOpen,
  onMobileMenuToggle,
}) => {
  const { t } = useTranslation();

  return (
    <PanelLayout
      isMobileMenuOpen={isMobileMenuOpen}
      onMobileMenuToggle={onMobileMenuToggle}
      title={t('pageTitles:myApplications')}
    >
      <MyApplications />
    </PanelLayout>
  );
};
