import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
const defaultOptions = {} as const;
export type PublicJobBoardOffersQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.PublicJobBoardOffersFiltersInputType>;
}>;

export type PublicJobBoardOffersQuery = {
  __typename?: 'Query';
  publicJobBoardOffers: Array<{
    __typename?: 'PublicJobBoardOffersResultType';
    id: string;
    jobOrderId: string;
    jobOrder: {
      __typename?: 'TruncatedJobOrderType';
      role?: string | null;
      leadingTechnologyName?: string | null;
      rateRangeTo?: number | null;
      rateRangeFrom?: number | null;
      locationPreference?: string | null;
      workMode?: Types.WorkModeEnum | null;
      seniority?: Types.SeniorityLevelEnum | null;
      minEnglishLevel?: Types.LanguageLevelEnum | null;
      experienceYears?: number | null;
      skillsMustHave: Array<{ __typename?: 'ExpertTagType'; id: string; name: string }>;
      skillsNiceToHave: Array<{ __typename?: 'ExpertTagType'; id: string; name: string }>;
    };
  }>;
};

export const PublicJobBoardOffersDocument = gql`
  query publicJobBoardOffers($filters: PublicJobBoardOffersFiltersInputType) {
    publicJobBoardOffers(filters: $filters) {
      id
      jobOrderId
      jobOrder {
        role
        leadingTechnologyName
        rateRangeTo
        rateRangeFrom
        locationPreference
        workMode
        seniority
        minEnglishLevel
        skillsMustHave {
          id
          name
        }
        skillsNiceToHave {
          id
          name
        }
        experienceYears
      }
    }
  }
`;

/**
 * __usePublicJobBoardOffersQuery__
 *
 * To run a query within a React component, call `usePublicJobBoardOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicJobBoardOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicJobBoardOffersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePublicJobBoardOffersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PublicJobBoardOffersQuery,
    PublicJobBoardOffersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PublicJobBoardOffersQuery, PublicJobBoardOffersQueryVariables>(
    PublicJobBoardOffersDocument,
    options,
  );
}
export function usePublicJobBoardOffersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PublicJobBoardOffersQuery,
    PublicJobBoardOffersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PublicJobBoardOffersQuery, PublicJobBoardOffersQueryVariables>(
    PublicJobBoardOffersDocument,
    options,
  );
}
export type PublicJobBoardOffersQueryHookResult = ReturnType<typeof usePublicJobBoardOffersQuery>;
export type PublicJobBoardOffersLazyQueryHookResult = ReturnType<
  typeof usePublicJobBoardOffersLazyQuery
>;
export type PublicJobBoardOffersQueryResult = Apollo.QueryResult<
  PublicJobBoardOffersQuery,
  PublicJobBoardOffersQueryVariables
>;
export function refetchPublicJobBoardOffersQuery(variables?: PublicJobBoardOffersQueryVariables) {
  return { query: PublicJobBoardOffersDocument, variables: variables };
}
