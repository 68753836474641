import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
const defaultOptions = {} as const;
export type CompleteExpertCvUploadMutationVariables = Types.Exact<{
  fileId: Types.Scalars['String'];
}>;

export type CompleteExpertCvUploadMutation = {
  __typename?: 'Mutation';
  completeExpertCvUpload: string;
};

export const CompleteExpertCvUploadDocument = gql`
  mutation completeExpertCvUpload($fileId: String!) {
    completeExpertCvUpload(fileId: $fileId)
  }
`;
export type CompleteExpertCvUploadMutationFn = Apollo.MutationFunction<
  CompleteExpertCvUploadMutation,
  CompleteExpertCvUploadMutationVariables
>;

/**
 * __useCompleteExpertCvUploadMutation__
 *
 * To run a mutation, you first call `useCompleteExpertCvUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteExpertCvUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeExpertCvUploadMutation, { data, loading, error }] = useCompleteExpertCvUploadMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useCompleteExpertCvUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteExpertCvUploadMutation,
    CompleteExpertCvUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteExpertCvUploadMutation,
    CompleteExpertCvUploadMutationVariables
  >(CompleteExpertCvUploadDocument, options);
}
export type CompleteExpertCvUploadMutationHookResult = ReturnType<
  typeof useCompleteExpertCvUploadMutation
>;
export type CompleteExpertCvUploadMutationResult =
  Apollo.MutationResult<CompleteExpertCvUploadMutation>;
export type CompleteExpertCvUploadMutationOptions = Apollo.BaseMutationOptions<
  CompleteExpertCvUploadMutation,
  CompleteExpertCvUploadMutationVariables
>;
