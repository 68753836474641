import { AddItemButton } from 'components';
import { CheckboxGroup, SearchInput } from 'components/Inputs';
import { ItemBadge } from 'components/ItemBadge';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { filterTechnicalSkills, findElementByKey, unique } from 'utils';
import { useOnboardingDataQuery } from '../../../hooks/api/onboardingData/onboardingData.generated';
import { SkillsStepValues } from './SkillsStep';

export interface ExpectationsStepValues extends SkillsStepValues {
  desiredTechnicalSkills: string[];
  desiredIndustries: string[];
  manuallyAddedDesiredTechnicalSkills: string[];
}

export const ExpectationsStep = () => {
  const [availableTags, setAvailableTags] = useState<string[]>([]);
  const { t } = useTranslation();

  const { watch, setValue } = useFormContext<ExpectationsStepValues>();

  const { data } = useOnboardingDataQuery();
  const { tags: skills, industries } = data ?? { tags: [], industries: [] };

  const tagsIdsValue = watch('tags') ?? [];
  const desiredTechnicalSkillsValue = watch('desiredTechnicalSkills') ?? [];
  const desiredIndustriesValue = watch('desiredIndustries') ?? [];
  const manuallyAddedDesiredTechnicalSkillsValue =
    watch('manuallyAddedDesiredTechnicalSkills') ?? [];

  const technicalTagsValue = filterTechnicalSkills(skills, tagsIdsValue);

  const desiredTechnicalSkillsOptions = availableTags?.map((tagId) => ({
    label: skills.find(({ id }) => id === tagId)?.name ?? tagId,
    value: tagId,
  }));

  const otherSkillOptions = skills
    .filter(({ id }) => !availableTags.includes(id))
    .map(({ id, name }) => ({
      value: id,
      label: name,
    }));

  const handleTechnologyAdd = (tagId: string) => {
    setValue('manuallyAddedDesiredTechnicalSkills', [
      ...manuallyAddedDesiredTechnicalSkillsValue,
      tagId,
    ]);
    setValue(
      'desiredTechnicalSkills',
      [...desiredTechnicalSkillsValue.filter((item) => item !== tagId), tagId],
      {
        shouldValidate: true,
      },
    );
    setAvailableTags((prev) => [...prev, tagId]);
  };

  const handleIndustryAdd = (industryId: string) => {
    setValue('desiredIndustries', [...desiredIndustriesValue, industryId], {
      shouldValidate: true,
    });
  };

  const handleIndustryRemove = (industryId: string) => {
    setValue(
      'desiredIndustries',
      desiredIndustriesValue.filter((id) => id !== industryId),
      {
        shouldValidate: true,
      },
    );
  };

  const industryOptions = industries
    .filter(({ industryId }) => !desiredIndustriesValue.includes(industryId))
    .map(({ industryId, name }) => ({
      value: industryId,
      label: name,
    }));

  useEffect(() => {
    if (!technicalTagsValue) return;
    setAvailableTags(
      unique([
        ...technicalTagsValue.map(({ tagId }) => tagId),
        ...(manuallyAddedDesiredTechnicalSkillsValue ?? []),
      ]),
    );
  }, [technicalTagsValue, manuallyAddedDesiredTechnicalSkillsValue]);

  return (
    <div className="mt-6 md:mt-14 space-y-14 md:mb-12">
      <div>
        <CheckboxGroup
          columns={3}
          label={t('onboarding:expectationsStep.desiredTechnicalSkillsLabel') ?? ''}
          name="desiredTechnicalSkills"
          onChange={(value) =>
            setValue('desiredTechnicalSkills', value, {
              shouldValidate: true,
            })
          }
          options={desiredTechnicalSkillsOptions}
          value={desiredTechnicalSkillsValue}
        />
        <AddItemButton
          className="mt-6"
          label={t('onboarding:expectationsStep.addOtherTechnology')}
          onChange={handleTechnologyAdd}
          options={otherSkillOptions}
        />
      </div>
      <div className="!mt-6 md:!mt-14">
        <SearchInput
          label={t('onboarding:expectationsStep.desiredIndustriesLabel') ?? ''}
          menuPlacement="bottom"
          onChange={(value) => handleIndustryAdd(value as string)}
          options={industryOptions}
          placeholder={t('onboarding:expectationsStep.desiredIndustriesPlaceholder') ?? ''}
        />
        <div className="flex gap-4 mt-2 md:mt-4 flex-wrap mb-32 md:mb-20 lg:mb-0">
          {desiredIndustriesValue.map((industryId) => {
            const industryName = findElementByKey(industries, 'industryId', industryId)?.name;
            const translatedIndustryName = industryName || industryId;

            return (
              <ItemBadge
                key={`desiredIndustry_${industryId}`}
                label={translatedIndustryName}
                onRemove={() => handleIndustryRemove(industryId)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
