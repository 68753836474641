import { useReactiveVar } from '@apollo/client';
import { Button, Modal, ModalProps } from 'components';
import { useAsyncCallback } from 'hooks';
import { VerifiedSuccessIcon } from 'icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { profileVar } from 'reactive-vars';
import { useUpdateExpertProfileMutation } from '../../../hooks/api/updateExpertProfile/updateExpertProfile.generated';

export const ExpertVerifiedInfoModal: FC<ModalProps> = ({ ...modalProps }) => {
  const { t } = useTranslation();
  const [updateExpertProfile] = useUpdateExpertProfileMutation();
  const { expertId } = useReactiveVar(profileVar) ?? {};

  const handleSubmit = async () => {
    if (!expertId) return;
    const res = await updateExpertProfile({
      variables: {
        expertId,
        params: {
          isVerifiedNotificationDisplayed: true,
        },
      },
    });
    const { updateExpertProfile: updatedProfile } = res.data ?? {};
    profileVar(updatedProfile);
    modalProps?.onClose?.();
  };
  const [handleSubmitCallback, { loading }] = useAsyncCallback(handleSubmit);

  return (
    <Modal className="flex flex-col items-center w-full max-w-[1108px] py-16" {...modalProps}>
      <VerifiedSuccessIcon className="text-primary-500 h-12 mb-4" />
      <p className="text-[32px] font-bold leading-10 text-center mb-2">
        {t('profile:expertVerifiedInfoModal.title')}
        <span className="block text-primary-500">
          {t('profile:expertVerifiedInfoModal.titlePrimary')}
        </span>
      </p>
      <p className="text-lg text-center mb-12 max-w-[788px]">
        {t('profile:expertVerifiedInfoModal.description')}
      </p>
      <Button
        className="w-44"
        isLoading={loading}
        label={t('common:actions.ok')}
        onClick={() => handleSubmitCallback()}
      />
    </Modal>
  );
};
