import { useReactiveVar } from '@apollo/client';
import { useJobOrderModalsHandlers } from 'hooks';
import { JobOrderQueryType, JobOrdersQuerySortByEnum } from 'interfaces';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { profileVar } from 'reactive-vars';
import { findElementByKey, mapObjectInArray } from 'utils';
import { LoadingSpinner } from '../LoadingSpinner';
import { JobOrderDetailsModal, MeetingScheduleModal } from '../Modals';
import { JobOrderListItem } from './subcomponents';

interface OfferListProps {
  name: string;
  jobOrders: JobOrderQueryType[];
  loading?: boolean;
  sortBy?: JobOrdersQuerySortByEnum;
  isRecommendedOffers?: boolean;
}

export const JobOrderList: FC<OfferListProps> = ({
  jobOrders,
  loading,
  name,
  sortBy,
  isRecommendedOffers,
}) => {
  const { t } = useTranslation();
  const { expertId } = useReactiveVar(profileVar) ?? {};
  const [listItems, setListItems] = useState<JobOrderQueryType[]>([]);

  const {
    toggleSavedJobOrderMutation,
    closeDetailsModalAndClearJobOrderId,
    handleScheduleMeetingClick,
    applyForJobOrder,
    calendarModalState,
    detailsModalState,
    openDetailsModal,
    openCalendarModal,
    closeCalendarModal,
  } = useJobOrderModalsHandlers();

  const detailsJobOrder = useMemo(
    () =>
      detailsModalState.data
        ? findElementByKey(listItems, 'jobOrderId', detailsModalState.data) ?? null
        : null,
    [detailsModalState.data, listItems],
  );

  const meetingJobOrder = useMemo(
    () =>
      calendarModalState.data
        ? findElementByKey(listItems, 'jobOrderId', calendarModalState.data) ?? null
        : null,
    [calendarModalState.data],
  );

  const handleFavouriteClick = async (jobOrderId: string) => {
    if (!expertId) return;

    const { data } = await toggleSavedJobOrderMutation({
      variables: { expertId, jobOrderId },
    });
    const { toggleExpertSavedJobOrder } = data ?? { toggleExpertSavedJobOrder: [] };

    setListItems((prev) =>
      mapObjectInArray(
        prev,
        (jobOrder) => jobOrder.jobOrderId === jobOrderId,
        (jobOrder) => ({ ...jobOrder, isSaved: toggleExpertSavedJobOrder.includes(jobOrderId) }),
      ),
    );

    const isOfferSaved = listItems?.find((jobOrder) => jobOrder.jobOrderId === jobOrderId)?.isSaved;

    if (!isOfferSaved) {
      toast.success(t('offers:offerListItem.offerAddedToSaved'));
    }
  };

  const handleApplyForJob = async (meetingDates: string[]): Promise<boolean> => {
    const joId = calendarModalState.data;
    if (!joId) {
      return false;
    }

    const application = await applyForJobOrder(meetingDates);
    if (!application) {
      return false;
    }

    setListItems((prev) =>
      mapObjectInArray(
        prev,
        (jobOrder) => jobOrder.jobOrderId === joId,
        (jobOrder) => ({ ...jobOrder, application }),
      ),
    );
    return !!application;
  };

  useEffect(() => setListItems(jobOrders), [jobOrders]);

  return (
    <>
      <MeetingScheduleModal
        isOpen={calendarModalState.isOpen}
        jobOrder={meetingJobOrder}
        onClose={closeCalendarModal}
        onSendSuccess={handleApplyForJob}
      />
      <JobOrderDetailsModal
        isMeetingScheduled={!!detailsJobOrder?.application?.meetingDates.length}
        isOpen={detailsModalState.isOpen}
        jobOrder={detailsJobOrder}
        onClose={closeDetailsModalAndClearJobOrderId}
        onFavouriteClick={handleFavouriteClick}
        onScheduleMeetingClick={handleScheduleMeetingClick}
      />
      {loading ? (
        <div className="flex justify-center py-16">
          <LoadingSpinner />
        </div>
      ) : (
        <ul>
          {listItems.length ? (
            <TransitionGroup className="space-y-4">
              {listItems.map((jobOrder) => (
                <CSSTransition
                  key={`${name}-${jobOrder.jobOrderId}`}
                  classNames="fade-in-right"
                  timeout={300}
                >
                  <JobOrderListItem
                    {...jobOrder}
                    key={`${name}-${jobOrder.jobOrderId}`}
                    onDetailsClick={openDetailsModal}
                    onFavouriteClick={handleFavouriteClick}
                    onScheduleMeetingClick={openCalendarModal}
                  />
                </CSSTransition>
              ))}
            </TransitionGroup>
          ) : (
            <p className="text-sm font-medium text-gray-400 whitespace-pre-line text-center p-6">
              {isRecommendedOffers
                ? t('offers:notFoundPlaceholders.recommendedOffers')
                : t('offers:notFoundPlaceholders.jobOrders')}
            </p>
          )}
        </ul>
      )}
    </>
  );
};
