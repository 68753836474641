import { IconType } from 'icons';
import { FC } from 'react';
import { mergeClasses } from 'utils';

interface LinkButtonProps {
  label?: string | null;
  onClick?: () => void;
  className?: string;
  icon?: IconType;
  underline?: boolean;
  iconClassName?: string;
}

export const LinkButton: FC<LinkButtonProps> = ({
  label,
  onClick,
  className = '',
  icon: Icon,
  underline = true,
  iconClassName,
}) => {
  return (
    <button
      className={mergeClasses(
        'font-medium text-primary-500 hover:text-primary-600 active:text-primary-700',
        underline ? 'underline' : '',
        className,
      )}
      onClick={onClick}
      type="button"
    >
      <span className="flex items-center">
        {Icon && <Icon className={mergeClasses('w-4 h-4 mr-2', iconClassName)} />}
        {label}
      </span>
    </button>
  );
};
