import { Dispatch, SetStateAction } from 'react';
import { mergeClasses } from 'utils';
import { SkillVariant } from '../Skill';

interface SkillDotsProps<TSkillLevel> {
  level?: TSkillLevel;
  onDotClick?: (level: TSkillLevel) => void;
  dotsNumber?: number;
  isReadOnly?: boolean;
  hoveredItemIndex?: TSkillLevel;
  setHoveredItemIndex?: Dispatch<SetStateAction<TSkillLevel>>;
  variant?: SkillVariant;
  dotClassName?: string;
  activeDotClassName?: string;
}

const getVariantClassNames = (
  isActive: boolean,
  isPrimary: boolean,
  activeDotClassName: string,
) => {
  if (isActive) {
    return mergeClasses(
      'bg-primary-500 outline-1 outline-primary-500 transition-all duration-300"',
      activeDotClassName,
    );
  }
  if (isPrimary) {
    return 'bg-gray-50 outline-1 outline-gray-50 transition-all duration-300"';
  }
  return 'bg-gray-50 outline-1 outline-gray-50 transition-all duration-300"';
};

export const SkillDots = function <TSkillLevel>({
  level,
  dotsNumber = 5,
  onDotClick,
  isReadOnly = false,
  hoveredItemIndex,
  setHoveredItemIndex,
  variant = 'white',
  dotClassName,
  activeDotClassName = '',
}: SkillDotsProps<TSkillLevel>) {
  const dots = Array.from(Array(dotsNumber).keys()).map((n) => n + 1) as TSkillLevel[];
  const isPrimary = variant === 'primary';

  return (
    <div className="flex items-center mb-2 transition-all duration-300">
      {dots.map((index) => {
        const isActive = !level ? false : level >= (index ?? 0);
        const isHovered = hoveredItemIndex && index ? hoveredItemIndex >= index : false;
        return (
          <div
            key={`dot_${index}`}
            className="pr-1 group"
            onClick={(e) => {
              if (onDotClick) {
                e.stopPropagation();
                onDotClick(index);
              }
            }}
            onMouseEnter={() => !isReadOnly && setHoveredItemIndex?.(index)}
            onMouseLeave={() => !isReadOnly && setHoveredItemIndex?.(null as TSkillLevel)}
          >
            <div
              className={mergeClasses(
                'w-2.5 h-2.5 rounded-full transition-all sm:w-2 sm:h-2 duration-300',
                getVariantClassNames(isActive && !hoveredItemIndex, isPrimary, activeDotClassName),
                onDotClick && !isReadOnly ? 'group-hover:bg-primary-400 cursor-pointer' : '',
                isHovered ? '!bg-primary-500' : '',
                dotClassName,
              )}
            />
          </div>
        );
      })}
    </div>
  );
};
