import { convertFromHTML, convertToHTML } from 'draft-convert';
import { Editor, EditorState, RichUtils } from 'draft-js';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { mergeClasses } from 'utils';
import { InputLabel } from '../../InputLabel';
import { ErrorMessage } from '../ErrorMessage';
import './RichTextEditor.css';
import { RichTextEditorHeader } from './subcomponents';

export const RICH_TEXT_EDITOR_EMPTY_BLOCK = '<p></p>';

export enum RichTextEditorInlineStyleEnum {
  BOLD = 'BOLD',
  ITALIC = 'ITALIC',
  UNDERLINE = 'UNDERLINE',
}

export enum RichTextEditorBlockStyleEnum {
  UNORDERED_LIST = 'unordered-list-item',
}

interface RichTextEditorProps {
  initialValue?: string;
  error?: FieldError;
  onChange: (value: string) => void;
  limit?: number;
  className?: string;
  wrapperClassName?: string;
  placeholder?: string;
  label?: string;
}

export const RichTextEditor = forwardRef<HTMLDivElement, RichTextEditorProps>(
  (
    {
      onChange,
      initialValue,
      error,
      limit,
      className = '',
      placeholder,
      label,
      wrapperClassName = '',
    },
    ref,
  ) => {
    const editorRef = useRef<Editor | null>(null);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const handleChange = (newEditorState: EditorState) => setEditorState(newEditorState);

    const handleInlineStyle = (styleType: RichTextEditorInlineStyleEnum) => {
      handleChange(RichUtils.toggleInlineStyle(editorState, styleType));
    };

    const handleBlockStyle = (styleType: RichTextEditorBlockStyleEnum) => {
      handleChange(RichUtils.toggleBlockType(editorState, styleType));
    };

    const handleFocus = () => editorRef.current?.focus();

    useEffect(() => {
      if (!onChange) {
        return;
      }

      const value = convertToHTML(editorState.getCurrentContent());
      onChange(value === RICH_TEXT_EDITOR_EMPTY_BLOCK ? '' : value);
    }, [editorState]);

    useEffect(() => {
      if (!initialValue || initialValue === RICH_TEXT_EDITOR_EMPTY_BLOCK) {
        return;
      }

      const initialState = convertFromHTML(initialValue);
      setEditorState(EditorState.createWithContent(initialState));
    }, [initialValue]);

    return (
      <div className={wrapperClassName}>
        {label && <InputLabel label={label} />}
        <div
          className={mergeClasses(
            'relative border rounded-md overflow-hidden max-h-[450px] overflow-y-auto',
            error ? 'border-red-500' : 'border-gray-200',
            className,
          )}
          onClick={handleFocus}
        >
          <RichTextEditorHeader
            editorState={editorState}
            limit={limit}
            onBold={() => handleInlineStyle(RichTextEditorInlineStyleEnum.BOLD)}
            onItalic={() => handleInlineStyle(RichTextEditorInlineStyleEnum.ITALIC)}
            onList={() => handleBlockStyle(RichTextEditorBlockStyleEnum.UNORDERED_LIST)}
            onUnderline={() => handleInlineStyle(RichTextEditorInlineStyleEnum.UNDERLINE)}
          />
          <div ref={ref} className="px-6 py-4 rich-text-editor min-h-[24px] cursor-text">
            <Editor
              ref={editorRef}
              editorState={editorState}
              onChange={handleChange}
              placeholder={placeholder}
            />
          </div>
        </div>
        <ErrorMessage error={error} />
      </div>
    );
  },
);

RichTextEditor.displayName = 'RichTextEditor';
