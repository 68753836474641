import { JobOrderQueryType } from 'interfaces';
import { FC } from 'react';
import { mergeClasses } from 'utils';
import { Skill } from './subcomponents';

interface ExternalOfferSkillsProps {
  title: string;
  skills: JobOrderQueryType['skillsMustHave'];
  className?: string;
  isPrimary?: boolean;
  hideSkillLevel?: boolean;
}

export const ExternalOfferSkills: FC<ExternalOfferSkillsProps> = ({
  title,
  skills,
  isPrimary = false,
  hideSkillLevel = false,
  className = '',
}) => {
  return (
    <div className={mergeClasses('', className)}>
      <p className="mb-6 font-medium">{title}</p>
      <div className="flex flex-wrap gap-4">
        {skills.map(({ id, name, weight }) => (
          <Skill
            key={id}
            hideSkillLevel={hideSkillLevel}
            isPrimary={isPrimary}
            level={weight}
            name={name}
          />
        ))}
      </div>
    </div>
  );
};
