import { useSitemap, useUrlTabs } from 'hooks';
import { useTranslation } from 'react-i18next';
import { mapArrayIntoOptions } from 'utils';
import useMobile from '../../hooks/useMobile';
import { Select } from '../Inputs';
import { JobOrderModalController } from '../JobOrderModalController';
import { TabsHeader } from '../Tabs';
import { AllOffersTab, SavedOffersTab } from './tabs';

export enum OffersTabEnum {
  All = 'all',
  Saved = 'saved',
}

const renderTab = (tab: OffersTabEnum) => {
  switch (tab) {
    case OffersTabEnum.All:
      return <AllOffersTab />;
    case OffersTabEnum.Saved:
      return <SavedOffersTab />;
    default: {
      const unknownTab: OffersTabEnum = tab;
      console.warn('Unknown tab:', unknownTab);
    }
  }
};

export const Offers = () => {
  const { t } = useTranslation();
  const sitemap = useSitemap();
  const { isTablet } = useMobile();

  const { handleTabChange, currentTab } = useUrlTabs<OffersTabEnum>(
    OffersTabEnum.All,
    sitemap.offers,
  );

  const tabOptions = mapArrayIntoOptions(
    Object.values(OffersTabEnum),
    (name) => `${isTablet ? `${t(`pageTitles:offers`)}: ` : ''}${t(`offers:tabs.${name}`)}`,
  );

  return (
    <div className="relative w-screen md:w-auto pb-10">
      <div className="w-screen md:w-auto lg:pb-6">
        <div className="hidden lg:block w-screen md:w-[calc(100%-80px)] absolute -top-24 left-10 mt-0">
          <TabsHeader
            className="!text-xs md:!text-sm"
            onChange={handleTabChange}
            options={tabOptions}
            value={currentTab}
          />
        </div>
      </div>
      <JobOrderModalController />
      <div className="px-4 md:px-0 mx-0 md:mx-10 mt-6 pb-24 lg:pb-14 space-y-6 w-screen md:w-[calc(100vw-80px)] lg:w-[calc(100vw-24rem)] xl:w-auto">
        <Select
          className="block lg:hidden"
          onChange={handleTabChange}
          options={tabOptions}
          placeholder={`${t('offers:tabs.all')}`}
          value={currentTab}
        />
        {renderTab(currentTab)}
      </div>
    </div>
  );
};
