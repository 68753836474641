import { OtherOffers, PublicOfferAppliedOffers, RecommendedOffers } from './papers';

export const AllOffersTab = () => {
  return (
    <div className="space-y-6 lg:relative lg:-top-20 xl:-top-24">
      <PublicOfferAppliedOffers />
      <RecommendedOffers />
      <OtherOffers />
    </div>
  );
};
