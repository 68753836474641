import { FC, ReactNode } from 'react';
import { mergeClasses } from 'utils';

interface MarqueeProps {
  className?: string;
  children: ReactNode[];
}

export const Marquee: FC<MarqueeProps> = ({ className = '', children }) => {
  return (
    <div className={mergeClasses('overflow-hidden relative', className)}>
      <div className="flex items-center w-[200%] animate-moveRight absolute hover:pause">
        <span className="overflow-hidden float-left w-1/2">
          <div className="flex items-center space-x-6">{children}</div>
        </span>
        <span className="overflow-hidden float-left w-1/2">
          <div className="flex items-center space-x-6">{children}</div>
        </span>
      </div>
    </div>
  );
};
