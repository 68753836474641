import { JobOrderQueryType } from 'interfaces';
import { useEffect } from 'react';
import { ActiveApplicationJobOrdersDocument } from './api/activeApplicationJobOrders/activeApplicationJobOrders.generated';
import { EndedApplicationJobOrdersDocument } from './api/endedApplicationJobOrders/endedApplicationJobOrders.generated';
import { ExpertApplicationCountDocument } from './api/expertApplicationCount/expertApplicationCount.generated';
import { useMarkExpertApplicationsAsViewedMutation } from './api/markExpertApplicationsAsViewed/markExpertApplicationsAsViewed.generated';

export const useViewedJobOrderApplications = (jobOrders: JobOrderQueryType[]) => {
  const [markExpertApplicationsAsViewed] = useMarkExpertApplicationsAsViewedMutation({
    refetchQueries: [
      {
        query: ExpertApplicationCountDocument,
      },
      {
        query: ActiveApplicationJobOrdersDocument,
      },
      {
        query: EndedApplicationJobOrdersDocument,
      },
    ],
  });

  useEffect(() => {
    if (!jobOrders.length) {
      return;
    }

    const jobOrderIds = jobOrders
      .filter(({ application }) => !application?.isViewedByExpert)
      .map(({ jobOrderId }) => jobOrderId);

    if (!jobOrderIds.length) {
      return;
    }

    markExpertApplicationsAsViewed({
      variables: {
        jobOrderIds,
      },
    });
  }, [jobOrders]);
};
