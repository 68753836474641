import { FC } from 'react';
import { Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom';

interface ConditionalRouteProps {
  condition: () => boolean;
  redirectPath: string;
  keepSearchParams?: boolean;
  keepPrevPath?: boolean;
}

export const ConditionalRedirectRoute: FC<ConditionalRouteProps> = ({
  condition,
  redirectPath,
  keepSearchParams = false,
  keepPrevPath = false,
}) => {
  const [searchParams] = useSearchParams();
  const { pathname: pathnameBeforeRedirect } = useLocation();

  if (keepPrevPath && pathnameBeforeRedirect !== '/') {
    searchParams.append('pageRedirect', pathnameBeforeRedirect);
  }
  const searchParamsString = searchParams.toString();

  if (!condition()) {
    return <Navigate to={`${redirectPath}${keepSearchParams ? `?${searchParamsString}` : ''}`} />;
  }
  return <Outlet />;
};
