import { TimeslotsData } from 'components/Modals/MeetingScheduleModal/MeetingScheduleModal';
import dayjs from 'dayjs';

export const filterPastTimeslots = (timeslotsData: TimeslotsData) => {
  return Object.keys(timeslotsData)
    .filter((date) => dayjs(date).isSameOrAfter(dayjs().startOf('day')))
    .reduce((obj: Record<string, (string | null)[]>, key: string) => {
      obj[key] = timeslotsData[key];
      return obj;
    }, {});
};
