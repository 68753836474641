import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
const defaultOptions = {} as const;
export type ApplyForPublicOfferMutationVariables = Types.Exact<{
  params: Types.ApplyForPublicOfferInputType;
}>;

export type ApplyForPublicOfferMutation = {
  __typename?: 'Mutation';
  applyForPublicOffer: { __typename?: 'PublicOfferApplicationType'; id: string };
};

export const ApplyForPublicOfferDocument = gql`
  mutation applyForPublicOffer($params: ApplyForPublicOfferInputType!) {
    applyForPublicOffer(params: $params) {
      id
    }
  }
`;
export type ApplyForPublicOfferMutationFn = Apollo.MutationFunction<
  ApplyForPublicOfferMutation,
  ApplyForPublicOfferMutationVariables
>;

/**
 * __useApplyForPublicOfferMutation__
 *
 * To run a mutation, you first call `useApplyForPublicOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyForPublicOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyForPublicOfferMutation, { data, loading, error }] = useApplyForPublicOfferMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useApplyForPublicOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApplyForPublicOfferMutation,
    ApplyForPublicOfferMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ApplyForPublicOfferMutation, ApplyForPublicOfferMutationVariables>(
    ApplyForPublicOfferDocument,
    options,
  );
}
export type ApplyForPublicOfferMutationHookResult = ReturnType<
  typeof useApplyForPublicOfferMutation
>;
export type ApplyForPublicOfferMutationResult = Apollo.MutationResult<ApplyForPublicOfferMutation>;
export type ApplyForPublicOfferMutationOptions = Apollo.BaseMutationOptions<
  ApplyForPublicOfferMutation,
  ApplyForPublicOfferMutationVariables
>;
