import { yupResolver } from '@hookform/resolvers/yup';
import { FileInput, TextField } from 'components';
import { RoundedButton } from 'components/Buttons';
import { useFeatureFlag, useFileUpload, useRecaptcha } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { allowedCvFileTypes } from 'utils';
import { useApplyForPublicOfferMutation } from '../../../../hooks/api/applyForPublicOffer/applyForPublicOffer.generated';
import { ExternalOfferLegalNotes } from '../ExternalOfferLegalNotes';
import { externalOfferApplicationFormValidationSchema } from './validationSchema';

export interface ApplicationFormValues {
  email: string;
  expertCv: File | null;
}

interface ExternalOfferApplicationFormProps {
  offerId: string;
  contextContactEmail?: string | null;
  onApplySuccess: (expertId: string) => void;
  onEmailSubmit: (email: string) => Promise<void>;
}

const recaptchaSignCredentialsAction = 'SIGN_EXPERT_CV_UPLOAD_CREDENTIALS';
const recaptchaApplyAction = 'EXTERNAL_OFFER_APPLY';
export const ExternalOfferApplicationForm: FC<ExternalOfferApplicationFormProps> = ({
  offerId,
  contextContactEmail,
  onApplySuccess,
  onEmailSubmit,
}) => {
  const { t } = useTranslation();
  const { uploadExpertCvWithEmail } = useFileUpload();
  const [applyForPublicOffer] = useApplyForPublicOfferMutation();
  const { executeRecaptcha } = useRecaptcha();
  const [submitLoading, setSubmitLoading] = useState(false);
  const isPublicOfferEmailPrefillDisabled = useFeatureFlag('prefillEmailDisabled');

  const {
    register,
    formState: { errors },
    setValue,
    setError,
    handleSubmit,
  } = useForm<ApplicationFormValues>({
    resolver: yupResolver(externalOfferApplicationFormValidationSchema(t)),
  });

  const handleError = () => {
    toast.error(t('toasts:error'));
    setSubmitLoading(false);
  };

  useEffect(() => {
    if (isPublicOfferEmailPrefillDisabled || !contextContactEmail) {
      return;
    }
    setValue('email', contextContactEmail);
  }, [contextContactEmail]);

  const onSubmit = async (values: ApplicationFormValues) => {
    const { email, expertCv } = values;
    if (!expertCv) {
      return;
    }
    setSubmitLoading(true);

    await executeRecaptcha(
      recaptchaSignCredentialsAction,
      async (uploadCvRecaptchaToken: string) => {
        const { fileId: cvFileId, expertId } = await uploadExpertCvWithEmail({
          expertEmail: email,
          offerId,
          recaptchaToken: uploadCvRecaptchaToken,
          recaptchaAction: recaptchaSignCredentialsAction,
          file: expertCv,
        });
        if (typeof cvFileId !== 'string') {
          return handleError();
        }
        await executeRecaptcha(recaptchaApplyAction, async (applyRecaptchaToken: string) => {
          const res = await applyForPublicOffer({
            onError: () => toast.error(t('toasts:error')),
            variables: {
              params: {
                recaptchaAction: recaptchaApplyAction,
                recaptchaToken: applyRecaptchaToken,
                expertId,
                offerId,
                cvFileId,
              },
            },
          });

          if (res.errors) {
            return handleError();
          }
          await onEmailSubmit(email);
          onApplySuccess(expertId);
          setSubmitLoading(false);
        });
      },
    );
  };

  return (
    <form
      className="bg-gradient-blue rounded-3xl p-6 text-white space-y-6"
      onSubmit={handleSubmit(onSubmit)}
    >
      <p className="text-lg lg:text-2xl font-medium text-center">
        {t('offers:externalOffer.apply')}
      </p>
      <TextField
        className="text-sm"
        disabled={submitLoading}
        label={t('forms:labels.yourEmail')}
        labelClassName="!text-sm"
        variant="transparent"
        {...register('email')}
        error={errors.email}
      />
      <FileInput
        accept={allowedCvFileTypes}
        disabled={submitLoading}
        error={errors.expertCv}
        fileNameMaxLength={30}
        helperText={t('offers:externalOffer.allowedCVFormats') ?? ''}
        helperTextClassName="text-light-blue-200 text-xs"
        label={t('offers:externalOffer.addCV')}
        name="expertCv"
        onChange={(cvFile) =>
          setValue('expertCv', cvFile, {
            shouldValidate: true,
          })
        }
        onFileRejection={() => {
          setError('expertCv', {
            message: t('forms:validationMessages.invalidFileType') ?? '',
          });
        }}
        variant="white"
      />
      <RoundedButton
        disabled={submitLoading}
        label={t('common:actions.next')}
        loading={submitLoading}
        type="submit"
      />
      <ExternalOfferLegalNotes />
    </form>
  );
};
