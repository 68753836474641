import { useSitemap } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { ExpiredOfferInfo } from '../../components/Modals/OfferDetailsModal/subcomponents/ExpiredOfferInfo';

export const ExternalOfferNotFoundPage = () => {
  const navigate = useNavigate();
  const sitemap = useSitemap();

  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center px-4 md:px-0">
      <ExpiredOfferInfo onSeeMoreOffers={() => navigate(sitemap.signIn)} />
    </div>
  );
};
