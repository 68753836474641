import { auth } from './auth';
import { datePicker } from './datePicker';
import { forms } from './forms';
import languages from './languages';

const en = {
  auth,
  datePicker,
  forms,
  languages,
};

export default en;
