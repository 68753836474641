import { FC, ReactNode, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

interface AnimationProps {
  isShowed: boolean;
  children: ReactNode;
  onAnimationEnd?: () => void;
  animationName:
    | 'fade-in-up'
    | 'fade-in-down'
    | 'grow'
    | 'slide-up'
    | 'slide-down'
    | 'fade-in-right';
  scrollIntoViewOnEnter?: boolean;
  className?: string;
}

export const Animation: FC<AnimationProps> = ({
  isShowed,
  children,
  onAnimationEnd,
  animationName,
  scrollIntoViewOnEnter = false,
  className = '',
}) => {
  const nodeRef = useRef<HTMLDivElement>(null);

  const handleScrollIntoView = () => {
    if (!nodeRef.current) return;
    nodeRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const handleAnimationEnd = () => {
    if (onAnimationEnd) onAnimationEnd();
    if (scrollIntoViewOnEnter && isShowed) handleScrollIntoView();
  };

  return (
    <CSSTransition
      addEndListener={handleAnimationEnd}
      classNames={animationName}
      in={isShowed}
      nodeRef={nodeRef}
      timeout={300}
      unmountOnExit
    >
      <div ref={nodeRef} className={className}>
        {children}
      </div>
    </CSSTransition>
  );
};
