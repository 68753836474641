import { Offers } from 'components';
import { PanelLayout } from 'layouts';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface OffersPageProps {
  isMobileMenuOpen?: boolean;
  onMobileMenuToggle: () => void;
}

export const OffersPage: FC<OffersPageProps> = ({ isMobileMenuOpen, onMobileMenuToggle }) => {
  const { t } = useTranslation();

  return (
    <PanelLayout
      isMobileMenuOpen={isMobileMenuOpen}
      onMobileMenuToggle={onMobileMenuToggle}
      title={t('pageTitles:offers')}
    >
      <Offers />
    </PanelLayout>
  );
};
