import { Select } from 'components';
import { useSitemap, useUrlTabs } from 'hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileTabEnum } from '../Profile';
import { ExperienceTab, PreferencesTab, ProfileTab } from './tabs';

interface ProfileFormsContainerProps {
  currentTab: ProfileTabEnum;
}

const renderTab = (attrs: {
  tab: ProfileTabEnum;
  options: { label: string; value: ProfileTabEnum }[];
  onChange: (newTab: ProfileTabEnum) => void;
}) => {
  switch (attrs.tab) {
    case ProfileTabEnum.MyData:
      return <ProfileTab />;
    case ProfileTabEnum.Preferences:
      return <PreferencesTab />;
    case ProfileTabEnum.Experience:
      return <ExperienceTab />;
    default: {
      const unknownTab: never = attrs.tab;
      console.warn('Unknown tab:', unknownTab);
    }
  }
};

export const ProfileFormsContainer: FC<ProfileFormsContainerProps> = ({ currentTab }) => {
  const { t } = useTranslation();
  const sitemap = useSitemap();

  const generateTabName = (name: string) => {
    return `${t(`profile:profileHeader.profile`)}: ${t(`profile:profileHeader.tabs.${name}`)}`;
  };

  const tabOptions = Object.values(ProfileTabEnum).map((name) => ({
    label: generateTabName(name),
    value: name,
  }));

  const { handleTabChange } = useUrlTabs<ProfileTabEnum>(currentTab, sitemap.profile);

  return (
    <div className="max-w-[100vw] md:w-[742px] lg:w-auto lg:max-w-[742px] lg:max-w-none">
      <Select
        className="lg:hidden mb-6 mx-4"
        onChange={handleTabChange}
        options={tabOptions}
        placeholder={t(`profile:tabs:${currentTab}`) || ''}
        value={currentTab}
      />
      <div>
        {renderTab({
          tab: currentTab,
          options: tabOptions,
          onChange: handleTabChange,
        })}
      </div>
    </div>
  );
};
