export enum CognitoErrorCodesEnum {
  NotAuthorizedException = 'NotAuthorizedException',
  UsernameExistsException = 'UsernameExistsException',
  ExpiredCodeException = 'ExpiredCodeException',
  CodeMismatchException = 'CodeMismatchException',
  LimitExceededException = 'LimitExceededException',
}

export interface CognitoError {
  code: CognitoErrorCodesEnum;
  name: CognitoErrorCodesEnum;
}
