import connectisLogo from 'assets/images/connectis-logo.svg';
import { useLogoNavigation } from 'hooks/useLogoNavigation';
import { FC, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { mergeClasses } from 'utils';

interface LogoLayoutProps {
  children: ReactNode;
  title?: string | null;
  className?: string;
}

export const LogoLayout: FC<LogoLayoutProps> = ({ children, className = '', title }) => {
  const { handleLogoClick, isPasswordChange, isMainPage } = useLogoNavigation();

  return (
    <main
      className={mergeClasses(
        className,
        'px-4 pt-4 lg:px-[72px] flex flex-col h-fit lg:h-auto box-border',
        isPasswordChange ? 'min-h-screen' : 'min-h-auto lg:min-h-screen',
      )}
    >
      <Helmet>{title && <title>{title}</title>}</Helmet>
      <div className="pt-4 pb-5 lg:pt-10 flex justify-center relative">
        {isMainPage ? (
          <a href="/">
            <button className="cursor-pointer">
              <img alt="Connectis" className="w-[120px] lg:w-[150px]" src={connectisLogo} />
            </button>
          </a>
        ) : (
          <button className="cursor-pointer" onClick={handleLogoClick}>
            <img alt="Connectis" className="w-[120px] lg:w-[150px]" src={connectisLogo} />
          </button>
        )}
      </div>
      <div
        className={mergeClasses(
          'flex grow flex-col lg:flex-row items-center -mt-24',
          isPasswordChange ? 'justify-center' : 'justify-start',
        )}
      >
        {children}
      </div>
    </main>
  );
};
