import { JobOrderList, Paper } from 'components';
import { t } from 'i18next';
import { transformCountForPluralInterval } from 'utils';
import { useActiveApplicationJobOrdersQuery } from '../../../hooks/api/activeApplicationJobOrders/activeApplicationJobOrders.generated';
import { useViewedJobOrderApplications } from '../../../hooks/useViewedJobOrderApplications';

export const ActiveApplications = () => {
  const { data, loading } = useActiveApplicationJobOrdersQuery();
  const appliedJobOrders = data?.activeApplicationJobOrders ?? [];
  useViewedJobOrderApplications(appliedJobOrders);

  return (
    <Paper
      className="lg:relative lg:-top-20 xl:-top-24"
      subtitle={t('applications:applicationsAmount', {
        count: transformCountForPluralInterval(appliedJobOrders.length),
        amount: appliedJobOrders.length,
        postProcess: 'interval',
      })}
      subtitleClassName="inline-block"
      title={t('applications:activeApplications.title')}
      titleClassName="inline-block !mb-2 mr-2"
    >
      <JobOrderList jobOrders={appliedJobOrders} loading={loading} name="appliedJobOrders" />
    </Paper>
  );
};
