import { omitObjectKeys } from 'utils';
import { DefaultSkillLevelType } from '../Skill/Skill';
import { ProfileValues } from './interfaces';
import { ExpertProfile } from './Profile';

export const mapProfileToValues = (profile: ExpertProfile): ProfileValues => {
  const {
    firstName,
    lastName,
    location,
    locationObject,
    seniority,
    rateDesired,
    experienceYears,
    languages,
    tagsSuperPowers,
    desiredIndustries,
    desiredOfficeLocations,
    desiredTechnicalSkills,
    jobPositionId,
    tags,
    noticeLength,
    availability,
    certificates,
    workHistory,
    workType,
    desiredFields,
    mainTechnologyIds,
    linkedInUrl,
    githubUrl,
    phoneNumber,
    isCertificatesSkipped,
    cvId,
  } = profile;

  return {
    firstName: firstName || null,
    lastName: lastName || null,
    location: location || null,
    seniority: seniority || null,
    rateDesired: rateDesired || null,
    experienceYears: experienceYears || 0,
    languages: languages?.map((lang) => omitObjectKeys(lang, ['__typename'])) || [],
    tagsSuperPowers: tagsSuperPowers || [],
    desiredIndustries: desiredIndustries || [],
    desiredOfficeLocations: desiredOfficeLocations || [],
    desiredTechnicalSkills: desiredTechnicalSkills || [],
    jobPositionId: jobPositionId || null,
    tags:
      tags?.map(({ id, weight }) => ({ tagId: id, weight: weight as DefaultSkillLevelType })) || [],
    noticeLength: noticeLength || null,
    availability: availability || null,
    certificates: certificates.map((cert) => omitObjectKeys(cert, ['__typename'])) || [],
    workHistory: workHistory.map((wh) => omitObjectKeys(wh, ['__typename'])) || [],
    workType: workType || null,
    desiredFields: desiredFields || [],
    locationObject: locationObject || null,
    mainTechnologyIds: mainTechnologyIds || [],
    linkedInUrl: linkedInUrl || null,
    githubUrl: githubUrl || null,
    phoneNumber: phoneNumber || null,
    isCertificatesSkipped,
    cvId: cvId || null,
  };
};

export const basicInfoFields = [
  'firstName',
  'lastName',
  'jobPositionId',
  'location',
  'seniority',
  'experienceYears',
  'languages',
  'tags',
];
export const preferencesFields = [
  'rateDesired',
  'desiredIndustries',
  'desiredTechnicalSkills',
  'noticeLength',
  'availability',
  'workType',
  'desiredFields',
];
export const experienceFields = ['workHistory'];

export const calculateProfileCompletionPercentage = (profile: ExpertProfile | null) => {
  let percentageFields: any[] = [...basicInfoFields, ...preferencesFields, ...experienceFields];

  if (!profile)
    return {
      filledPercent: 0,
      unfilledFields: percentageFields,
    };

  const unfilledFields: string[] = [];
  percentageFields = [
    ...percentageFields,
    ...(!profile.isCertificatesSkipped ? ['certificates'] : []),
  ];

  const filledFields = percentageFields.reduce<number>(
    (sum, currentField: keyof typeof profile) => {
      const value = profile[currentField];
      const isArray = Array.isArray(value);

      if ((isArray && value.length > 0) || (!isArray && typeof value !== 'undefined')) {
        return sum + 1;
      }
      unfilledFields.push(currentField);
      return sum;
    },
    0,
  );

  return {
    filledPercent: Math.ceil((100 * filledFields) / percentageFields.length / 5) * 5,
    unfilledFields,
  };
};
