import pl from 'date-fns/locale/pl';
import { CalendarIcon } from 'icons';
import { FC, useEffect, useRef, useState } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'utils';
import { Nullable } from '../../../interfaces/commons';
import { InputLabel } from '../../InputLabel';
import { DateRange, DatesRange } from './DateRange';
import './react-datepicker.css';

registerLocale('pl', pl);

interface DatePickerProps {
  onChange: (date: string) => void;
  value?: string;
  name: string;
  label?: string;
  error?: FieldError;
  placeholder?: string;
  showMonthYearPicker?: boolean;
  isDisabled?: boolean;
  allowedRanges?: DatesRange;
}

export const DatePicker: FC<DatePickerProps> = ({
  name,
  onChange,
  value,
  error,
  placeholder,
  showMonthYearPicker,
  label,
  isDisabled,
  allowedRanges,
}) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState<Nullable<Date>>(null);

  const datePickerRef = useRef<ReactDatePicker | null>(null);

  const dateFormat = showMonthYearPicker ? 'MM.yyyy' : 'dd.MM.yyyy';

  useEffect(() => {
    setSelectedDate(value ? new Date(value) : null);
  }, [value]);

  const handleChange = (date: Date) => {
    if (!allowedRanges || DateRange.contains(allowedRanges, date)) {
      setSelectedDate(date);
      onChange(date?.toISOString() ?? undefined);
    }
  };

  const handleIconClick = () => {
    datePickerRef.current?.setOpen(true);
  };

  return (
    <label htmlFor={name}>
      {label && <InputLabel label={label} />}
      <div className="relative">
        <ReactDatePicker
          ref={datePickerRef}
          className={mergeClasses(
            'border w-full rounded-lg pl-6 pr-[36px] h-14 transition-all',
            isDisabled ? 'bg-gray-100 cursor-not-allowed' : '',
            error ? 'border-red-500' : 'border-gray-200',
          )}
          dateFormat={dateFormat}
          disabled={isDisabled}
          id={`${name}-input`}
          locale="pl"
          maxDate={allowedRanges && new Date(allowedRanges.right)}
          minDate={allowedRanges && new Date(allowedRanges.left)}
          name={name}
          onChange={handleChange}
          placeholderText={placeholder ?? t('forms:placeholders.selectDate') ?? undefined}
          selected={selectedDate || undefined}
          showMonthYearPicker={showMonthYearPicker}
        />
        <button
          className="absolute top-0 bottom-0 right-5 w-4 h-4 my-auto"
          onClick={handleIconClick}
          type="button"
        >
          <CalendarIcon />
        </button>
      </div>
      {error && <p className="text-red-500 leading-[18px] mt-2">{error.message}</p>}
    </label>
  );
};
