import { yupResolver } from '@hookform/resolvers/yup';
import { useUrlObjectParams } from 'hooks';
import { SortingIcon } from 'icons';
import { FC, useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  PublicJobBoardOffersQuery,
  usePublicJobBoardOffersLazyQuery,
} from '../../hooks/api/publicJobBoardOffers/publicJobBoardOffers.generated';
import { LinkButton } from '../Buttons';
import { JobBoardFilters, JobBoardOffersList, JobBoardSearchBar } from './subcomponents';
import { jobBoardFiltersValidationSchema } from './subcomponents/JobBoardFilters/jobBoardFiltersValidation';
import { mapJobBoardFilters, parseJobBoardFilters } from './subcomponents/JobBoardFilters/utils';
import { JobBoardSearchBarMenuOption } from './subcomponents/JobBoardSearchBar/subcomponents';

type PublicOffersType = PublicJobBoardOffersQuery['publicJobBoardOffers'];

export interface JobBoardFiltersValues {
  rateRangeFrom?: number | null;
  rateRangeTo?: number | null;
  location?: string;
  keywords?: JobBoardSearchBarMenuOption[];
  seniority?: {
    Junior: boolean;
    Regular: boolean;
    Senior: boolean;
  };
  workMode?: {
    Office: boolean;
    Remote: boolean;
    Hybrid: boolean;
  };
}

export const jobBoardFiltersDefaultValues: JobBoardFiltersValues = {
  rateRangeFrom: null,
  rateRangeTo: null,
  location: '',
  keywords: [],
  seniority: {
    Junior: false,
    Regular: false,
    Senior: false,
  },
  workMode: {
    Office: false,
    Remote: false,
    Hybrid: false,
  },
};

interface JobBoardProps {
  onPublicOffersFetch?: () => void;
}

export const JobBoard: FC<JobBoardProps> = ({ onPublicOffersFetch }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { getUrlObject, setUrlObject } = useUrlObjectParams();
  const [publicOffers, setPublicOffers] = useState<PublicOffersType>([]);

  const defaultValueWithUrl = useMemo(() => {
    return {
      ...jobBoardFiltersDefaultValues,
      ...parseJobBoardFilters(getUrlObject()),
    };
  }, []);

  const formMethods = useForm<JobBoardFiltersValues>({
    defaultValues: defaultValueWithUrl,
    resolver: yupResolver(jobBoardFiltersValidationSchema(t)),
  });
  const [isFiltersOpen, setFiltersOpen] = useState(false);

  const [getPublicOffers, { loading }] = usePublicJobBoardOffersLazyQuery({
    onCompleted: ({ publicJobBoardOffers }) => setPublicOffers(publicJobBoardOffers),
  });

  const handleFetchOffers = formMethods.handleSubmit(async (values: JobBoardFiltersValues) => {
    const filters = mapJobBoardFilters(values);
    setUrlObject(filters);

    await getPublicOffers({
      variables: {
        filters,
      },
    });
  });

  useEffect(() => {
    handleFetchOffers();
  }, []);

  useEffect(() => {
    if (location.state?.scrollToAnchor) {
      document.getElementById(location.state.scrollToAnchor)?.scrollIntoView();
    }
  }, [location]);

  useEffect(() => {
    if (onPublicOffersFetch) {
      onPublicOffersFetch();
    }
  }, [publicOffers]);

  return (
    <div className="w-full">
      <h2 className="font-medium text-center text-3xl lg:text-5xl mb-7 lg:mb-20">
        {t('jobBoard:title')}
      </h2>
      <div className="flex gap-14 items-start w-full flex-col lg:flex-row">
        <FormProvider {...formMethods}>
          <JobBoardFilters
            closeFilters={() => setFiltersOpen(false)}
            isFiltersOpen={isFiltersOpen}
            loading={loading}
            onSubmit={handleFetchOffers}
          />
          <div className="w-full flex flex-col items-end">
            <Controller
              name="keywords"
              render={({ field: { name, value, onChange } }) => (
                <JobBoardSearchBar
                  className="mb-8 w-full"
                  name={name}
                  onChange={(v) => {
                    onChange(v);
                    handleFetchOffers();
                  }}
                  placeholder={t('jobBoard:searchBar.placeholder')}
                  value={value}
                />
              )}
            />
            <LinkButton
              className="no-underline mb-6 text-sm lg:hidden"
              icon={SortingIcon}
              iconClassName="rotate-90"
              label={t('jobBoard:filters.title')}
              onClick={() => setFiltersOpen(true)}
            />
            <JobBoardOffersList offers={publicOffers} />
          </div>
        </FormProvider>
      </div>
    </div>
  );
};
