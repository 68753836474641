import { t } from 'i18next';
import { HomeIcon, UserIcon } from 'icons';
import { FC } from 'react';
import { IconListItem } from '../../Modals/OfferDetailsModal/subcomponents/JobOrderDetailsContactPerson';
import { ExternalOfferType } from '../ExternalOffer';

interface ExternalOfferContactPersonProps {
  owner: ExternalOfferType['owner'];
}

export const ExternalOfferContactPerson: FC<ExternalOfferContactPersonProps> = ({ owner }) => {
  if (!owner) {
    return null;
  }

  const { name, email } = owner;

  return (
    <div>
      <p className="mb-4 font-medium text-xl">{t('offers:externalOffer.contactWithUs')}</p>
      <ul className="space-y-1">
        <IconListItem icon={UserIcon}>{name}</IconListItem>
        <IconListItem icon={HomeIcon}>
          <a className="hover:underline" href={`mailto:${email}`}>
            {email}
          </a>
        </IconListItem>
      </ul>
    </div>
  );
};
