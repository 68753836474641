import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
const defaultOptions = {} as const;
export type ResendOtpMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  isExternalOffer?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type ResendOtpMutation = { __typename?: 'Mutation'; resendOtp: string };

export const ResendOtpDocument = gql`
  mutation resendOtp($email: String!, $isExternalOffer: Boolean) {
    resendOtp(email: $email, isExternalOffer: $isExternalOffer)
  }
`;
export type ResendOtpMutationFn = Apollo.MutationFunction<
  ResendOtpMutation,
  ResendOtpMutationVariables
>;

/**
 * __useResendOtpMutation__
 *
 * To run a mutation, you first call `useResendOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendOtpMutation, { data, loading, error }] = useResendOtpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      isExternalOffer: // value for 'isExternalOffer'
 *   },
 * });
 */
export function useResendOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<ResendOtpMutation, ResendOtpMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResendOtpMutation, ResendOtpMutationVariables>(
    ResendOtpDocument,
    options,
  );
}
export type ResendOtpMutationHookResult = ReturnType<typeof useResendOtpMutation>;
export type ResendOtpMutationResult = Apollo.MutationResult<ResendOtpMutation>;
export type ResendOtpMutationOptions = Apollo.BaseMutationOptions<
  ResendOtpMutation,
  ResendOtpMutationVariables
>;
