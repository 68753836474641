import { FC } from 'react';
import { FieldError } from 'react-hook-form';

export const InputError: FC<{ error?: FieldError }> = ({ error }) => {
  if (!error) {
    return null;
  }

  return <p className="text-red-500 leading-[18px] mt-2">{error.message}</p>;
};
