import { LoadingSpinner, LoadingSpinnerProps } from 'components';
import { FC } from 'react';

export const PageLoadingSpinner: FC<LoadingSpinnerProps> = (props) => {
  return (
    <div className="fixed top-0 left-0 z-50 w-full h-full min-h-screen min-w-screen flex items-center justify-center">
      <LoadingSpinner size={64} {...props} />
    </div>
  );
};
