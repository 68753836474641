import domToImage from 'dom-to-image';

export const downloadDOMasPNG = async (element: HTMLElement, fileName: string) => {
  const uri = await domToImage.toPng(element);
  const download = document.createElement('a');
  download.href = uri;
  download.download = fileName;
  download.click();
  download.remove();
};
