import { EditAvatarButton } from 'components';
import { FC } from 'react';
import { mergeClasses } from 'utils';

const NO_FIRST_NAME_PLACEHOLDER = 'C';
const AvatarPlaceholder = ({ firstName = '' }) => {
  const firstNameFirstLetter = firstName?.charAt(0) ?? NO_FIRST_NAME_PLACEHOLDER;
  return <span className="text-bold text-white">{firstNameFirstLetter}</span>;
};

interface AvatarProps {
  avatarUrl?: string | null;
  firstName?: string;
  onChangeClick?: () => void;
  className?: string;
  size?: 'lg' | 'sm';
}

export const Avatar: FC<AvatarProps> = ({
  avatarUrl,
  firstName,
  onChangeClick,
  className,
  size = 'lg',
}) => {
  return (
    <div
      className={mergeClasses(
        'rounded-full flex items-center justify-center relative overflow-hidden border-white border',
        !avatarUrl ? 'bg-primary-700' : '',
        size === 'lg'
          ? 'text-5xl w-28 min-w-[80px] md:min-w-[112px] min-h-[80px] max-h-[80px] h-28 md:min-h-[112px] max-w-[80px] md:max-w-[112px] md:max-h-[112px]'
          : 'text-2xl w-14 h-14',
        className,
      )}
    >
      {onChangeClick && <EditAvatarButton onClick={onChangeClick} />}
      {avatarUrl ? (
        <div
          className="w-full h-full bg-cover bg-center"
          style={{
            backgroundImage: `url(${avatarUrl})`,
          }}
        />
      ) : (
        <AvatarPlaceholder firstName={firstName} />
      )}
    </div>
  );
};
