import { IconButton } from 'components';
import { AngleIcon } from 'icons';
import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { ConnectisWebsiteMenu } from '../../../configs';
import { ConnectisWebsiteHeaderMenuItem } from '../ConnectisWebsiteHeaderMenu/subcomponents';
import { MobileMenuHeader } from './MobileMenuHeader';
import { PortfolioButton } from './PortfolioButton';
import { PortfolioItem } from './PortfolioItem';

interface ConnectisWebsiteMobileMenuItemProps extends Partial<ConnectisWebsiteMenu> {
  open: boolean;
  onBack: () => void;
  onClose: () => void;
}

export const ConnectisWebsiteMobileMenuItem: FC<ConnectisWebsiteMobileMenuItemProps> = ({
  open,
  items,
  name,
  portfolioProject,
  onBack,
  onClose,
}) => {
  const { t } = useTranslation();
  const nodeRef = useRef(null);

  const visibleItems = items?.filter(({ header }) => header !== false);

  return (
    <CSSTransition classNames="fade" in={open} nodeRef={nodeRef} timeout={200} unmountOnExit>
      <div
        ref={nodeRef}
        className="flex xl:hidden fixed top-0 left-0 bg-white w-screen h-screen text-black px-4 py-6 pb-[308px] flex-col z-40"
      >
        <MobileMenuHeader onClose={onClose} />
        <div className="space-y-4">
          <IconButton
            className="p-0"
            icon={AngleIcon}
            iconClassName="w-6 h-6 rotate-90"
            onClick={onBack}
          />
          <p className="text-2xl">{t(`connectisWebsite:menus.${name}.heading`)}</p>
        </div>
        <ul className="w-full col-span-2 space-y-6 pt-6 pb-4 overflow-auto">
          {visibleItems?.length &&
            name &&
            visibleItems.map((item) => (
              <ConnectisWebsiteHeaderMenuItem
                key={`${name}${item.name}`}
                {...item}
                menuName={name}
              />
            ))}
        </ul>
        <div className="absolute bottom-0 left-0 px-4 py-6 border-t border-t-new-gray-100 w-full bg-white">
          {portfolioProject && <PortfolioItem {...portfolioProject} />}
          <PortfolioButton />
        </div>
      </div>
    </CSSTransition>
  );
};
