import signUpFormBanner from '../../assets/images/signUpForm-banner.png';

interface SideBoxProps {
  showBackgroundImage?: boolean;
}

export const SideBox = ({ showBackgroundImage = true }: SideBoxProps) => {
  return (
    <div
      className="hidden lg:block lg:bg-primary-700 lg:bg-contain lg:bg-center lg:bg-no-repeat lg:h-full lg:w-full lg:max-w-[538px] lg:min-h-[725px] lg:rounded-[20px] max-h-[1010px]"
      style={{
        backgroundImage: showBackgroundImage ? `url(${signUpFormBanner})` : '',
      }}
    />
  );
};
