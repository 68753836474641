import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from 'components';
import { TFunction } from 'i18next';
import { Shape } from 'interfaces';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ResendCodeButton } from '../../../../Auth/forms/ResendCodeButton';

interface SettingsOTPInputStepFormValues {
  otp: string;
}

const settingsOTPInputStepSchema = (t: TFunction) =>
  yup.object().shape<Shape<SettingsOTPInputStepFormValues>>({
    otp: yup.string().required(
      t('forms:validationMessages.required', {
        fieldName: t('forms:labels.verificationCode'),
      }) ?? '',
    ),
  });

interface SettingsOTPInputStepProps {
  onOTPSubmit: (otp: string) => any;
  onResendCodeClick: () => any;
  loading: boolean;
  onCancel: () => void;
}

export const SettingsOTPInputStep = ({
  onOTPSubmit,
  onResendCodeClick,
  loading,
  onCancel,
}: SettingsOTPInputStepProps) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SettingsOTPInputStepFormValues>({
    resolver: yupResolver(settingsOTPInputStepSchema(t)),
  });

  const onSubmit = (values: SettingsOTPInputStepFormValues) => onOTPSubmit(values.otp);

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full md:max-w-[376px] mx-auto pt-6">
        <TextField
          {...register('otp')}
          error={errors.otp}
          label={t('forms:labels.verificationCode')}
          labelClassName="text-sm md:text-base"
        />
        <ResendCodeButton isInColumn onClick={onResendCodeClick} />
        <div className="flex md:flex-row flex-col-reverse gap-6 items-center w-full mt-8">
          <Button
            className="w-full"
            label={t('common:actions.cancel')}
            labelClassName="text-sm lg:text-base"
            onClick={onCancel}
            variant="outline"
          />
          <Button
            className="!rounded-lg w-full"
            isDisabled={loading}
            isLoading={loading}
            label={t('settings:actions.deleteAccount')}
            labelClassName="text-sm lg:text-base"
            type="submit"
            variant="danger"
          />
        </div>
      </div>
    </form>
  );
};
