import { useReactiveVar } from '@apollo/client';
import { ProfileHeader, Sidebar } from 'components';
import { useSitemap } from 'hooks';
import { FC, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { profileVar } from 'reactive-vars';
import { mergeClasses } from 'utils';
import { UserPanel } from '../../components/Navigation/UserPanel';
import { ProfileTabEnum } from '../../components/Profile/Profile';
import { calculateProfileCompletionPercentage } from '../../components/Profile/utils';
import { useExpertNotificationsQuery } from '../../hooks/api/expertNotifications/expertNotifications.generated';

interface PanelLayoutProps {
  children: ReactNode;
  title?: string | null;
  className?: string;
  isMobileMenuOpen?: boolean;
  onMobileMenuToggle?: () => void;
  sidebarClassName?: string;
}

const tabFieldsSchema = {
  profile: ['languages', 'skills'],
  preferences: [
    'availability',
    'location',
    'desiredIndustries',
    'desiredTechnicalSkills',
    'desiredFields',
  ],
  experience: ['certificates', 'workHistory'],
};

export const PanelLayout: FC<PanelLayoutProps> = ({
  title,
  className,
  children,
  isMobileMenuOpen,
  onMobileMenuToggle,
  sidebarClassName,
}) => {
  const navigate = useNavigate();
  const sitemap = useSitemap();
  const profile = useReactiveVar(profileVar);
  const { tabName } = useParams();

  const { filledPercent, unfilledFields } = calculateProfileCompletionPercentage(profile);

  const { data: expertNotificationData } = useExpertNotificationsQuery({
    variables: {
      limit: 5,
    },
  });

  const handleScrollToElement = (elementId: string) => {
    const targetElement = document.getElementById(elementId);
    if (!targetElement) return;

    targetElement.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const handleScrollToUnfilledField = () => {
    if (!unfilledFields.length) return;
    const [fieldName] = unfilledFields;

    const [targetTabName] =
      Object.entries(tabFieldsSchema).find(([, fields]) => fields.includes(fieldName)) ?? [];
    if (targetTabName !== tabName) {
      navigate(sitemap.profile(targetTabName === ProfileTabEnum.MyData ? '' : targetTabName));
      setTimeout(() => handleScrollToElement(fieldName), 200);
      return;
    }
    handleScrollToElement(fieldName);
  };

  return (
    <div className={className}>
      <Helmet>{title && <title>{title}</title>}</Helmet>
      <div>
        <Sidebar isMobileMenuOpen={isMobileMenuOpen} onMobileMenuToggle={onMobileMenuToggle} />
        <div
          className={mergeClasses(
            'min-h-screen bg-background absolute lg:static mt-0 mx-0 lg:ml-[240px] mb-24 lg:mb-0 lg:px-8 lg:pt-8',
            sidebarClassName,
          )}
        >
          <div className="hidden lg:flex justify-end items-center lg:mb-9">
            <UserPanel notificationsData={expertNotificationData} />
          </div>
          <ProfileHeader
            completionPercent={filledPercent}
            notificationsData={expertNotificationData}
            onCompleteYourProfileClick={handleScrollToUnfilledField}
            onMobileMenuToggle={onMobileMenuToggle}
          />
          {children}
        </div>
      </div>
    </div>
  );
};
