import { AvailabilitySelect } from 'components';
import { ExpertAvailabilityEnum } from 'interfaces';
import { useFormContext } from 'react-hook-form';

export interface AvailabilityStepValues {
  availability: string;
  noticeLength?: number;
}

export const AvailabilityStep = () => {
  const {
    setValue,
    formState: { errors },
    watch,
  } = useFormContext<AvailabilityStepValues>();

  const availabilityValue = watch('availability');
  const noticeLengthValue = watch('noticeLength');

  const handleAvailabilityChange = (value: string) => {
    setValue('availability', value, {
      shouldValidate: true,
      shouldDirty: true,
    });
    if (value === ExpertAvailabilityEnum.Days) {
      setValue('noticeLength', 30, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
    if (value === ExpertAvailabilityEnum.Months) {
      setValue('noticeLength', 1, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  };

  const handleNoticeLengthChange = (value: number) =>
    setValue('noticeLength', value, {
      shouldValidate: true,
      shouldDirty: true,
    });

  return (
    <AvailabilitySelect
      availabilityError={errors.availability}
      availabilityValue={availabilityValue}
      className="mt-2"
      noticeLengthError={errors.noticeLength}
      noticeLengthValue={noticeLengthValue}
      onAvailabilityChange={handleAvailabilityChange}
      onNoticeLengthChange={handleNoticeLengthChange}
    />
  );
};
