import { BackButton, FullPageModal, ModalProps, WorkHistoryForm } from 'components';
import { FC } from 'react';
import { WorkExperienceFormValues } from '../../WorkHistory/WorkHistoryForm';

interface WorkExperienceModalProps extends ModalProps {
  currentExperience: WorkExperienceFormValues | null;
  onSave: () => void;
}

export const WorkExperienceModal: FC<WorkExperienceModalProps> = ({
  currentExperience,
  onSave,
  onClose,
  ...modalProps
}) => {
  return (
    <FullPageModal {...modalProps}>
      <div className="max-w-container-xs mx-auto max-h-screen overflow-auto">
        <BackButton className="mb-14" onClick={onClose} />
        <WorkHistoryForm
          initialData={currentExperience ?? undefined}
          onCancelClick={onClose}
          onSaveClick={onSave}
        />
      </div>
    </FullPageModal>
  );
};
