import { yupResolver } from '@hookform/resolvers/yup';
import mobileSideImgBg from 'assets/images/onboarding/sidebar-bg-mobile.svg';
import SideImgBg from 'assets/images/onboarding/sidebar-bg.svg';
import { useElementSize, useExpertProfile, useFileUpload, useSitemap } from 'hooks';
import { useUpdateExpertProfileMutation } from 'hooks/api/updateExpertProfile/updateExpertProfile.generated';
import { FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { profileVar } from 'reactive-vars';
import { capitalizeFirstLetter, mergeClasses } from 'utils';
import { useFinishOnboardingMutation } from '../../hooks/api/finishOnboarding/finishOnboarding.generated';
import useMobile from '../../hooks/useMobile';
import { ExternalOfferModal } from './modals';
import {
  AvailabilityStepValues,
  CertificateStepValues,
  ExpectationsStepValues,
  ExperienceStepValues,
  LanguagesStepValues,
  NameStepValues,
  PreferencesStepValues,
  RoleStepValues,
  SalaryStepValues,
  SkillsStepValues,
  SuperSkillsStepValues,
} from './steps';
import { OnboardingStepsEnum, stepsConfig } from './stepsConfig';
import { OffersLoading, OnboardingFormFooter, StepProgressBar } from './subcomponents';
import {
  getAllowedInitialStepIndex,
  getWrapperBottomPadding,
  mapExpertProfileToValues,
} from './utils';

export type OnboardingFormValues = NameStepValues &
  RoleStepValues &
  SkillsStepValues &
  SuperSkillsStepValues &
  LanguagesStepValues &
  PreferencesStepValues &
  SalaryStepValues &
  AvailabilityStepValues &
  ExpectationsStepValues &
  ExperienceStepValues &
  CertificateStepValues;

export const OnboardingForm = () => {
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [, wrapperHeight] = useElementSize(wrapperRef);
  const { t, i18n } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const sitemap = useSitemap();
  const { isMobile } = useMobile();
  const [searchParams] = useSearchParams();

  const { stepName } = params ?? {};

  const [finishOnboarding] = useFinishOnboardingMutation();
  const [updateExpertProfile] = useUpdateExpertProfileMutation();
  const { uploadExpertCv, uploadExpertCvLoading } = useFileUpload();

  const { profile, setProfileField } = useExpertProfile();

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);

  const {
    component: StepComponent,
    name: currentStepName,
    stepNumber: currentStepNumber,
    showStepNumber,
    validationSchema,
    sideImg,
    sideImgMobile,
    hideFooter,
    skipTo,
  } = useMemo(() => stepsConfig[currentStepIndex], [currentStepIndex]);

  const currentValidationSchema = validationSchema && yupResolver(validationSchema(t));
  const formMethods = useForm<OnboardingFormValues>({
    resolver: currentValidationSchema,
  });
  const { trigger, watch, reset, getValues } = formMethods;

  const isFirstStep = currentStepIndex === 0;
  const isLastStep = currentStepIndex === stepsConfig.length - 1;
  const isSubtitleAvailable = i18n.exists(`onboarding:${currentStepName}.subtitle`);
  const firstNameValue = watch('firstName');

  const handleFormSubmit = async () => {
    if (!profile) return;
    setIsSuccess(true);
    const res = await finishOnboarding();
    if (res.errors) {
      return toast.error(t('toasts:error'));
    }

    const { isCertificatesSkipped, isOnboardingFinished } = res.data?.finishOnboarding ?? {
      isCertificatesSkipped: false,
      isOnboardingFinished: false,
    };
    setProfileField('isOnboardingFinished', isOnboardingFinished);
    setProfileField('isCertificatesSkipped', isCertificatesSkipped);

    const pageRedirect = searchParams.get('pageRedirect') || sitemap.offers();
    searchParams.delete('pageRedirect');
    const restSearchParams = searchParams.toString();

    navigate(`${pageRedirect}?${restSearchParams}`);
  };

  const handleUpdateExpertProfile = async () => {
    const { expertId, cvId, cv } = profile ?? {};
    if (!expertId) {
      return;
    }

    const { requiredMainTechnologyLength, expertCv, ...values } = getValues();
    let uploadedCvId = cvId;

    const isFileChanged = expertCv && (expertCv.name !== cv?.name || expertCv.size !== cv?.size);
    const isFileRemoved = expertCv === null;

    if (isFileRemoved) {
      uploadedCvId = null;
    }

    if (expertCv && (isFileChanged || isFileRemoved)) {
      uploadedCvId = await uploadExpertCv(expertId, expertCv);
      if (typeof uploadedCvId !== 'string') {
        toast.error(t('toasts:error'));
        formMethods.setValue('expertCv', null);
        uploadedCvId = null;
      }
    }

    const res = await updateExpertProfile({
      variables: {
        expertId,
        params: {
          ...(values as any),
          cvId: uploadedCvId,
        },
      },
    });
    const updatedProfile = res.data?.updateExpertProfile;
    profileVar(updatedProfile);
  };

  const handleUrlStepNameChange = (newIndex: number) => {
    const urlStepName = stepsConfig[newIndex].name.replace('Step', '');
    const searchParamsString = searchParams.toString();

    navigate(
      `${sitemap.expertOnboarding(urlStepName)}${
        searchParamsString ? `?${searchParamsString}` : ''
      }`,
    );
  };

  const handleRemoveUnselectedRecommendedSkills = () => {
    const tags = formMethods.getValues('tags');
    formMethods.setValue(
      'tags',
      tags.filter(({ weight }) => weight),
    );
  };

  const handleNextStepClick = async (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    const isValid = await trigger();
    if (!isValid) {
      return;
    }

    if (isLastStep) {
      return handleFormSubmit();
    }

    if (stepName === OnboardingStepsEnum.Skills.replace('Step', '')) {
      handleRemoveUnselectedRecommendedSkills();
    }

    const newIndex = currentStepIndex + 1;
    handleUrlStepNameChange(newIndex);

    await handleUpdateExpertProfile();
    setCurrentStepIndex(newIndex);

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handlePrevStepClick = () => {
    if (isFirstStep) {
      return;
    }

    const newIndex = currentStepIndex - 1;
    handleUrlStepNameChange(newIndex);
    setCurrentStepIndex(newIndex);
    setIsNextDisabled(false);
  };

  const handleSkipStepClick = async () => {
    if (!skipTo) return;
    handleUrlStepNameChange(skipTo);

    await handleUpdateExpertProfile();
    setCurrentStepIndex(skipTo);
  };

  const handleToggleNextDisabled = (setTo?: boolean) =>
    setIsNextDisabled((prev) => {
      if (typeof setTo === 'undefined') return !prev;
      return setTo;
    });

  useEffect(() => {
    if (!profile) {
      return;
    }
    const { requiredMainTechnologyLength, expertCv } = getValues();
    const mappedProfileValues = mapExpertProfileToValues(profile);
    const allValues = { ...mappedProfileValues, requiredMainTechnologyLength, expertCv };
    reset(allValues);
  }, [profile, reset]);

  useEffect(() => {
    if (!stepName) {
      return;
    }

    const validStepName =
      OnboardingStepsEnum[capitalizeFirstLetter(stepName) as keyof typeof OnboardingStepsEnum];
    if (!validStepName) {
      return handleUrlStepNameChange(0);
    }

    let initialStepIndex = stepsConfig.findIndex(({ name }) => name === validStepName) ?? {};
    const values = getValues();
    const lastValidStepIndex = getAllowedInitialStepIndex(values);
    if (initialStepIndex === -1) {
      return;
    }
    initialStepIndex = Math.min(lastValidStepIndex, initialStepIndex);
    setCurrentStepIndex(initialStepIndex);
  }, [stepName, navigate, sitemap]);

  const hasOverflow = wrapperHeight > window.innerHeight - 132;

  const setBgImg = () => {
    if (isMobile) {
      return `url(${mobileSideImgBg})`;
    }
    return `url(${SideImgBg})`;
  };

  return (
    <div
      ref={wrapperRef}
      className="h-[calc(100vh - 64px)] h-full flex flex-col w-screen lg:items-center lg:max-w-container-md lg:mx-auto transition-all"
    >
      <ExternalOfferModal />
      {isSuccess ? (
        <OffersLoading />
      ) : (
        <div className="mt-24 px-4 w-full">
          <StepProgressBar currentStepIndex={currentStepIndex} />
          <FormProvider {...formMethods}>
            <div
              className={mergeClasses(
                'flex flex-col lg:flex-row items-center justify-center h-auto lg:h-full mt-4 gap-x-[146px] lg:gap-x-[146px] !pb-0 !grow md:!grow-0 min-h-[calc(100vh-132px)] lg:w-full',
                getWrapperBottomPadding(!hideFooter, hasOverflow),
              )}
            >
              {sideImg && (
                <div
                  className="bg-cover bg-gray-100 rounded-[20px] min-w-fit md:min-w-[424px] lg:max-w-[424px] p-0 md:py-0 md:mb-10 md:mt-4 lg:p-0 lg:m-0 flex md:block justify-end h-48 md:h-56 lg:h-auto relative bg-right bg-no-repeat w-full mb-8 md:m-0"
                  style={{
                    backgroundImage: setBgImg(),
                  }}
                >
                  <picture>
                    <source media="(max-width:1023px)" srcSet={sideImgMobile} />
                    <source media="min-width:1024px" srcSet={sideImg} />
                    <img
                      alt=""
                      className="md:h-80 lg:h-[630px] translate-x-[80px] block absolute lg:relative bottom-0 lg:top-0 right-24 md:right-28 lg:right-0 lg:ml-auto"
                      src={sideImg || sideImgMobile}
                    />
                  </picture>
                </div>
              )}
              <div className="flex flex-col justify-start md:justify-center md:items-center lg:align-start md:w-full grow lg:grow-0 w-full">
                <div className="w-full max-w-container-xs">
                  {showStepNumber && (
                    <p className="text-gray-500 text-[15px] leading-[22.5px] lg:text-base">{`${t(
                      'onboarding:step',
                    )} ${currentStepNumber + 1}`}</p>
                  )}
                  <h1
                    className={mergeClasses(
                      'font-bold text-[24px] md:text-3xl lg:text-[40px] leading-[32px] lg:leading-[54px] mb-0',
                      isSubtitleAvailable ? 'mb-4' : '',
                    )}
                  >
                    {t(`onboarding:${currentStepName}.title`, {
                      firstName: firstNameValue,
                    })}
                  </h1>
                  {isSubtitleAvailable && (
                    <p className="3text-gray-500 text-[15px] leading-[22.5px] lg:text-lg mt-2 lg:mt-4">
                      {t(`onboarding:${currentStepName}.subtitle`)}
                    </p>
                  )}
                </div>
                <form
                  className={`md:max-w-container-xs ${
                    currentStepIndex === 14 && 'lg:max-w-container-md'
                  } w-full flex lg:block grow md:grow-0 flex-col lg:min-h-[652px] md:mb-12`}
                  onSubmit={(e) => handleNextStepClick(e)}
                >
                  <StepComponent
                    onNextClick={handleNextStepClick}
                    onPrevClick={handlePrevStepClick}
                    onSkipClick={handleSkipStepClick}
                    onToggleNextDisabled={handleToggleNextDisabled}
                  />
                  <button className="hidden" type="submit">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </FormProvider>
          {!hideFooter && (
            <OnboardingFormFooter
              isNextDisabled={isNextDisabled}
              isPrevDisabled={isFirstStep || uploadExpertCvLoading}
              isSkipShowed={typeof skipTo !== 'undefined'}
              onNextClick={handleNextStepClick}
              onPrevClick={handlePrevStepClick}
              onSkipClick={handleSkipStepClick}
            />
          )}
        </div>
      )}
    </div>
  );
};
