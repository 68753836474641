import { useEffect, useState } from 'react';
import useWindowSize from './useWindowSize';

const useMobile = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isTablet, setIsTablet] = useState<boolean>(false);
  const { width } = useWindowSize();

  useEffect(() => {
    setIsMobile(!!width && width < 768);
    setIsTablet(!!width && width < 1024);
  }, [width]);

  return { isMobile, isTablet };
};

export default useMobile;
