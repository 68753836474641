import dayjs from 'dayjs';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isPolishHoliday } from '../../utils/isPolishHoliday';
import { DayItem, MonthHeader, WeekdaysHeader } from './subcomponents';
import { CalendarDatePickerCaption } from './subcomponents/CalendarDatePickerCaption';
import { getAllCalendarDays } from './utils';

interface DatePickerProps {
  selectedDays: string[];
  onDayClick: (selectedDay: string) => void;
  onRemove: (date: string) => void;
  error?: boolean;
  isDatesLimitExceeded?: boolean;
}

export const CalendarDatePicker: FC<DatePickerProps> = ({
  selectedDays,
  onDayClick,
  onRemove,
  error,
  isDatesLimitExceeded,
}) => {
  const { t } = useTranslation();
  const [currentDate, setCurrentDate] = useState(dayjs().startOf('day').toISOString());

  const handleNextMountClick = () => {
    setCurrentDate(dayjs(currentDate).add(1, 'month').toISOString());
  };

  const handlePrevMountClick = () => {
    setCurrentDate(dayjs(currentDate).subtract(1, 'month').toISOString());
  };

  const { currentMonthDays, nextMonthDays, prevMonthDays } = useMemo(
    () => getAllCalendarDays(currentDate),
    [currentDate],
  );

  const today = dayjs().startOf('day').toISOString();

  const disableDays = selectedDays.length >= 5;

  return (
    <div className="w-full max-w-[343px] md:max-w-[437px] lg:w-full lg:max-w-[460px] lg:min-h-[512px]">
      <MonthHeader
        currentDate={currentDate}
        onNext={handleNextMountClick}
        onPrev={handlePrevMountClick}
      />
      <WeekdaysHeader />
      <div className="grid grid-cols-7 gap-[3px] md:gap-2.5 mb-2 md:mb-0">
        {prevMonthDays.map((date) => (
          <DayItem key={date} date={date} isDisabled onRemove={onRemove} />
        ))}
        {currentMonthDays.map((date) => {
          const isPast = dayjs(date).isBefore(dayjs().startOf('day'));
          const isToday = date === today;
          const isDisabled = isPast || isPolishHoliday(date) || isToday || disableDays;

          return (
            <DayItem
              key={date}
              date={date}
              isActive={selectedDays.includes(date)}
              isDatesLimitExceeded={isDatesLimitExceeded}
              isDisabled={isDisabled}
              onClick={onDayClick}
              onRemove={onRemove}
            />
          );
        })}
        {nextMonthDays.map((date) => (
          <DayItem key={date} date={date} isDisabled onRemove={onRemove} />
        ))}
      </div>
      {error && (
        <p className="text-[12px] md:text-[14px] leading-[18px] text-red-500 pt-1">
          {t('datePicker:noDaysPickedError')}
        </p>
      )}
      <CalendarDatePickerCaption />
    </div>
  );
};
