import { Button } from 'components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StepComponentProps } from '../stepsConfig';
import { CheckedListItem } from '../subcomponents';

export const PreferencesTitleStep: FC<StepComponentProps> = ({ onNextClick }) => {
  const { t } = useTranslation();

  return (
    <div className="flex md:block flex-col grow lg:justify-between">
      <ul className="mt-6 md:mt-10 space-y-4">
        <CheckedListItem label={t('onboarding:preferencesTitleStep.stepContent.desiredLocation')} />
        <CheckedListItem
          label={t('onboarding:preferencesTitleStep.stepContent.desiredTechnologies')}
        />
      </ul>
      <Button
        className="mt-12 lg:mt-16 md:w-full lg:w-auto"
        label={t('onboarding:preferencesTitleStep.nextButtonText')}
        onClick={onNextClick}
      />
    </div>
  );
};
