import { useEffect, useState } from 'react';
import { useRefSafeCallback } from './useRefSafeCallback';

export const useInterval = (callback: () => void, delay: number) => {
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);

  const start = () => {
    if (intervalId) return;
    const id = setInterval(callback, delay);
    setIntervalId(id);
  };
  const safeStart = useRefSafeCallback(start);

  const clear = () => {
    if (!intervalId) return;
    clearInterval(intervalId);
    setIntervalId(null);
  };
  const safeClear = useRefSafeCallback(clear);

  useEffect(() => {
    return safeClear;
  }, []);

  return {
    startInterval: safeStart,
    clearInterval: safeClear,
    intervalId,
  };
};
