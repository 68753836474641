import { PageLoadingSpinner, RoundedButton } from 'components';
import dayjs from 'dayjs';
import { useExpertProfile, useScrollLock, useSitemap } from 'hooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import successImgSrc from '../../assets/images/external-offer-success.svg';
import { usePublicOfferQuery } from '../../hooks/api/publicOffer/publicOffer.generated';

export const ExpertOfferSuccessPage = () => {
  const { t } = useTranslation();
  const sitemap = useSitemap();
  const navigate = useNavigate();
  const { lockPageScroll, unlockPageScroll } = useScrollLock();
  const { offerId } = useParams();

  const { profile } = useExpertProfile();
  const { isOnboardingFinished, createdAt } = profile ?? {};

  const fiveMinutesBeforeDate = dayjs().subtract(5, 'minutes');
  const isOldUser = dayjs(createdAt).isBefore(fiveMinutesBeforeDate);

  const { data: offerData, loading: offerLoading } = usePublicOfferQuery({
    variables: {
      offerId: offerId ?? '',
      shouldCreateEvent: false,
    },
  });

  const { jobOrder, jobOrderId } = offerData?.publicOffer ?? { jobOrderId: '' };
  const { isChubVisible } = jobOrder ?? { isChubVisible: false };

  const translationPath = [
    isOldUser ? 'oldUser' : 'newUser',
    isOnboardingFinished ? 'onboardingFinished' : 'onboardingNotFinished',
    isChubVisible ? 'offerActive' : 'offerInactive',
  ].join('.');

  useEffect(() => {
    lockPageScroll();
    return () => {
      unlockPageScroll();
    };
  }, []);

  if (offerLoading) {
    return <PageLoadingSpinner />;
  }

  return (
    <div className="fixed z-30 top-0 left-0 h-screen w-screen pt-32 md:pt-0 flex items-start md:items-center justify-center bg-white">
      <div className="flex items-center justify-center md:space-x-4 lg:space-x-[130px] px-4">
        <img alt="" className="hidden md:block" src={successImgSrc} />
        <div>
          <h3 className="text-xl lg:text-3xl font-bold mb-6">
            {t('offers:externalOffer.successPage.title')}
          </h3>
          <div className="mb-12 max-w-[544px] whitespace-pre-line space-y-2 md:space-y-4">
            <p>{t(`offers:externalOffer.successPage.description.${translationPath}.part1`)}</p>
            <p>{t(`offers:externalOffer.successPage.description.${translationPath}.part2`)}</p>
          </div>
          <RoundedButton
            className="sm:max-w-[310px]"
            label={t('common:actions.next')}
            onClick={() => navigate(sitemap.offer(jobOrderId, offerId))}
            variant="primary"
          />
        </div>
      </div>
    </div>
  );
};
