import { Button, SmallButton } from 'components';
import { ArrowIcon, ArrowRightIcon } from 'icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useMobile from '../../../hooks/useMobile';

interface OnboardingFormFooterProps {
  onNextClick: () => void;
  onPrevClick: () => void;
  onSkipClick: () => void;
  isPrevDisabled?: boolean;
  isNextDisabled?: boolean;
  isSkipShowed?: boolean;
}

export const OnboardingFormFooter: FC<OnboardingFormFooterProps> = ({
  onNextClick,
  onPrevClick,
  onSkipClick,
  isPrevDisabled = false,
  isNextDisabled = false,
  isSkipShowed = false,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useMobile();

  return (
    <div className="w-full fixed left-0 bottom-0 bg-white flex justify-center shadow-md-up px-4 pt-4 pb-[34px] lg:p-5 flex-wrap lg:flex-nowrap gap-6 lg:gap-0 z-50">
      <div className="max-w-container-md flex items-center justify-between w-full md:relative">
        <Button
          className="px-[44px] py-[20px] !h-12 md:!h-14 w-24"
          isDisabled={isPrevDisabled}
          label={isMobile ? '' : t('common:actions.back')}
          leftIcon={isMobile ? ArrowIcon : undefined}
          leftIconClassName="w-4 h-4"
          onClick={onPrevClick}
          variant="outline"
        />
        <div className="flex space-x-8 flex-col-reverse lg:flex-row order-3 md:order-2">
          <Button
            className="px-[44px] py-[20px] !h-12 md:!h-14 w-24"
            isDisabled={isNextDisabled}
            label={isMobile ? '' : t('common:actions.next')}
            onClick={onNextClick}
            rightIcon={isMobile ? ArrowRightIcon : undefined}
            rightIconClassName="w-4 h-4"
          />
        </div>
        {isSkipShowed && (
          <SmallButton
            className="sm:justify-center md:absolute md:right-[160px]"
            label={t('common:actions.skip')}
            onClick={onSkipClick}
            variant="black"
          />
        )}
      </div>
    </div>
  );
};
