import { IconType } from 'icons';
import { FC } from 'react';

export interface JobBoardOfferIconDetailProps {
  icon: IconType;
  label: string;
}

export const JobBoardOfferIconDetail: FC<JobBoardOfferIconDetailProps> = ({
  label,
  icon: Icon,
}) => {
  return (
    <li className="flex items-center space-x-2">
      <Icon className="w-4 h-4 text-gray-350" />
      <span className="font-medium">{label}</span>
    </li>
  );
};
