import { CheckIcon, CrossIcon } from 'icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeClasses } from 'utils';

enum PasswordRequirementsEnum {
  MinLength = 'minLength',
  AtLeastOneBigLetter = 'atLeastOneBigLetter',
  AtLeastOneSmallLetter = 'atLeastOneSmallLetter',
  AtLeastOneNumber = 'atLeastOneNumber',
  AtLeastOneSpecial = 'atLeastOneSpecial',
}

interface Requirement {
  name: PasswordRequirementsEnum;
  validationFn: (value: string) => boolean;
}

const requirements: Requirement[] = [
  {
    name: PasswordRequirementsEnum.MinLength,
    validationFn: (value) => value?.length > 7,
  },
  {
    name: PasswordRequirementsEnum.AtLeastOneBigLetter,
    validationFn: (value) => /(?=.*[A-Z])/.test(value),
  },
  {
    name: PasswordRequirementsEnum.AtLeastOneSmallLetter,
    validationFn: (value) => /(?=.*[a-z])/.test(value),
  },
  {
    name: PasswordRequirementsEnum.AtLeastOneNumber,
    validationFn: (value) => /(?=.*[0-9])/.test(value),
  },
  {
    name: PasswordRequirementsEnum.AtLeastOneSpecial,
    validationFn: (value) => /(?=.*[$&+,:;=?@#|'<>.^*()%!-])/.test(value),
  },
];

interface PasswordRequirementsListProps {
  password: string;
  isActive: boolean;
}

export const PasswordRequirementsList: FC<PasswordRequirementsListProps> = ({
  password,
  isActive,
}) => {
  const { t } = useTranslation();

  return (
    <ul
      className={mergeClasses('transition-all overflow-hidden', isActive ? 'max-h-40' : 'max-h-0')}
    >
      <p className="text-gray-500">{t(`auth:signUpForm.passwordRequirements.title`)}</p>
      {requirements.map(({ name, validationFn }) => {
        const isFulfilled = validationFn(password);
        return (
          <li key={name} className="flex items-center space-x-2 text-gray-500">
            {isFulfilled ? (
              <CheckIcon className="w-4 h-4 text-green-500" />
            ) : (
              <CrossIcon className="w-4 h-4 text-red-500" />
            )}
            <span>{t(`auth:signUpForm.passwordRequirements.${name}`)}</span>
          </li>
        );
      })}
    </ul>
  );
};
