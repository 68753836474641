import { ProfileChubId } from '../../ProfileChubId';
import { BasicInfo, ContactData, CvInfo, Languages, Skills } from './papers';

export const ProfileTab = () => {
  return (
    <div className="space-y-4 lg:space-y-6">
      <ProfileChubId className="lg:hidden !mb-4" />
      <BasicInfo />
      <CvInfo />
      <Skills />
      <Languages />
      <ContactData />
    </div>
  );
};
