import { ContainerWithSidebox } from 'layouts';
import { useTranslation } from 'react-i18next';
import { AuthGenericForm } from '../../components/Auth/forms/AuthGenericForm';

export const SignUpPage = () => {
  const { t } = useTranslation();

  return (
    <ContainerWithSidebox title={t('pageTitles:signUp')}>
      <AuthGenericForm mode="signUp" />
    </ContainerWithSidebox>
  );
};
