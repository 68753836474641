import { useScrollLock } from 'hooks';
import { FC, ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';

interface FullPageModalProps {
  isOpen: boolean;
  children?: ReactNode;
}

const modalRoot = document.getElementById('modal-root');
export const FullPageModal: FC<FullPageModalProps> = ({ isOpen, children }) => {
  const { unlockPageScroll, lockPageScroll } = useScrollLock();

  useEffect(() => {
    const openedModals = modalRoot?.childNodes.length ?? 0;
    if (isOpen) {
      lockPageScroll();
    } else if (openedModals === 0) {
      unlockPageScroll();
    }
  }, [isOpen, modalRoot]);

  return isOpen && modalRoot
    ? createPortal(
        <div className="fixed top-0 left-0 z-40 flex flex-col justify-between w-screen h-screen p-6 bg-white md:justify-center">
          {children}
        </div>,
        modalRoot,
      )
    : null;
};
