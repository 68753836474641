import { useReactiveVar } from '@apollo/client';
import chubIdBackground from 'assets/images/chubid-background.svg';
import { Button, Paper, SmallButton } from 'components';
import { useExpertAvatar, useModalState } from 'hooks';
import { ArrowIcon } from 'icons';
import { ExpertAvatarEnum } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { profileVar } from 'reactive-vars';
import { mergeClasses } from 'utils';
import {
  basicInfoFields,
  calculateProfileCompletionPercentage,
  experienceFields,
  preferencesFields,
} from '../../utils';
import { ChubIdGenerateModal } from '../modals';
import { ProfileCompletionList } from './subcomponents';
import { isAllFieldsFilled } from './subcomponents/ProfileCompletionList';

interface ProfileChubIdProps {
  className?: string;
}

export const ProfileChubId = ({ className }: ProfileChubIdProps) => {
  const { t } = useTranslation();
  const { openModal, modalState, closeModal } = useModalState<undefined>();
  const { changeExpertAvatar, avatarChangeLoading } = useExpertAvatar();

  const profile = useReactiveVar(profileVar);
  const {
    fileUrls: { wordCloudUrl },
    avatar,
    expertId,
  } = profile ?? { fileUrls: {} };
  const isWordCloudGenerated = !!wordCloudUrl;

  const { filledPercent, unfilledFields } = calculateProfileCompletionPercentage(profile);

  const isGeneratingDisabled = !isAllFieldsFilled(
    [...basicInfoFields, ...preferencesFields, ...experienceFields],
    unfilledFields,
  );
  const isWordCloudAsAvatar = avatar === ExpertAvatarEnum.WordCloud;

  const handleSetWordCloudAsAvatar = async () => {
    if (!expertId) return;
    await changeExpertAvatar({
      expertId,
      avatar: ExpertAvatarEnum.WordCloud,
    });
  };

  return (
    <>
      <ChubIdGenerateModal isOpen={modalState.isOpen} onClose={closeModal} />
      <Paper
        className={mergeClasses(
          'row-start-1 row-end-2 lg:col-start-2 lg:col-end-3 w-screen md:w-auto !p-4 xl:!p-8',
          className,
        )}
        subtitleClassName="!mb-4 lg:!mb-6"
        title={t('profile:papers.chubId.title') ?? ''}
        titleClassName="text-base lg:text-lg !mb-4 lg:!mb-6"
      >
        <div className="flex flex-col items-center">
          {!wordCloudUrl && (
            <ProfileCompletionList
              completionPercent={filledPercent}
              unfilledFields={unfilledFields}
            />
          )}
          <div
            className={mergeClasses(
              'flex items-center justify-center h-[276px] w-full rounded-lg bg-primary-700 bg-cover bg-no-repeat bg-center transition-all ',
              isGeneratingDisabled ? 'opacity-10' : '',
              isWordCloudGenerated ? 'cursor-pointer hover:opacity-80' : '',
            )}
            onClick={() => isWordCloudGenerated && openModal(undefined)}
            style={{
              backgroundImage: `url(${wordCloudUrl || chubIdBackground})`,
            }}
          >
            {!isWordCloudGenerated && (
              <Button
                className={mergeClasses(
                  'transition-all py-2  px-4 rounded-[48px] lg:rounded-lg',
                  isGeneratingDisabled ? 'opacity-0 pointer-events-none' : '',
                )}
                label={t('profile:papers.chubId.generate')}
                onClick={() => openModal(undefined)}
                size="sm"
                variant="white"
              />
            )}
          </div>
          {isWordCloudGenerated && !isWordCloudAsAvatar && (
            <SmallButton
              className="text-xs mt-4"
              icon={ArrowIcon}
              iconClassName="rotate-[135deg]"
              isDisabled={avatarChangeLoading}
              label={t('profile:papers.chubId.setAsAvatar')}
              onClick={handleSetWordCloudAsAvatar}
              variant="primary"
            />
          )}
        </div>
      </Paper>
    </>
  );
};
