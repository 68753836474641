import { InfoBox, NumberInput } from 'components';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface SalaryStepValues {
  rateDesired: number;
}

export const SalaryStep = () => {
  const { t } = useTranslation();
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<SalaryStepValues>();

  const rateDesiredValue = watch('rateDesired');

  return (
    <div className="flex justify-center flex-col">
      <InfoBox className="mt-8 md:mt-4" text={t('onboarding:salaryStep.onlyB2bText')} />
      <div className="mt-8 md:mt-14 flex justify-start">
        <NumberInput
          className="w-full mr-auto"
          currency="PLN/h"
          error={errors.rateDesired}
          min={0}
          name="rateDesired"
          onChange={(value) =>
            setValue('rateDesired', value, {
              shouldValidate: true,
            })
          }
          placeholder="0"
          value={rateDesiredValue}
        />
      </div>
    </div>
  );
};
