import * as Types from '../../../interfaces/api.types.generated.d';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
const defaultOptions = {} as const;
export type ExpertNotificationsQueryVariables = Types.Exact<{
  sort?: Types.InputMaybe<Types.ExpertNotificationSortEnum>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type ExpertNotificationsQuery = {
  __typename?: 'Query';
  expertNotifications: {
    __typename?: 'ExpertNotificationsQueryResultType';
    notifications: Array<{
      __typename?: 'ExpertNotificationType';
      id: string;
      type: Types.ExpertNotificationTypeEnum;
      expertId: string;
      metadata?: string | null;
      createdAt: string;
      viewedAt?: string | null;
      relatesTo: {
        __typename?: 'ExpertNotificationRelationsType';
        jobOrderId?: string | null;
        jobOrderApplicationId?: string | null;
      };
    }>;
  };
};

export const ExpertNotificationsDocument = gql`
  query expertNotifications($sort: ExpertNotificationSortEnum, $limit: Int) {
    expertNotifications(sort: $sort, limit: $limit) {
      notifications {
        id
        type
        expertId
        relatesTo {
          jobOrderId
          jobOrderApplicationId
        }
        metadata
        createdAt
        viewedAt
      }
    }
  }
`;

/**
 * __useExpertNotificationsQuery__
 *
 * To run a query within a React component, call `useExpertNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpertNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpertNotificationsQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useExpertNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ExpertNotificationsQuery,
    ExpertNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExpertNotificationsQuery, ExpertNotificationsQueryVariables>(
    ExpertNotificationsDocument,
    options,
  );
}
export function useExpertNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExpertNotificationsQuery,
    ExpertNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExpertNotificationsQuery, ExpertNotificationsQueryVariables>(
    ExpertNotificationsDocument,
    options,
  );
}
export type ExpertNotificationsQueryHookResult = ReturnType<typeof useExpertNotificationsQuery>;
export type ExpertNotificationsLazyQueryHookResult = ReturnType<
  typeof useExpertNotificationsLazyQuery
>;
export type ExpertNotificationsQueryResult = Apollo.QueryResult<
  ExpertNotificationsQuery,
  ExpertNotificationsQueryVariables
>;
export function refetchExpertNotificationsQuery(variables?: ExpertNotificationsQueryVariables) {
  return { query: ExpertNotificationsDocument, variables: variables };
}
