import { EffectCallback, useEffect, useRef } from 'react';
import { useRefSafeCallback } from './useRefSafeCallback';

export const useEffectOnce = (effect: EffectCallback, when = true) => {
  const hasRunOnceRef = useRef(false);
  const callbackRef = useRefSafeCallback(effect);

  useEffect(() => {
    if (when && !hasRunOnceRef.current) {
      callbackRef();
      hasRunOnceRef.current = true;
    }
  }, [when]);
  return useEffectOnce;
};
